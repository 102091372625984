<template>
  <div
    class="container-no-data-yet d-flex justify-content-center custom-badge badge-blue"
  >
    <div
      class="no-address bg-transparent border-0 d-flex justify-content-center align-items-center"
    >
      {{ text }}
    </div>
  </div>
</template>

<script lang="ts">
export default {
  props: {
    text: {
      type: String,
      default: "Aún no has agregado información",
    },
  },
};
</script>
