export interface IOfferMonthDetail {
  offer_id: string;
  months: number;
  minimun_amount: number;
  interest_percent: number;
  discount_percent: number;
  month_price: number;
  total: number;
}

export default class OfferMonthDetail implements IOfferMonthDetail {
  public offer_id: string;
  public months: number;
  public minimun_amount: number;
  public interest_percent: number;
  public discount_percent: number;
  public month_price: number;
  public total: number;

  public constructor({
    offer_id,
    months,
    minimun_amount,
    interest_percent,
    discount_percent,
    month_price,
    total,
  }: IOfferMonthDetail) {
    this.offer_id = offer_id;
    this.months = months;
    this.minimun_amount = minimun_amount;
    this.interest_percent = interest_percent;
    this.discount_percent = discount_percent;
    this.month_price = month_price;
    this.total = total;
  }

  public static fromArray(items: IOfferMonthDetail[]): OfferMonthDetail[] {
    return items.map((item: IOfferMonthDetail) => new OfferMonthDetail(item));
  }
}
