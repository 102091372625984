<template lang="">
  <a
    class="clear-cart text-decoration-none pointer clear-cart-sm"
    :class="{ 'pe-none text-opacity-10': disabled }"
    @click="removeItem"
  >
    {{ linkText }}
  </a>
</template>

<script lang="ts">
export default {
  props: {
    productId: {
      validator: function (value) {
        return typeof value === "string" || typeof value === "number";
      },
      required: true,
    },
    linkText: {
      type: String,
      default: "Remove Item",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["remove-item"],
  methods: {
    removeItem() {
      if (!this.disabled) {
        this.$emit("remove-item", this.productId);
      }
    },
  },
};
</script>
