import axios, { AxiosRequestConfig } from "axios";

import { VITE_API_ENDPOINT } from "../config/constants.js";

export class CompanyService {
  async privacy() {
    const response = await axios.get("eshop/privacy");
    if (response) {
      return response.data;
    }
  }

  async faq() {
    const response = await axios.get("eshop/faq");
    if (response) {
      return response.data;
    }
  }

  async paymentMethods() {
    const response = await axios.get("eshop/company-payment-methods");
    if (response) {
      return response.data;
    }
  }

  async legal() {
    const response = await axios.get("eshop/legal");
    if (response) {
      return response.data;
    }
  }

  async terms() {
    const response = await axios.get("eshop/terms");
    if (response) {
      return response.data;
    }
  }

  async warrantys() {
    const response = await axios.get("eshop/warrantys");
    if (response) {
      return response.data;
    }
  }

  async returnPolicy() {
    const response = await axios.get("eshop/return-policy");
    if (response) {
      return response.data;
    }
  }

  async stores() {
    try {
      const options: AxiosRequestConfig = {
        baseURL: VITE_API_ENDPOINT,
        headers: {
          Accept: "application/vnd.api+json",
        },
      };
      const url = `/shop-v1/stores?filter[branches]=true`;
      const response = await axios.get(url, options);

      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response) {
          console.error("Error con respuesta de la API:", error.response);
          return error.response.data;
        }
        console.error("Error de red o sin respuesta:", error.message);
      }
      console.error("Error inesperado:", error);
    }
  }
}

export const companyService = new CompanyService();
