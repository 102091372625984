<template>
  <div class="container-fluid px-0 px-lg-1 text-center mb-4 placeholder-glow">
    <div class="row gx-0">
      <div class="col-6 ps-0">
        <span class="placeholder w-100 py-3 bg-body-secondary px-2"></span>
      </div>
      <div class="col-6">
        <div class="ps-2">
          <span class="placeholder w-100 py-3 bg-body-secondary"></span>
        </div>
      </div>
      <div class="col-12 mt-2">
        <span
          class="placeholder w-100 py-5 bg-body-secondary"
          style="height: 230px"
        ></span>
      </div>
    </div>
  </div>
</template>
