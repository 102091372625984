import { defineRule } from "vee-validate";

defineRule(
  "required",
  (
    value: number | string,
    { fieldName }: { fieldName: string; min: number },
  ): boolean | string => {
    const newValue = value + "";
    if (!newValue || !newValue.length || value == null) {
      return fieldName
        ? `El campo ${fieldName} es requerido.`
        : "El campo es requerido";
    }
    return true;
  },
);

defineRule("email", (value: number | string) => {
  const newValue = value + "";
  if (!newValue || !newValue.length || value == null) {
    return true;
  }
  // Check if email
  if (!/[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}/.test(value as string)) {
    return "El campo debe ser un email válido.";
  }
  return true;
});

defineRule(
  "min",
  (
    value: number | string,
    { fieldName, min }: { fieldName: string; min: number },
  ): boolean | string => {
    const newValue = value + "";

    if (newValue.length < min) {
      return `El campo ${fieldName} debe tener al menos ${min} caracteres.`;
    }
    return true;
  },
);

defineRule("person_name", (value: string) => {
  const newValue = value + "";
  if (!newValue || !newValue.length || value == null) {
    return true;
  }
  // Check if person name
  if (
    !/^[a-zA-ZÀÈÌÒÙÁÉÍÓÚáéíóúàèìòùñÑ.\s]{3,80}$/.test(
      value.toLowerCase() as string,
    )
  ) {
    return "El campo debe ser un nombre válido de mínimo 3 caracteres.";
  }
  return true;
});

defineRule("phone", (value: string) => {
  const newValue = value + "";
  if (!newValue || !newValue.length || value == null) {
    return true;
  }
  // Check if person name
  if (!/[0-9]{10}|[0-9]{2} [0-9]{4} [0-9]{4}/.test(value as string)) {
    return "El campo debe ser un teléfono válido.";
  }
  return true;
});
