import { auth } from "./modules/auth";
import { catalogs } from "./modules/catalogs";
import { translations } from "./modules/translations";
import { cart, defaultCartInit } from "./modules/cart";
import { session } from "./modules/session";
import { assembly } from "./modules/assembly";
import { checkout, defaultCheckoutInit } from "./modules/checkout";
import { cfdi } from "./modules/cfdi";

import {
  createStore,
  createLogger,
  useStore as baseUseStore,
  Store,
} from "vuex";
import { InjectionKey } from "vue";
import { State } from "@/interfaces/State";

const debug = false; //TODO: Cambié a false mientras tanto que no se trabaja con esto para no ver todos los mensajes que envía process.env.NODE_ENV !== 'production' Cambai

export const key: InjectionKey<Store<State>> = Symbol();

const persistState = (store: any) => {
  const persistedState: any = localStorage.getItem("vuex");
  const oldState = JSON.parse(persistedState);
  if (persistedState) {
    oldState.catalogs = {
      categories: {
        links: [],
        data: [],
        included: [],
        meta: [],
      },
      brands: [],
      banners: {
        links: [],
        data: [],
        included: [],
        meta: [],
      },
    };
    oldState.translations = {
      sections: [],
      loading: [],
    };
    oldState.token = null;
  }
  if (oldState && oldState.checkout?.version !== defaultCheckoutInit?.version) {
    oldState.checkout = defaultCheckoutInit;
  }
  if (
    oldState &&
    (oldState.cart?.version !== defaultCartInit?.version ||
      !oldState.cart?.version)
  ) {
    oldState.cart = defaultCartInit;
  }
  if (persistedState) {
    store.replaceState(oldState);
  }
  // called when the store is initialized
  store.subscribe((_mutation: any, state: any) => {
    localStorage.setItem("vuex", JSON.stringify(state));
  });
};

export const store = createStore<State>({
  modules: {
    auth,
    catalogs,
    translations,
    cart,
    session,
    assembly,
    checkout,
    cfdi,
  },

  state: {
    theme: "light",
    canCloseMenu: false,
    pageTransition: {
      name: "router-view",
      mode: "in-out",
    },
    // auth: null,
    // catalogs: null,
  } as State,
  getters: {
    getTheme(state) {
      return state.theme;
    },
    getCanCloseMenu(state) {
      return state.canCloseMenu;
    },
    getImageNotAvailable() {
      return "../images/imagen-no-disponible.png";
    },
  },

  mutations: {
    setPageTransition(state, value) {
      if ("default" === value) {
        state.pageTransition = {
          name: "router-view",
          mode: "in-out",
        };
      }
      if ("back" === value) {
        state.pageTransition = {
          name: "router-view-back",
          mode: "",
        };
      }
    },
    toggleTheme(state) {
      state.theme = state.theme == "light" ? "dark" : "light";
    },
    setCanCloseMenu(state, canCloseMenu: boolean) {
      state.canCloseMenu = canCloseMenu;
    },
  },

  strict: debug,
  plugins: debug ? [createLogger(), persistState] : [persistState],
});

export function useStore() {
  return baseUseStore(key);
}
