<template>
  <div class="row gx-2 mt-4 mt-lg-5">
    <fieldset class="d-flex flex-column">
      <legend class="col-12 col-lg-9 d-flex p-3 rounded-2 align-items-center">
        <span class="me-1">WhatsApp</span>
        <font-awesome-icon icon="fa-brands fa-whatsapp" />
      </legend>
      <div class="container mt-3 mb-3">
        <ul class="list-group list-group-horizontal px-lg-5">
          <li class="list-group-item d-flex align-items-center">
            Número de WhatsApp
          </li>
          <li class="list-group-item d-flex align-items-center py-md-3">
            <a
              href="https://wa.me/523341605433"
              target="_blank"
              class="ms-1 text-decoration-none d-flex flex-column flex-md-row align-items-center"
              title="Se abrirá en otra pestaña"
            >
              <i class="bi bi-arrow-up-right border-0 h4 m-0"></i>
              <span class="ms-1 fw-semibold phone">+52 334 160 5433</span>
            </a>
          </li>
        </ul>
      </div>
    </fieldset>
    <fieldset class="d-flex flex-column mt-5">
      <legend
        class="col-12 col-lg-9 d-flex justify-content-start p-3 rounded-2"
      >
        <span class="ms-lg-2">Contáctanos</span>
      </legend>
      <div
        class="mt-lg-3 mb-4 form-contact border border-light py-4 px-4 rounded-3"
      >
        <VeeForm :validation-schema="contactSchema" @submit="onContact">
          <div class="row mb-3 mb-lg-4">
            <div class="col d-flex flex-column">
              <label class="text-start d-lg-none" for="name">Nombre</label>
              <label for="name" class="input-contact d-flex align-items-center">
                <div class="box-input-card" />
                <span class="mx-2 d-none d-lg-block">Nombre</span>
                <Field id="name" v-model="name" type="text" name="name" />
              </label>
              <ErrorMessage name="name" class="error text-start" />
            </div>
          </div>
          <div class="row mb-3 mb-lg-4">
            <div class="col-12 col-md d-flex flex-column mb-3 mb-lg-0">
              <label class="text-start d-lg-none" for="subject">Asunto</label>
              <label
                for="subject"
                class="input-contact d-flex align-items-center"
              >
                <div class="box-input-card" />
                <span class="mx-2 d-none d-lg-block">Asunto</span>
                <select id="subject" name="" class="border-0">
                  <option
                    value=""
                    selected
                    disabled
                    class="text-danger"
                  ></option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                </select>
              </label>
            </div>
            <div class="col-12 col-md d-flex flex-column">
              <label class="text-start d-lg-none" for="store"
                >Sucursal (si aplica)</label
              >
              <label
                for="store"
                class="input-contact d-flex align-items-center"
              >
                <div class="box-input-card" />
                <span class="mx-2 d-none d-lg-block"
                  >Sucursal
                  <small class="d-block if-apply">(si aplica)</small></span
                >
                <select id="store" name="" class="border-0 border-left-0">
                  <option value="" selected disabled class="text-danger">
                    Falta enviarlos desde el backend
                  </option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                </select>
              </label>
            </div>
          </div>

          <div class="row mb-3 mb-lg-4">
            <div class="col-12 col-lg-7 d-flex flex-column mb-3 mb-lg-0">
              <label class="text-start d-lg-none" for="email"
                >Correo electrónico</label
              >
              <label
                for="email"
                class="input-contact d-flex align-items-center"
              >
                <div class="box-input-card" />
                <span class="mx-2 col-auto d-none d-lg-block"
                  >Correo electrónico</span
                >
                <Field id="email" v-model="email" type="email" name="email" />
              </label>
              <ErrorMessage name="email" class="error text-start" />
            </div>
            <div class="col col-lg-5 d-flex flex-column">
              <label class="text-start d-lg-none" for="phone">Teléfono</label>

              <label
                for="phone"
                class="input-contact d-flex align-items-center"
              >
                <div class="box-input-card" />
                <span class="mx-2 d-none d-lg-block">Teléfono</span>
                <Field id="phone" v-model="phone" type="number" name="phone" />
              </label>
              <ErrorMessage name="phone" class="error text-start" />
            </div>
          </div>
          <div class="row mb-0 mb-md-3">
            <div class="col d-flex flex-column">
              <div class="d-flex align-items-center mb-2">
                <div class="box-input-card" />
                <label for="comments" class="text-start ms-2"
                  >Comentarios</label
                >
              </div>
              <Field
                id="comments"
                v-model="comments"
                as="textarea"
                name="comments"
                rows="6"
                class="input-contact"
              />
              <ErrorMessage name="comments" class="error text-start" />
            </div>
          </div>
          <div
            class="form-row d-flex flex-column flex-md-row align-items-center"
          >
            <div class="col-md-6 order-md-2">
              <div class="alert alert-warning rounded-5 mt-3 px-3 py-2 ms-md-2">
                Revisa que todos los campos sean validos para continuar.
              </div>
            </div>
            <div class="col-md-auto mt-md-0 d-flex">
              <button type="reset" class="btn-reset me-2">Limpiar</button>
              <button class="btn-send order-md-1">Enviar mensaje</button>
            </div>
          </div>
        </VeeForm>
      </div>
    </fieldset>
  </div>
</template>

<script lang="ts">
import { Form as VeeForm, Field, ErrorMessage } from "vee-validate";
import TabTitle from "@/interfaces/TabTitle";

export default {
  components: {
    ErrorMessage,
    Field,
    VeeForm,
  },

  setup() {
    const contactSchema = {
      name(value: string) {
        if (!value || !value.length || value.length < 2) {
          return "Tu nombre es necesario";
        } else {
          return true;
        }
      },
      email(value: string) {
        const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
        if (!value) {
          return "Tu correo elctrónico es necesario";
        } else if (!regex.test(value)) {
          return "Ingresa un correo válido";
        } else {
          return true;
        }
      },
      phone(value: string) {
        if (!value) {
          return "Tu número de teléfono es necesario";
        }
        const regex = /^\d{10}$/;
        if (!regex.test(value)) {
          return "Ingresa un número de teléfono válido";
        }
        return true;
      },

      comments(value: string) {
        if (!value || !value.length || value.length < 10) {
          return "Tu mensaje es necesario";
        } else {
          return true;
        }
      },
    };

    return {
      contactSchema,
    };
  },

  data: function () {
    TabTitle("Contáctanos");
    return {
      name: "",
      email: "",
      phone: "",
      comments: "",
    };
  },

  mounted() {
    window.scroll(0, 0);
  },

  methods: {
    async onContact() {
      try {
        console.log("Enviar formulario");
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>
