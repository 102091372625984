<template lang="">
  <base-link
    :external="external"
    :to="to"
    class="p-1 link-underline link-underline-opacity-0"
  >
    <i v-if="icon" class="fs-5 bi" :class="icon" />

    <component
      :is="dynamicComponent"
      v-if="component"
      :height="21"
      :width="21"
    />
  </base-link>
</template>
<script lang="ts">
import { defineAsyncComponent } from "vue";
import BaseLink from "./BaseLink.vue";
export default {
  components: {
    BaseLink,
  },
  props: {
    icon: {
      type: String,
      default: "",
    },
    component: {
      type: String,
      default: "",
    },
    to: {
      type: String,
      default: "/",
    },
    external: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    dynamicComponent() {
      if (!this.component) return null;
      return defineAsyncComponent(
        () => import(`../icons/${this.component}.vue`),
      );
    },
  },
};
</script>
<style scoped lang="sass"></style>
