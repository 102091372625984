import axios from "axios";
import Model from "./Model";
import Jsona from "jsona";

export interface ITransactionChatMessage {
  id: string | null;
  message: string | null;
  sender_name: string | null;
  created_at_time: string | null;
  is_self: boolean | null;
  is_seen: boolean | null;
}

export default class TransactionChatMessage
  extends Model
  implements ITransactionChatMessage
{
  public id: string | null = null;
  public message: string | null = null;
  public sender_name: string | null;
  public created_at_time: string | null;
  public is_self: boolean | null;
  public is_seen: boolean | null;

  public constructor({
    id,
    message,
    sender_name,
    created_at_time,
    is_self,
    is_seen,
  }: ITransactionChatMessage) {
    super();

    this.id = id || null;
    this.message = message || null;
    this.sender_name = sender_name || null;
    this.created_at_time = created_at_time || null;
    this.is_self = is_self || null;
    this.is_seen = is_seen || null;
  }

  public static async fetchByTransaction(
    transactiontId: string,
    chatId: string = "0",
  ) {
    const path = `/shop-v1/transaction-chat-messages`;
    const query: Record<string, string> = {
      transaction: transactiontId,
      "[fields]": "transaction,sender",
      "after-offset-id[offsetId]": chatId,
    };
    const queryStr = new URLSearchParams(query).toString();
    const url = path + "?" + queryStr;
    const res = await axios.get(url);
    if (res.status != 200) {
      throw res;
    }
    const jsona = new Jsona();
    const data = jsona.deserialize(res.data);
    const instance = TransactionChatMessage.fromArray(
      data as ITransactionChatMessage[],
    );
    return instance;
  }

  public static async sendMessage(
    transactiontId: string,
    message: string,
  ): Promise<ITransactionChatMessage> {
    const path = `/shop-v1/transaction-chat-messages`;
    const query: Record<string, string> = {
      transaction: transactiontId,
    };
    const body = {
      data: {
        type: "transaction-chat-messages",
        attributes: {
          message: message,
        },
        relationships: {
          transaction: {
            data: {
              type: "transactions",
              id: transactiontId,
            },
          },
        },
      },
    };
    const queryStr = new URLSearchParams(query).toString();
    const url = path + "?" + queryStr;
    const response = await axios.post(url, body, {
      headers: {
        Accept: "application/vnd.api+json",
        "Content-Type": "application/vnd.api+json",
      },
    });
    if (response.status != 201) {
      throw response;
    }

    const jsona = new Jsona();
    const data = jsona.deserialize(response.data);

    const instance = new TransactionChatMessage(
      data as ITransactionChatMessage,
    );
    return instance;
  }

  public static fromArray(
    items: ITransactionChatMessage[],
  ): TransactionChatMessage[] {
    return items.map(
      (item: ITransactionChatMessage) => new TransactionChatMessage(item),
    );
  }
}
