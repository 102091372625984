<template lang="">
  <nav class="navbar fixed-top bg-danger d-block d-lg-none p-0 print vw-100">
    <!-- <link-icon 
        class="col text-white gap-3"
        component="CartSvg" 
        :to="'/busqueda-por-categoria'"
    /> -->
    <div class="position-relative p-0">
      <div
        class="height-mobile-menu d-flex justify-content-between align-items-center"
      >
        <div class="col-1">
          <router-link class="btn btn-link" :to="{ name: 'search_categories' }">
            <i class="bi bi-list text-white fs-1" />
          </router-link>
        </div>
        <div class="col-4 text-white text-end">
          <logo-digitalife height="20" width="160" />
        </div>
        <div
          class="col-6 d-flex row-cols-4 justify-content-end align-items-center"
        >
          <div
            v-if="!isUserAuthenticated"
            class="col col-lg-6 gap-3 d-flex justify-content-center"
          >
            <base-link class="text-white" :to="'/login'">
              <i class="bi bi-person-circle d-lg-none fs-2"></i>
            </base-link>
          </div>
          <div
            v-if="isUserAuthenticated"
            class="col col-lg-6 gap-3 d-flex justify-content-center"
          >
            <drop-down-menu-profile />
          </div>
          <div class="col col-lg-6 gap-3 d-flex justify-content-center">
            <assemble-your-pc-link />
          </div>

          <link-icon
            class="col text-white gap-3 d-flex justify-content-center"
            icon="bi-search"
            :to="'#'"
            @click="isOpen = !isOpen"
          />
          <link-icon
            class="col text-white gap-3 d-flex justify-content-center"
            component="CartSvg"
            :to-object="{ name: 'cart-detail' }"
          />
        </div>
      </div>
      <base-transition>
        <div
          v-if="isOpen"
          class="container-fluid height-mobile-menu bg-danger position-absolute top-100 start-0 rounded-bottom-5 rounded-top-0 pt-2 mt-n1"
        >
          <suggestion-input type="mobile" @close="isOpen = false" />
        </div>
      </base-transition>
    </div>
  </nav>
</template>
<script lang="ts">
import LinkIcon from "./../buttons/LinkIcon.vue";
import BaseTransition from "@/components/common/BaseTransition.vue";
import LogoDigitalife from "./../icons/LogoDigitalife.vue";
import SuggestionInput from "../navigation/SuggestionInput.vue";
import { getLinkSelfTranslate } from "../../utils/general";
import { CartService } from "@/services/CartService";
import AssembleYourPcLink from "./Menu/AssembleYourPcLink.vue";
import DropDownMenuProfile from "@/components/auth/DropDownMenuProfile.vue";
import BaseLink from "@/components/buttons/BaseLink.vue";
import auth from "@/services/AuthService";
import eventBus from "@/event-bus";
import mainSearch from "@/services/MainSearchService";

export default {
  components: {
    BaseTransition,
    SuggestionInput,
    LogoDigitalife,
    LinkIcon,
    AssembleYourPcLink,
    DropDownMenuProfile,
    BaseLink,
  },
  data: function () {
    return {
      searchResponse: mainSearch.reactiveResponse,
      isOpen: false,
      cartService: null as CartService | null,
    };
  },
  computed: {
    isUserAuthenticated() {
      return auth.isUserLogged();
    },
  },
  watch: {
    searchResponse() {
      if (!this.isOpen) {
        this.isOpen = !!mainSearch.reactiveResponse.value?.meta.searchText;
      }
    },
  },
  created() {
    eventBus.on("show-input-search", (isOpen) => {
      this.isOpen = isOpen as boolean;
    });
  },
  mounted() {
    this.cartService = new CartService();
    this.cartService.getCartInitData();
  },
  beforeUnmount() {
    eventBus.off("show-input-search");
  },
  methods: {
    getLink(section: string, name: string) {
      return getLinkSelfTranslate(section, name);
    },
  },
};
</script>
<style lang="scss" scoped>
.input-group input:focus {
  box-shadow: unset !important;
}

@media print {
  body navbar.print {
    background-color: red;
    display: none;
  }
}
</style>
