<script lang="ts" setup>
import { onMounted, ref, computed } from "vue";
import { store } from "@/store";
import {
  VITE_API_ENDPOINT,
  VITE_PAYPAL_CLIENT_ID,
  VITE_PAYPAL_FRAUDNET_ID,
  VITE_PAYPAL_SANDBOX,
} from "@/config/constants";
import { loadScript, PayPalNamespace, OnApproveData } from "@paypal/paypal-js";
import { loadScript as loadScriptGeneral } from "@/utils/general";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import eventBus from "@/event-bus";
import { CustomNotifyService } from "@/services/CustomNotifyService";

const notifySrv = new CustomNotifyService();

const trackingId = ref("");
const tokenId = ref("");
const loading = ref(true);

interface ExtendedOnApproveData extends OnApproveData {
  vaultSetupToken?: string;
}

const isActiveToken = computed(() => {
  return store.getters["checkout/isActiveToken"];
});

function loadScriptPaypal() {
  const scriptFncls = document.createElement("script");
  scriptFncls.type = "application/json";
  scriptFncls.setAttribute(
    "fncls",
    "fnparams-dede7cc5-15fd-4c75-a9f4-36c430ee3a99",
  );
  trackingId.value = uuidv4();

  // Contenido JSON del script
  const jsonData = {
    f: trackingId.value,
    s: VITE_PAYPAL_FRAUDNET_ID,
    sandbox: VITE_PAYPAL_SANDBOX,
  };

  scriptFncls.text = JSON.stringify(jsonData);
  document.body.appendChild(scriptFncls);

  const script = "https://c.paypal.com/da/r/fb.js";

  // Promesas para los dos scripts
  const scriptPromises = [loadScriptGeneral(script)];

  // Ejecutar las promesas y validar que ambos scripts se cargaron
  Promise.all(scriptPromises)
    .then(() => {
      console.log("Script Paypal");
    })
    .catch((reason) => {
      console.log("Error:", reason);
    });
}

onMounted(async () => {
  loadScriptPaypal();
  store.commit("setCanCloseMenu", true);
  store.dispatch("checkout/verifyTokenizable");

  let paypal: PayPalNamespace | null = null;

  if (!isActiveToken.value) {
    store.commit("checkout/setPaymentTokenizable", null);
  }

  try {
    loading.value = true;
    const response = await axios.post(
      `${VITE_API_ENDPOINT}/checkout/paypal/token`,
      {
        type: "id_token",
        tracking_id: trackingId.value,
      },
    );

    const result = response.data;
    if (result.id_token) {
      tokenId.value = result.id_token;
      store.commit("checkout/setExpiresTokenizable", result.exoires_in);
    }

    try {
      paypal = await loadScript({
        clientId: VITE_PAYPAL_CLIENT_ID,
        dataUserIdToken: tokenId.value,
      });
    } catch (error) {
      console.error("failed to load the PayPal JS SDK script", error);
      loading.value = false;
    }

    if (paypal && paypal.Buttons) {
      try {
        await paypal
          .Buttons({
            createVaultSetupToken: async () => {
              try {
                const response = await axios.post(
                  `${VITE_API_ENDPOINT}/checkout/paypal/setup/token`,
                  {
                    tracking_id: trackingId.value,
                  },
                );

                const result = response?.data;

                if (!result.id) {
                  const errorDetail = result.details[0];
                  const errorMessage = errorDetail
                    ? `${errorDetail.issue} ${errorDetail.description} (${result.debug_id})`
                    : "Unexpected error occurred, please try again.";

                  throw new Error(errorMessage);
                }

                return result.id;
              } catch (error) {
                console.error(error);
                throw error;
              }
            },
            onApprove: async ({ vaultSetupToken }: ExtendedOnApproveData) => {
              try {
                const response = await axios.post(
                  `${VITE_API_ENDPOINT}/checkout/paypal/payment/token`,
                  {
                    vaultSetupToken: vaultSetupToken,
                    tracking_id: trackingId.value,
                  },
                );

                const result = response?.data;

                if (!result.id) {
                  const errorDetail = result.details[0];
                  const errorMessage = errorDetail
                    ? `${errorDetail.issue} ${errorDetail.description} (${result.debug_id})`
                    : "Unexpected error occurred, please try again.";

                  throw new Error(errorMessage);
                }

                store.commit("checkout/setPaymentTokenizable", result.id);

                setTimeout(() => {
                  eventBus.emit("next-checkout-step");
                }, 500);

                return result;
              } catch (error) {
                console.error(error);
                throw error;
              }
            },
            onError: (error) => {
              notifySrv.getNotification({
                type: "error",
                title: "Error al inciar tu sesión, intentalo nuevamente",
                text: "Error al inciar tu sesión, intentalo nuevamente",
                time: -1,
              });
              console.log(error);
            },
          })
          .render("#paypal-button-container");

        loading.value = false;
      } catch (error) {
        console.error("failed to render the PayPal Buttons", error);
        loading.value = false;
      }
    }
  } catch (error) {
    notifySrv.getNotification({
      type: "error",
      title: "Error al inciar tu sesión, intentalo nuevamente",
      text: "Error al inciar tu sesión, intentalo nuevamente",
      time: -1,
    });
    console.log(error);
  }
});
</script>

<template>
  <div class="row">
    <div
      class="mx-2 my-3 mx-md-auto my-md-5 d-flex align-items-center justify-content-center"
    >
      <div class="mt-3 mx-auto w-50">
        <div id="paypal-button-container"></div>
        <div v-if="!loading">
          <div v-if="isActiveToken && !loading" class="mt-1">
            <span class="text-success fs-4 fw-bold">
              <i class="bi bi-person-check"></i> Sesión activa
            </span>
            <br />
            <small class="text-secondary">
              Puedes continuar con tu compra.
            </small>
          </div>
          <div v-else>
            <span class="text-warning">Por favor, inicia tu sessión.</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped></style>
