<template lang="">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-9 col-xl-9 d-none d-lg-block">
        <div class="d-flex align-items-center">
          <div class="me-4 small subtitle">
            {{ getTitle("cart", "see_my_saved_products") }}
          </div>
          <div class="small subtitle">
            {{ getTitle("cart", "buy_again") }}
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-xl-3 d-none d-lg-block text-end">
        <a
          class="text-decoration-none clean-entire-cart pointer"
          @click="clearCart()"
        >
          {{ getTitle("cart", "clear_cart") }}
        </a>
      </div>
    </div>
    <div class="mt-lg-3">
      <cart-item
        :show-price-step-one="true"
        :show-price-step-one-small="false"
        :show-applies-product="true"
        :show-available-in-20-min="true"
        :show-pieces="true"
        :color-price-with-tax="'price-with-tax-green'"
        :color-text-with-tax="'vat-included-green'"
        :show-available-pieces="false"
        :shadow="'border-item-cart-detail'"
        :border-img="'long-border-img-cart'"
        :show-favorites-and-similar="true"
      />
    </div>
  </div>
</template>
<script lang="ts">
import CartItem from "../cart/CartItem.vue";
import { getTitleTranslate } from "../../utils/general";
import { store } from "@/store";
import { CartService } from "@/services/CartService";
import eventBus from "@/event-bus";
import auth from "@/services/AuthService";

export default {
  components: {
    CartItem,
  },
  data: function () {
    return {
      cartService: null as CartService | null,
    };
  },
  computed: {
    isUserAuthenticated() {
      return auth.isUserLogged();
    },
  },
  mounted() {
    this.cartService = new CartService();
    this.cartService.getCartInitData();
  },
  methods: {
    getTitle(section: string, name: string) {
      return getTitleTranslate(section, name);
    },
    clearCart() {
      if (this.isUserAuthenticated && this.cartService) {
        this.cartService.clearCart().then((response) => {
          if (response) {
            eventBus.emit("update-cart-details", null);
            eventBus.emit("update-cart-total-items", 0);
          }
        });
      } else {
        localStorage.clear();
        store.commit("cart/clearLocalStorageProducts");
        eventBus.emit("update-cart-details", null);
        eventBus.emit("update-cart-total-items", 0);
      }
    },
  },
};
</script>
