<template>
  <!-- OCULTO HASTA QUE FUNCIONE -->
  <link-nav
    v-if="false"
    :to="path"
    class="box-search-bar text-uppercase"
    :class="{ active: getActivePath() }"
  >
    <div class="d-flex flex-column align-items-center">
      <i
        class="bi bi-columns-gap icon"
        :class="{ 'text-white': getActivePath() }"
      />
      <span
        class="title-box mt-2 d-none d-lg-block"
        :class="{ active: getActivePath() }"
      >
        Arma tu equipo
      </span>
    </div>
  </link-nav>
</template>

<script lang="ts">
import LinkNav from "@/components/buttons/LinkNav.vue";

export default {
  components: {
    LinkNav,
  },
  data: function () {
    return {
      path: "/arma-tu-equipo" as string,
    };
  },
  methods: {
    getActivePath(): boolean {
      return this.$route.path == this.path;
    },
  },
};
</script>
