<template>
  <div class="bank-cards-list" :class="classProfile">
    <div v-if="classProfile" class="px-3 py-2 px-lg-3 py-lg-3">
      <h4
        class="title-add-new-address mt-2 pb-3 border-bottom border-light text-secondary"
      >
        {{
          action === "new" ? "Agregar nueva dirección" : "Actualizar dirección"
        }}
      </h4>
    </div>
    <VeeForm :validation-schema="addressSchema" @submit="onAddAddress">
      <div class="d-flex justify-content-end p-3">
        <button
          type="button"
          class="btn-close focus-ring focus-ring-secondary"
          aria-label="Close"
          @click="closeForm()"
        />
      </div>
      <div class="row" :class="{ 'mt-3': !classProfile }">
        <div class="row mb-3 mx-0">
          <div class="col-12 col-lg-12 mb-2">
            <label for="name" class="input-container">
              <div class="box-input-card d-none d-lg-block me-2" />
              <Field
                id="name"
                v-model="address.name"
                type="text"
                name="name"
                class="address-input"
                :disabled="isShow"
                :placeholder="'Nombre de ubicación'"
              />
            </label>
            <ErrorMessage name="name" class="error" />

            <span v-if="hasErrorsByName('name')" class="error">
              <p>
                {{ getFirstErrorByName("name") }}
              </p>
            </span>
          </div>
          <!-- Billing address -->
          <div v-if="isBilling" class="col-12 col-lg-6 mb-2">
            <label for="business_name" class="input-container">
              <div class="box-input-card d-none d-lg-block me-2" />
              <Field
                id="business_name"
                v-model="address.business_name"
                type="text"
                name="business_name"
                class="address-input"
                :disabled="isShow"
                :placeholder="'Razón social'"
              />
            </label>
            <ErrorMessage name="business_name" class="error" />
            <span v-if="hasErrorsByName('business_name')" class="error">
              <p>
                {{ getFirstErrorByName("business_name") }}
              </p>
            </span>
          </div>
          <div v-if="isBilling" class="col-12 col-lg-6 mb-2">
            <label for="rfc" class="input-container">
              <div class="box-input-card d-none d-lg-block me-2" />
              <Field
                id="rfc"
                v-model="address.rfc"
                type="text"
                name="rfc"
                class="address-input"
                :disabled="isShow"
                :placeholder="'RFC'"
              />
            </label>
            <ErrorMessage name="rfc" class="error" />
            <span v-if="hasErrorsByName('rfc')" class="error">
              <p>
                {{ getFirstErrorByName("rfc") }}
              </p>
            </span>
          </div>
          <div v-if="isBilling" class="col-12 col-lg-6 mb-2">
            <label for="email" class="input-container">
              <div class="box-input-card d-none d-lg-block me-2" />
              <Field
                id="email"
                v-model="address.email"
                type="text"
                name="email"
                class="address-input"
                :disabled="isShow"
                :placeholder="'Email para recibir la factura'"
              />
            </label>
            <ErrorMessage name="email" class="error" />
            <span v-if="hasErrorsByName('email')" class="error">
              <p>
                {{ getFirstErrorByName("email") }}
              </p>
            </span>
          </div>
          <div v-if="isBilling" class="col-12 col-lg-6 mb-2">
            <label for="cfdi_use" class="input-container">
              <div class="box-input-card d-none d-lg-block me-2" />
              <Field
                id="cfdi_use"
                v-slot="{ value }"
                v-model="cfdiUse"
                name="cfdi_use"
                as="select"
                class="address-input"
                :disabled="isShow"
              >
                <option value="" disabled>Uso factura</option>
                <option
                  v-for="option in cfdiUses"
                  :key="option.id"
                  :value="option.id"
                  :selected="value && value.includes(option.id)"
                >
                  {{ option.name }}
                </option>
              </Field>
            </label>
            <ErrorMessage name="cfdi_use" class="error" />
            <span v-if="hasErrorsByName('cfdi_use')" class="error">
              <p>
                {{ getFirstErrorByName("cfdi_use") }}
              </p>
            </span>
          </div>
          <!-- end Billing addresss -->
          <div v-if="!isBilling" class="col-12 col-lg-12 mb-2">
            <label for="person-first-name" class="input-container">
              <div class="box-input-card d-none d-lg-block me-2" />
              <Field
                id="person-first-name"
                v-model="address.person_first_name"
                type="text"
                name="person_first_name"
                class="address-input"
                :disabled="isShow"
                :placeholder="'Nombres'"
              />
            </label>
            <ErrorMessage name="person_first_name" class="error" />
            <span v-if="hasErrorsByName('person_first_name')" class="error">
              <p>
                {{ getFirstErrorByName("person_first_name") }}
              </p>
            </span>
          </div>
          <div v-if="!isBilling" class="col-12 col-lg-6 mb-2">
            <label for="person-last-name" class="input-container">
              <div class="box-input-card d-none d-lg-block me-2" />
              <Field
                id="person-last-name"
                v-model="address.person_last_name"
                type="text"
                name="person_last_name"
                class="address-input"
                :disabled="isShow"
                :placeholder="'Apellido paterno'"
              />
            </label>
            <ErrorMessage name="person_last_name" class="error" />
            <span v-if="hasErrorsByName('person_last_name')" class="error">
              <p>
                {{ getFirstErrorByName("person_last_name") }}
              </p>
            </span>
          </div>
          <div v-if="!isBilling" class="col-12 col-lg-6 mb-2">
            <label for="person-mother-last-name" class="input-container">
              <div class="box-input-card d-none d-lg-block me-2" />
              <Field
                id="person-mother-last-name"
                v-model="address.person_mother_last_name"
                type="text"
                name="person_mother_last_name"
                class="address-input"
                :disabled="isShow"
                :placeholder="'Apellido materno'"
              />
            </label>
            <ErrorMessage name="person_mother_last_name" class="error" />
            <span
              v-if="hasErrorsByName('person_mother_last_name')"
              class="error"
            >
              <p>
                {{ getFirstErrorByName("person_mother_last_name") }}
              </p>
            </span>
          </div>

          <div class="col-12 col-lg-6 mb-2">
            <label for="postal-code" class="input-container">
              <div class="box-input-card d-none d-lg-block me-2" />
              <Field
                id="postal-code"
                v-model="address.postal_code"
                :placeholder="'Código postal'"
                type="tel"
                name="postal_code"
                :disabled="isShow"
                class="address-input"
                @change="handleBlur()"
                @keydown.enter.prevent
                @keypress="isNumber($event)"
              />
            </label>
            <ErrorMessage name="postal_code" class="error" />
            <span v-if="hasErrorsByName('postal_code')" class="error">
              <p>
                {{ getFirstErrorByName("postal_code") }}
              </p>
            </span>
            <span v-if="postalCodeNotFoud" class="error">
              {{ textpostalCodeNotFoud }}
            </span>
          </div>
          <div v-if="isBilling" class="col-12 col-lg-6 mb-2">
            <label for="cfdi_regime" class="input-container">
              <div class="box-input-card d-none d-lg-block me-2" />
              <Field
                id="cfdi_regime"
                v-slot="{ value }"
                v-model="cfdiRegime"
                name="cfdi_regime"
                as="select"
                class="address-input"
                :disabled="isShow"
              >
                <option value="" disabled>Regimen fiscal</option>
                <option
                  v-for="option in cfdiRegimes"
                  :key="option.id"
                  :value="option.id"
                  :selected="value && value.includes(option.id)"
                >
                  {{ option.name }}
                </option>
              </Field>
            </label>
            <ErrorMessage name="cfdi_regime" class="error" />
            <span v-if="hasErrorsByName('cfdi_regime')" class="error">
              <p>
                {{ getFirstErrorByName("cfdi_regime") }}
              </p>
            </span>
          </div>
          <div class="w-100" />
          <div class="col-12 col-lg-6 mb-2">
            <label for="state" class="input-container disabled">
              <div class="box-input-card d-none d-lg-block me-2" />
              <Field
                id="state"
                v-model="address.state.name"
                type="text"
                name="state"
                class="address-input"
                :disabled="true"
                :placeholder="'Estado'"
              />
            </label>
            <ErrorMessage name="state" class="error" />
            <span v-if="hasErrorsByName('state')" class="error">
              <p>
                {{ getFirstErrorByName("state") }}
              </p>
            </span>
          </div>
          <div class="col-12 col-lg-6 mb-2">
            <label for="municipality" class="input-container disabled">
              <div class="box-input-card d-none d-lg-block me-2" />
              <Field
                id="municipality"
                v-model="address.municipality.name"
                type="text"
                name="municipality"
                class="address-input"
                :disabled="true"
                :placeholder="'Municipio / Delegación'"
              />
            </label>
            <ErrorMessage name="municipality" class="error" />
            <span v-if="hasErrorsByName('municipality')" class="error">
              <p>
                {{ getFirstErrorByName("municipality") }}
              </p>
            </span>
          </div>

          <div class="col-12 col-lg-12 mb-2">
            <label for="settlement" class="input-container">
              <div class="box-input-card d-none d-lg-block me-2" />
              <Field
                id="settlement"
                v-slot="{ value }"
                v-model="address.settlement.id"
                name="settlement"
                as="select"
                class="address-input"
                :disabled="isShow"
              >
                <option value="" disabled>Colonia</option>
                <option
                  v-for="option in postalCodeData.settlements"
                  :key="option.id"
                  :value="option.id"
                  :selected="value && value.includes(option.id)"
                >
                  {{ option.name }}
                </option>
              </Field>
            </label>
            <ErrorMessage name="settlement" class="error" />
            <span v-if="hasErrorsByName('settlement')" class="error">
              <p>
                {{ getFirstErrorByName("settlement") }}
              </p>
            </span>
          </div>
          <div class="col-12 col-lg-12 mb-2">
            <label for="street_name" class="input-container">
              <div class="box-input-card d-none d-lg-block me-2" />
              <Field
                id="street_name"
                v-model="address.street_name"
                type="text"
                name="street_name"
                class="address-input"
                :disabled="isShow"
                :placeholder="'Calle'"
              />
            </label>
            <ErrorMessage name="street_name" class="error" />
            <span v-if="hasErrorsByName('street_name')" class="error">
              <p>
                {{ getFirstErrorByName("street_name") }}
              </p>
            </span>
          </div>
          <div class="col-12 col-lg-6 mb-2">
            <label for="street_number" class="input-container">
              <div class="box-input-card d-none d-lg-block me-2" />
              <Field
                id="street_number"
                v-model="address.street_number"
                type="text"
                name="street_number"
                class="address-input"
                :disabled="isShow"
                :placeholder="'Número exterior'"
              />
            </label>
            <ErrorMessage name="street_number" class="error" />
            <span v-if="hasErrorsByName('street_number')" class="error">
              <p>
                {{ getFirstErrorByName("street_number") }}
              </p>
            </span>
          </div>
          <div class="col-12 col-lg-6 mb-2">
            <label for="interior_number" class="input-container">
              <div class="box-input-card d-none d-lg-block me-2" />
              <Field
                id="interior_number"
                v-model="address.interior_number"
                type="text"
                name="interior_number"
                class="address-input"
                :disabled="isShow"
                :placeholder="'Número interior'"
              />
            </label>
            <ErrorMessage name="interior_number" class="error" />
            <span v-if="hasErrorsByName('interior_number')" class="error">
              <p>
                {{ getFirstErrorByName("interior_number") }}
              </p>
            </span>
          </div>

          <div class="col-12 col-lg-12 mb-2">
            <label for="between_streets" class="input-container">
              <div class="box-input-card d-none d-lg-block me-2" />
              <Field
                id="between_streets"
                v-model="address.between_streets"
                :placeholder="'Entre calles'"
                type="text"
                name="between_streets"
                :disabled="isShow"
                class="address-input"
              />
            </label>
            <ErrorMessage name="between_streets" class="error" />
            <span v-if="hasErrorsByName('between_streets')" class="error">
              <p>
                {{ getFirstErrorByName("between_streets") }}
              </p>
            </span>
          </div>
          <div class="col-12 col-lg-12 mb-2">
            <label for="reference" class="input-container">
              <div class="box-input-card d-none d-lg-block me-2" />
              <Field
                id="reference"
                v-model="address.reference"
                :placeholder="'Referencia'"
                type="text"
                name="reference"
                :disabled="isShow"
                class="address-input"
              />
            </label>
            <ErrorMessage name="reference" class="error" />
          </div>
          <div class="col-12 col-lg-12 mb-2">
            <label for="telephone" class="input-container">
              <div class="box-input-card d-none d-lg-block me-2" />
              <Field
                id="telephone"
                v-model="address.telephone"
                :placeholder="'Teléfono de contacto'"
                type="number"
                name="telephone"
                :disabled="isShow"
                class="address-input"
              />
            </label>
            <ErrorMessage name="telephone" class="error" />
            <span v-if="hasErrorsByName('telephone')" class="error">
              <p>
                {{ getFirstErrorByName("telephone") }}
              </p>
            </span>
          </div>
          <div
            v-if="!isShow"
            class="col-12 mt-3 d-flex flex-row justify-content-center align-items-center gap-3"
          >
            <button
              class="btn-block w-25 btn-save-rfc border-0 text-white h-100 py-2 position-relative"
              :disabled="savingCustomerAddress"
            >
              <span> Guardar </span>
              <div
                v-if="savingCustomerAddress"
                class="spinner-border text-white position-absolute top-0 start-50"
                role="status"
              >
                <span class="visually-hidden">Loading...</span>
              </div>
            </button>
            <button
              class="btn btn-secondary w-25 h-100 py-2 rounded-3"
              :disabled="savingCustomerAddress"
              @click="closeForm()"
            >
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </VeeForm>
  </div>
</template>
<script lang="ts">
import { Form as VeeForm, Field, ErrorMessage } from "vee-validate";
import { CartService } from "@/services/CartService";

import { CustomerAddressService } from "@/services/CustomerAddressService";
import { ShippingService } from "@/services/ShippingService";
import { CfdiCatalogsService } from "@/services/CfdiCatalogsService";
import { CustomerAddress } from "@/interfaces/CustomerAddress";
import eventBus from "@/event-bus";
import Jsona from "jsona";
import TabTitle from "@/interfaces/TabTitle";
import { ErrorItem } from "@/interfaces/ErrorItem";
import { Address } from "@/interfaces/Address";
import { CfdiRegime } from "@/interfaces/CfdiRegime";
import { CfdiUses } from "@/interfaces/CfdiUses";
import { CustomNotifyService } from "@/services/CustomNotifyService";
import { Settlement } from "@/interfaces/ZipCodeResponse";

const notifyService = new CustomNotifyService();
const dataFormatter = new Jsona();

const defaultAddress: CustomerAddress = {
  id: null,
  name: "",
  street_name: "",
  postal_code: null,
  telephone: "",
  person_first_name: "",
  person_last_name: "",
  person_mother_last_name: "",
  business_name: "",
  rfc: "",
  email: "",
  state: {
    name: "",
    id: null,
  },
  municipality: {
    name: "",
    id: null,
  },
  city: {
    name: "",
    id: null,
  },
  settlement: {
    name: "",
    id: null,
  },
  street_number: "",
  interior_number: "",
  between_streets: "",
  reference: "",
  cfdiUse: {
    name: "",
    id: null,
  },
  cfdiRegime: {
    name: "",
    id: null,
  },
};

// interface ClearAddress {
//   [key: string]: string | Record<string, string> | null;
// }

interface PostalCodeData {
  settlements: Settlement[];
}

export default {
  components: {
    VeeForm,
    Field,
    ErrorMessage,
  },
  props: {
    selectedAddress: {
      type: Object as () => CustomerAddress | null,
      default: function () {
        return defaultAddress;
      },
    },
    action: {
      type: String,
      default: "show",
    },
    classProfile: {
      type: String,
      default: "",
    },
    isDelivery: {
      type: Boolean,
      default: true,
    },
    isBilling: {
      type: Boolean,
      default: false,
    },
    returnToProfile: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["updateAddresses", "error-message-show", "updateShowAddress"],

  data: function () {
    TabTitle(
      this.action === "new"
        ? "Agregar nueva dirección"
        : "Actualizar dirección",
    );
    return {
      cartService: null as CartService | null,
      address: defaultAddress as CustomerAddress,
      loadingPostalCode: false,
      postalCodeData: {
        settlements: [] as Settlement[],
      },
      cfdiUses: [] as CfdiUses[],
      cfdiRegimes: [] as CfdiRegime[],
      savingCustomerAddress: false,
      cfdiUse: null as null | string,
      cfdiRegime: null as null | string,
      addressErrors: [] as ErrorItem[],
      postalCodeNotFoud: false,
      textpostalCodeNotFoud: "",
    };
  },
  computed: {
    isShow() {
      return this.action.toLowerCase() == "show";
    },
    addressSchema() {
      let addressSchema = {};
      addressSchema = {
        ...addressSchema,
        name: {
          required: { fieldName: "Nombre de ubicación" },
        },
        postal_code: {
          required: { fieldName: "Código postal" },
          min: { fieldName: "Código postal", min: 5 },
        },
        settlement: {
          required: { fieldName: "Colonia" },
        },
        street_name: {
          required: { fieldName: "Calle" },
        },
        street_number: {
          required: { fieldName: "Número exterior" },
        },
        interior_number: {
          required: { fieldName: "Número interior" },
        },
        beetween_streets: "",
        reference: "",
        telephone: {
          required: { fieldName: "Teléfono de contacto" },
          min: {
            fieldName: "Teléfono de contacto",
            min: 10,
          },
        },
      };
      if (this.isDelivery) {
        addressSchema = {
          ...addressSchema,
          person_first_name: {
            required: { fieldName: "Nombres" },
          },
          person_last_name: {
            required: { fieldName: "Apellido paterno" },
          },
          person_mother_last_name: {
            required: {
              fieldName: "Apellido materno",
            },
          },
        };
      }
      if (this.isBilling) {
        addressSchema = {
          ...addressSchema,
          business_name: {
            required: { fieldName: "Razón social" },
          },
          rfc: { required: { fieldName: "RFC" } },
          email: {
            required: { fieldName: "Email para recibir la factura" },
          },
          cfdi_use: {
            required: { fieldName: "Uso factura" },
          },
          cfdi_regime: {
            required: { fieldName: "Regimen fiscal" },
          },
        };
      }
      return addressSchema;
    },
  },
  watch: {
    selectedAddress(newSelectedAddress) {
      if (newSelectedAddress == null) {
        this.address = defaultAddress;
      } else {
        this.address = newSelectedAddress;
        this.cfdiUse = this.address.cfdiUse?.id;
        this.cfdiRegime = this.address.cfdiRegime?.id;
      }
    },
    address() {
      this.getPostalCodes();
    },
  },
  mounted() {
    this.cartService = new CartService();
    this.cartService.getCartInitData();
    this.getPostalCodes();
    this.getCfdiUses();
    this.getCfdiRegimes();
    if (this.action == "update") {
      this.address = { ...this.selectedAddress } as CustomerAddress;
      this.cfdiUse = this.address.cfdiUse ? this.address.cfdiUse?.id : null;
      this.cfdiRegime = this.address.cfdiRegime
        ? this.address.cfdiRegime?.id
        : null;
    } else {
      this.address = defaultAddress;
    }
  },

  methods: {
    handleBlur() {
      this.$nextTick(() => {
        this.getPostalCodes();
      });
    },
    async onAddAddress() {
      let statusResponse = 201;
      this.savingCustomerAddress = true;
      const customerAddressService = new CustomerAddressService();
      let attributes: Address = {
        name: this.address.name,
        is_delivery: this.isDelivery,
        is_billing: this.isBilling,
        postal_code: Number(this.address.postal_code),
        street_name: this.address.street_name,
        between_streets: this.address.between_streets,
        street_number: this.address.street_number,
        interior_number: this.address.interior_number,
        telephone: this.address.telephone + "",
        reference: this.address.reference,
        person_first_name: this.address.person_first_name,
        person_last_name: this.address.person_last_name,
        person_mother_last_name: this.address.person_mother_last_name,
        cfdi_use_code: null,
        relationshipNames: [],
      };
      if (this.address.settlement.id) {
        attributes.settlement = {
          type: "settlements",
          id: this.address.settlement.id,
        };
        attributes.relationshipNames.push("settlement");
      }
      if (this.address.state.id) {
        attributes.state = {
          type: "states",
          id: this.address.state.id,
        };
        attributes.relationshipNames.push("state");
      }
      if (this.address.city.id) {
        attributes.city = {
          type: "cities",
          id: this.address.city.id,
        };
        attributes.relationshipNames.push("city");
      }
      if (this.address.municipality.id) {
        attributes.municipality = {
          type: "municipalities",
          id: this.address.municipality.id,
        };
        attributes.relationshipNames.push("municipality");
      }
      if (!attributes) {
        this.savingCustomerAddress = false;
      }

      if (this.isDelivery) {
        attributes = {
          ...attributes,
        };
      }

      if (this.isBilling) {
        attributes = {
          ...attributes,
          rfc: this.address.rfc,
          business_name: this.address.business_name,
          email: this.address.email,
          cfdiUse: {
            type: "cfdi-uses",
            id: this.cfdiUse,
          },
          cfdiRegime: {
            type: "cfdi-regimes",
            id: this.cfdiRegime,
          },
        };
        attributes.relationshipNames.push("cfdiUse");
        attributes.relationshipNames.push("cfdiRegime");
      }

      let promise = null;
      if (this.action == "new") {
        promise = customerAddressService.storeCustomerAddress(attributes);
      } else if (this.action == "update" && this.address.id != null) {
        attributes = {
          id: this.address.id,
          ...attributes,
        };
        statusResponse = 200;
        promise = customerAddressService.updateCustomerAddress(
          this.address.id,
          attributes,
        );
      }
      if (promise) {
        promise
          .then(async (response) => {
            console.log(response);
            if (response && response.status == statusResponse) {
              this.$emit("updateAddresses");

              this.getNotification(response.status, this.address.name);
              this.cleanForm(this.address as CustomerAddress);

              if (this.returnToProfile) {
                this.$router.push("/perfil/direcciones");
              }
            } else if (response && response.status === 422) {
              this.addressErrors = response.data.errors;
              eventBus.emit("error-message-show", {
                errors: response.data.errors,
              });
              this.savingCustomerAddress = false;
            }
          })
          .catch(() => {
            notifyService.getNotification({
              title: "Error al actualizar su dirección",
              text: "Error al actualizar su dirección",
              type: "error",
              time: 3000,
            });
            this.savingCustomerAddress = false;
          });
      }
    },
    resetPostalCode() {
      this.postalCodeData = {
        settlements: [],
      };
      this.address.state = {
        name: "",
        id: null,
      };
      this.address.municipality = {
        name: "",
        id: null,
      };
      this.address.city = {
        name: "cities",
        id: null,
      };
    },
    async getPostalCodes() {
      if (this.address.postal_code != null) {
        this.resetPostalCode();
        const shippingService = new ShippingService();

        shippingService
          .getSettlemant(this.address.postal_code)
          .then(async (response) => {
            if (response.data) {
              if (this.postalCodeNotFoud) {
                this.postalCodeNotFoud = false;
              }
              let data = dataFormatter.deserialize(response);
              this.postalCodeData = data as PostalCodeData;

              if (!Array.isArray(data)) {
                if (this.action == "new") {
                  this.address.state = data.state;
                  const city = data.settlements.find(
                    (settlement: Settlement) => settlement.city !== null,
                  );
                  this.address.city = city ? city.city : { id: null, name: "" };
                  this.address.municipality = data.settlements[0].municipality;
                }
              }
            } else {
              if (response.errors && response.errors[0].status === "404") {
                this.postalCodeNotFoud = true;

                if (this.postalCodeNotFoud) {
                  this.textpostalCodeNotFoud =
                    "El código postal seleccionado no existe.";
                }
              }
              console.log("Ha ocurrido un error");
            }
          })
          .catch((response) => {
            if (
              response.response.data.errors &&
              response.response.status == "404"
            ) {
              this.postalCodeNotFoud = true;

              this.resetPostalCode();

              if (this.postalCodeNotFoud) {
                this.textpostalCodeNotFoud = `El código postal ${this.address.postal_code} no existe.`;
              }
            }
            console.log("Ha ocurrido un error");
          });
      }
    },
    async getCfdiUses() {
      this.cfdiUses = [];
      const cfdiCatalogsService = new CfdiCatalogsService();
      cfdiCatalogsService.getCfdiUses().then(async (response: CfdiRegime[]) => {
        if (response) {
          this.cfdiUses = response;
        } else {
          console.log("Ha ocurrido un error");
        }
      });
    },
    async getCfdiRegimes() {
      this.cfdiRegimes = [];
      const cfdiCatalogsService = new CfdiCatalogsService();
      cfdiCatalogsService
        .getCfdiRegimes()
        .then(async (response: CfdiRegime[]) => {
          if (response) {
            this.cfdiRegimes = response;
          } else {
            console.log("Ha ocurrido un error");
          }
        });
    },

    getNotification(status: number, name: string) {
      let title = `${status === 200 ? "¡Actualización exitosa!" : status === 201 ? "¡Registro exitoso!" : "Error"}`;
      let text = `¡La dirección <strong>${name}</strong> fue ${status === 200 ? " actualizada" : " agregada!"}`;
      notifyService.getNotification({
        title: title,
        text: text,
        time: 3000,
      });
    },

    hasErrorsByName(field: string) {
      const errors: ErrorItem[] = this.addressErrors.filter(
        (error: ErrorItem) => {
          return error.source.pointer == "/data/attributes/" + field;
        },
      );
      return errors.length > 0;
    },

    getErrorsByName(field: string) {
      return this.addressErrors.filter((error: ErrorItem) => {
        return error.source.pointer == "/data/attributes/" + field;
      });
    },

    getFirstErrorByName(field: string): string {
      const errors = this.getErrorsByName(field);
      if (this.hasErrorsByName(field) && errors[0].detail) {
        return errors[0].detail;
      }
      return "";
    },

    cleanForm(address: CustomerAddress): void {
      address.name = "";
      address.between_streets = "";
      address.business_name = "";
      address.cfdiRegime = {
        id: "",
        name: "",
      };
      address.cfdiUse = {
        id: "",
        name: "",
      };
      address.city = {
        id: null,
        name: "",
      };
      address.email = "";
      address.id = 0;
      address.interior_number = "";
      address.municipality = {
        id: null,
        name: "",
      };
      address.person_first_name = "";
      address.person_last_name = "";
      address.person_mother_last_name = "";
      address.postal_code = null;
      address.reference = "";
      address.street_name = "";
      address.rfc = "";

      address.settlement = {
        id: null,
        name: "",
      };
      address.state = {
        id: null,
        name: "",
      };
      address.municipality = {
        id: null,
        name: "",
      };
      address.cfdiRegime = {
        id: null,
        name: "",
      };
      address.street_number = "";
      address.telephone = "";
    },
    updateShowAddress(show: boolean) {
      this.$emit("updateShowAddress", show);
    },

    closeForm() {
      this.updateShowAddress(false);
    },

    isNumber(event: KeyboardEvent): void {
      const char = event.key;
      if (!/^[0-9.]$/.test(char) && event.key !== "Backspace") {
        event.preventDefault();
      }
    },
  },
};
</script>
