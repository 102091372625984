<template>
  <custom-notification />
  <div v-if="showNotification" class="d-none d-lg-block">
    <status-internet
      v-if="showNotification"
      :on-line="onLine"
      :show-back-online="showBackOnline"
    />
  </div>

  <component :is="layout" :footer="footer">
    <div v-if="showNotification" class="d-block d-lg-none fixed-top bg-dark">
      <status-internet
        v-if="showNotification"
        :on-line="onLine"
        :show-back-online="showBackOnline"
      />
    </div>
    <router-view />
  </component>
</template>
<script lang="ts">
import MainLayout from "./components/layouts/MainLayout.vue";
import { store } from "./store";
import StatusInternet from "./components/common/StatusInternet.vue";
import CustomNotification from "./components/common/CustomNotification.vue";

export default {
  components: {
    MainLayout,
    StatusInternet,
    CustomNotification,
  },
  data() {
    return {
      showNotification: false,
      onLine: navigator.onLine,
      showBackOnline: false,
    };
  },
  computed: {
    layout() {
      return this.$route.meta.layout ? this.$route.meta.layout : MainLayout;
    },
    footer() {
      return this.$route.meta.withFooter;
    },
  },
  watch: {
    onLine(v) {
      if (v) {
        this.showBackOnline = true;
        setTimeout(() => {
          this.showBackOnline = false;
          this.showNotification = false;
          window.location.href = this.$route.fullPath;
        }, 2000);
      }
    },
  },
  mounted() {
    store.commit("auth/setIsUserAuthenticated", false);

    window.addEventListener("online", this.updateOnlineStatus);
    window.addEventListener("offline", this.updateOnlineStatus);
  },
  beforeMount() {
    window.removeEventListener("online", this.updateOnlineStatus);
    window.removeEventListener("offline", this.updateOnlineStatus);
  },
  methods: {
    updateOnlineStatus(e: Event) {
      this.showNotification = true;
      const { type } = e;
      this.onLine = type === "online";
    },
  },
};
</script>
