import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { store } from "@/store";
import Jsona from "jsona";
import { VITE_API_ENDPOINT } from "@/config/constants";

const dataFormatter = new Jsona();
export class HomeService {
  public async getCategories() {
    const response = await axios.get("/eshop/home/categories", {
      params: {
        depth_lvl: 3,
      },
    });
    if (response && response.data) {
      store.commit("catalogs/setCategories", response.data);
      return response.data;
    }
  }

  public async getBrands() {
    const response = await axios.get("/eshop/home/brands", {});
    if (response && response.data) {
      store.commit("catalogs/setBrands", response.data);
      return response.data;
    }
  }

  public async getNewProducts() {
    const response = await axios.get("/eshop/home/new-products", {});
    if (response) {
      return response.data;
    }
  }

  public async getFlashProducts(page: number | null) {
    const response = await axios.get("/eshop/home/flash-products", {
      params: {
        page: page,
      },
    });
    if (response) {
      return response.data;
    }
  }

  public async getPromotionProducts(page: number | null) {
    const response = await axios.get("/eshop/home/promotion-products", {
      params: {
        page: page,
      },
    });
    if (response) {
      return response.data;
    }
  }

  public async getBanners(typeBanner: string) {
    const banners = store.getters["catalogs/getBanners"];
    let bannersType = [];

    if (banners.length > 0) {
      const bannersSerialize = store.getters["catalogs/getDeserealizeBanners"];

      bannersType = bannersSerialize.filter(
        (banner: any) => banner.banner_type == typeBanner,
      );
    }

    if (bannersType.length > 0) {
      return bannersType;
    } else {
      const response = await axios.get("/eshop/home/banners", {
        params: {
          banner_type: typeBanner,
        },
      });
      if (response && response.data) {
        store.commit("catalogs/setBanners", response.data);
        return dataFormatter.deserialize(response.data);
      }
    }
  }

  public async getMostPopular() {
    const response = await axios.get("/eshop/home/most-searched-this-week");
    if (response) {
      return response.data;
    }
  }

  public async getFavoriteProducts() {
    const response = await axios.get("/eshop/favoritos");
    if (response) {
      return response.data;
    }
  }

  public async deleteFavoriteProduct(id: number) {
    const response = await axios.delete("/eshop/home/favorites/" + id);
    if (response) {
      return response.data;
    }
  }

  public async getMonthlyPromotionByProduct(id: number) {
    const response = await axios.get(
      "/eshop/home/monthly-payment-promotions/" + id,
    );
    if (response) {
      return response.data;
    }
  }

  public async getPromotionsByProduct(id: number) {
    const response = await axios.get("/eshop/home/promotion-by-product/" + id);
    if (response) {
      return response.data;
    }
  }

  public lastSuggestSignal?: AbortController;
  public async getSuggestions(find: string) {
    if (this.lastSuggestSignal) {
      this.lastSuggestSignal.abort();
    }
    this.lastSuggestSignal = new AbortController();
    const response = await axios.get(
      "/eshop/suggest/" + encodeURIComponent(find),
      {
        signal: this.lastSuggestSignal.signal,
      },
    );
    if (response) {
      return response.data;
    }
  }

  public async getHomeInitData() {
    const translations = store.getters["translations/getSection"]("home");
    if (translations) {
      return translations;
    }
    const response = await axios.get("/eshop/home/init-data");
    if (response) {
      if (
        response.data &&
        response.data.meta &&
        response.data.meta.translations
      ) {
        store.commit("translations/setSection", {
          home: response.data.meta.translations,
        });
      }
      return response.data;
    }
  }

  public async getHeaderInitData() {
    const translations = store.getters["translations/getSection"]("header");
    if (translations) {
      return translations;
    }
    const response = await axios.get("/eshop/header/init-data");
    if (response) {
      if (
        response.data &&
        response.data.meta &&
        response.data.meta.translations
      ) {
        store.commit("translations/setSection", {
          header: response.data.meta.translations,
        });
      }
      return response.data;
    }
  }

  public async getFooterInitData() {
    const translations = store.getters["translations/getSection"]("footer");
    if (translations) {
      return translations;
    }
    const response = await axios.get("/eshop/footer/init-data");
    if (response) {
      if (
        response.data &&
        response.data.meta &&
        response.data.meta.translations
      ) {
        store.commit("translations/setSection", {
          footer: response.data.meta.translations,
        });
      }
      return response.data;
    }
  }

  public async getSuggestionInitData() {
    const translations =
      store.getters["translations/getSection"]("suggestions");
    if (translations) {
      return translations;
    }
    const response = await axios.get("/eshop/search/init-data");
    if (response) {
      if (
        response.data &&
        response.data.meta &&
        response.data.meta.translations
      ) {
        store.commit("translations/setSection", {
          suggestions: response.data.meta.translations,
        });
      }
      return response.data;
    }
  }

  public async addToFavorites(productId: number) {
    const data = {
      product_id: productId,
    };
    const response = await axios.post(`eshop/favoritos`, data);

    if (response) {
      return response.data;
    }
  }

  public async removeFromFavorites(productId: number) {
    const response = await axios.delete(`eshop/favoritos/${productId}`);

    if (response) {
      return response.data;
    }
  }

  async getLanding(id: string) {
    const options: AxiosRequestConfig = {
      baseURL: VITE_API_ENDPOINT,
      headers: {
        Accept: "application/vnd.api+json",
      },
    };
    const url = `/shop-v1/micro-sites/${id}?filter[not-expired]=true`;
    const response = await axios.get(url, options);

    if (!(response instanceof AxiosError)) {
      const data = dataFormatter.deserialize(response.data);
      return data;
    } else if (response.response) {
      const resp: AxiosResponse = response.response;
      return resp.data;
    }
  }
}
export const homeService = new HomeService();
