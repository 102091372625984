// define your typings for the store state
import { ActionTree } from "vuex";
import eventBus from "@/event-bus";
import Jsona from "jsona";
import router from "../../router";
import { AxiosError } from "axios";
import {
  TRANSACTIONS_ORDERS_PAYMENT_STATUS_PAYED,
  TRANSACTIONS_ORDERS_PAYMENT_STATUS_PENDING,
  TRANSACTIONS_ORDERS_PAYMENT_STATUS_PARTIALLY_PAYED,
  VITE_OPENPAY_MERCHANT_ID,
  VITE_OPENPAY_PUBLIC_API_KEY,
  VITE_OPENPAY_IS_SANDBOX,
} from "@/config/constants";
import Transaction, { ITransaction } from "@/models/Transaction";
import { CustomNotifyService } from "@/services/CustomNotifyService";
import { errorsToString } from "@/utils/general";
import { BankCard } from "@/interfaces/BankCard";
import moment, { Moment } from "moment";

const notifyService = new CustomNotifyService();

function showErrors(reason: AxiosError, message: string, time: number = 5000) {
  notifyService.getNotification({
    type: "error",
    title: `Ocurrio un error al procesar tu información`,
    text: errorsToString(reason) ?? message,
    time: time,
  });
}

const dataFormatter = new Jsona();

interface IStep {
  step: number;
  code: string;
  title: string;
  titleMobile: string;
  to: { name: string };
  store: string;
  reset: string;
  isValid: string;
  isCompleted: boolean;
  show: boolean;
}

interface IPaymentMethod {
  code: string;
  tokenizer: boolean | null;
  secure_code_required: boolean | null;
  tokenizables: Array<BankCard>;
}

interface IPickupPerson {
  name: string;
  lastName: string;
  motherLastName: string;
  telephone: string;
}

export interface StateCheckout {
  version: number;
  paymentMethodCode: null | string;
  paymentTokenizable: null | string;
  expiresTokenizable: null | Moment;
  isShipping: boolean | null;
  customerAddress: null | any;
  billingCustomerAddress: null | any;
  requiredBilling: boolean;
  store: null | any;
  pickupPerson: IPickupPerson;
  courier: null | any;
  paymentMethods: Array<IPaymentMethod>;
  monthlyPlans: Array<any>;
  monthlyPaymentOptions: Array<any>;
  hasMonthlyPayment: boolean;
  hasShippingInsurance: boolean;
  step: string;
  steps: Array<IStep>;
  loading: boolean;
  acceptTerms: boolean;
  acceptShippingConditions: boolean;
  errorMessage: string;
  cvcValidate: string;
  paymentTokenizableTemporaly: BankCard[];
  deviceSessionId: string;
  trackingId: string;
  isSavingTransaction: boolean;
}

const steps: Array<IStep> = [
  {
    step: 1,
    code: "detail",
    title: "Carrito",
    titleMobile: "Carrito",
    to: { name: "cart-detail" },
    store: "",
    reset: "",
    isValid: "",
    isCompleted: false,
    show: true,
  },
  {
    step: 2,
    code: "payment",
    title: "Pago",
    titleMobile: "Pago",
    to: { name: "cart-payment" },
    isValid: "isValidPayment",
    store: "storePayment",
    reset: "resetPayment",
    isCompleted: false,
    show: true,
  },
  {
    step: 3,
    code: "shipment",
    title: "Envío",
    titleMobile: "Entrega",
    to: { name: "cart-shipping" },
    isValid: "isValidShipment",
    store: "storeShipping",
    reset: "resetShipping",
    isCompleted: false,
    show: true,
  },
  {
    step: 4,
    code: "monthly-payment",
    title: "Mensualidades",
    titleMobile: "Mensualidades",
    to: { name: "cart-monthly" },
    isValid: "isValidMonthlyPayment",
    store: "storeMonthlyPayment",
    reset: "resetMonthlyPayment",
    isCompleted: false,
    show: true,
  },
  {
    step: 5,
    code: "review",
    title: "Detalles de mi orden",
    titleMobile: "Finalizar",
    to: { name: "cart-review" },
    isValid: "isValidReview",
    store: "",
    reset: "",
    isCompleted: false,
    show: true,
  },
];

export const defaultCheckoutInit = {
  version: 1.4,
  paymentMethodCode: null,
  paymentTokenizable: null,
  expiresTokenizable: null,
  isShipping: null,
  customerAddress: null,
  billingCustomerAddress: null,
  requiredBilling: false,
  store: null,
  pickupPerson: {
    name: "",
    lastName: "",
    motherLastName: "",
    telephone: "",
  },
  courier: null,
  paymentMethods: [],
  monthlyPlans: [],
  monthlyPaymentOptions: [],
  hasMonthlyPayment: false,
  hasShippingInsurance: true,
  step: "detail",
  steps: steps,
  loading: false,
  acceptTerms: false,
  acceptShippingConditions: false,
  cvcValidate: "",
  paymentTokenizableTemporaly: [],
  deviceSessionId: "",
  trackingId: "",
  isSavingTransaction: false,
};

// export interface GetterCheckout {
//   getPaymentMethodCode(state: StateCheckout): string | null;
//   getPaymentTokenizable(state: StateCheckout): string | null;
//   getExpiresTokenizable(state: StateCheckout): Moment | null;
//   getPaymentMethod(state: StateCheckout): IPaymentMethod | null;
//   getSelectedPaymentTokenizable(state: StateCheckout): BankCard | null;
//   getPaymentMethods(state: StateCheckout): Array<IPaymentMethod>;
//   getCustomerAddress(state: StateCheckout): any;
//   getBillingCustomerAddress(state: StateCheckout): any;
//   getIsShipping(state: StateCheckout): boolean;
//   getIsPickup(state: StateCheckout): boolean;
//   getStore(state: StateCheckout): null | any;
//   getCourier(state: StateCheckout): null | any;
//   getMonthlyPlans(state: StateCheckout): Array<any>;
//   getMonthlyPaymentOptions(state: StateCheckout): Array<any>;
//   getHasMonthlyPayment(state: StateCheckout): boolean;
//   getHasShippingInsurance(state: StateCheckout): boolean;
//   getActivePaymentConfig(state: StateCheckout): IPaymentMethod | null;
//   getPaymentTokenizableTemporaly(state: StateCheckout): BankCard[] | null;
//   getPaymentTokenizableTemporalyByMethodPayment(state: StateCheckout): (code: string) => BankCard[];
//   getDeviceSessionId(state: StateCheckout): string;
//   getTrackingId(state: StateCheckout): string;
//   getSteps(state: StateCheckout): Array<IStep>;
//   getStep(state: StateCheckout): string;
//   getIndexStepByCode (state: StateCheckout): (code: string) => number;
//   getActiveStep(state: StateCheckout): IStep | null;
//   getNextStep(state: StateCheckout, getters: GetterCheckout): IStep | null;
//   getPrevStep(state: StateCheckout, getters: GetterCheckout): IStep | null;
//   isActiveToken(state: StateCheckout): boolean;
//   isValidCVC(state: StateCheckout, getters: GetterCheckout): boolean;
//   isValidPayment(state: StateCheckout, getters: GetterCheckout): boolean;
//   isValidShipment(state: StateCheckout) : boolean;
//   isValidMonthlyPayment(state: StateCheckout) : boolean;
//   isValidReview(state: StateCheckout, getters: GetterCheckout): boolean;
//   getPickupPerson(state: StateCheckout): IPickupPerson;
//   getPickupPersonName(state: StateCheckout): string;
//   getPickupPersonLastName(state: StateCheckout): string;
//   getPickupPersonMotherLastName(state: StateCheckout): string;
//   getPickupPersonTelephone(state: StateCheckout): string;
//   getIsLoading(state: StateCheckout) : boolean;
//   getIsSavingTransaction(state: StateCheckout): boolean;
//   getIsLastStep(state: StateCheckout, getters: GetterCheckout): boolean;
//   getAcceptTerms(state: StateCheckout): boolean;
//   getAcceptShippingConditions(state: StateCheckout): boolean;
//   getErrorMessage(state: StateCheckout): string;
//   getCvcValidate(state: StateCheckout): string;
// }

const state = () => defaultCheckoutInit;

// mutations
const mutations = {
  setPaymentMethods(
    state: StateCheckout,
    paymentMethods: Array<IPaymentMethod>,
  ) {
    state.paymentMethods = paymentMethods;
  },
  setPaymentMethodCode(state: StateCheckout, paymentMethodCode: string) {
    state.paymentMethodCode = paymentMethodCode;
    state.expiresTokenizable = null;
  },
  setPaymentTokenizable(state: StateCheckout, paymentTokenizable: string) {
    state.paymentTokenizable = paymentTokenizable;
  },
  setExpiresTokenizable(state: StateCheckout, expiresTokenizable: number) {
    state.expiresTokenizable = moment().add(expiresTokenizable, "seconds");
  },
  setStep(state: StateCheckout, step: string) {
    state.step = step;
    state.loading = false;
  },
  setCustomerAddress(state: StateCheckout, customerAddress: any) {
    state.customerAddress = customerAddress;
  },
  setBillingCustomerAddress(state: StateCheckout, customerAddress: any) {
    state.billingCustomerAddress = customerAddress;
  },
  setRequiredBilling(state: StateCheckout, requiredBilling: boolean) {
    state.requiredBilling = requiredBilling;
  },
  setIsShipping(state: StateCheckout) {
    state.isShipping = true;
    state.store = null;
  },
  setIsPickup(state: StateCheckout) {
    state.isShipping = false;
    state.customerAddress = null;
    state.courier = null;
  },
  setStore(state: StateCheckout, store: any) {
    state.store = store;
  },
  setCourier(state: StateCheckout, courier: any) {
    state.courier = courier;
  },
  setMonthlyPlans(state: StateCheckout, monthlyPlans: Array<number>) {
    state.monthlyPlans = monthlyPlans;
  },
  setMonthlyPlansByIndex(state: StateCheckout, item: { index: number, value: number}) {
    state.monthlyPlans[item.index] = item.value;
  },
  setHasMonthlyPayment(state: StateCheckout, hasMonthlyPayment: boolean) {
    state.hasMonthlyPayment = hasMonthlyPayment;
  },
  setHasShippingInsurance(state: StateCheckout, hasShippingInsurance: boolean) {
    state.hasShippingInsurance = hasShippingInsurance;
  },
  toggleHasShippingInsurance(state: StateCheckout) {
    state.hasShippingInsurance = !state.hasShippingInsurance;
  },
  setIsCompleted(state: StateCheckout) {
    const index = state.steps.findIndex((item: IStep) => {
      return item.code == state.step;
    });
    if (index >= 0) {
      state.steps[index].isCompleted = true;
    }
  },
  setPickupPersonName(state: StateCheckout, name: string) {
    state.pickupPerson.name = name;
  },
  setPickupPersonLastName(state: StateCheckout, lastName: string) {
    state.pickupPerson.lastName = lastName;
  },
  setPickupPersonMotherLastName(state: StateCheckout, motherLastName: string) {
    state.pickupPerson.motherLastName = motherLastName;
  },
  setPickupPersonTelephone(state: StateCheckout, telephone: string) {
    state.pickupPerson.telephone = telephone;
  },
  setAcceptTerms(state: StateCheckout, acceptTerms: boolean) {
    state.acceptTerms = acceptTerms;
  },
  setAcceptShippingConditions(
    state: StateCheckout,
    acceptShippingConditions: boolean,
  ) {
    state.acceptShippingConditions = acceptShippingConditions;
  },
  toggleAcceptTerms(state: StateCheckout) {
    state.acceptTerms = !state.acceptTerms;
  },
  toggleAcceptShippingConditions(state: StateCheckout) {
    state.acceptShippingConditions = !state.acceptShippingConditions;
  },
  reset(state: StateCheckout) {
    state.paymentMethodCode = null;
    state.paymentMethods = [];
    // state.paymentTokenizable = null;
    state.paymentMethods = [];
    state.isShipping = null;
    state.requiredBilling = false;
    state.customerAddress = null;
    state.billingCustomerAddress = null;
    state.store = null;
    state.pickupPerson = {
      name: "",
      lastName: "",
      motherLastName: "",
      telephone: "",
    };
    state.courier = null;
    state.monthlyPlans = [];
    state.monthlyPaymentOptions = [];
    state.hasMonthlyPayment = false;
    state.hasShippingInsurance = true;
    state.step = "detail";
    state.steps.forEach((step: IStep) => {
      step.isCompleted = false;
      step.show = true;
    });
    state.acceptTerms = false;
    state.acceptShippingConditions = false;
    state.errorMessage = "";
    state.cvcValidate = "";
    state.paymentTokenizableTemporaly = [];
    // state.deviceSessionId = "";
    // state.trackingId = "";
  },
  resetPayment(state: StateCheckout) {
    state.paymentMethodCode = null;
    state.paymentTokenizable = null;
    state.courier = null;
    const step = state.steps.find((step: IStep) => {
      return step.code == "payment";
    });
    if (step) {
      step.isCompleted = false;
    }
  },
  resetShipping(state: StateCheckout) {
    state.isShipping = null;
    state.customerAddress = null;
    state.store = null;
    state.pickupPerson = {
      name: "",
      lastName: "",
      motherLastName: "",
      telephone: "",
    };
    state.courier = null;
    state.hasShippingInsurance = true;
    const step = state.steps.find((step: IStep) => {
      return step.code == "shipping";
    });
    if (step) {
      step.isCompleted = false;
    }
  },
  resetMonthlyPayment(state: StateCheckout) {
    state.monthlyPlans = [];
    state.monthlyPaymentOptions = [];
    state.hasMonthlyPayment = false;
    const step = state.steps.find((step: IStep) => {
      return step.code == "monthly-payment";
    });
    if (step) {
      step.isCompleted = false;
    }
  },
  setIsLoading(state: StateCheckout, loading: boolean) {
    state.loading = loading;
  },
  setIsSavingTransaction(state: StateCheckout, isSavingTransaction: boolean) {
    state.isSavingTransaction = isSavingTransaction;
  },
  setErrorMessage(state: StateCheckout, errorMessage: string) {
    state.errorMessage = errorMessage;
  },
  setCvcValidate(state: StateCheckout, cvcValidate: string) {
    state.cvcValidate = cvcValidate;
  },
  setPaymentTokenizableTemporaly(
    state: StateCheckout,
    paymentTokenizableTemporaly: BankCard[],
  ) {
    state.paymentTokenizableTemporaly = paymentTokenizableTemporaly;
  },
  addPaymentTokenizableTemporaly(
    state: StateCheckout,
    paymentTokenizableTemporaly: BankCard,
  ) {
    paymentTokenizableTemporaly.creation_date = moment().format();
    state.paymentTokenizableTemporaly.push(paymentTokenizableTemporaly);
  },
  removePaymentTokenizableTemporaly(
    state: StateCheckout,
    paymentTokenizableTemporalyId: string,
  ) {
    state.paymentTokenizableTemporaly =
      state.paymentTokenizableTemporaly.filter((card: BankCard) => {
        return paymentTokenizableTemporalyId != card.id;
      });
  },
  removeOldPaymentTokenizableTemporaly(state: StateCheckout) {
    state.paymentTokenizableTemporaly =
      state.paymentTokenizableTemporaly.filter((card: BankCard) => {
        // Parsear la fecha en string
        const creationDate = moment(card.creation_date);
        const currentDate = moment();

        // Diferencia en horas
        const diffInHours = currentDate.diff(creationDate, "hours");

        return diffInHours < 3;
      });
  },
  setDeviceSessionId(state: StateCheckout, deviceSessionId: string) {
    state.deviceSessionId = deviceSessionId;
  },
  setTrackingId(state: StateCheckout, trackingId: string) {
    state.trackingId = trackingId;
  },
};

const getters = {
  getPaymentMethodCode(state: StateCheckout) {
    return state.paymentMethodCode;
  },
  getPaymentTokenizable(state: StateCheckout) {
    return state.paymentTokenizable;
  },
  getExpiresTokenizable(state: StateCheckout) {
    return state.expiresTokenizable;
  },
  getPaymentMethod(state: StateCheckout) {
    if (state.paymentMethodCode) {
      const methodPayment = state.paymentMethods.find(
        (item: IPaymentMethod) => {
          return item.code == state.paymentMethodCode;
        },
      );
      return methodPayment ? methodPayment : null;
    }
    return null;
  },
  getSelectedPaymentTokenizable(state: StateCheckout) {
    if (state.paymentMethodCode && state.paymentTokenizable) {
      const methodPayment = state.paymentMethods.find(
        (item: IPaymentMethod) => {
          return item.code == state.paymentMethodCode;
        },
      );
      if (methodPayment) {
        const paymentTokenizableTemp = state.paymentTokenizableTemporaly.find(
          (item: BankCard) => {
            return (
              item.id == state.paymentTokenizable &&
              item.payment_method_code == state.paymentMethodCode
            );
          },
        );
        if (paymentTokenizableTemp != undefined) {
          return paymentTokenizableTemp;
        }

        const paymentTokenizable = methodPayment.tokenizables.find(
          (item: BankCard) => {
            return item.id == state.paymentTokenizable;
          },
        );
        return paymentTokenizable ? paymentTokenizable : null;
      }
    }
    return null;
  },
  getPaymentMethods(state: StateCheckout) {
    return state.paymentMethods;
  },
  getCustomerAddress(state: StateCheckout) {
    return state.customerAddress;
  },
  getBillingCustomerAddress(state: StateCheckout) {
    return state.billingCustomerAddress;
  },
  getRequiredBilling(state: StateCheckout) {
    return state.requiredBilling;
  },
  getIsShipping(state: StateCheckout) {
    return state.isShipping;
  },
  getIsPickup(state: StateCheckout) {
    return state.isShipping === false;
  },
  getStore(state: StateCheckout) {
    return state.store;
  },
  getCourier(state: StateCheckout) {
    return state.courier;
  },
  getMonthlyPlans(state: StateCheckout) {
    return state.monthlyPlans;
  },
  getMonthlyPaymentOptions(state: StateCheckout) {
    return state.monthlyPaymentOptions;
  },
  getHasMonthlyPayment(state: StateCheckout) {
    return state.hasMonthlyPayment;
  },
  getHasShippingInsurance(state: StateCheckout) {
    return state.hasShippingInsurance;
  },
  getActivePaymentConfig(state: StateCheckout) {
    const paymentMethod = state.paymentMethods.find((item: IPaymentMethod) => {
      return item.code == state.paymentMethodCode;
    });
    return paymentMethod ? paymentMethod : null;
  },
  getPaymentTokenizableTemporaly(state: StateCheckout) {
    return state.paymentTokenizableTemporaly;
  },
  getPaymentTokenizableTemporalyByMethodPayment:
    (state: StateCheckout) => (code: string) => {
      return state.paymentTokenizableTemporaly.filter((card: BankCard) => {
        return card.payment_method_code == code;
      });
    },
  getDeviceSessionId(state: StateCheckout) {
    return state.deviceSessionId;
  },
  getTrackingId(state: StateCheckout) {
    return state.trackingId;
  },
  getSteps: (state: StateCheckout) => {
    if (state.steps) {
      return state.steps
        .filter((item: IStep) => {
          return item.show;
        })
        .sort((a, b) => a.step - b.step);
    }
    return [];
  },
  getStep(state: StateCheckout) {
    return state.step;
  },
  getIndexStepByCode: (state: StateCheckout) => (code: string) => {
    return state.steps.findIndex((item: IStep) => {
      return item.code == code;
    });
  },
  getActiveStep(state: StateCheckout) {
    const step = state.steps.find((item: IStep) => {
      return item.code == state.step;
    });

    return step ? step : null;
  },
  getNextStep(state: StateCheckout, getters: any) {
    const index = getters.getSteps.findIndex((item: IStep) => {
      return item.code == state.step;
    });
    if (
      steps.length > index + 1 &&
      steps[index + 1] &&
      steps[index + 1].code == "monthly-payment" &&
      !state.hasMonthlyPayment
    ) {
      if (steps.length > index + 2) {
        return steps[index + 2];
      }
    }
    if (steps.length > index + 1) {
      return steps[index + 1];
    }
    return null;
  },
  getPrevStep(state: StateCheckout, getters: any) {
    const steps: Array<IStep> = getters.getSteps;
    const index = steps.findIndex((item: IStep) => {
      return item.code == state.step;
    });
    if (
      0 <= index - 2 &&
      steps[index - 1].code == "monthly-payment" &&
      !state.hasMonthlyPayment
    ) {
      if (steps.length >= index - 2) {
        return steps[index - 2];
      }
    }
    if (0 <= index - 1) {
      return steps[index - 1];
    }
    return null;
  },
  isActiveToken(state: StateCheckout) {
    if (state.expiresTokenizable == null) {
      return true;
    }
    if (state.expiresTokenizable) {
      return (
        moment().isAfter(state.expiresTokenizable) && state.paymentTokenizable
      );
    }
  },
  isValidCVC(state: StateCheckout, getters: any) {
    console.log(getters.getSelectedPaymentTokenizable?.payload?.type_resource);
    console.log(state.paymentMethodCode);
    const creationDate = getters.getSelectedPaymentTokenizable
      ? getters.getSelectedPaymentTokenizable?.payload?.creation_date
      : null;
    const cvc = getters.getSelectedPaymentTokenizable
      ? getters.getSelectedPaymentTokenizable?.payload?.cvc
      : null;
    if (getters.getSelectedPaymentTokenizable) {
      if (
        getters.getSelectedPaymentTokenizable?.payload?.type_resource ==
          "token" &&
        state.paymentMethodCode == "paypal_card_v2"
      ) {
        return true;
      }
      if (!creationDate || creationDate == "" || cvc == "") {
        return false;
      }
      const diffMinutes = moment().diff(moment(creationDate), "minutes");
      if (diffMinutes <= 10) {
        return true;
      }
    }
    return false;
  },
  isValidPayment(state: StateCheckout, getters: any) {
    state.errorMessage = "";
    const activePaymentMethod = getters.getActivePaymentConfig;
    if (state.paymentMethodCode !== null && activePaymentMethod) {
      if (activePaymentMethod.tokenizer && state.paymentTokenizable === null) {
        if (state.paymentMethodCode.includes("card")) {
          state.errorMessage = "Por favor, selecciona tu tarjeta.";
        } else {
          state.errorMessage = "Por favor, inicia sesión.";
        }
        return false;
      }
      return true;
    }
    state.errorMessage = "Por favor, selecciona tu método de pago.";
    return false;
  },
  isValidShipment(state: StateCheckout) {
    state.errorMessage = "";
    if (state.isShipping == null) {
      state.errorMessage =
        "Por favor, selecciona si deseas envío o recolección para tu pedido.";
    } else if (state.isShipping) {
      if (state.customerAddress !== null && state.courier !== null) {
        return true;
      } else {
        state.errorMessage = "Por favor, selecciona ";
        if (state.customerAddress == null) {
          state.errorMessage += "tu dirección de envío";
        }
        if (state.customerAddress == null && state.courier == null) {
          state.errorMessage += " y ";
        }
        if (state.courier == null) {
          state.errorMessage += "la paquetería que deseas";
        }
        state.errorMessage += ".";
      }
    } else if (state.isShipping === false) {
      if (state.store != null) {
        return true;
      } else {
        state.errorMessage += "Por favor, selecciona la tienda deseada.";
      }
    }
    return false;
  },
  isValidMonthlyPayment(state: StateCheckout) {
    state.errorMessage = "";
    if (
      state.hasMonthlyPayment &&
      state.monthlyPlans.length > 0 &&
      state.monthlyPlans.filter((item: number | null) => {
        return item == null;
      }).length == 0
    ) {
      return true;
    }
    if (!state.hasMonthlyPayment) {
      state.errorMessage += "Por favor, selecciona la mensualidad deseada.";
      return false;
    }
    return false;
  },
  isValidReview(state: StateCheckout, getters: any) {
    state.errorMessage = "";
    const activePaymentMethod = getters.getActivePaymentConfig;
    const regex = /^\d{3,4}$/;
    if (state.requiredBilling && state.billingCustomerAddress == null) {
      state.errorMessage += "Por favor, selecciona una dirección de facutación";
      return false;
    }

    if (
      activePaymentMethod &&
      activePaymentMethod.secure_code_required &&
      !getters.isValidCVC
    ) {
      if (state.cvcValidate && regex.test(state.cvcValidate)) {
        return true;
      } else {
        state.errorMessage +=
          "Por favor, ingresa un código de seguridad válido.";
        return false;
      }
    }

    state.errorMessage = "Errr";
    return true;
  },
  getPickupPerson(state: StateCheckout) {
    return state.pickupPerson;
  },
  getPickupPersonName(state: StateCheckout) {
    return state.pickupPerson.name;
  },
  getPickupPersonLastName(state: StateCheckout) {
    return state.pickupPerson.lastName;
  },
  getPickupPersonMotherLastName(state: StateCheckout) {
    return state.pickupPerson.motherLastName;
  },
  getPickupPersonTelephone(state: StateCheckout) {
    return state.pickupPerson.telephone;
  },
  getIsLoading(state: StateCheckout) {
    return state.loading;
  },
  getIsSavingTransaction(state: StateCheckout) {
    return state.isSavingTransaction;
  },
  getIsLastStep(state: StateCheckout, getters: any) {
    const steps: Array<IStep> = getters.getSteps;
    const index = steps.findIndex((item: IStep) => {
      return item.code == state.step;
    });
    if (index == steps.length - 1) {
      return true;
    }
    return false;
  },
  getAcceptTerms(state: StateCheckout) {
    return state.acceptTerms;
  },
  getAcceptShippingConditions(state: StateCheckout) {
    return state.acceptShippingConditions;
  },
  getErrorMessage(state: StateCheckout) {
    return state.errorMessage;
  },
  getCvcValidate(state: StateCheckout) {
    return state.cvcValidate;
  },
};

const actions: ActionTree<StateCheckout, unknown> = {
  async storeShipping({ commit, dispatch }) {
    commit("setIsLoading", true);
    const { ShippingService } = await import("@/services/ShippingService");
    const shippingService = new ShippingService();
    shippingService
      .storeShipping()
      .then((response: any) => {
        if (response) {
          const data: any = dataFormatter.deserialize(response);
          commit("cart/setCartFormatDB", data, { root: true });
          dispatch("nextStep");
        } else {
          commit("cart/resetCart", { root: true });
        }
        commit("setIsLoading", false);
      })
      .catch(function (reason: AxiosError) {
        console.log(reason);
        commit("setIsLoading", false);

        showErrors(reason, `Ocurrio un error al guardar el envío`);
      });
  },
  async storePayment({ state, commit, dispatch, getters }) {
    const { PaymentMethodService } = await import(
      "@/services/PaymentMethodService"
    );
    const paymentMethodService = new PaymentMethodService();
    const seletedPlans: Array<number> = [];
    if (getters.isValidPayment && state.paymentMethodCode) {
      commit("setIsLoading", true);
      paymentMethodService
        .getMonthlyPayment(state.paymentMethodCode, state.paymentTokenizable)
        .then((response: any) => {
          if (response.meta) {
            let hasMonth = false;
            if (
              response.meta.monthly_payment_options &&
              response.meta.monthly_payment_options.length > 1
            ) {
              hasMonth = true;
            } else if (response.meta.monthly_payment_options) {
              response.meta.monthly_payment_options.forEach(
                (element: { max_months: number }) => {
                  if (element.max_months > 1) {
                    hasMonth = true;
                  } else {
                    // seletedPlans.push(0);
                  }
                },
              );
            }
            commit("resetMonthlyPayment");
            commit("setHasMonthlyPayment", hasMonth);
            const stepIndex = getters.getIndexStepByCode("monthly-payment");
            if (stepIndex >= 0) {
              state.steps[stepIndex].show = hasMonth;
            }
            if (!hasMonth) {
              commit("setMonthlyPlans", seletedPlans);
              dispatch("storeMonthlyPayment");
            } else {
              dispatch("nextStep");
            }
          }
          commit("setIsLoading", false);
        })
        .catch(function (reason: AxiosError) {
          console.log(reason);
          commit("setIsLoading", false);

          showErrors(reason, `Ocurrio un error al guardar el método de pago`);
        });
    }
  },
  async storeMonthlyPayment({ commit, dispatch, getters }) {
    commit("setIsLoading", true);
    const { PaymentMethodService } = await import(
      "@/services/PaymentMethodService"
    );
    const selectedPlans = getters.getMonthlyPlans;
    if (selectedPlans.length > 0) {
      const paymentMethodService = new PaymentMethodService();
      paymentMethodService
        .storeMonthlyPayment()
        .then((response: any) => {
          if (response) {
            const data: any = dataFormatter.deserialize(response);
            commit("cart/setCartFormatDB", data, { root: true });
            dispatch("nextStep");
          } else {
            commit("cart/resetCart", { root: true });
          }
          commit("setIsLoading", false);
        })
        .catch(function (reason: AxiosError) {
          console.log(reason);
          commit("setIsLoading", false);
          showErrors(
            reason,
            `Ocurrio un error al guardar las mensualidades de pago`,
          );
        });
    } else {
      dispatch("nextStep");
    }
  },
  async nextStep({ commit, getters, dispatch }) {
    commit("setErrorMessage", "");
    if (getters.getIsLastStep) {
      document.body.style.overflow = "hidden";

      commit("setIsSavingTransaction", true);
      commit("setIsLoading", true);
      const { TransactionService } = await import(
        "@/services/TransactionService"
      );
      const transactionService = new TransactionService();
      transactionService
        .createTransaction()
        .then((response: any) => {
          if (response) {
            const data = dataFormatter.deserialize(response.data);
            const transaction = new Transaction(data as ITransaction);

            dispatch("resetCheckout");
            commit("cart/resetCart", null, { root: true });

            if (response?.data?.meta?.redirectTo) {
              window.location = response.data.meta.redirectTo;
            } else if (
              transaction.payment_status ==
              TRANSACTIONS_ORDERS_PAYMENT_STATUS_PAYED
            ) {
              router.push({
                name: "success-payment",
                params: { transactionId: transaction.id },
              });
            } else if (
              transaction.payment_status ==
                TRANSACTIONS_ORDERS_PAYMENT_STATUS_PENDING ||
              transaction.payment_status ==
                TRANSACTIONS_ORDERS_PAYMENT_STATUS_PARTIALLY_PAYED
            ) {
              router.push({
                name: "pending-payment",
                params: { transactionId: transaction.id },
              });
            }
          }
        })
        .catch(function (reason: AxiosError) {
          dispatch("resetDeviceSessionId");
          showErrors(reason, `Ocurrio un error al procesar el pago`, -1);
        })
        .finally(() => {
          commit("setIsLoading", false);
          commit("setIsSavingTransaction", false);
          document.body.style.overflow = "auto";
        });
    }
    if (getters.getNextStep) {
      eventBus.emit("next-step", {
        beforePath: getters.getActiveStep.to,
        nextPath: getters.getNextStep.to,
      });
      commit("setIsCompleted");
      commit("setStep", getters.getNextStep.code);
    }
  },
  resetCheckout({ commit, dispatch }, force: false) {
    commit("reset");
    if (force) {
      commit("setPaymentTokenizable", null);
      dispatch("resetDeviceSessionId");
    }
  },
  resetDeviceSessionId({ commit }) {
    let deviceSessionId = null;
    if (window.OpenPay) {
      window.OpenPay.setId(VITE_OPENPAY_MERCHANT_ID);
      window.OpenPay.setApiKey(VITE_OPENPAY_PUBLIC_API_KEY);
      window.OpenPay.setSandboxMode(VITE_OPENPAY_IS_SANDBOX);
      deviceSessionId = window.OpenPay.deviceData
        ? window.OpenPay.deviceData.setup()
        : null;
    }
    commit("setDeviceSessionId", deviceSessionId);
  },
  addPaymentTokenizable({ state }, paymentTokenizable: BankCard) {
    if (state.paymentMethodCode) {
      const methodPayment: IPaymentMethod | undefined =
        state.paymentMethods.find((item: IPaymentMethod) => {
          return item.code == state.paymentMethodCode;
        });
      if (methodPayment) {
        methodPayment.tokenizables.push(paymentTokenizable);
      }
    }
  },
  removePaymentTokenizable({ state }, paymentTokenizable: BankCard) {
    if (state.paymentMethodCode) {
      const methodPayment: IPaymentMethod | undefined =
        state.paymentMethods.find((item: IPaymentMethod) => {
          return item.code == state.paymentMethodCode;
        });
      if (methodPayment) {
        methodPayment.tokenizables = methodPayment.tokenizables.filter(
          (item: BankCard) => item.id != paymentTokenizable.id,
        );
      }
    }
  },
  verifyTokenizable({ state, commit }) {
    console.log("verifyTokenizable");
    if (state.paymentTokenizable) {
      const tokenizesPaypal: IPaymentMethod | undefined =
        state.paymentMethods.find((item: IPaymentMethod) => {
          return item.code == "paypal_account";
        });
      console.log(state.paymentMethods);
      console.log(tokenizesPaypal);
      if (tokenizesPaypal) {
        const paymentTokenizable = tokenizesPaypal.tokenizables.find(
          (item: BankCard) => {
            return item.id == state.paymentTokenizable;
          },
        );
        console.log(paymentTokenizable);
        if (!paymentTokenizable) {
          commit("setPaymentTokenizable", null);
        }
      }
    }
  },
};

export const checkout = {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
