export const TRANSACTIONS_ORDERS_STATUS_NEW = 1;
export const TRANSACTION_DETAIL_STATUS_EMAIL_ON_TRANSFER = 12; // calculated
export const TRANSACTIONS_ORDERS_STATUS_OLD = 13; // calculated
export const TRANSACTIONS_ORDERS_STATUS_CONFIRMED = 2;
export const TRANSACTIONS_ORDERS_STATUS_PARTIALLY_ORDERED = 3;
export const TRANSACTIONS_ORDERS_STATUS_ORDERED = 4;
export const TRANSACTIONS_ORDERS_STATUS_CANCELLED = 5;
export const TRANSACTIONS_ORDERS_STATUS_CLOSED = 6;
export const TRANSACTIONS_ORDERS_STATUS_SCANNING = 7;
export const TRANSACTIONS_ORDERS_STATUS_DELIVERED = 9;
