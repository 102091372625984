export function numberWithCommas(x: number) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function formatPrice(
  price: number,
  html: boolean = true,
  classes: string = "",
) {
  const priceFixed = Number.parseFloat((price * 1).toFixed(2)) || 0;
  const getHtml = (sign: string, int: string, dec: string) => {
    return (
      `<span class="position-relative d-inline-block text-nowrap">` +
      `<span class="fw-light d-inline-block ${classes}" style="scale: 0.75">$</span>` +
      `<span class="d-inline-block ${classes}">${sign}${int}</span>` +
      `<span class="d-inline-block ${classes}" style="scale: 0.7; transform-origin: 0 0;">.${dec}</span>` +
      `</span>`
    );
  };
  if (isNaN(priceFixed)) {
    if (html) {
      return getHtml("", "0", "00");
    }
    return "$ 0.00";
  }
  const int = parseInt(priceFixed.toString());
  const dec = Math.abs(priceFixed - int)
    .toFixed(2)
    .replace("0.", "");
  const intFormatted = numberWithCommas(Math.abs(int));
  const negative = priceFixed < 0 ? "-" : "";
  if (html) {
    return getHtml(negative, intFormatted, dec);
  }
  return `$ ${negative}${intFormatted}.${dec}`;
}
