<template>
  <div class="container-fluid px-lg-1 text-center mb-4 placeholder-glow mt-1">
    <div class="row">
      <div v-for="index in 6" :key="index" class="col-4 mb-3">
        <span
          class="placeholder w-100 py-3 bg-body-secondary p-5 rounded-4"
          style="height: 230px"
        >
        </span>
      </div>
    </div>
  </div>
</template>
