<template>
  <div v-if="isUserAuthenticated">
    <drop-down-menu-profile />
  </div>
  <link-nav
    v-if="!isUserAuthenticated"
    :to="'/login'"
    :text="'Iniciar sesión'"
    component="UserSvg"
    component-hover="UserFillSvg"
    class="box-search-bar text-uppercase"
    align-icon="center"
  />
</template>

<script lang="ts">
import LinkNav from "@/components/buttons/LinkNav.vue";
import DropDownMenuProfile from "./DropDownMenuProfile.vue";
import auth from "@/services/AuthService";

export default {
  components: {
    LinkNav,
    DropDownMenuProfile,
  },
  computed: {
    isUserAuthenticated() {
      return auth.isUserLogged();
    },
    loggedInUser() {
      return auth.isUserLogged();
    },
  },
};
</script>

<style lang="scss" scoped>
.pointer {
  cursor: pointer;
}
</style>
