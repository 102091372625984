import { ActionTree } from "vuex";
// define your typings for the store state
export interface AssemblyGroup {
  id: string;
  product_id: string;
  max: number;
}

export interface AssemblyConfig {
  id: string | null;
  name: string;
}

export interface AssemblyProduct {
  product: {
    id: string;
    current_price: number;
  };
  assemblyId: string | null;
  assemblyGroup: string;
  assemblyConfigId: string | null;
  qty: number;
  total: number;
}

export interface StateAssembly {
  assemblyGroups: Array<any>;
  maxAssemblyGroups: Array<AssemblyGroup>;
  products: Array<AssemblyProduct>;
  lists: Array<Array<AssemblyProduct>>;
  assemblyConfigs: Array<AssemblyConfig>;
  selectedAssemblyConfigId: string | null;
}

const state = () => ({
  maxAssemblyGroups: [],
  assemblyGroups: [],
  products: [],
  lists: [],
  assemblyConfigs: [],
  selectedAssemblyConfigId: null,
});

// mutations
const mutations = {
  setAssemblyGroups(state: StateAssembly, assemblyGroups: any) {
    state.assemblyGroups = assemblyGroups;
  },
  updateActiveAssemblyGroup(state: StateAssembly, assemblyGroup: any) {
    const existAssemblyGroup = state.assemblyGroups.findIndex(
      (item: any) => item.id == assemblyGroup.id,
    );
    if (existAssemblyGroup >= 0) {
      state.assemblyGroups[existAssemblyGroup].isActive =
        assemblyGroup.isActive;
    }
  },
  setSelectedAssemblyConfig(state: StateAssembly, assemblyConfigId: any) {
    state.selectedAssemblyConfigId = assemblyConfigId;
  },
  resetAssemblyConfig(state: StateAssembly) {
    state.selectedAssemblyConfigId = null;
  },
  resetNewAssemblyConfig(state: StateAssembly) {
    const newAssemblyConfigIndex = state.assemblyConfigs.findIndex(
      (item: any) => {
        return item.id == null;
      },
    );
    if (newAssemblyConfigIndex >= 0) {
      state.assemblyConfigs[newAssemblyConfigIndex].name = "Nuevo ensamble";
    }
  },
  setSelectedAssemblyConfigName(
    state: StateAssembly,
    assemblyConfigName: string,
  ) {
    const index = state.assemblyConfigs.findIndex((item: any) => {
      return item.id == state.selectedAssemblyConfigId;
    });
    if (index < 0 && state.selectedAssemblyConfigId == null) {
      state.assemblyConfigs.push({
        id: null,
        name: assemblyConfigName,
      });
    } else {
      state.assemblyConfigs[index].name = assemblyConfigName;
    }
  },
  setAssemblyConfigs(state: StateAssembly, assemblyConfigs: any) {
    const newAssemblyConfig = state.assemblyConfigs.find((item: any) => {
      return item.id == null;
    });
    state.assemblyConfigs = assemblyConfigs;
    if (newAssemblyConfig) {
      state.assemblyConfigs.push(newAssemblyConfig);
    } else {
      state.assemblyConfigs.push({
        id: null,
        name: "Nuevo ensamble",
      });
    }
  },
  addProduct(state: StateAssembly, product: any) {
    product.assemblyConfigId = state.selectedAssemblyConfigId;
    product.total = product.product.current_price * product.qty;
    state.products.push(product);
  },
  updateProduct(state: StateAssembly, product: any) {
    const existProduct = state.products
      .filter(
        (item: any) => item.assemblyConfigId == state.selectedAssemblyConfigId,
      )
      .findIndex((item: any) => item.product.id == product.id);
    if (existProduct >= 0) {
      state.products[existProduct].qty = product.qty;
      state.products[existProduct].assemblyGroup = product.assemblyGroup;
      state.products[existProduct].total =
        state.products[existProduct].product.current_price *
        state.products[existProduct].qty;
      if (state.products[existProduct].qty == 0) {
        state.products = state.products.filter(
          (item: any) => item.product.id != product.id,
        );
        state.maxAssemblyGroups = state.maxAssemblyGroups.filter(
          (item: any) => item.product_id != product.id,
        );
      }
    }
  },
  deleteProduct(state: StateAssembly, productId: string) {
    const productsOtherAssemblyConfig = state.products.filter(
      (item: any) => item.assemblyConfigId != state.selectedAssemblyConfigId,
    );
    const productsSameAssemblyConfig = state.products.filter(
      (item: any) =>
        item.product.id != productId &&
        item.assemblyConfigId == state.selectedAssemblyConfigId,
    );
    state.products = [
      ...productsOtherAssemblyConfig,
      ...productsSameAssemblyConfig,
    ];
    const existSameProductId = state.products.findIndex(
      (item: any) => item.product.id == productId,
    );
    if (existSameProductId == -1) {
      state.maxAssemblyGroups = state.maxAssemblyGroups.filter(
        (item: any) => item.product_id != productId,
      );
    }
  },
  resetProducts(state: StateAssembly) {
    state.products = state.products.filter(
      (item: any) => item.assemblyConfigId != state.selectedAssemblyConfigId,
    );
  },
  resetLists(state: StateAssembly) {
    state.lists = [];
  },
  saveList(state: StateAssembly) {
    const newAssembly = state.products.filter(
      (item: any) => item.assemblyConfigId == null,
    );
    state.lists.push(newAssembly);
  },
  updateAssemblyGroup(state: StateAssembly, assemblyGroup: any) {
    const existGroupByProduct = state.maxAssemblyGroups.findIndex(
      (group: any) =>
        group.product_id == assemblyGroup.product_id &&
        group.id == assemblyGroup.id,
    );
    if (existGroupByProduct == -1) {
      state.maxAssemblyGroups.push(assemblyGroup);
    } else {
      state.maxAssemblyGroups[existGroupByProduct].max = assemblyGroup.max;
    }
  },
  setAssemblyId(state: StateAssembly, assemblyId: string) {
    state.products
      .filter(
        (item: any) => item.assemblyConfigId == state.selectedAssemblyConfigId,
      )
      .forEach((item: any) => {
        return (item.assemblyId = assemblyId);
      });
  },
};

const getters = {
  getAssemblyGroups(state: StateAssembly) {
    return state.assemblyGroups;
  },
  getTotalComponents(state: StateAssembly) {
    return state.assemblyGroups.filter((group: any) => !group.is_accessory)
      .length;
  },
  getTotalAccessories(state: StateAssembly) {
    return state.assemblyGroups.filter((group: any) => group.is_accessory)
      .length;
  },
  getSelectedAssemblyConfigId(state: StateAssembly) {
    return state.selectedAssemblyConfigId;
  },
  getSelectedAssemblyConfig(state: StateAssembly) {
    if (state.assemblyConfigs.length > 0) {
      const exist = state.assemblyConfigs.find((item: any) => {
        return item.id == state.selectedAssemblyConfigId;
      });
      if (exist != undefined) {
        return exist;
      }
    }
    return {
      id: null,
      name: "",
    };
  },
  getAssemblyConfigs(state: StateAssembly) {
    return state.assemblyConfigs;
  },
  getProducts(state: StateAssembly) {
    return state.products.filter(
      (item: any) => item.assemblyConfigId == state.selectedAssemblyConfigId,
    );
  },
  getFormatedProducts(_state: StateAssembly, getters: any) {
    const formattedProducts = [];
    const products = getters.getProducts;
    for (let index = 0; index < products.length; index++) {
      const element = {
        product_id: products[index].product.id,
        qty: products[index].qty,
      };
      formattedProducts.push(element);
    }

    return formattedProducts;
  },
  getIdProducts(_state: StateAssembly, getters: any) {
    const idProducts = [];
    const products = getters.getProducts;
    for (let index = 0; index < products.length; index++) {
      idProducts.push(products[index].product.id);
    }

    return idProducts;
  },
  getProductsByAssemblyGroup:
    (_state: StateAssembly, getters: any) => (assemblyGroupId: string) => {
      return getters.getProducts.filter((product: AssemblyProduct) => {
        return product.assemblyGroup == assemblyGroupId;
      });
    },
  hasProductsByAssemblyGroup:
    (_state: StateAssembly, getters: any) => (assemblyGroupId: string) => {
      const products = getters.getProducts.filter(
        (product: AssemblyProduct) => {
          return product.assemblyGroup == assemblyGroupId;
        },
      );
      return products.length > 0;
    },
  getTotalAssemblyComponentsSelected(state: StateAssembly, getters: any) {
    const groupsWithProducts = getters.getProducts
      .map((product: AssemblyProduct) => {
        return product.assemblyGroup;
      })
      .filter((assemblyGroup: string) => {
        const find = state.assemblyGroups.find((item: any) => {
          return item.id == assemblyGroup;
        });
        return find && !find.is_accessory;
      });
    const uniqueGroupsWithProducts = [...new Set(groupsWithProducts)];
    return uniqueGroupsWithProducts.length;
  },
  getTotalAssemblyAccessoriesSelected(state: StateAssembly, getters: any) {
    const groupsWithProducts = getters.getProducts
      .map((product: AssemblyProduct) => {
        return product.assemblyGroup;
      })
      .filter((assemblyGroupId: string) => {
        const find = state.assemblyGroups.find((item: any) => {
          return item.id == assemblyGroupId;
        });
        return find && find.is_accessory;
      });
    const uniqueGroupsWithProducts = [...new Set(groupsWithProducts)];
    return uniqueGroupsWithProducts.length;
  },
  getTotal(_state: StateAssembly, getters: any) {
    let total = 0;
    getters.getProducts.forEach((product: any) => {
      total += product.total * 1;
    });
    return total;
  },
  getMaxByAssemblyGroup:
    (state: StateAssembly) => (assemblyGroupId: string) => {
      return state.maxAssemblyGroups
        .filter((item: any) => {
          return item.id == assemblyGroupId;
        })
        .reduce((max: number, item: any) => {
          return item.max > max ? item.max : max;
        }, 1);
    },
  getTotalByAssemblyGroup:
    (_state: StateAssembly, getters: any) => (assemblyGroupId: string) => {
      return getters.getProducts
        .filter((item: any) => {
          return item.assemblyGroup == assemblyGroupId;
        })
        .reduce((total: number, item: any) => {
          return total + item.qty;
        }, 0);
    },
  isAssemblyCompleted(state: StateAssembly, getters: any) {
    let isCompleted = true;
    state.assemblyGroups
      .filter((item: any) => {
        return item.is_required;
      })
      .forEach((item: any) => {
        const products = getters.getProducts.filter(
          (product: AssemblyProduct) => {
            return product.assemblyGroup == item.id;
          },
        );
        if (products.length <= 0) {
          isCompleted = false;
        }
      });
    return isCompleted;
  },
  findAssemblyGroupByCategory:
    (state: StateAssembly) => (categoryId: string) => {
      return state.assemblyGroups.find((group: any) => {
        const find = group.categories.find((category: any) => {
          return category.id == categoryId;
        });
        return find != undefined;
      });
    },
};

const actions: ActionTree<StateAssembly, any> = {
  addProduct({ getters, commit }, newProduct: any) {
    const max = getters.getMaxByAssemblyGroup(newProduct.assemblyGroup);
    const total = getters.getTotalByAssemblyGroup(newProduct.assemblyGroup);
    const existProduct = getters.getProducts.find(
      (item: any) => item.product.id == newProduct.product.id,
    );
    if (max >= total + 1) {
      if (existProduct == undefined) {
        newProduct.qty = 1;
        newProduct.total = newProduct.product.current_price * newProduct.qty;
        commit("addProduct", newProduct);
      } else {
        commit("updateProduct", {
          id: newProduct.product.id,
          assemblyGroup: newProduct.assemblyGroup,
          qty: existProduct.qty + 1,
        });
      }
    }
  },
  addProducts({ getters, commit, state }, products: Array<any>) {
    commit("resetProducts");
    products.forEach((detail: any, index: number) => {
      const assemblyGroup = getters.findAssemblyGroupByCategory(
        detail.product.category_id,
      );
      const newProduct = {
        product: detail.product,
        assemblyGroup: assemblyGroup != undefined ? assemblyGroup.id : null,
        assemblyConfigId: state.selectedAssemblyConfigId,
        qty: detail.qty,
        total: detail.qty * detail.product.current_price,
      };
      commit("addProduct", newProduct);
      if (detail.assemblyConfig && index == 0) {
        commit("setSelectedAssemblyConfig", detail.assemblyConfig.id);
      }
    });
  },
  addProductsFromCart({ getters, commit, state }, products: Array<any>) {
    commit("resetProducts");
    products.forEach((detail: any, index: number) => {
      const assemblyGroup = getters.findAssemblyGroupByCategory(
        detail.product.category_id,
      );
      const newProduct = {
        product: detail.product,
        assemblyGroup: assemblyGroup != undefined ? assemblyGroup.id : null,
        assemblyConfigId: state.selectedAssemblyConfigId,
        assemblyId: detail.assembly_id,
        qty: detail.quantity,
        total: detail.qty * detail.product.current_price,
      };
      commit("addProduct", newProduct);
      if (detail.assemblyConfig && index == 0) {
        commit("setSelectedAssemblyConfig", detail.assemblyConfig.id);
      }
    });
  },
  updateQtyProduct({ getters, commit }, product: any) {
    const max = getters.getMaxByAssemblyGroup(product.assemblyGroupId);
    const total = getters.getTotalByAssemblyGroup(product.assemblyGroupId);
    const existProduct = getters.getProducts.find(
      (item: any) => item.product.id == product.productId,
    );
    if (
      existProduct != undefined &&
      max >= total + product.qty - existProduct.qty
    ) {
      commit("updateProduct", {
        id: product.productId,
        assemblyGroup: product.assemblyGroup,
        qty: product.qty,
      });
    }
  },
  updateAssemblyGroups({ commit }, assemblyGroups: Array<any>) {
    assemblyGroups.forEach((assemblyGroup) => {
      commit("updateAssemblyGroup", {
        id: assemblyGroup.id,
        product_id: assemblyGroup.product_id,
        max: assemblyGroup.max,
      });
    });
  },
  setAssemblyGroups({ commit, state, getters }, assemblyGroups: Array<any>) {
    commit("setAssemblyGroups", assemblyGroups);
    const withoutAssemblyGroup = state.products.filter(
      (item: any) => item.assemblyGroup == null,
    );
    if (withoutAssemblyGroup.length > 0) {
      withoutAssemblyGroup.forEach((product: any) => {
        const assemblyGroup = getters.findAssemblyGroupByCategory(
          product.product.category_id,
        );
        commit("updateProduct", {
          id: product.product.id,
          assemblyGroup: assemblyGroup != undefined ? assemblyGroup.id : null,
          qty: product.qty,
        });
      });
    }
  },
  duplicateAssemblyConfig({ commit, getters }) {
    const selectedAssemblyConfig = getters.getSelectedAssemblyConfig;
    const newName: string = selectedAssemblyConfig
      ? selectedAssemblyConfig.name + " - copy"
      : "copy";
    const products: Array<any> = getters.getProducts;
    commit("resetAssemblyConfig");
    commit("setSelectedAssemblyConfigName", newName);
    commit("resetLists");
    products.forEach((product) => {
      const newProduct = { ...product };
      newProduct.assemblyConfigId = null;
      commit("addProduct", newProduct);
    });
  },
};

export const assembly = {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
