<template lang="">
  <div>Garantías perfil</div>
</template>
<script lang="ts">
import TabTitle from "@/interfaces/TabTitle";

export default {
  data() {
    TabTitle("Garantías");
  },
};
</script>
<style lang=""></style>
