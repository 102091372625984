import { App } from "vue";
import router from "./router";
import * as Sentry from "@sentry/vue";
import { AxiosError, CanceledError } from "axios";

export default function initSentry(app: App) {
  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 0.7, //  Capture 70% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", import.meta.env.VITE_API_ENDPOINT],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    beforeSend(event, hint) {
      const error = hint.originalException;

      // Ignora errores comunes de autenticación y validación
      if (
        (error instanceof AxiosError &&
          (error.response?.status === 401 ||
            error.response?.status === 403 ||
            error.response?.status === 422)) /* ||
            error.code === "ERR_NETWORK"*/ ||
        error instanceof CanceledError
      ) {
        // Ignora el error
        return null;
      }

      // Deja pasar otros errores
      return event;
    },
  });
}
