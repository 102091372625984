import { ActionTree } from "vuex";
import eventBus from "@/event-bus";
import { formatPrice } from "@/utils/general";
// import { ItemCart } from "@/interfaces/ItemCart"

// define your typings for the store state

interface Cart {
  count: number;
  subtotal: number;
  tax: number;
  total: number;
  ivaPercentage: number;
  pickupStoreId: number;
  shipping: number;
  shippingDiscount: number;
  shippingInsurance: number;
  shippingMinAmount: number;
  shippingRequired: boolean;
  totalDiscount: number;
  promotionDiscount: number;
  couponDiscount: number;
  hasCoupon: boolean;
  couponCode: string;
  hasShipping: boolean;
  hasShippingInsurance: boolean;
  insurancePerThousand: number;
  walletPromotionAmount: number;
  walletPromotionDetails: Array<any>;
  shippingPromotionId: number;
  shippingPromotionProducts: Array<any>;
  pickupPerson: any;
  creditToUse: number;
  walletToUse: number;
  totalWithCreditAndWallet: number;
  maxWalletAmount: number;
  monthlyPaymentGroups: Array<any>;
}

interface LocalTotalCart {
  count: number;
  subtotal: number;
  total: number;
  newShipping: number;
  newShippingInsurance: number;
  totalDiscount: number;
  tax: number;
  shipping: number;
  shippingInsurance: number;
}
export interface StateCart {
  localStorageProducts: any;
  localStorageMeta: LocalTotalCart;
  totalItems: number;
  items: StateLocalProductInCart[];
  cart: Cart;
}

interface StateLocalProductInCart {
  productId: number;
  qty: number;
}
// const meta = localStorage.getItem("meta");
// const products = localStorage.getItem("products");
// const productsId = localStorage.getItem("productsId");

function getCalculatedShippingInsurance(state: StateCart) {
  const total =
    (state.cart.subtotal - state.cart.promotionDiscount + state.cart.shipping) *
    (1 + state.cart.ivaPercentage / 100);
  return Math.ceil(total / 1000) * +state.cart.insurancePerThousand;
}

export const defaultCartInit = {
  version: 1.1,
  totalItems: 0,
  // items: productsId ? JSON.parse(productsId) : [],
  // localStorageMeta: meta ? JSON.parse(meta) : "",
  // localStorageProducts: products ? JSON.parse(products) : [],
  items: [],
  localStorageMeta: {
    count: 0,
    subtotal: 0,
    total: 0,
    newShipping: 0,
    newShippingInsurance: 0,
    totalDiscount: 0,
    tax: 0,
    shipping: 0,
    shippingInsurance: 0,
  },
  localStorageProducts: [],

  cart: {
    count: 0,
    subtotal: 0,
    tax: 0,
    total: 0,
    ivaPercentage: 0,
    pickupStoreId: 0,
    shipping: 0,
    shippingInsurance: 0,
    shippingDiscount: 0,
    shippingMinAmount: 0,
    shippingRequired: false,
    totalDiscount: 0,
    promotionDiscount: 0,
    couponDiscount: 0,
    hasCoupon: false,
    couponCode: "",
    hasShipping: false,
    hasShippingInsurance: false,
    insurancePerThousand: 0,
    walletPromotionAmount: 0,
    walletPromotionDetails: [],
    shippingPromotionId: 0,
    shippingPromotionProducts: [],
    pickupPerson: null,
    creditToUse: 0,
    walletToUse: 0,
    maxWalletAmount: 0,
    totalWithCreditAndWallet: 0,
    monthlyPaymentGroups: [],
  },
};

const state = () => defaultCartInit;

// mutations
const mutations = {
  clearLocalStorageProducts(state: StateCart) {
    state.localStorageProducts = [];
    state.localStorageMeta = {
      count: 0,
      subtotal: 0,
      total: 0,
      newShipping: 0,
      newShippingInsurance: 0,
      totalDiscount: 0,
      tax: 0,
      shipping: 0,
      shippingInsurance: 0,
    };
    state.items = [];
  },
  restProductInCart(state: StateCart) {
    state.items = [];
  },
  setLocalStorageMeta(state: StateCart, meta: any) {
    state.localStorageMeta = meta;
  },
  setLocalStorageProducts(state: StateCart, products: any) {
    state.localStorageProducts = products;
  },
  setItems(state: StateCart, items: Array<any>) {
    state.items = items;
  },

  removeProductInLocal(state: StateCart, productId: number) {
    const index = state.items.findIndex((item) => item.productId === productId);
    if (index !== -1) {
      state.totalItems -= state.items[index].qty;
      state.items.splice(index, 1);
      // localStorage.setItem("productsId", JSON.stringify(state.items));
    }
  },

  addProductTotalItems(state: StateCart, qty: number) {
    state.totalItems = state.totalItems + qty;
  },

  // localStorageProducts(state: StateCart) {
  //   const products = localStorage.getItem("products");
  //   if (products) {
  //     state.localStorageProducts = JSON.parse(products);
  //   } else {
  //     state.localStorageProducts = [];
  //   }
  // },

  setCart(state: StateCart, cart: Cart) {
    state.cart = cart;
  },
  setCartFormatDB(state: StateCart, data: any) {
    const cart: Cart = {
      count: data?.count ?? 0,
      total: +data?.total * 1,
      subtotal: data?.subtotal,
      tax: data?.tax,
      ivaPercentage: data?.iva_percentage,
      pickupStoreId: data?.pickup_store_id,
      shipping: data?.shipping,
      shippingInsurance: data?.shipping_insurance,
      shippingDiscount: data?.shipping_discount,
      shippingMinAmount: data?.shipping_min_amount,
      shippingRequired: data?.shipping_required,
      totalDiscount: data?.total_discount,
      promotionDiscount: data?.promotion_discount,
      couponDiscount: data?.coupon_discount,
      hasCoupon: data?.has_coupon,
      couponCode: data?.coupon_code,
      hasShipping: data?.has_shipping,
      hasShippingInsurance: data?.has_shipping_insurance,
      insurancePerThousand: data?.insurance_per_thousand,
      walletPromotionAmount: data?.wallet_promotion_amount,
      walletPromotionDetails: data?.wallet_promotion_details,
      shippingPromotionId: data?.shipping_promotion_id,
      shippingPromotionProducts: data?.shipping_promotion_products,
      pickupPerson: data?.pickup_person,
      creditToUse: data?.credit_to_use,
      walletToUse: data?.wallet_to_use,
      maxWalletAmount: data?.max_wallet_amount,
      totalWithCreditAndWallet: data?.total_with_credit_and_wallet,
      monthlyPaymentGroups: [],
    };
    state.cart = cart;
  },
  resetLocalCart(state: StateCart) {
    state.localStorageMeta = {
      count: 0,
      subtotal: 0,
      total: 0,
      newShipping: 0,
      newShippingInsurance: 0,
      totalDiscount: 0,
      tax: 0,
      shipping: 0,
      shippingInsurance: 0,
    };
    state.localStorageProducts = [];
    state.items = [];
  },
  resetCart(state: StateCart) {
    state.cart = {
      count: 0,
      subtotal: 0,
      tax: 0,
      total: 0,
      ivaPercentage: 0,
      pickupStoreId: 0,
      shipping: 0,
      shippingInsurance: 0,
      shippingMinAmount: 0,
      shippingRequired: false,
      shippingDiscount: 0,
      totalDiscount: 0,
      promotionDiscount: 0,
      couponDiscount: 0,
      hasCoupon: false,
      couponCode: "",
      hasShipping: false,
      hasShippingInsurance: false,
      walletPromotionAmount: 0,
      walletPromotionDetails: [],
      insurancePerThousand: 0,
      shippingPromotionId: 0,
      shippingPromotionProducts: [],
      pickupPerson: null,
      creditToUse: 0,
      walletToUse: 0,
      maxWalletAmount: 0,
      totalWithCreditAndWallet: 0,
      monthlyPaymentGroups: [],
    };
    state.items = [];
    state.totalItems = 0;
  },
  setSubtotal(state: StateCart, newSubtotal: number) {
    const oldSubtotal = state.cart.subtotal;
    const oldSubtotalTax =
      +((oldSubtotal * state.cart.ivaPercentage) / 100).toFixed(2) * 1;
    const newSubtotalTax =
      +((newSubtotal * state.cart.ivaPercentage) / 100).toFixed(2) * 1;
    state.cart.subtotal = +newSubtotal.toFixed(2) * 1;
    state.cart.tax =
      +(state.cart.tax - oldSubtotalTax + newSubtotalTax).toFixed(2) * 1;
    state.cart.total =
      +(
        state.cart.total -
        oldSubtotal -
        oldSubtotalTax +
        newSubtotal +
        newSubtotalTax
      ).toFixed(2) * 1;
  },
  calcShippingInsurance(state: StateCart, forceInsurance: any) {
    const force = forceInsurance !== undefined && forceInsurance !== null;
    if (state.cart.shippingInsurance <= 0 && !force) {
      return;
    }
    const newInsuranceValue = force
      ? forceInsurance
      : getCalculatedShippingInsurance(state);
    const newInsuranceTax =
      (newInsuranceValue * state.cart.ivaPercentage) / 100;
    const oldInsuranceTax =
      (state.cart.shippingInsurance * state.cart.ivaPercentage) / 100;
    const newInsuranceWithTax = newInsuranceValue + newInsuranceTax;
    const oldInsuranceWithTax = state.cart.shippingInsurance + oldInsuranceTax;
    state.cart.total =
      state.cart.total - oldInsuranceWithTax + newInsuranceWithTax;
    state.cart.tax = state.cart.tax - oldInsuranceTax + newInsuranceTax;
    state.cart.shippingInsurance = newInsuranceValue;
    state.cart.hasShippingInsurance = newInsuranceValue > 0;
    state.cart.totalWithCreditAndWallet =
      state.cart.total - state.cart.walletToUse;
  },
  setShipping(state: StateCart, newShipping: number) {
    const taxtRatio = state.cart.ivaPercentage / 100;
    const oldShippingTax = state.cart.shipping * taxtRatio;
    const newShippingTax = newShipping * taxtRatio;
    const oldShippingWithTaxt = state.cart.shipping + oldShippingTax;
    const newShippingWithTaxt = newShipping + newShippingTax;
    state.cart.total =
      +(state.cart.total - oldShippingWithTaxt + newShippingWithTaxt).toFixed(
        2,
      ) * 1;
    state.cart.tax =
      +(state.cart.tax - oldShippingTax + newShippingTax).toFixed(2) * 1;
    state.cart.shipping = +newShipping.toFixed(2) * 1;
    state.cart.hasShipping = true;
    state.cart.totalWithCreditAndWallet =
      state.cart.total - state.cart.walletToUse;
  },
  setMonthlyPaymentGroups(state: StateCart, groups: Array<any>) {
    state.cart.monthlyPaymentGroups = groups;
  },
  setShippingInsurance(state: StateCart, newshippingInsurance: number) {
    const taxtRatio = state.cart.ivaPercentage / 100;
    const oldShippingInsuranceTax = state.cart.shippingInsurance * taxtRatio;
    const newShippingInsuranceTax = newshippingInsurance * taxtRatio;
    const oldShippingInsuranceWithTaxt =
      state.cart.shippingInsurance + oldShippingInsuranceTax;
    const newShippingInsuranceWithTaxt =
      newshippingInsurance + newShippingInsuranceTax;
    state.cart.total =
      +(
        state.cart.total -
        oldShippingInsuranceWithTaxt +
        newShippingInsuranceWithTaxt
      ).toFixed(2) * 1;
    state.cart.tax =
      +(
        state.cart.tax -
        oldShippingInsuranceTax +
        newShippingInsuranceTax
      ).toFixed(2) * 1;
    state.cart.shippingInsurance = +newshippingInsurance.toFixed(2) * 1;
    if (newshippingInsurance > 0) {
      state.cart.hasShippingInsurance = true;
    } else {
      state.cart.hasShippingInsurance = false;
    }
    state.cart.totalWithCreditAndWallet =
      state.cart.total - state.cart.walletToUse;
  },
  setTotalItems(state: StateCart, totalItems: number) {
    state.totalItems = totalItems;
  },
};

const getters = {
  getTotalItems(state: StateCart) {
    return state.totalItems;
  },
  getItems(state: StateCart) {
    return state.items;
  },
  getLocalProductInCart(state: StateCart) {
    // const products = localStorage.getItem("productsId");
    // if (products) {
    //   return JSON.parse(products);
    // } else {
    //   return [];
    // }
    return state.items.filter((item: StateLocalProductInCart) => {
      return item.productId != undefined;
    });
  },
  getLocalStorageProducts(state: StateCart) {
    if (state.localStorageProducts) {
      return state.localStorageProducts;
    } else {
      return [];
    }
  },
  // getLocalStorageMeta() {
  //   return localStorage.getItem("meta");
  // },
  getLocalCart(state: StateCart) {
    return state.localStorageMeta;
  },
  getCart(state: StateCart) {
    return state.cart;
  },
  getCount(state: StateCart) {
    return state.cart.count;
  },
  getSubtotal(state: StateCart) {
    return state.cart.subtotal;
  },
  getTax(state: StateCart) {
    return state.cart.tax;
  },
  getTotal(state: StateCart) {
    return state.cart.total;
  },
  getIvaPercentage(state: StateCart) {
    return state.cart.ivaPercentage;
  },
  getPickupStoreId(state: StateCart) {
    return state.cart.pickupStoreId;
  },
  getShipping(state: StateCart) {
    return state.cart.shipping;
  },
  getShippingInsurance(state: StateCart) {
    return state.cart.shippingInsurance;
  },
  getShippingMinAmount(state: StateCart) {
    return state.cart.shippingMinAmount;
  },
  getTotalDiscount(state: StateCart) {
    return state.cart.totalDiscount;
  },
  getPromotionDiscount(state: StateCart) {
    return state.cart.promotionDiscount;
  },
  getCouponDiscount(state: StateCart) {
    return state.cart.couponDiscount;
  },
  getHasCoupon(state: StateCart) {
    return state.cart.hasCoupon;
  },
  getCouponCode(state: StateCart) {
    return state.cart.couponCode;
  },
  calculatedInsuranceCost(state: StateCart) {
    return getCalculatedShippingInsurance(state);
  },
  getMonthlyPaymentGroups(state: StateCart) {
    return state.cart.monthlyPaymentGroups;
  },
  getWalletPromotionAmount(state: StateCart) {
    return state.cart.walletPromotionAmount;
  },
};

const actions: ActionTree<StateCart, any> = {
  addProductsInCart({ commit }, products: Array<any>) {
    products.forEach((product: any) => {
      commit("addProductInCart", product);
    });
  },
  setSubtotalAndRecalcInsurance({ commit }, newSubtotal) {
    commit("setSubtotal", newSubtotal);
    commit("calcShippingInsurance");
  },
  setShipping({ commit }, newShipping) {
    commit("setShipping", newShipping);
  },
  addProductIdInLocal({ state, commit }, product: StateLocalProductInCart) {
    const productFind = state.items.find((item: StateLocalProductInCart) => {
      return item.productId == product.productId;
    });
    if (productFind != undefined) {
      productFind.qty = productFind.qty + product.qty;
    } else {
      state.items.push({
        productId: product.productId,
        qty: product.qty,
      });
    }
    const totalItems = state.totalItems + product.qty;
    commit("setTotalItems", totalItems);
    // localStorage.setItem("productsId", JSON.stringify(state.items));
  },
  addProductToCart({ state, commit, dispatch }, productInfo: any) {
    const cardInfo = productInfo.cart;

    if (!cardInfo) {
      return;
    }

    if (productInfo.assembly_id == undefined) {
      productInfo.assembly_id = null;
    }
    const existingProduct = state.localStorageProducts.find(
      (product: { product: { id: string | number }; assembly_id: string }) => {
        return (
          product.product.id === productInfo.product.id &&
          product.assembly_id == productInfo.assembly_id
        );
      },
    );
    let finalPrice = 0;
    if (existingProduct) {
      finalPrice = existingProduct.product.common_price_with_tax;
      if (existingProduct.product.price_detail.promotion_id) {
        finalPrice = existingProduct.product.promotion_price_with_tax;
      } else if (existingProduct.product.mwi_one_disc_percent > 0) {
        finalPrice = existingProduct.product.common_price_with_tax;
      }
    }
    if (!existingProduct) {
      state.localStorageProducts.push(productInfo);
      // meta.count += productInfo.quantity;
    } else if (productInfo.is_updated) {
      existingProduct.quantity = productInfo.quantity;
      existingProduct.product.total_by_item =
        existingProduct.quantity * finalPrice;
      existingProduct.product.total_by_item_text = formatPrice(
        existingProduct.product.total_by_item,
      );
    } else {
      existingProduct.quantity += productInfo.quantity;
      existingProduct.product.total_by_item =
        existingProduct.quantity * finalPrice;
      existingProduct.product.total_by_item_text = formatPrice(
        existingProduct.product.total_by_item,
      );
    }

    if (!Array.isArray(state.localStorageProducts)) {
      state.localStorageProducts = [];
    }

    commit("setLocalStorageProducts", state.localStorageProducts);
    dispatch("recalculateLocalCart");
  },
  updateProductQuantityLocal(
    { state, dispatch },
    {
      productId,
      newQuantity,
      assemblyId,
    }: {
      productId: string;
      newQuantity: number;
      assemblyId: string | null;
    },
  ) {
    const cartLocalStorage = state.localStorageProducts;
    // const meta = state.localStorageMeta;
    if (assemblyId == undefined) {
      assemblyId = null;
    }
    const cartItem = cartLocalStorage.find(
      (res: { product: { id: string | number }; assembly_id: string }) => {
        return res.product.id === productId && res.assembly_id == assemblyId;
      },
    );

    const item = state.items.find((res: StateLocalProductInCart) => {
      return res.productId === +productId;
    });

    if (cartItem) {
      const priceDetail = cartItem.product.price_detail;
      if (!priceDetail) {
        return;
      }
      let finalPrice = cartItem.product.common_price_with_tax;
      if (cartItem.product.price_detail.promotion_id) {
        finalPrice = cartItem.product.promotion_price_with_tax;
      } else if (cartItem.product.mwi_one_disc_percent > 0) {
        finalPrice = cartItem.product.common_price_with_tax;
      }
      console.log(finalPrice);
      cartItem.quantity = newQuantity;
      cartItem.product.total_by_item = (newQuantity * finalPrice).toFixed(2);
      cartItem.product.total_by_item_text = formatPrice(
        cartItem.product.total_by_item,
      );

      if (item) {
        item.qty = newQuantity;
      }
      dispatch("recalculateLocalCart");
      eventBus.emit("update-shopping-cart-totals", state.localStorageMeta);
    }
  },
  removeFromCart(
    { state, commit, dispatch },
    {
      productId,
      assemblyId,
    }: { productId: string; qty: number; assemblyId: string | null },
  ) {
    if (assemblyId == undefined) {
      assemblyId = null;
    }
    const existingProductIndex = state.localStorageProducts.findIndex(
      (product: { product: { id: string | number }; assembly_id: string }) => {
        return (
          product.product.id === productId && product.assembly_id == assemblyId
        );
      },
    );
    if (existingProductIndex !== -1) {
      const localStorageProducts = state.localStorageProducts.filter(
        (product: {
          product: { id: string | number };
          assembly_id: string;
        }) => {
          return !(
            product.product.id === productId &&
            product.assembly_id == assemblyId
          );
        },
      );
      const items = state.items.filter((product: StateLocalProductInCart) => {
        return product.productId != +productId;
      });

      commit("setLocalStorageProducts", localStorageProducts);
      commit("setItems", items);
      dispatch("recalculateLocalCart");

      eventBus.emit("update-shopping-cart-totals", state.localStorageMeta);
      eventBus.emit(
        "update-cart-total-items",
        state.localStorageProducts.length,
      );
    }
  },
  recalculateLocalCart({ state }) {
    let total = 0;
    let subtotal = 0;
    let discount = 0;
    let tax = 0;

    state.localStorageProducts.forEach((product: any) => {
      const subtotalByProduct = product.subtotal * product.quantity;
      const discountByProduct = product.discount * product.quantity;
      const taxByProduct = product.tax * product.quantity;
      const totalByProduct =
        subtotalByProduct - discountByProduct + taxByProduct;
      subtotal += subtotalByProduct;
      discount += discountByProduct;
      tax += taxByProduct;
      total += totalByProduct;
    });

    state.localStorageMeta = {
      count: state.localStorageProducts.length,
      subtotal: subtotal,
      total: total,
      newShipping: 0,
      newShippingInsurance: 0,
      totalDiscount: discount,
      tax: tax,
      shipping: 0,
      shippingInsurance: 0,
    };
  },
};

export const cart = {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
