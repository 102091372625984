<template lang="">
  <header class="margin-bottom-mobile-menu main-header">
    <div class="container-fluid d-none d-lg-block">
      <nav-bar />
      <search-bar />
      <secondary-nav-bar />
    </div>
    <nav-bar-mobile />
    <nav-bar-footer-mobile />
  </header>
</template>
<script lang="ts">
import NavBar from "../navigation/NavBar.vue";
import SearchBar from "../navigation/SearchBar.vue";
import NavBarMobile from "../navigation/NavBarMobile.vue";
import NavBarFooterMobile from "../navigation/NavBarFooterMobile.vue";
import SecondaryNavBar from "../navigation/SecondaryNavBar.vue";
import { HomeService } from "@/services/HomeService";

export default {
  components: {
    NavBar,
    SearchBar,
    NavBarMobile,
    SecondaryNavBar,
    NavBarFooterMobile,
  },
  mounted() {
    const homeService = new HomeService();
    homeService.getHeaderInitData();
  },
};
</script>
<style lang="scss">
.main-header {
  z-index: 1021; // asegura que se muestre por encima de los sticky-top cuando esta activo el menú
}
</style>
