<script setup lang="ts">
import { ref, onMounted } from "vue";
import { companyService } from "@/services/CompanyService";
import Jsona from "jsona";
import PickupStore from "@/models/PickupStore";
import { Loader } from "@googlemaps/js-api-loader";
import TabTitle from "@/interfaces/TabTitle";

TabTitle("Nuestras tiendas");
const stores = ref<PickupStore[]>([]);
const dataFormatter = new Jsona();
const visibleSchedule = ref<string | null>(null);
const positions = ref<[number, number][]>();
const map = ref();
const storeMarkers = ref<StoreMarker[]>([]);
const storeId = ref<string | null>(null);
const error = ref<string | null>(null);

interface LatLngLiteral {
  lat: number;
  lng: number;
}

interface CustomMap {
  setCenter: (position: LatLngLiteral) => void;
  setZoom: (zoom: number) => void;
}

interface CustomInfoWindow {
  open: (map: CustomMap, marker: CustomMarker) => void;
  close: () => void;
  setContent: (content: string) => void;
}

interface CustomMarker {
  setPosition: (position: LatLngLiteral) => void;
  setMap: (map: CustomMap | null) => void;
  addListener: (event: "click", callback: () => void) => void;
}

interface StoreMarker {
  id: string;
  infoWindow: CustomInfoWindow;
  marker: CustomMarker;
}

const getPickupStores = async () => {
  if (companyService) {
    const response = await companyService.stores();

    if (response && response.data) {
      const deserializedData = dataFormatter.deserialize(
        response,
      ) as PickupStore[];
      stores.value = deserializedData;
    } else {
      stores.value = [];
      error.value =
        response?.message ||
        "Ha ocurrido un error al cargar la información de nuestras tiendas, por favor, intente más tarde.";
      console.error(response?.message || "Error fetching stores");
    }

    initMap();
  }
};

const initMap = () => {
  if (stores.value && stores.value.length > 0) {
    positions.value = stores.value
      .map((store) => store.location)
      .filter((loc) => loc);

    const center = positions.value.length
      ? { lat: positions.value[0][0], lng: positions.value[0][1] }
      : { lat: 20.656763, lng: -103.3898936 };

    const loader = new Loader({
      apiKey: "AIzaSyCGGNJ5FcRSQhuesXWYJ_Jt93WWBvMOs-k",
      version: "weekly",
    });

    loader.load().then((google) => {
      const mapOptions = {
        zoom: 12,
        center,
      };
      map.value = new google.maps.Map(
        document.getElementById("stores-map") as HTMLElement,
        mapOptions,
      );
      if (stores.value) {
        //colocado por ahora como unknown
        (storeMarkers.value as unknown) = stores.value
          .filter((store) => store.location)
          .map((store) => {
            const infoWindow = new google.maps.InfoWindow({
              content: `<div>
                  <h6>${store.name}</h6>
                  <address class="text-dark">${store.formatted_address}</address>
                  Tel. <a class="text-decoration-none" href="tel:${store.telephone}">${store.telephone}</a>
                </div>`,
            });

            const marker = new google.maps.Marker({
              position: { lat: store.location[0], lng: store.location[1] },
              map: map.value,
              title: store.name,
              icon: {
                url: "/images/marker.png",
                scaledSize: new google.maps.Size(40, 40),
              },
            });

            const storeMarker = {
              id: store.id,
              infoWindow,
              marker,
            };

            marker.addListener("click", () => {
              chooseStore(store.id);
            });

            return storeMarker;
          });
      }
    });
  }
};

const toggleShowSchedule = (id: string) => {
  visibleSchedule.value = visibleSchedule.value === id ? null : id;
};

const chooseStore = (id: string) => {
  storeId.value = id;
  const storeMarker = storeMarkers.value.find((s) => s.id === id);
  if (storeMarker) {
    storeMarker.infoWindow.open(map.value, storeMarker.marker);

    storeMarkers.value.forEach((sm) => {
      if (sm.id !== storeMarker.id) {
        sm.infoWindow.close();
      }
    });
  }
};

const getDayName = (dayNumber: number): string => {
  const days = [
    "Domingo",
    "Lunes",
    "Martes",
    "Miercoles",
    "Jueves",
    "Viernes",
    "Sábado",
  ];
  const index = dayNumber - 1;
  return days[index] || "Día inválido";
};

const isStoreSelected = (id: string) => {
  return storeId.value == id;
};

onMounted(async () => {
  getPickupStores();
});
</script>
<template>
  <div class="row gx-2 mt-4 mt-lg-5">
    <fieldset class="d-flex flex-column">
      <legend
        class="col-12 col-lg-9 d-flex justify-content-start p-3 rounded-2"
      >
        <span class="ms-lg-2">Nuestras tiendas</span>
      </legend>
      <div class="container">
        <div v-if="error" class="row">
          <div class="col-11 alert alert-danger">{{ error }}</div>
        </div>
        <div v-else class="row">
          <div class="col-12">
            <div id="stores-map"></div>
          </div>
        </div>
        <div class="mt-5">
          <div class="row gx-3 store-list justify-content-center">
            <div
              v-for="(store, index) in stores"
              :key="index"
              class="col-12 col-xl-4 mb-3"
              :class="{ 'active-store': isStoreSelected(store.id) }"
              @click="chooseStore(store.id)"
            >
              <div class="px-2 py-3 item-store">
                <div class="row align-items-center">
                  <div class="col-12 col-lg-1 d-flex">
                    <div
                      class="rounded-circle border shadow-sm p-1 flex-shrink-0 mr-2 shape-small ms-2"
                      :class="{ 'border-danger': isStoreSelected(store.id) }"
                    >
                      <div
                        class="w-100 h-100 rounded-circle shadow-sm"
                        :class="[
                          isStoreSelected(store.id) ? 'bg-danger' : 'bg-white',
                        ]"
                      ></div>
                    </div>
                  </div>
                  <div class="col-12 col-lg-11">
                    <div class="pointer">
                      <div class="fw-semibold">
                        {{ store.name }}
                      </div>
                      <div class="fs-7 mt-3">
                        <span>
                          {{ store.formatted_address }}
                        </span>
                        <div class="mt-3 small">
                          <i class="bi bi-telephone-outbound-fill"></i>
                          <a
                            :href="'tel:' + store.telephone"
                            class="ms-2 text-decoration-none"
                          >
                            {{ store.telephone }}
                          </a>
                        </div>
                        <a
                          v-if="store.schedules && store.schedules.length > 0"
                          class="text-decoration-none text-center d-block mt-2 fw-semibold pointer"
                          :class="{
                            'text-danger': visibleSchedule === store.id,
                          }"
                          @click="toggleShowSchedule(store.id)"
                        >
                          <i
                            :class="[
                              visibleSchedule === store.id
                                ? 'bi bi-chevron-down'
                                : 'bi bi-chevron-up',
                            ]"
                          ></i>
                          {{
                            visibleSchedule === store.id
                              ? "Horarios"
                              : "Ver horarios"
                          }}
                        </a>
                        <div v-if="visibleSchedule === store.id">
                          <div
                            v-for="(schedule, indexSchedule) in store.schedules"
                            :key="indexSchedule"
                            class="row"
                          >
                            <div class="col-3">
                              {{ getDayName(schedule.day_number) }}
                            </div>
                            <div
                              class="col-3"
                              :class="
                                schedule.is_closed
                                  ? 'text-danger'
                                  : 'text-success'
                              "
                            >
                              {{ schedule.is_closed ? "Cerrado" : "Abierto" }}
                            </div>
                            <div class="col-3">
                              {{ schedule.opening_time }}
                            </div>
                            <div class="col-3">
                              {{ schedule.closing_time }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </fieldset>
  </div>
</template>

<style scoped lang="scss">
.item-store {
  box-shadow: 0px 5px 7px var(--bs-gray-200);
  border: 1px solid var(--bs-gray-200);
  transition: box-shadow 0.1s ease-in-out;
  border-radius: 7px;
  // height: 180px;
}

.item-store:hover {
  box-shadow: 0px 7px 10px var(--bs-gray-400);
}

#stores-map {
  height: 500px;
}

.shape-small {
  width: 18px;
  height: 18px;
}

.active-store {
  border-left: 6px solid var(--bs-red);
  border-radius: 7px;
  box-shadow: 10px 10px 10px var(--bs-gray-200);
}
</style>
