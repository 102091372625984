<template lang="">
  <div class="ps-5 mt-3">
    <div v-if="isOpen" class="bg-transparent py-0 d-flex">
      <div class="col-3 col-xl-2 position-relative z-3 col-auto">
        <OnClickOutside
          :options="{ ignore: ['.ignoreMenu'] }"
          @trigger="onClickOutsideHandler"
        >
          <ul
            v-if="listItems && layout == 'list'"
            class="navbar-menu list-unstyled border border-danger border-3 border-start-0 border-end-0 bg-white w-100 position-absolute top-0 start-0 z-3 overflow-hidden py-2"
          >
            <li
              v-for="(item, index) in items"
              :key="item.id"
              :ref="(el) => (itemsRef[index] = el)"
              class="px-1 py-1 d-flex overflow-y-auto"
              :class="{
                'bg-body-secondary':
                  isHovered[item.id] || isHoveredMenu[item.id],
              }"
              @mouseenter="hoverButtonMenu(item, 'isHovered', true)"
              @mouseleave="hoverButtonMenu(item, 'isHovered', false)"
            >
              <drop-down-link
                :is-hovered="isHovered[item.id] || isHoveredMenu[item.id]"
                :item="item"
              />
            </li>
          </ul>
          <ul
            v-else-if="listItems && layout == 'grid'"
            class="navbar-menu col-3 col-xl-2 list-unstyled m-0 border border-danger border-3 border-start-0 border-end-0 bg-white overflow-auto w-100 position-absolute top-0 start-0"
          >
            <li
              v-for="(itemsInside, index) in items"
              :key="index"
              class="px-1 py-1 overflow-auto"
            >
              <span class="fs-7 fw-semibold px-1">{{ index }}</span>
              <ul
                class="list-unstyled row row-cols-3 row-gap-1 mx-0 mt-1 py-1 border border-light-subtle border-bottom-0 border-start-0 border-end-0"
              >
                <li
                  v-for="item in itemsInside"
                  :key="item.id"
                  :ref="
                    (el) => {
                      itemsRef[item.newIndex] = el;
                    }
                  "
                  class="col p-1"
                >
                  <a
                    class="d-flex justify-content-center align-items-center border border-light w-100 p-1 text-secondary fs-7 link-underline link-underline-opacity-0 link-brand pointer"
                    :class="{
                      'shadow-brand': isHovered[item.image],
                      'active-brand': item.links.self === activeLink,
                    }"
                    @click="
                      getResults(
                        item.links && item.links.self ? item.links.self : '/',
                      )
                    "
                    @mouseenter="isHovered[item.image] = true"
                    @mouseleave="isHovered[item.image] = false"
                  >
                    <img
                      v-if="!imageErrors[item.image]"
                      :src="item.image"
                      :alt="item.name"
                      class="brand"
                      @error="imageErrors[item.image] = true"
                    />
                    <span
                      v-if="imageErrors[item.image]"
                      class="text-secondary fw-semibold text-nowrap overflow-hidden text-capitalize fs-9"
                    >
                      {{ item.name }}
                    </span>
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </OnClickOutside>
      </div>
      <!-- Submenú de subcategorías que se muestran al realizar hover en la categoría del menú principla o de categorías -->
      <div
        v-if="listItems && layout == 'list'"
        :class="{ 'w-0 d-none': !isShowSubMenu }"
        class="ignoreMenu col-9 col-xl-10 position-relative z-3"
      >
        <TransitionGroup name="list" tag="div">
          <template v-for="item in items" :key="item.id">
            <!-- isHovered[item.id] || isHoveredMenu[item.id] -->
            <div
              v-if="isHovered[item.id] || isHoveredMenu[item.id]"
              class="navbar-menu-height w-100 border border-light-subtle shadow position-absolute top-0 start-0 p-3 bg-white z-3 d-flex flex-column"
              @mouseenter="isHoveredMenu[item.id] = true"
              @mouseleave="hoverButtonMenu(item, 'isHoveredMenu')"
            >
              <div
                class="row pt-2 pb-5 justify-content-center align-items-center"
              >
                <div class="col-6 row row-cols-3 row-cols-xl-12">
                  <link-animation
                    v-for="(button, index) in buttons"
                    :key="index"
                    :text="button.title"
                    class="col"
                    @click="
                      getResults(
                        button.links && button.links.self
                          ? button.links.self
                          : '/',
                      )
                    "
                  />
                </div>
                <div
                  class="col-6 row row-cols-3 row-cols-xl-6 justify-content-end"
                >
                  <link-image
                    v-for="(brand, index) in item.brands?.slice(0, 5)"
                    :key="index"
                    :src="brand.image || ''"
                    :alt="brand.name"
                    :to="
                      brand.links && brand.links.self ? brand.links.self : '/'
                    "
                    font-size="fs-9"
                    class="col"
                  />
                </div>
              </div>
              <ul
                class="list-unstyled row row-cols-3 row-cols-xl-5 row-gap-4 flex-grow-1"
              >
                <li
                  v-for="subcategory in item.categories"
                  :key="subcategory.id"
                  class="col pe-4"
                >
                  <a
                    class="fw-bold fs-7 text-primary text-uppercase link-underline link-underline-opacity-0 pointer"
                    @click="
                      getResults(
                        subcategory.links && subcategory.links.self
                          ? subcategory.links.self
                          : '/',
                      )
                    "
                  >
                    {{ subcategory.name }}
                  </a>
                  <ul class="list-unstyled mt-2">
                    <li
                      v-for="subcategory2 in subcategory.categories"
                      :key="subcategory2.id"
                      class="py-1"
                    >
                      <a
                        class="link-underline link-underline-opacity-0 pointer text-subcategory2"
                        :class="{
                          'active-subcategory-2':
                            subcategory2.links.self == activeLink,
                        }"
                        @click="
                          getResults(
                            subcategory2.links && subcategory2.links.self
                              ? subcategory2.links.self
                              : '/',
                          )
                        "
                      >
                        {{ subcategory2.name }}
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
              <div class="row g-3" :style="{ minHeight: '180px' }">
                <div
                  v-for="(banner, index) in fixedBanners"
                  :key="index"
                  class="col-6 pb-3"
                >
                  <a
                    :target="banner.action_url ? '_blank' : '_self'"
                    :href="banner.action_url || '#'"
                  >
                    <img
                      v-lazy="banner.image"
                      :alt="banner.name"
                      class="img-fluid image-banner"
                    />
                  </a>
                </div>
                <div
                  v-for="(banner, index) in fixedBannersSmall"
                  :key="index"
                  class="col-3"
                >
                  <a
                    :target="banner.action_url ? '_blank' : '_self'"
                    :href="banner.action_url || '#'"
                  >
                    <img
                      v-lazy="banner.image"
                      :alt="banner.name"
                      class="img-fluid image-banner"
                    />
                  </a>
                </div>
              </div>
            </div>
          </template>
        </TransitionGroup>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import BaseTransition from "@/components/common/BaseTransition.vue";
import DropDownLink from "./DropDownLink.vue";
import LinkAnimation from "@/components/buttons/LinkAnimation.vue";
import LinkImage from "@/components/buttons/LinkImage.vue";
import { HomeService } from "@/services/HomeService";
import { store } from "@/store";
import { OnClickOutside } from "@vueuse/components";
import { getFinalEshopUrl, getTitleTranslate } from "@/utils/general";
import { ref, nextTick } from "vue";
import { useRoute } from "vue-router";
import eventBus from "@/event-bus";
import {
  VITE_BANNER_MENU_CATEGORIES,
  VITE_BANNER_MENU_CATEGORIES_SMALL,
} from "@/config/constants.js";

interface itemMenu {
  id: number;
  name: string;
  slug: string;
  image: string;
  links: unknown;
  categories: itemMenu[];
  brands: unknown[];
  banners: unknown[];
  buttons: unknown[];
}

interface itemBanner {
  id: number;
  image: string;
  name: string;
  banner_type: string;
}

export default {
  components: {
    BaseTransition,
    LinkAnimation,
    DropDownLink,
    LinkImage,
    OnClickOutside,
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    canClose: {
      type: Boolean,
      default: false,
    },
    tab: {
      type: String,
      default: "",
    },
    layout: {
      type: String,
      default: "list",
    },
  },
  emits: ["change-open", "get-results"],
  data: function () {
    return {
      lastChart: "",
      isHovered: [],
      imageErrors: [],
      isHoveredMenu: [],
      outsideMenu: false,
      outsideSubMenu: false,
      stepLoad: 13,
      loaded: 13,
      itemsRef: ref([]),
      seeMoreCategories: "/",
      homeService: null as HomeService | null,
      banners: [] as itemBanner[],
      bannersSmall: [] as itemBanner[],
      buttons: [],
      activeLink: "" as string,
    };
  },
  computed: {
    fixedBanners() {
      return this.banners.slice(0, 2);
    },
    fixedBannersSmall() {
      return this.bannersSmall.slice(0, 2);
    },
    route() {
      return useRoute();
    },
    items() {
      if (this.layout == "list") {
        let items = this.collectionLoaded;
        const isSeeAll = items.some((item) => item.id === 0);
        if (this.collectionLoaded.length >= this.stepLoad && !isSeeAll) {
          items.push({
            id: 0,
            name: this.getTitle("home", "see_all")
              ? this.getTitle("home", "see_all")
              : "Ver todas",
            links: {
              self: "/busqueda-por-categoria",
            },
          });
        }
        return items;
      }
      let newIndex = 0;
      return this.collectionLoaded.reduce((groups, item) => {
        const orderBy = item.name.toUpperCase().charAt(0);
        if (!groups[orderBy]) {
          groups[orderBy] = [];
        }
        const cloned = JSON.parse(JSON.stringify(item));
        cloned.newIndex = newIndex++;
        groups[orderBy].push(cloned);
        this.startObserving();
        return groups;
      }, {});
    },
    isShowSubMenu() {
      return (
        this.isHovered.find((element) => element) ||
        this.isHoveredMenu.find((element) => element)
      );
    },
    listItems() {
      if (this.tab == "categories") {
        return store.getters["catalogs/getDeserealizeCategories"];
      }
      if (this.tab == "brands") {
        return store.getters["catalogs/getDeserealizeBrands"];
      }
      return [];
    },
    collectionLoaded() {
      if (!this.listItems) {
        return [];
      }
      let items = [...this.listItems];
      items.sort((a: itemMenu, b: itemMenu) =>
        a.name
          .toUpperCase()
          .charAt(0)
          .localeCompare(b.name.toUpperCase().charAt(0)),
      );
      // return items.slice(0, this.loaded); //muestra 13 o 14 categorías
      return items;
    },
    lastItemRef() {
      return this.loaded >= this.itemsRef.length
        ? this.itemsRef[this.itemsRef.length - 1]
        : this.itemsRef[this.loaded - 1];
    },
    observer() {
      return new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            if (this.loaded === this.listItems.length) {
              return this.stopObserving();
            }

            this.loaded += this.stepLoad;
          }
        });
      });
    },
  },
  mounted() {
    this.homeService = new HomeService();
    if (this.tab == "categories") {
      const categories = store.getters["catalogs/getDeserealizeCategories"];
      if (categories == undefined || categories.length == 0) {
        this.homeService.getCategories().then((response) => {
          if (response) {
            this.buttons =
              response.meta && response.meta.translations
                ? response.meta.translations
                : [];
            this.seeMoreCategories = response.links.self;
          }
        });
      }
    }

    if (this.tab == "brands") {
      const brands = store.getters["catalogs/getDeserealizeBrands"];
      if (brands == undefined || brands.length == 0) {
        this.homeService.getBrands();
      }
    }

    this.homeService
      .getBanners(VITE_BANNER_MENU_CATEGORIES)
      .then((response) => {
        if (response) {
          this.banners = response;
        }
      });

    this.homeService
      .getBanners(VITE_BANNER_MENU_CATEGORIES_SMALL)
      .then((response) => {
        if (response) {
          this.bannersSmall = response;
        }
      });
  },
  methods: {
    hoverButtonMenu(item: itemMenu, type: string, inside: boolean) {
      setTimeout(this.boundMethod, 50, item, type, inside);
    },
    async startObserving() {
      await nextTick();
      this.observer.observe(this.lastItemRef);
    },
    stopObserving() {
      this.observer.disconnect();
    },
    onClickOutsideHandler() {
      if (this.canClose) {
        this.$emit("change-open", false);
      }
    },
    getFinalUrl(path: string) {
      return getFinalEshopUrl(path);
    },
    getTitle(section: string, name: string) {
      return getTitleTranslate(section, name);
    },
    boundMethod(item: itemMenu, type: string, inside: boolean) {
      if ((this as any)[type]) {
        const dataValue = (this as any)[type];
        dataValue[item.id] = inside;
      }
    },

    getResults(path: string) {
      this.activeLink = path;
      eventBus.emit("get-results", path);
      this.$router.push(path).catch((err) => {
        if (err.name !== "NavigationDuplicated") {
          console.error(err);
        }
      });
    },
    sortRandom(array: Array<any>) {
      return array.sort(() => {
        return Math.random() - 0.5;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@use "@/scss/styles.scss";

.navbar-menu {
  @extend .navbar-menu-height;
  border-right: 1px solid var(--bs-gray-200) !important;
  border-left: 1px solid var(--bs-gray-200) !important;
}

.link-brand {
  height: 32px;
}

.brand {
  height: 100%;
  max-width: 100%;
  object-fit: contain;
}

.list-enter-active {
  transition: all 0.5s ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
}

.list-leave-active {
  position: absolute;
  transition: all 0.1s ease;
}

.w-0 {
  width: 0% !important;
}

.image-banner {
  height: 176px;
  width: 100%;
  object-fit: container;
  object-position: center;
}

.shadow-brand {
  box-shadow: 1px 1px 6px #e5e5e5;
  border: 1px solid #f3f3f3;
}

.active-brand {
  box-shadow: 1px 1px 6px #55586580;
  border: 10px solid #f3f3f3;
}

.active-subcategory-2 {
  color: #b00020;
}

.navbar-menu-height {
  overflow-y: auto;
}

.navbar-menu-height::-webkit-scrollbar {
  display: none;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  overflow: -moz-scrollbars-none; /* Firefox versiones anteriores */
}
</style>
