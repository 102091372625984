<template lang="">
  <base-link
    class="dropdown-item ps-2 small d-flex align-items-center"
    :class="[isHovered ? 'justify-content-between' : 'justify-content-start']"
    @click="getResults(item.links.self)"
  >
    <div class="icon">
      <img
        v-if="item.icon && !imageError"
        :src="item.icon"
        class="w-100"
        @error="imageError = true"
      />

      <!-- <div 
                v-if="isIconSvg"
                class="w-100 text-secondary bg-danger"
                v-html="svgContent" 
            />
        
            <img
                v-if="item.icon && !imageError && !isIconSvg"
                :src="item.icon"
                class="w-100"
                @error="imageError = true;"
            > -->
    </div>
    <span
      class="ps-2 me-4 text-secondary text-truncate"
      style="font-size: 14px"
    >
      {{ item.name }}
    </span>
    <i
      ref="divIcon"
      class="bi bi-chevron-right text-end flex-grow-1 icon-right text-primary"
      :style="{
        transform: 'translateX(' + translateX + 'px)',
        opacity: isHovered ? '1' : '0',
      }"
    />
  </base-link>
</template>
<script lang="ts">
import BaseLink from "@/components/buttons/BaseLink.vue";
import { ref } from "vue";
import eventBus from "@/event-bus";

export default {
  components: {
    BaseLink,
  },
  props: {
    isHovered: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: null,
    },
  },
  emits: ["get-results"],
  setup() {
    const divIcon = ref<HTMLElement | null>(null);
    return {
      divIcon,
    };
  },
  data: function () {
    return {
      imageError: false,
      svgContent: "",
    };
  },
  computed: {
    translateX() {
      if (this.isHovered) {
        return 0;
      }
      const divWidth = this.divIcon ? this.divIcon.offsetWidth - 20 : 0;
      return divWidth * -1;
    },
    isIconSvg() {
      return this.getFileExtension(this.item.icon) == "svg";
    },
  },
  mounted() {
    // Realiza una solicitud para obtener el contenido del SVG
    // this.fetchSVG(this.item.icon);
  },
  methods: {
    async fetchSVG(urlIcon: string) {
      if (!urlIcon || !this.isIconSvg) {
        return false;
      }

      try {
        const response = await fetch(urlIcon);
        const svgText = await response.text();
        this.svgContent = svgText;
      } catch (error) {
        console.error("Error fetching SVG:", error);
      }
    },
    getFileExtension(fileName: string) {
      if (!fileName) {
        return "";
      }
      const parts = fileName.split(".");
      return parts.length > 1 ? parts[parts.length - 1] : null;
    },
    getResults(path: string) {
      eventBus.emit("get-results", path);
      this.$router.push(path).catch((err) => {
        if (err.name !== "NavigationDuplicated") {
          console.error(err);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.icon {
  height: 18px;
  width: 18px;
}

.icon-right {
  transition: all 0.3s ease-out;
}
</style>
