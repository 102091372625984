<script setup lang="ts">
import { ref, onMounted } from "vue";
import TabTitle from "@/interfaces/TabTitle";
import { companyService } from "@/services/CompanyService";
import { Results } from "@/interfaces/Results";

TabTitle("Formas de pago");

const description = ref<string>("");
const paymentMethods = ref<Array<Results>>([]);
const breadCrumbs = ref<{ name: string }>({ name: "" });

onMounted(async () => {
  try {
    const response = await companyService.paymentMethods();
    description.value = response.description;
    paymentMethods.value = response.results;
    breadCrumbs.value = response.breadcrumb_links[1];
  } catch (error) {
    console.error("Error fetching paymentMethods:", error);
  }
});
</script>
<template>
  <div class="row gx-2 mt-4 mt-lg-5 payment-methods-customers">
    <fieldset class="d-flex flex-column">
      <legend
        class="col-12 col-lg-9 d-flex justify-content-start p-3 rounded-2"
      >
        <span class="ms-lg-2">{{ breadCrumbs.name }}</span>
      </legend>
      <div class="container">
        <div class="description px-1 px-lg-5 mt-1 mt-lg-2 mb-5">
          <div v-html="description"></div>
          <div>
            <div v-for="(item, index) in paymentMethods" :key="index">
              <h3 class="fw-semibold">{{ item.title }}</h3>
              <div
                class="description"
                v-html="item.description?.replace('/img/', '/images/')"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </fieldset>
  </div>
</template>
