<template lang="">
  <base-link
    v-element-hover="onHover"
    :to="to"
    :external="external"
    class="link-underline link-underline-opacity-0 d-flex justify-content-end link-animation-right text-secondary fs-7 p-1 p-xl-2"
  >
    <span>
      {{ text }}
    </span>
    <Transition name="fade">
      <i v-if="isHovered" class="bi bi-chevron-right" />
    </Transition>
  </base-link>
</template>
<script lang="ts">
import { vElementHover } from "@vueuse/components";
import BaseLink from "./BaseLink.vue";
export default {
  components: {
    BaseLink,
  },
  directives: {
    "element-hover": vElementHover,
  },
  props: {
    text: {
      type: String,
      default: "",
      required: true,
    },
    to: {
      type: String,
      default: "/",
    },
    external: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      isHovered: false,
    };
  },
  methods: {
    onHover(state: boolean) {
      this.isHovered = state;
    },
  },
};
</script>
<style lang="sass" scoped>
.link-animation-right
    span
        transition: all 0.3s ease-out

.link-animation-right:hover
    span
        transform: translateX(-10px)
        transition: all 0.5s ease-out
</style>
