<template>
  <div v-if="loading">
    <bank-cards-placeholder />
  </div>
  <div v-else>
    <div class="container-credit-debit-cards-NO">
      <div v-for="item in items" :key="item.id">
        <div v-if="item.code === 'openpay_card' && item.tokenizer">
          <bank-card
            :payment-method-code="item.code"
            :cards="item.tokenizables"
            :loading="isLoadingCard"
            @update-tokenizables="updateTokenizables"
            @is-loading="isLoading"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { PaymentMethodService } from "@/services/PaymentMethodService";
import TabTitle from "@/interfaces/TabTitle";
import { getTitleTranslate } from "@/utils/general";
import AddBankCard from "@/components/cart/payment/AddBankCard.vue";
import BankCard from "@/components/cart/payment/BankCard.vue";
import Jsona from "jsona";
import { store } from "@/store";
import BankCardsPlaceholder from "./BankCardsPlaceholder.vue";

const paymentMethodService = new PaymentMethodService();
const dataFormatter = new Jsona();

export default {
  components: {
    AddBankCard,
    BankCard,
    BankCardsPlaceholder,
  },

  data() {
    TabTitle("Tarjetas crédito/débito");
    return {
      items: [] as any, //BankCard[] comentado mientras se realiza el build, revisar campos,
      showAddBankCard: false,
      loading: false,
      isLoadingCard: false,
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    this.loading = true;
    paymentMethodService.getPaymentMehods().then((response) => {
      if (response) {
        this.items = dataFormatter.deserialize(response);
        if (this.items) {
          store.commit("checkout/setPaymentMethods", this.items);
        }
      }
      this.loading = false;
    });
  },
  methods: {
    getTitle(section: string, name: string) {
      return getTitleTranslate(section, name);
    },
    addBankCard() {
      this.showAddBankCard = !this.showAddBankCard;
    },
    updateTokenizables(paymentMethodCode: string) {
      if (paymentMethodService) {
        this.isLoadingCard = true;
        paymentMethodService
          .getPaymentMehod(paymentMethodCode)
          .then((response) => {
            if (response) {
              const index = this.items.findIndex((item: { code: string }) => {
                return item.code == paymentMethodCode;
              });
              const data: any = dataFormatter.deserialize(response);
              if (index >= 0 && data) {
                this.items[index].tokenizables = data.tokenizables;
              }
              this.isLoadingCard = false;
            } else {
              this.isLoadingCard = false;
            }
          });
      }
    },
    isLoading(status: boolean) {
      this.isLoadingCard = status;
    },
  },
};
</script>
