<template lang="">
  <nav
    class="row navbar navbar-expand-lg bg-body-tertiary py-0 px-5 d-flex justify-content-between first-nav-bar"
  >
    <ul
      class="col-5 navbar-nav d-flex justify-content-start align-items-center"
    >
      <li class="nav-item me-3">
        <link-icon
          icon="bi-instagram"
          class="text-secondary"
          target="_blank"
          :to="'https://www.instagram.com/digitalifemx'"
        />
      </li>
      <li class="nav-item me-3">
        <link-icon
          icon="bi-facebook"
          class="text-secondary"
          target="_blank"
          :to="'https://www.facebook.com/DigitalifeMX'"
        />
      </li>
      <li class="nav-item me-3">
        <link-icon
          icon="bi-youtube"
          class="text-secondary"
          target="_blank"
          :to="'https://www.youtube.com/channel/UC1_EqXjKy6IqtdH6jPC-U5w'"
        />
      </li>
      <!-- <li class="nav-item me-3">
        <link-icon
          icon="bi-twitter"
          class="text-secondary"
          target="_blank"
          :to="'url'"
        />
      </li>
      <li class="nav-item me-3">
        <link-icon
          icon="bi-linkedin"
          class="text-secondary"
          target="_blank"
          :to="'url'"
        />
      </li> -->
    </ul>
    <div class="col-6">
      <div class="row d-flex align-items-center">
        <!-- <div class="col-2">
          <btn-switch class="mx-auto" />
        </div> -->
        <div class="col-12">
          <ul class="d-flex list-unstyled pt-3 justify-content-between">
            <li class="nav-item col-2" style="width: 140px">
              <link-nav
                :to="'/contactanos'"
                :external="true"
                :text="'(33) 3630 0660'"
                component="CallSvg"
                component-hover="CallFillSvg"
                class="border-0"
              />
            </li>
            <li class="nav-item col-2">
              <link-nav
                :to="'/sucursales'"
                :text="'Tiendas'"
                component="StoreSvg"
                component-hover="StoreFillSvg"
                class="border-0 text-capitalize"
              />
            </li>
            <!-- OCULTO HASTA QUE FUNCIONE -->
            <!-- <li class="nav-item col-2">
              <link-nav
                :to="/prime"
                :text="'Prime'"
                component="PrimeSvg"
                component-hover="PrimeFillSvg"
                class="border-0 text-capitalize"
              />
            </li> -->
            <li class="nav-item col-2">
              <link-nav
                :to="'/garantias-y-devoluciones'"
                :text="'Garantía'"
                component="GuaranteeSvg"
                component-hover="GuaranteeFillSvg"
                class="border-0 text-capitalize"
              />
            </li>
            <li class="nav-item col-2">
              <link-nav
                :to="'/preguntas-frecuentes'"
                :text="'Ayuda'"
                icon="bi-info-circle"
                icon-hover="bi-info-circle-fill"
                class="border-0 text-capitalize"
              />
            </li>
          </ul>
        </div>
      </div>
    </div>
  </nav>
</template>
<script lang="ts">
import LinkIcon from "../buttons/LinkIcon.vue";
import LinkNav from "../buttons/LinkNav.vue";
// import BtnSwitch from "@/components/buttons/BtnSwitch.vue";

export default {
  components: {
    LinkIcon,
    LinkNav,
    // BtnSwitch,
  },
};
</script>
<style lang="scss" scoped>
.navbar {
  height: 40px;
}

.navbar-nav {
  flex-direction: row !important;
}
</style>
