<template lang="">
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 870.000000 107.000000"
    style="width: 128px; height: 16px"
    @click="goHome"
  >
    <g
      transform="translate(0.000000,107.000000) scale(0.100000,-0.100000)"
      fill="currentColor"
      stroke="none"
    >
      <path
        d="M0 945 l0 -125 165 0 c182 0 253 -15 321 -67 88 -66 112 -209 60
            -353 -59 -167 -169 -280 -338 -350 l-73 -31 140 5 c115 4 155 9 223 31 281 89
            426 351 352 638 -46 181 -162 293 -364 351 -82 24 -103 26 -287 26 l-199 0 0
            -125z"
      />
      <path
        d="M1037 1063 c-4 -3 -7 -240 -7 -525 l0 -518 145 0 145 0 0 525 0 525
            -138 0 c-76 0 -142 -3 -145 -7z"
      />
      <path
        d="M1791 1030 c-283 -134 -389 -470 -241 -760 38 -75 147 -175 237 -218
            l78 -37 190 0 c187 0 191 0 260 29 73 31 162 83 183 109 9 11 12 74 10 243
            l-3 229 -237 3 -238 2 0 -104 0 -105 103 -3 102 -3 0 -65 c0 -61 -2 -65 -28
            -78 -15 -8 -56 -18 -90 -24 -127 -21 -251 38 -307 148 -21 41 -25 62 -25 139
            0 73 4 99 22 136 48 97 143 159 243 159 72 0 118 -14 192 -57 69 -41 52 -49
            156 75 34 40 62 78 62 85 0 19 -80 72 -161 107 -70 30 -73 30 -248 30 l-176
            -1 -84 -39z"
      />
      <path
        d="M2680 545 l0 -525 145 0 145 0 0 525 0 525 -145 0 -145 0 0 -525z"
      />
      <path
        d="M3106 1054 c-3 -9 -6 -65 -6 -125 l0 -108 158 -3 157 -3 3 -397 2
            -398 145 0 145 0 2 398 3 397 153 3 152 3 0 124 0 125 -454 0 c-405 0 -455 -2
            -460 -16z"
      />
      <path
        d="M4503 1038 c-11 -18 -34 -58 -51 -88 -16 -30 -40 -73 -52 -95 -13
            -22 -110 -200 -217 -395 -107 -195 -206 -374 -220 -398 l-24 -43 151 3 152 3
            54 95 c30 52 91 161 136 242 44 82 84 150 87 153 4 2 68 -108 142 -245 l134
            -249 153 -1 c83 0 152 1 152 3 0 7 -565 1033 -575 1045 -2 2 -12 -12 -22 -30z"
      />
      <path
        d="M5212 543 l3 -528 398 -3 397 -2 0 130 0 130 -252 2 -253 3 -3 398
            -2 397 -145 0 -145 0 2 -527z"
      />
      <path
        d="M6142 543 l3 -528 145 0 145 0 3 528 2 527 -150 0 -150 0 2 -527z"
      />
      <path
        d="M6632 543 l3 -528 140 0 140 0 3 187 2 188 248 2 247 3 0 115 0 115
            -245 5 -245 5 -3 87 -3 88 273 2 273 3 3 128 3 127 -421 0 -420 0 2 -527z"
      />
      <path
        d="M7602 543 l3 -528 423 -3 422 -2 0 125 0 125 -277 2 -278 3 -3 82 -3
            83 253 2 253 3 0 110 0 110 -252 3 -253 2 0 80 0 80 280 0 280 0 0 125 0 125
            -425 0 -425 0 2 -527z"
      />
      <path
        d="M8536 1039 c-45 -53 -25 -128 39 -149 95 -32 164 72 99 149 -21 26
            -33 31 -69 31 -36 0 -48 -5 -69 -31z m124 -9 c30 -30 27 -83 -6 -109 -35 -28
            -69 -26 -99 4 -31 30 -32 71 -3 102 27 29 80 31 108 3z"
      />
      <path
        d="M8567 1024 c-4 -4 -7 -27 -7 -51 0 -32 4 -43 15 -43 8 0 15 7 15 17
            0 15 1 15 18 0 22 -20 47 -23 35 -5 -4 7 -7 29 -8 48 0 32 -3 35 -30 38 -17 2
            -34 0 -38 -4z m53 -24 c0 -5 -7 -10 -15 -10 -8 0 -15 5 -15 10 0 6 7 10 15 10
            8 0 15 -4 15 -10z"
      />
      <path
        d="M0 415 c0 -341 1 -365 18 -365 124 2 261 56 352 139 l55 50 -82 1
            -83 0 0 270 0 270 -130 0 -130 0 0 -365z"
      />
    </g>
  </svg>
</template>
<script lang="ts">
export default {
  props: {
    width: {
      type: String,
      default: "870.000000pt",
    },
    height: {
      type: String,
      default: "107.000000pt",
    },
  },
  methods: {
    goHome() {
      this.$router.push("/");
    },
  },
};
</script>
