<template>
  <div class="accordion-option mt-3 mb-2">
    <div class="row py-xl-4">
      <div class="d-flex">
        <div class="col-lg-2 col-xl-1 d-none d-lg-block">
          <div
            class="d-flex align-items-center justify-content-around h-100 courier-section-radio"
          >
            <input
              id="select-branch"
              type="radio"
              :checked="isPickup"
              name="radio-group"
              class="custom-radio-input green-radio-input"
            />
            <label for="select-branch" class="custom-radio-label" />
            <i class="bi bi-shop-window fs-3" />
          </div>
        </div>
        <div class="col-12 col-lg-10 col-xl-11 pointer">
          <div class="d-flex" @click="togglePanel">
            <i
              class="bi bi-shop-window fa-truck d-lg-none icon-truck-courier-sm"
              :class="{ 'text-success': showAccordion }"
            />
            <h3 class="title-accordion-option ms-4 ms-lg-0">
              {{ getTitle("cart", "pick_up_at_branch") }}
              <!-- Recolectar en sucursal -->
            </h3>
          </div>
          <p class="text-nowrap text-select-a-courier">
            {{
              getTitle(
                "cart",
                "available_at_your_nearest_branch_in_just_20_minutes",
              )
            }}
            <!-- ¡Disponible en tu sucursal más cercana, en tan solo 20 minutos! -->
          </p>
          <div v-if="showAccordion">
            <VeeForm class="mt-3 mb-4" @submit="onAddPersonPickup">
              <h5 class="fw-semibold">
                {{ getTitle("cart", "who_receives") }}
              </h5>
              <div
                v-if="!defaultPickupPerson?.is_valid"
                class="alert alert-warning"
                role="alert"
              >
                La información de su usuario es incorrecta o falta algún dato,
                por favor
                <router-link :to="{ name: 'account-info' }">
                  Valida tu información
                </router-link>
              </div>
              <div class="row mb-2">
                <div class="col-12 col-lg-12">
                  <label
                    for="pickup_person_name"
                    class="card-input card-input-small"
                  >
                    <div class="box-input-card" />
                    <Field
                      id="name"
                      v-model="pickupPersonName"
                      type="text"
                      name="pickup_person_name"
                      :placeholder="getTitle('cart', 'person_first_name')"
                      :rules="pickupSchema.pickupPersonName"
                      :disabled="true"
                      @input="
                        (event) =>
                          setValue(
                            'checkout/setPickupPersonName',
                            event.target.value,
                          )
                      "
                      @keydown.enter.prevent
                    />
                  </label>
                  <ErrorMessage name="pickup_person_name" class="error" />
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-12 col-lg-6">
                  <label
                    for="pickup_person_last_name"
                    class="card-input card-input-small"
                  >
                    <div class="box-input-card" />
                    <Field
                      id="name"
                      v-model="pickupPersonLastName"
                      type="text"
                      name="pickup_person_last_name"
                      :placeholder="getTitle('cart', 'person_last_name')"
                      :rules="pickupSchema.pickupPersonLastName"
                      :disabled="true"
                      @input="
                        (event) =>
                          setValue(
                            'checkout/setPickupPersonLastName',
                            event.target.value,
                          )
                      "
                      @keydown.enter.prevent
                    />
                  </label>
                  <ErrorMessage name="pickup_person_last_name" class="error" />
                </div>
                <div class="col-12 col-lg-6">
                  <label
                    for="pickup_person_mother_last_name"
                    class="card-input card-input-small"
                  >
                    <div class="box-input-card" />
                    <Field
                      id="name"
                      v-model="pickupPersonMotherLastName"
                      type="text"
                      name="pickup_person_mother_last_name"
                      :placeholder="getTitle('cart', 'person_mother_last_name')"
                      :rules="pickupSchema.pickupPersonMotherLastName"
                      :disabled="true"
                      @input="
                        (event) =>
                          setValue(
                            'checkout/setPickupPersonMotherLastName',
                            event.target.value,
                          )
                      "
                      @keydown.enter.prevent
                    />
                  </label>
                  <ErrorMessage
                    name="pickup_person_mother_last_name"
                    class="error"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-lg-12">
                  <label
                    for="pickup_person_telephone"
                    class="card-input card-input-small"
                  >
                    <div class="box-input-card" />
                    <Field
                      id="name"
                      v-model="pickupPersonTelephone"
                      type="text"
                      name="pickup_person_telephone"
                      :placeholder="getTitle('cart', 'telephone_contact')"
                      :rules="pickupSchema.pickupPersonTelephone"
                      :disabled="true"
                      @input="
                        (event) =>
                          setValue(
                            'checkout/setPickupPersonTelephone',
                            event.target.value,
                          )
                      "
                      @keydown.enter.prevent
                    />
                  </label>
                  <ErrorMessage name="pickup_person_telephone" class="error" />
                </div>
              </div>
            </VeeForm>
            <h5 class="fw-semibold">
              {{ getTitle("cart", "who_receives") }}
            </h5>
            <div v-if="!loading">
              <ul
                v-if="items.length > 0"
                class="list-unstyled accordion-option-shipping overflow-y-auto"
                :class="{ closed: isDirectionActive || isCourierActive }"
              >
                <li
                  v-for="(item, index) in items"
                  :key="index"
                  class="me-3 list-option pointer available-products-list see-active-products w-100 mb-2"
                  :class="{
                    'active-available-products overflow-y-auto':
                      item.id === store?.id,
                  }"
                  @click="selectBranch(item)"
                >
                  <div class="row mt-2 mb-1">
                    <div class="col-12 col-lg-5">
                      <div class="d-flex">
                        <div class="box-light-branch ms-2 me-2 mb-1" />
                        <div class="d-flex flex-row-reverse flex-lg-column">
                          <div
                            class="branch-name p-lg-1 p-sm-2 ps-1 mt-2 mt-lg-0"
                          >
                            {{ item?.name }}
                          </div>
                          <div
                            class="branch-name-subtitle p-lg-1 ps-1 mt-2 mt-lg-0"
                          >
                            {{ getTitle("cart", "branch") }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- <div class="col-3 col-lg-2 px-1">
                      <div class="d-flex flex-column">
                        <span class="fw-bold branch-name p-lg-1">{{
                          format_km(item.distance)
                        }}</span>
                        <span
                          class="p-lg-0 text-distance p-lg-1 mt-1 mt-lg-0"
                          >{{ getTitle("cart", "distance") }}</span
                        >
                      </div>
                    </div> -->
                    <div class="col-12 col-lg-5 d-none d-lg-block mt-1">
                      <div
                        class="d-flex flex-row-reverse flex-lg-column float-start"
                      >
                        <h6
                          v-if="
                            item.is_open_now &&
                            item.product_in_stocks.length > 0
                          "
                          class="fw-bold text-nowrap"
                        >
                          {{ getTitle("cart", "label_pickup_in_store") }}
                        </h6>
                        <h6
                          v-else-if="item.is_open_now"
                          class="fw-bold text-nowrap"
                        >
                          Puedes recolectar dentro de 4 a 6 días
                        </h6>
                        <h6 v-else class="fw-bold text-nowrap">
                          {{ getTitle("cart", "label_pickup_tomorrow") }}
                        </h6>
                        <span
                          v-if="item.has_all_products && item.is_open_now"
                          class="text-green"
                        >
                          {{
                            getTitle(
                              "cart",
                              "label_available_to_shop_all_products",
                            )
                          }}
                        </span>
                        <span
                          v-else-if="!item.has_all_products && item.is_open_now"
                          class="text-danger"
                        >
                          {{
                            getTitle(
                              "cart",
                              "label_available_to_shop_some_products",
                            )
                          }}
                        </span>
                        <span
                          v-else-if="item.has_all_products && !item.is_open_now"
                          class="text-blue"
                        >
                          {{ getTitle("cart", "label_store_is_closed") }}
                        </span>
                      </div>
                    </div>
                    <div
                      class="col-12 col-lg-2 d-flex align-items-center justify-content-end d-none d-lg-block mt-2"
                      @click="showProduct($event, item.id)"
                    >
                      <a
                        class="text-danger text-decoration-none me-2 text-nowrap"
                      >
                        <i
                          class="bi text-danger"
                          :class="[
                            showProducts[item.id] && item.id === store?.id
                              ? 'bi-dash-circle'
                              : 'bi-plus-circle',
                          ]"
                        />
                        {{
                          showProducts[item.id] && item.id === store?.id
                            ? getTitle("cart", "hide_products")
                            : getTitle("cart", "view_products")
                        }}
                      </a>
                    </div>
                  </div>
                  <Transition name="fade-down">
                    <div
                      v-if="showProducts[item.id] && item.id === store?.id"
                      class="row mt-3"
                    >
                      <div class="col-12">
                        <div class="d-flex flex-column overflow-auto">
                          <div class="row d-flex align-items-center mx-2">
                            <div
                              class="col-6 offset-1 text-center fs-5 fw-bold"
                            >
                              Producto
                            </div>
                            <div class="col-1 text-center fs-5 fw-bold">
                              Piezas
                            </div>
                          </div>
                          <div
                            v-for="(product, indexProduct) in products"
                            :key="product.product.id ?? indexProduct"
                            class="col-12 mb-3"
                            style="min-width: 200px"
                          >
                            <div class="row d-flex align-items-center mx-2">
                              <div class="col-1 d-none d-lg-flex">
                                <img
                                  v-if="product.product.medium_cover_url"
                                  :src="product.product.medium_cover_url"
                                  :alt="product.product.name ?? ''"
                                  width="50"
                                />
                              </div>
                              <div class="col-6 text-left">
                                <small class="fs-7">
                                  {{ product.product.name }}
                                </small>
                              </div>
                              <div class="col-6 col-lg-1 text-center">
                                {{ product.quantity }}
                              </div>
                              <div class="col-12 col-lg-4 text-left">
                                <small class="fs-7">
                                  <span
                                    v-if="
                                      getTotalByProductInStock(
                                        item.product_in_stocks,
                                        product.product.id ?? '',
                                      ) == 0
                                    "
                                    class="text-danger"
                                  >
                                    Disponibles dentro de 4 a 6 días
                                  </span>
                                  <span
                                    v-else-if="
                                      getTotalByProductInStock(
                                        item.product_in_stocks,
                                        product.product.id ?? '',
                                      ) < product.quantity
                                    "
                                    class="text-warning"
                                  >
                                    Disponibles solo
                                    <strong>{{
                                      getTotalByProductInStock(
                                        item.product_in_stocks,
                                        product.product.id ?? "",
                                      )
                                    }}</strong>
                                    de las
                                    <strong>{{ product.quantity }}</strong>
                                    solicitadas, las piezas restantes estarán
                                    disponibles dentro de 4 a 6 días
                                  </span>
                                  <span v-else class="text-success">
                                    Disponibles
                                  </span>
                                </small>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Transition>
                </li>
              </ul>
              <div
                v-else-if="origin == null"
                class="alert alert-warning"
                role="alert"
              >
                Por favor, selecciona tú código postal
              </div>
              <div v-else class="alert alert-info" role="alert">
                No hay existencias en las surcursales
              </div>
            </div>
            <loading :loading="loading" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { Form as VeeForm, Field, ErrorMessage } from "vee-validate";
import { ShippingService } from "@/services/ShippingService";
import { getTitleTranslate, errorsToString } from "../../utils/general";
import { ItemBranch } from "@/interfaces/ItemBranch";
import { mapGetters } from "vuex";
import Jsona from "jsona";
import { store } from "@/store";
import auth from "@/services/AuthService";
import Loading from "../ui/Loading.vue";
import eventBus from "@/event-bus.js";
import { CustomNotifyService } from "@/services/CustomNotifyService";
import { AxiosError } from "axios";

const dataFormatter = new Jsona();
const notifyService = new CustomNotifyService();

interface PersonPickup {
  first_name: string;
  last_name: string;
  mother_last_name: string;
  phone: string;
  mobil: string;
  is_valid: boolean;
}

export default {
  components: {
    VeeForm,
    Field,
    Loading,
    ErrorMessage,
  },

  props: {
    isDirectionActive: {
      type: Boolean,
      default: false,
    },
    isCourierActive: {
      type: Boolean,
      default: false,
    },
    isShow: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      showAccordion: false,
      items: [] as ItemBranch[],
      showProducts: [] as Array<boolean>,
      loading: false,
      defaultPickupPerson: null as PersonPickup | null,
      pickupSchema: {
        pickupPersonName: "required|person_name",
        pickupPersonLastName: "required|person_name",
        pickupPersonMotherLastName: "person_name",
        pickupPersonTelephone: "required|min:10|phone",
      },
    };
  },
  computed: {
    isUserAuthenticated() {
      return auth.isUserLogged();
    },
    ...mapGetters("checkout", {
      isPickup: "getIsPickup",
      store: "getStore",
      pickupPersonName: "getPickupPersonName",
      pickupPersonLastName: "getPickupPersonLastName",
      pickupPersonMotherLastName: "getPickupPersonMotherLastName",
      pickupPersonTelephone: "getPickupPersonTelephone",
    }),
    products() {
      return store.getters["cart/getItems"];
    },

    storeWitStock() {
      return this.items.filter((item: any) => {
        return item.product_in_stocks.length > 0;
      });
    },
    origin() {
      const address = store.getters["session/getAddress"];
      const zipCodeSession = store.getters["session/getZipCode"];
      const selectedAddress = store.getters["session/getSelectedAddress"];

      let origin = null;
      if (zipCodeSession) {
        origin = `México, Cp. ${zipCodeSession}`;
      } else if (selectedAddress) {
        origin = selectedAddress.formatted_address;
      } else if (address && address.length > 0) {
        origin = address[0].formatted_address;
      }
      return origin;
    },
  },
  watch: {
    isPickup(newIsPickup) {
      this.showAccordion = newIsPickup;
    },
    isShow(newShow) {
      if (newShow && this.origin == null) {
        eventBus.emit("toggle-zip-code-pop-over", { status: true });
      }
    },
    origin() {
      this.getPickupStores();
    },
  },
  mounted() {
    this.getPickupStores();
    this.showAccordion = this.isPickup;

    if (this.isShow && this.origin == null) {
      eventBus.emit("toggle-zip-code-pop-over", { status: true });
    }
  },
  methods: {
    getTitle(section: string, name: string) {
      return getTitleTranslate(section, name);
    },
    togglePanel() {
      this.showAccordion = !this.showAccordion;
      store.commit("checkout/setIsPickup");
    },
    getPickupStores() {
      const shippingService = new ShippingService();
      if (this.isUserAuthenticated && shippingService && this.origin) {
        this.loading = true;
        shippingService
          .getPickupStores(this.origin)
          .then((response) => {
            if (response && response.data) {
              this.items = dataFormatter.deserialize(response.data) as [];
            }
            this.defaultPickupPerson =
              response?.data.meta.default_pickup_person;
            this.resetPickupPerson();
          })
          .catch((response: AxiosError) => {
            console.log(response);
            notifyService.getNotification({
              type: "error",
              title: `Ocurrio un error al cargar la información de sucursales`,
              text: errorsToString(response),
              time: 5000,
            });
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.items = [];
      }
    },
    format_km(meters: number) {
      return (meters / 100).toFixed(1) + " Km";
    },
    selectBranch(branch: any) {
      store.commit("checkout/setStore", branch);
      this.showProduct(null, branch.id);
    },
    showProduct(event: Event | null, storeId: number) {
      if (!this.showProducts[storeId]) {
        this.showProducts.forEach(
          (_item: boolean, index: number, array: Array<boolean>) => {
            array[index] = false;
          },
        );
      }
      this.showProducts[storeId] = !this.showProducts[storeId];
      if (event) {
        event.stopPropagation();
      }
    },
    onAddPersonPickup() {
      console.log("onAddPersonPickup");
    },
    setValue(commit: string, value: string) {
      store.commit(commit, value);
    },
    resetPickupPerson() {
      store.commit(
        "checkout/setPickupPersonName",
        this.defaultPickupPerson?.first_name,
      );
      store.commit(
        "checkout/setPickupPersonLastName",
        this.defaultPickupPerson?.last_name,
      );
      store.commit(
        "checkout/setPickupPersonMotherLastName",
        this.defaultPickupPerson?.mother_last_name,
      );
      store.commit(
        "checkout/setPickupPersonTelephone",
        this.defaultPickupPerson?.mobil
          ? this.defaultPickupPerson?.mobil
          : this.defaultPickupPerson?.phone,
      );
    },
    getTotalByProductInStock(
      productsIStock: Array<{ product: { id: string }; total: number }>,
      productIdFind: string,
    ) {
      const product = productsIStock.find(
        (product: { product: { id: string }; total: number }) => {
          return product.product.id == productIdFind;
        },
      );
      return product?.total ?? 0;
    },
    getQtyByProductInStock(
      productsIStock: Array<{ product: { id: string }; qty_request: number }>,
      productIdFind: string,
    ) {
      const product = productsIStock.find(
        (product: { product: { id: string }; qty_request: number }) => {
          return product.product.id == productIdFind;
        },
      );
      return product?.qty_request ?? 0;
    },
  },
};
</script>
<style lang="scss">
.fade-down-enter-active {
  transition: all 0.3s ease-out;
}

.fade-down-leave-active {
  transition: none;
}

.fade-down-enter-from,
.fade-down-leave-to {
  opacity: 0;
}
</style>
