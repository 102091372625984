<template lang="">
  <base-link
    v-if="product"
    class="row border border-light-subtle p-2 mx-0 mb-2"
    @click="goToProductDetail(product.links.self)"
  >
    <div class="col-4">
      <image-product
        v-if="product && product.extra_small_cover_url"
        :image="product.extra_small_cover_url"
        :max-height="100"
        :max-sm-height="80"
      />
    </div>
    <div class="col-8">
      <h6 class="text-secondary fs-7 line-clamp-2">
        {{ product.name }}
      </h6>
      <div>
        <span class="fs-8 text-secondary text-capitalize">{{
          $t("Cash price")
        }}</span>
      </div>
      <div>
        <div
          class="text-blue fw-semibold fs-5"
          v-html="product.current_price_with_tax"
        />
        <span class="text-body-tertiary fs-7"> / {{ product.unit_name }} </span>
      </div>
    </div>
  </base-link>
</template>
<script lang="ts">
import ImageProduct from "../ui/ImageProduct.vue";
import BaseLink from "../buttons/BaseLink.vue";
import { getFinalEshopUrl } from "../../utils/general";
import eventBus from "@/event-bus";
export default {
  components: {
    ImageProduct,
    BaseLink,
  },
  props: {
    product: {
      type: Object,
      default: null,
    },
  },
  emits: ["close-suggestions"],
  methods: {
    getFinalUrl(path: string) {
      return getFinalEshopUrl(path);
    },
    goToProductDetail(path: string) {
      eventBus.emit("close-suggestions");
      return this.$router.push(path);
    },
  },
};
</script>
<style lang=""></style>
