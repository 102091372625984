import Brand, { IBrand } from "./Brand";
import Category, { ICategory } from "./Category";
import Product, { IProduct } from "./Product";
import { IProductImage } from "./ProductImage";

export type TMainSearchFilterLink = [string, { path: string }, boolean];
export interface IMainSearchFilterOption {
  code: string;
  count: number;
  link: TMainSearchFilterLink;
  unlink: TMainSearchFilterLink;
  slug: string;
  raw_value: string;
  value: string;
}
export interface IMainSearchPagination {
  current_page: number;
  pages: number;
  per_page: number;
  total: number;
}
export interface IMainSearchCategory {
  id: string;
  image: string;
  name: string;
  link: TMainSearchFilterLink;
  slug: string;
}
export interface IMainSearchFilter {
  code: string;
  name: string;
  options: IMainSearchFilterOption[];
  options_count: number;
  order: number;
  parent_id: string | null;
  parent_name: string | null;
  raw_name: string;
  slug: string;
  slug_route: string;
  slug_translate: string;
  slug_prefix: string;
}
export interface IMainSearchPricing {
  min: number;
  max: number;
}
export interface IMainSearchMeta {
  newFilters: IMainSearchFilter[];
  pagination: IMainSearchPagination;
  parentCategoryImages: IMainSearchCategory[];
  prices: IMainSearchPricing;
  searchPath: string | null;
  searchText: string | null;
  secondarySearchText: string | null;
  selectedCategoriesPath: IMainSearchCategory[];
  selectedCategory: ICategory | null;
  selectedBrand: IBrand | null;
  selectedFilters: IMainSearchFilter[];
  totalCount: number;
}
export interface IMainSearchError {
  meta: { route: [string, string | unknown] };
  status: number;
  title: string | null;
}
export interface IMainSearchResult {
  data: IProduct[];
  meta: IMainSearchMeta;
  errors?: IMainSearchError[];
}
type TMainSearchMeta = {
  selectedCategory: Category | null;
  selectedBrand: Brand | null;
} & IMainSearchMeta;
export default class MainSearchResult implements IMainSearchResult {
  public data: Product[] = [];
  public meta: TMainSearchMeta = this.parseMeta({} as IMainSearchMeta);
  public errors: IMainSearchError[];
  private lastPage = 0;
  private firstPage = 0;
  private pageList = new Map<number, Product[]>();

  public constructor({ data, meta, errors }: IMainSearchResult) {
    this.setMeta(meta);
    this.appendPage(this.meta.pagination.current_page, data);
    this.errors = Array.isArray(errors) ? errors : [];
  }

  private parsePagination(
    pagination: IMainSearchPagination,
  ): IMainSearchPagination {
    return {
      current_page: pagination?.current_page * 1 || 0,
      per_page: pagination?.per_page * 1 || 0,
      pages: pagination?.pages * 1 || 0,
      total: pagination?.total * 1 || 0,
    };
  }

  private parsePricing(pricing: IMainSearchPricing): IMainSearchPricing {
    return { min: pricing?.min * 1 || 0, max: pricing?.max * 1 };
  }

  private parseArray<T>(arr: T): T {
    return Array.isArray(arr) ? arr : ([] as T);
  }

  public getFixedNewFilters() {
    return this.meta.newFilters;
  }

  public hasMore(page?: number) {
    if (page) {
      return page < this.meta.pagination.pages;
    }
    return this.meta.pagination.current_page < this.meta.pagination.pages;
  }

  public isValidPage(page: number) {
    return this.fixPage(page) === page;
  }

  public fixPage(page: number) {
    let fixedPage = page;
    if (page <= 0) {
      fixedPage = 1;
    } else if (page > this.meta.pagination.pages) {
      fixedPage = this.meta.pagination.pages;
    }
    return fixedPage;
  }

  public appendPage(newPage: number, products: IProduct[]) {
    if (Array.isArray(products)) {
      // Compatibilización de propiedades que no existen en el modelo común.
      products.forEach((item) => {
        // Mueve el cover a la estructura de imágenes del modelo.
        const auxItem = item as unknown as {
          small_cover: IProductImage;
        };
        if (auxItem.small_cover) {
          item.images = [auxItem.small_cover];
        }
      });
    }
    const fixedProducts = Array.isArray(products)
      ? Product.fromArray(products)
      : [];
    // Agrega la página a la cuál pertenece el producto, ayuda a hacer scrolling.
    fixedProducts.forEach((prod) => (prod.page = newPage));
    const sortedList: [number, Product[]][] = [];
    this.pageList.set(newPage, fixedProducts);
    this.pageList.forEach((otherProducts, otherPage) => {
      sortedList.push([otherPage, otherProducts]);
    });
    sortedList.sort((a, b) => a[0] - b[0]);
    const newData: Product[] = [];
    sortedList.forEach((item) => newData.push(...item[1]));
    this.lastPage = newPage;
    if (!this.firstPage || this.firstPage > newPage) {
      this.firstPage = newPage;
    }
    this.data = newData;
    this.meta.pagination.current_page = newPage;
  }

  /** Obtiene la última página cargada. */
  public getLastPage() {
    return this.lastPage;
  }

  /** Obtiene la primera página cargada. */
  public getFirstPage() {
    return this.firstPage;
  }

  public parseMeta(meta: IMainSearchMeta) {
    return {
      newFilters: this.parseArray(meta?.newFilters),
      pagination: this.parsePagination(meta?.pagination),
      parentCategoryImages: meta?.parentCategoryImages || [],
      prices: this.parsePricing(meta?.prices),
      searchPath: meta?.searchPath || null,
      searchText: meta?.searchText || null,
      secondarySearchText: meta?.searchText || null,
      selectedCategoriesPath: this.parseArray(meta?.selectedCategoriesPath),
      selectedCategory: meta?.selectedCategory
        ? new Category(meta.selectedCategory)
        : null,
      selectedBrand: meta?.selectedBrand ? new Brand(meta.selectedBrand) : null,
      selectedFilters: this.parseArray(meta?.selectedFilters),
      totalCount: meta?.totalCount || 0,
    };
  }

  public setMeta(meta: IMainSearchMeta) {
    this.meta = this.parseMeta(meta);
  }

  public setErrors(meta: IMainSearchMeta) {
    this.meta = this.parseMeta(meta);
  }

  public getPageLoaded(page: number) {
    return this.pageList.get(page);
  }
}
