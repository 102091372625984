import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { VITE_API_ENDPOINT } from "@/config/constants.js";
import { store } from "@/store";
import Jsona from "jsona";

const dataFormatter = new Jsona();

export class CfdiCatalogsService {
  public async getCfdiUses() {
    const cfdiUses = store.getters["cfdi/getUses"];
    if (cfdiUses && cfdiUses.length > 0) {
      return cfdiUses;
    }
    const options: AxiosRequestConfig = {
      baseURL: VITE_API_ENDPOINT,
      headers: {
        Accept: "application/vnd.api+json",
      },
    };
    const url = "/shop-v1/cfdi-uses";
    const response = await axios.get(url, options);
    if (!(response instanceof AxiosError)) {
      const data = dataFormatter.deserialize(response.data);
      store.commit("cfdi/setUses", data);
      return data;
    } else if (response.response) {
      const data = dataFormatter.deserialize(response.data);
      store.commit("cfdi/setUses", data);
      return data;
    } else {
      return null;
    }
  }

  public async getCfdiRegimes() {
    const cfdiRegimes = store.getters["cfdi/getRegimes"];
    if (cfdiRegimes && cfdiRegimes.length > 0) {
      return cfdiRegimes;
    }
    const options: AxiosRequestConfig = {
      baseURL: VITE_API_ENDPOINT,
      headers: {
        Accept: "application/vnd.api+json",
      },
    };
    const url = "/shop-v1/cfdi-regimes";
    const response = await axios.get(url, options);
    if (!(response instanceof AxiosError)) {
      const data = dataFormatter.deserialize(response.data);
      store.commit("cfdi/setRegimes", data);
      return data;
    } else if (response.response) {
      const data = dataFormatter.deserialize(response.data);
      store.commit("cfdi/setRegimes", data);
      return data;
    } else {
      return null;
    }
  }
}
