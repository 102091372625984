import { Component } from "vue";
import GuaranteesAndDevolutions from "@/components/company/GuaranteesAndDevolutions.vue";
import LegalWarningPage from "@/components/company/LegalWarningPage.vue";
import PrivacityPage from "@/components/company/PrivacityPage.vue";
import TermsAndConditionsPage from "@/components/company/TermsAndConditionsPage.vue";
import ReturnPolicy from "@/components/company/ReturnPolicy.vue";
import BillingCustomer from "@/components/company/BillingCustomer.vue";

const createRoute = (path: string, name: string, component: Component) => [
  {
    path,
    name,
    component,
  },
];

const router = [
  ...createRoute("/privacidad", "privacidad", PrivacityPage),
  ...createRoute("/aviso-legal", "aviso-legal", LegalWarningPage),
  ...createRoute(
    "/terminos-y-condiciones",
    "terminos-y-condiciones",
    TermsAndConditionsPage,
  ),
  ...createRoute(
    "/garantias-y-devoluciones",
    "garantias-y-devoluciones",
    GuaranteesAndDevolutions,
  ),
  ...createRoute(
    "/politicas-de-devolucion",
    "politicas-de-devolucion",
    ReturnPolicy,
  ),
  ...createRoute("/facturacion", "facturacion", BillingCustomer),
];

export default router;
