<template lang="">
  <div ref="popOver" class="h-100 w-100">
    <div
      class="bg-primary"
      :class="[isOpen ? 'bg-fade-inactive' : 'bg-fade-active']"
      :style="{
        top: isOpen ? 0 : topButton + 'px',
        left: isOpen ? 0 : leftButton + 'px',
        width: isOpen ? '100%' : widthButton + 'px',
        height: isOpen ? '100%' : heightButton + 'px',
      }"
    />
    <OnClickOutside
      class="buttonText h-100 w-100"
      :options="{ ignore: ['.popover'] }"
      @trigger="close"
      @click="toggle"
    >
      <slot name="buttonText" />
    </OnClickOutside>

    <div class="invisible">
      <div class="title">
        <slot name="title" />
      </div>
      <div ref="popoverContent" class="popover-content">
        <slot />
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { Popover } from "bootstrap";
import eventBus from "@/event-bus";
import { OnClickOutside } from "@vueuse/components";
import { ref } from "vue";

export default {
  components: {
    OnClickOutside,
  },
  setup() {
    const popOver = ref<HTMLElement | null>(null);
    return {
      popOver,
    };
  },
  data: function () {
    return {
      popoverComp: null as Popover | null,
      isOpen: false,
      topButton: 0,
      leftButton: 0,
      widthButton: 0,
      heightButton: 0,
    };
  },
  computed: {
    buttonId() {
      return "popover_" + this.$.uid;
    },
  },
  watch: {
    isOpen(newIsOpen, oldIsOpen) {
      if (newIsOpen != oldIsOpen && this.popoverComp) {
        this.popoverComp.toggle();
      }
    },
  },
  mounted() {
    window.addEventListener("resize", this.updateViewportDimensions);

    // Inicializar el popover al montar el componente
    this.$nextTick(() => {
      const popoverButton = this.popOver?.querySelector(".buttonText button");
      if (popoverButton) {
        popoverButton.id = this.buttonId;
        const title = this.popOver?.querySelector(".title");
        const content = this.popOver?.querySelector(".popover-content");
        if (content && title) {
          this.popoverComp = new Popover(popoverButton, {
            html: true,
            title: title,
            content: content,
            trigger: "manual",
            placement: "bottom",
          });
        }
      }
    });
    const componenteElement: HTMLElement | null | undefined =
      this.popOver?.querySelector(".buttonText button");
    if (componenteElement) {
      const boundingRect = componenteElement.getBoundingClientRect();

      this.topButton = boundingRect.top;
      this.leftButton = boundingRect.left;
      this.widthButton = componenteElement.offsetWidth;
      this.heightButton = componenteElement.offsetHeight;
    }
  },
  created() {
    eventBus.on("close-pop-over", (data: any) => {
      const idElement: string = this.popOver?.id ? this.popOver?.id : "";
      if (idElement == data.id) {
        if (!data.status) {
          this.close();
        } else {
          this.toggle();
        }
      }
    });
  },
  beforeUnmount() {
    eventBus.off("close-pop-over");
  },
  onUnmounted() {
    window.removeEventListener("resize", this.updateViewportDimensions);
  },
  methods: {
    toggle() {
      this.isOpen = !this.isOpen;
      this.emitCustomEvent(this.isOpen);
    },
    close() {
      this.isOpen = false;
      this.emitCustomEvent(this.isOpen);
    },
    emitCustomEvent(status: boolean) {
      eventBus.emit("set-is-hovered", { id: this.buttonId, status: status });
    },
    updateViewportDimensions() {
      const componenteElement: HTMLElement | null | undefined =
        this.popOver?.querySelector(".buttonText button");
      if (componenteElement) {
        const boundingRect = componenteElement.getBoundingClientRect();

        this.topButton = boundingRect.top;
        this.leftButton = boundingRect.left;
        this.widthButton = componenteElement.offsetWidth;
        this.heightButton = componenteElement.offsetHeight;
      }
    },
  },
};
</script>
<style lang="sass" scoped>
.bg-fade-inactive
    position: fixed
    z-index: 1000
    opacity: 0.5
    transition: all 0.3s ease

.bg-fade-active
    position: fixed
    z-index: -1
    opacity: 0
    transition: all 0.3s ease

.scale-enter-active,
.scale-leave-active
    transition: all 2s linear

.scale-enter-from,
.scale-leave-to
    width: 10px
    height: 10px
    opacity: 0
    top: 150px !important
</style>
