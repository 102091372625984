<script lang="ts">
import { ref, computed, onMounted } from "vue";
import TabTitle from "@/interfaces/TabTitle";
import Wallet from "@/models/Wallet";
import auth from "@/services/AuthService";
import { userProfileService } from "@/services/UserProfileService";
import { CustomNotifyService } from "@/services/CustomNotifyService";
import axios, { AxiosError } from "axios";
import { formatPrice } from "@/utils/general";

export default {
  setup() {
    const electronicWallet = ref<Wallet[]>([]);
    const isUserAuthenticated = computed(() => auth.isUserLogged());
    const notifySrv = new CustomNotifyService();
    const hasWallet = ref<boolean>(false);
    const walletAmount = ref<number>(0);
    const loading = ref<boolean>(false);
    const itemsPerPage = ref(15);
    const currentPage = ref(1);

    const paginatedElectronicWallet = computed(() => {
      return electronicWallet.value.slice(
        0,
        itemsPerPage.value * currentPage.value,
      );
    });

    function loadMore() {
      currentPage.value++;
    }
    async function fetchPersonalInfo() {
      loading.value = true;
      if (isUserAuthenticated.value) {
        try {
          const response = await userProfileService.wallet();
          hasWallet.value = response.has_wallet;
          walletAmount.value = response.wallet_amount;
          electronicWallet.value = response.wallet_movements_detail;
          loading.value = false;
        } catch (error) {
          if (axios.isAxiosError(error)) {
            const axiosError = error as AxiosError;
            console.error("Error fetching wallet:", error);

            if (axiosError.response && axiosError.response.status === 404) {
              notifySrv.getNotification({
                type: "error",
                title: "Error 404",
                text: "¡Aún no cuenta con monedero electrónico!",
              });
            } else {
              notifySrv.getNotification({
                type: "error",
                title: "Error inesperado",
                text: "Lo sentimos, ha ocurrido un error. Por favor, intente más tarde.",
              });
            }
          }
        }
      }
    }

    const formattedPrice = (price: number) => {
      return formatPrice(price);
    };

    onMounted(async () => {
      TabTitle("Tu monedero electrónico");
      await fetchPersonalInfo();
    });

    return {
      electronicWallet,
      notifySrv,
      hasWallet,
      loading,
      walletAmount,
      formattedPrice,
      paginatedElectronicWallet,
      loadMore,
    };
  },
};
</script>

<template>
  <div class="container-wallet mt-1">
    <div v-if="loading">
      <p v-for="n in 6" :key="n" class="placeholder-glow">
        <span class="placeholder col-12 bg-light p-4"></span>
      </p>
    </div>
    <div v-if="hasWallet && !loading">
      <div class="d-flex justify-content-between align-items-center mb-4">
        <h1 class="title text-secondary mt-1 p-0 m-0">
          Información de tu monedero
        </h1>
        <button type="button" class="btn">
          <span class="text-dark">Saldo</span>
          <span
            class="badge ms-1 rounded-pill py-2 fw-normal fs-6"
            :class="[
              Number(walletAmount) > 0 ? 'text-bg-success' : 'text-bg-dark',
            ]"
          >
            {{ formattedPrice(Number(walletAmount) || 0) }}
          </span>
        </button>
      </div>
      <div></div>
      <div class="flex-nowrap overflow-auto">
        <div
          class="col-12 d-flex justify-content-between align-items-center subtitles mb-1 text-secondary text-center"
        >
          <div class="col-2">Fecha</div>
          <div class="col-2">Monto</div>
          <div class="col-2">Tipo</div>
          <div class="col-2">Estatus</div>
          <div class="col-2">ID pago</div>
          <div class="col-2">Vigencia</div>
        </div>
        <div
          v-for="item in paginatedElectronicWallet"
          :key="item.id"
          class="container d-flex justify-content-between align-items-center data mb-1 text-center"
          :class="{ 'text-decoration-line-through': item.canceled_or_expired }"
        >
          <div class="col-2 small">{{ item.created_at }}</div>
          <div
            class="col-2 small"
            :class="[Number(item.amount) > 0 ? 'text-success' : 'text-danger']"
          >
            {{ formattedPrice(Number(item.amount)) }}
          </div>
          <div class="col-2 small">{{ item.type_text }}</div>
          <div class="col-2 small" :style="{ color: item.status_color || '' }">
            {{ item.status_text }}
          </div>
          <div class="col-2 small">
            {{ item.transaction_payment_id || "-" }}
          </div>
          <div class="col-2 small">{{ item.expires_at || "-" }}</div>
        </div>
        <div
          v-if="electronicWallet.length > paginatedElectronicWallet.length"
          class="col-12 d-flex justify-content-center mt-3"
        >
          <button class="btn btn-success" @click="loadMore">Ver más</button>
        </div>
      </div>
    </div>
    <div v-if="!hasWallet && !loading" class="col-12 alert alert-danger">
      ¡Aún no cuenta con monedero electrónico!
    </div>
  </div>
</template>
