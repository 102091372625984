<template lang="">
  <pop-over :id="popOverId">
    <template #buttonText>
      <link-nav-transaction
        tag="button"
        icon="bi-geo-alt"
        :text="getTitle('header', 'select_your_postal_code')"
        :text-inactive="textZipCodeOrAddress"
        color-hover="#018786"
      />
    </template>
    <template #title>
      {{ getTitle("header", "choose_your_location") }}
    </template>
    <template #default>
      <p class="fs-9">
        {{ getTitle("header", "delivery_options_and_speeds_may_vary") }}.
      </p>
      <base-link
        v-if="!isUserAuthenticated"
        to="login"
        class="btn btn-info text-white rounded-0 fs-9 w-100"
        @click="closePopover()"
      >
        {{ getTitle("header", "sign_in_to_see_your_addresses") }}
      </base-link>
      <div class="scroll-zip-code">
        <div
          v-for="(address, index) in addresses"
          :key="index"
          class="border border-light-subtle p-2 d-flex flex-row align-items-center mb-1"
          :class="[selectedAddress == address.id ? 'selected-address' : '']"
        >
          <div
            class="form-check d-flex justify-content-center align-items-center"
          >
            <input
              :id="'addressCustomer' + address.id"
              class="form-check-input me-2"
              :style="{ width: '15px', height: '15px' }"
              type="radio"
              name="addressCustomer"
              @click="selectAddress(address)"
            />
            <label
              class="form-check-label"
              :for="'addressCustomer' + address.id"
            >
              <h5
                class="fs-9 fw-semibold text-center mb-0 p-2 border-bottom border-light-subtle"
              >
                {{ address.name }}
              </h5>
              <p class="fs-9 mb-0 py-2">
                {{ address.formatted_address }}
              </p>
            </label>
          </div>
        </div>
      </div>
      <div class="my-2 d-flex justify-content-between align-items-center">
        <div class="line flex-grow-1" />
        <span class="text-center px-2">
          {{ getTitle("header", "or") }}
        </span>
        <div class="line flex-grow-1" />
      </div>
      <form class="input-group mb-3" @submit.prevent="sendZipCode">
        <input type="hidden" name="_token" :value="csrfToken" />
        <input
          v-model="zipCode"
          type="text"
          class="form-control rounded-0 fs-9"
          :placeholder="getTitle('header', 'enter_a_zip_code')"
          :aria-label="getTitle('header', 'enter_a_zip_code')"
          aria-describedby="requestZip"
          maxlength="5"
        />
        <button
          id="requestZip"
          class="btn btn-info text-white rounded-0 fs-9"
          type="submit"
        >
          {{ getTitle("header", "request") }}
        </button>
      </form>
      <button
        class="btn btn-link text-danger text-center link-underline link-underline-opacity-0 fs-9 fw-semibold w-100"
        type="button"
        @click="resetZipCode()"
      >
        <i class="bi bi-x-lg me-1" />
        {{ getTitle("header", "remove_current_selection") }}
      </button>
    </template>
  </pop-over>
</template>
<script lang="ts">
import { getTitleTranslate, getEshopLinkTranslate } from "../../utils/general";
import LinkNavTransaction from "./LinkNavTransaction.vue";
import PopOver from "@/components/utils/PopOver.vue";
import BaseLink from "./BaseLink.vue";
import eventBus from "@/event-bus.js";
import { store } from "@/store";
import { StateAddress } from "@/store/modules/session";
import { ZipCodeService } from "@/services/ZipCodeService";
import auth from "@/services/AuthService";
export default {
  components: {
    PopOver,
    BaseLink,
    LinkNavTransaction,
  },
  data() {
    return {
      zipCode: "",
      addresses: [],
    };
  },
  computed: {
    popOverId() {
      return "pop-over-zip" + Math.random().toString(36).substring(7);
    },
    csrfToken() {
      return store.getters["auth/getCsrfToken"];
    },
    isUserAuthenticated() {
      return auth.isUserLogged();
    },
    zipCodeSession() {
      return store.getters["session/getZipCode"];
    },
    selectedAddress() {
      return store.getters["session/getSelectedAddress"];
    },
    textZipCodeOrAddress() {
      let text = "";
      if (this.selectedAddress) {
        text = `${this.getTitle("header", "send_to")} ${this.selectedAddress.name}`;
      } else if (this.zipCodeSession) {
        text = `${this.getTitle("header", "c_p")} ${this.zipCodeSession}`;
      }
      return text;
    },
  },
  watch: {
    isUserAuthenticated(newIsUserAuthenticated) {
      if (newIsUserAuthenticated) {
        this.updateAddress();
      }
    },
    zipCodeSession() {
      this.zipCode = this.zipCodeSession;
    },
  },
  created() {
    this.updateAddress();

    eventBus.on("toggle-zip-code-pop-over", (data: any) => {
      eventBus.emit("close-pop-over", {
        id: this.popOverId,
        status: data.status,
      });
    });
  },
  beforeUnmount() {
    eventBus.off("toggle-zip-code-pop-over");
  },
  methods: {
    getTitle(section: string, name: string) {
      return getTitleTranslate(section, name);
    },
    getEshopLink(section: string, name: string) {
      return getEshopLinkTranslate(section, name);
    },
    closePopover() {
      eventBus.emit("close-pop-over", { id: this.popOverId, status: false });
    },
    sendZipCode() {
      const zipService = new ZipCodeService();
      zipService.setZipCode(this.zipCode);
      this.closePopover();
      store.commit("session/setSelectedAddress", null);
    },
    selectAddress(address: StateAddress) {
      store.commit("session/setSelectedAddress", address);
      store.commit("session/setZipCode", null);
      this.closePopover();
    },
    resetZipCode() {
      store.commit("session/setZipCode", null);
      this.zipCode = "";
      this.closePopover();
    },
    updateAddress() {
      if (this.isUserAuthenticated) {
        const zipCodeService = new ZipCodeService();
        // authService.getCsrfToken();
        zipCodeService.getAddress().then((response) => {
          if (response && response.errors == undefined) {
            this.addresses = response;
          }
        });
      }
    },
  },
};
</script>
<style lang="sass">
.selected-address
    box-shadow: -5px 0px 0 0px var(--bs-red)
</style>
