<template>
  <div class="min-h-screen" :data-bs-theme="theme">
    <main>
      <slot />
    </main>
  </div>
</template>
<script lang="ts">
import { store } from "@/store";
export default {
  computed: {
    theme() {
      return store.getters.getTheme;
    },
  },
};
</script>
<style lang=""></style>
