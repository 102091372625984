// define your typings for the store state
export interface StateTranslation {
  sections: any;
  loading: Array<string>;
}

const state = () => ({
  sections: [],
  loading: [],
});

// mutations
const mutations = {
  setSection(state: StateTranslation, newSectionTrans: any) {
    state.sections = { ...state.sections, ...newSectionTrans };
  },
  addLoading(state: StateTranslation, sectionName: string) {
    state.loading.push(sectionName);
  },
  removeLoading(state: StateTranslation, sectionName: string) {
    state.loading = state.loading.filter((item) => item !== sectionName);
  },
};

const getters = {
  getSections(state: StateTranslation) {
    return state.sections;
  },
  getSection: (state: StateTranslation) => (sectionName: string) => {
    return state.sections[sectionName];
  },
  hasLoading: (state: StateTranslation) => (sectionName: string) => {
    return state.loading.indexOf(sectionName) > -1;
  },
};

export const translations = {
  namespaced: true,
  state,
  mutations,
  getters,
};
