import { notify } from "@kyvg/vue3-notification";
import { NotificationOptions } from "@/interfaces/NotificationOptions";

export class CustomNotifyService {
  public getNotification({
    type = "success",
    title,
    text,
    time = -1, //el negativo es para que se quite sólo cuando se de en cerrar la notificación, de lo contario, toma el argumento que recibe
  }: NotificationOptions) {
    notify({
      type: type,
      title: title,
      text: text,
      duration: time,
      ignoreDuplicates: true,
    });
  }
}
