<template lang="">
  <component
    :is="tag"
    :[gotoAttibute]="goto"
    :target="target"
    class="link-underline link-underline-opacity-0"
  >
    <slot />
  </component>
</template>
<script lang="ts">
export default {
  props: {
    to: {
      type: String,
      default: "/",
    },
    toObject: {
      type: Object,
      default: null,
    },
    target: {
      type: String,
      default: "_self",
    },
  },
  computed: {
    external() {
      if (this.toObject != null) {
        return false;
      } else if (
        this.to &&
        (this.to.startsWith("http:") ||
          this.to.startsWith("https:") ||
          this.to.startsWith("mailto:") ||
          this.to.startsWith("tel:") ||
          this.to.startsWith("ftp:"))
      ) {
        return true;
      }
      return false;
    },
    tag() {
      return this.external ? "a" : "router-link";
    },
    gotoAttibute() {
      return this.external ? "href" : "to";
    },
    goto() {
      if (this.toObject) {
        return this.toObject;
      }
      return this.to;
    },
  },
};
</script>
<style scoped lang="sass"></style>
