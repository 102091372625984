<template lang="">
  <div
    v-if="loading"
    class="d-flex justify-content-center align-content-center p-4"
  >
    <div class="spinner-border" :class="color" role="status">
      <span class="visually-hidden mx-auto">Loading...</span>
    </div>
  </div>
</template>
<script lang="ts">
export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: "text-secondary",
    },
  },
};
</script>
<style lang=""></style>
