<template>
  <div class="container">
    <div>
      <!-- <description-shopping-cart
        :description="getDescription()"
        :with-html="true"
        class="d-none d-lg-block"
      /> -->
      <div
        class="shipping-options px-3 my-3"
        :class="{ 'accordion-active': isShipping }"
        @click="setIsShipping()"
      >
        <div class="row">
          <address-accordion />
        </div>
      </div>
      <div
        class="shipping-options px-3 my-3"
        :class="{ 'accordion-active': isPickup }"
        @click="setIsPickup()"
      >
        <div class="row">
          <branches-accordion :is-show="isPickup" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { getTitleTranslate } from "../../utils/general";
import { CartService } from "@/services/CartService";
// import DescriptionShoppingCart from "./DescriptionShoppingCart.vue";
import AddressAccordion from "./AddressAccordion.vue";
import BranchesAccordion from "./BranchesAccordionSection.vue";
import { store } from "@/store";

export default {
  components: {
    // DescriptionShoppingCart,
    AddressAccordion,
    BranchesAccordion,
  },

  data: function () {
    return {
      cartService: null as CartService | null,
      description: "",
    };
  },

  computed: {
    isShipping() {
      return store.getters["checkout/getIsShipping"];
    },
    isPickup() {
      return store.getters["checkout/getIsPickup"];
    },
  },

  mounted() {
    this.cartService = new CartService();
    this.cartService.getCartInitData();
  },

  methods: {
    getTitle(section: string, name: string) {
      return getTitleTranslate(section, name);
    },
    getDescription() {
      return (this.description = `${this.getTitle("cart", "learn_about_prime_membership_for")} <span class="fw-semibold mx-1">${this.getTitle("cart", "free_shipping")}</span> ${this.getTitle("cart", "for_only_99_a_month")}`);
    },
    setIsShipping() {
      store.commit("checkout/setIsShipping");
    },
    setIsPickup() {
      store.commit("checkout/setIsPickup");
    },
  },
};
</script>
