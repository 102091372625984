<script setup lang="ts">
import TabTitle from "@/interfaces/TabTitle";
import EditableField from "./EditableField.vue";
import { computed, onMounted, reactive, ref } from "vue";
import { userProfileService } from "@/services/UserProfileService";
import auth from "@/services/AuthService";
import { Customer } from "@/interfaces/PersonalInfo";
import Jsona from "jsona";
const dataFormatter = new Jsona();

const showData = ref(true);

const personalInfo = reactive<Customer>({
  type: "",
  id: "",
  name: "",
  last_name: "",
  mother_last_name: "",
  email: "",
  phone: "",
  phone_verified_at: false,
  rfc: "",
  trade_name: "",
  business_name: "",
  has_credit: 0,
  credit_amount: "",
  links: { self: "" },
  creditMovements: [],
  wallet: {
    type: "",
    id: "",
    amount: "",
    links: { self: "" },
  },
  relationshipNames: [],
  relationships: [],
});

const isUserAuthenticated = computed(() => auth.isUserLogged());

const hideData = () => {
  return (showData.value = !showData.value);
};

async function fetchPersonalInfo() {
  if (isUserAuthenticated.value) {
    try {
      const personalInformation =
        await userProfileService.getPersonalInfoWithCredit();

      if (personalInformation) {
        const data = dataFormatter.deserialize(personalInformation);
        //TODO:
        //funciona pero se debe modificar o adaptar con el modelo,
        //También en las secciones del perfil llamar sólo la información que sea necesaria (cómo está aquí) y no cargar todo, cuando no sea necesario
        personalInfo.creditMovements =
          personalInformation.data.relationships.creditMovements.meta;

        Object.assign(personalInfo, data);
      } else {
        console.warn("No se encontró información personal.");
      }
    } catch (error) {
      console.error("Error fetching personal information:", error);
    }
  }
}

onMounted(async () => {
  await fetchPersonalInfo();
});

TabTitle("Tus datos");
</script>

<template>
  <div class="container-account-information">
    <div class="row">
      <div class="col-12 d-flex justify-content-center">
        <button
          class="btn-show-data"
          :class="{ 'text-blue': showData, 'text-danger': !showData }"
          @click="hideData()"
        >
          <i v-if="!showData" class="bi bi-eye-slash" />
          <i v-else class="bi bi-eye-fill" />
          <span class="ms-1">
            {{ showData ? "Mostrar mis datos" : "Ocultar mis datos" }}
          </span>
        </button>
      </div>
    </div>
    <div class="row mt-3 g-0 information">
      <ul class="list-unstyled">
        <li class="bg-light">Acceso</li>
        <editable-field
          :show-data="showData"
          :title="'Correo electrónico'"
          :value="personalInfo.email"
          :field-name="'email'"
          :disabled="true"
          @info-saved="fetchPersonalInfo"
        />
        <editable-field
          :show-data="showData"
          :title="'Nueva contraseña'"
          :value="'************'"
          :field-name="'password'"
          :input-type="'password'"
          @info-saved="fetchPersonalInfo"
        />
      </ul>
    </div>
    <div class="row mt-lg-3 g-0 information">
      <ul class="list-unstyled pointer">
        <li class="bg-light">Información personal</li>
        <editable-field
          :show-data="showData"
          :title="'Nombre'"
          :value="personalInfo.name"
          :field-name="'name'"
          :rules="'required|min:2'"
          @info-saved="fetchPersonalInfo"
        />
        <editable-field
          :show-data="showData"
          :title="'Apellido paterno'"
          :value="personalInfo.last_name"
          :field-name="'last_name'"
          @info-saved="fetchPersonalInfo"
        />
        <editable-field
          :show-data="showData"
          :title="'Apellido materno'"
          :value="personalInfo.mother_last_name"
          :field-name="'mother_last_name'"
          @info-saved="fetchPersonalInfo"
        />
        <editable-field
          :show-data="showData"
          :title="'Razón social'"
          :value="personalInfo.business_name"
          :field-name="'business_name'"
          @info-saved="fetchPersonalInfo"
        />
        <editable-field
          :show-data="showData"
          :title="'RFC'"
          :value="personalInfo.rfc"
          :field-name="'rfc'"
          @info-saved="fetchPersonalInfo"
        />
        <editable-field
          :show-data="showData"
          :title="'Teléfono'"
          :value="personalInfo.phone"
          :field-name="'phone'"
          :only-value="
            personalInfo.phone_verified_at ? 'Verificado' : 'No verificado'
          "
          @info-saved="fetchPersonalInfo"
        />
      </ul>
    </div>
    <div v-if="personalInfo.has_credit" class="row mt-lg-3 g-0 information">
      <ul class="m-0">
        <li class="bg-light">Información de crédito</li>
        <li class="d-flex justify-content-start">
          <p class="py-2 pe-5 m-0">Saldo</p>
          <p class="py-2 px-5 m-0">$0.00</p>
        </li>
        <li class="d-flex justify-content-center">
          <p class="text-center text-condensed m-0">Últimos movimientos</p>
        </li>
      </ul>
      <div class="table-responsive">
        <table class="table table-striped table-hover border border-light">
          <thead>
            <tr class="text-center">
              <th>Fecha</th>
              <th>Monto</th>
              <th>Tipo</th>
              <th>Estátus</th>
              <th>Id de pago</th>
            </tr>
          </thead>
          <tbody class="fs-8">
            <tr
              v-for="move in personalInfo.creditMovements"
              :key="move.id"
              class="text-center"
            >
              <td>{{ move.created_at }}</td>
              <td v-html="move.amount"></td>
              <td>{{ move.type_text }}</td>
              <td>{{ move.canceled_at }}</td>
              <td>{{ move.transaction_payment_id }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
