<template lang="">
  <div class="col px-1">
    <div
      class="p-0 h-100"
      :class="{ 'border border-light-subtle shadow': !mobile }"
    >
      <div v-if="!mobile" class="border-bottom border-light-subtle p-2 mx-2">
        <h5 class="m-0 fs-5 text-secondary text-start">
          <span class="text-success fw-bold">{{
            getTitle("suggestions", "brands")
          }}</span>
          {{ getTitle("suggestions", "recommended") }}
        </h5>
      </div>
      <ul
        ref="scrollBrands"
        class="list-unstyled m-0 overflow-y-hidden overflow-x-hidden"
        :style="{ height: '40vh !important' }"
      >
        <li
          v-for="(itemsInside, index) in items"
          :key="index"
          class="px-1 py-1"
        >
          <span class="fs-7 fw-semibold px-1">{{ index }}</span>
          <ul
            class="list-unstyled row row-cols-3 row-gap-1 mx-0 mt-1 py-1 border border-light-subtle border-bottom-0 border-start-0 border-end-0"
          >
            <li
              v-for="item in itemsInside"
              :key="item.id"
              :ref="
                (el) => {
                  itemsRef[item.newIndex] = el;
                }
              "
              class="col p-1"
            >
              <base-link
                class="d-flex justify-content-center align-items-center border w-100 p-1 text-secondary fs-7 link-underline link-underline-opacity-0 link-brand"
                :class="{ shadow: isHovered[item.id] }"
                @click="getResults(item.links.self)"
                @mouseenter="isHovered[item.id] = true"
                @mouseleave="isHovered[item.id] = false"
              >
                <image-brand
                  :image="item.image"
                  :name="item.name"
                  :max-height="16"
                  font-size="fs-9"
                />
              </base-link>
            </li>
          </ul>
        </li>
      </ul>
      <div
        class="flex-grow-1 d-flex align-items-end p-2 w-100 mt-2"
        :class="[
          !isScrollTop ? 'justify-content-between' : 'justify-content-end',
        ]"
      >
        <button
          v-if="!isScrollTop"
          type="button"
          class="text-end text-danger fs-8 p-0 btn btn-link link-underline link-underline-opacity-0"
          @click="scrollUp"
        >
          {{ getTitle("suggestions", "back") }}
        </button>
        <button
          v-if="!isScrollBottom && hasScroll"
          type="button"
          class="text-end text-danger fs-8 p-0 btn btn-link link-underline link-underline-opacity-0"
          @click="scrollDown"
        >
          {{ getTitle("suggestions", "show_more") }}
        </button>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import BaseLink from "../buttons/BaseLink.vue";
import ImageBrand from "../ui/ImageBrand.vue";
import { getFinalEshopUrl, getTitleTranslate } from "../../utils/general";
import { ref } from "vue";
import eventBus from "@/event-bus";

export default {
  components: {
    BaseLink,
    ImageBrand,
  },
  props: {
    brands: {
      type: Array,
      default() {
        return [];
      },
    },
    mobile: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["close-suggestions", "get-results"],
  setup() {
    const scrollBrands = ref<HTMLElement | null>(null);
    return {
      scrollBrands,
    };
  },
  data: function () {
    return {
      imageErrors: [],
      isHovered: [],
      itemsRef: ref([]),
      scrollTop: 0,
      isScrollBottom: false,
      isScrollTop: true,
      hasScroll: false,
    };
  },
  computed: {
    items() {
      let newIndex = 0;
      let items = [...this.brands];
      items = items.sort((a: any, b: any) => {
        const name = a.name ? a.name : "";
        return name
          .toUpperCase()
          .charAt(0)
          .localeCompare(b.name.toUpperCase().charAt(0));
      });

      return items.reduce((groups: any, item: any) => {
        const name = item.name ? item.name : "";
        const orderBy: string = name.toUpperCase().charAt(0);
        if (!groups[orderBy]) {
          groups[orderBy] = [];
        }
        const cloned = JSON.parse(JSON.stringify(item));
        cloned.newIndex = newIndex++;
        groups[orderBy].push(cloned);
        return groups;
      }, {});
    },
  },
  mounted() {
    if (this.scrollBrands) {
      if (this.scrollBrands.clientHeight < this.scrollBrands.scrollHeight) {
        this.hasScroll = true;
      }
      this.scrollBrands.addEventListener("scroll", this.handleScroll);
    }
  },
  methods: {
    getFinalUrl(path: string) {
      return getFinalEshopUrl(path);
    },
    getTitle(section: string, name: string) {
      return getTitleTranslate(section, name);
    },
    scrollDown() {
      const container = this.scrollBrands;
      if (container) {
        this.scrollTop += container.clientHeight - 50;

        container.scrollTo({
          top: this.scrollTop,
          behavior: "smooth",
        });
      }
    },
    scrollUp() {
      const container = this.scrollBrands;
      if (container) {
        this.scrollTop -= container.clientHeight - 50;
        if (this.scrollTop < 0) {
          this.scrollTop = 0;
        }

        container.scrollTo({
          top: this.scrollTop,
          behavior: "smooth",
        });
      }
    },
    handleScroll() {
      if (this.scrollBrands) {
        const isAtEnd =
          this.scrollBrands.scrollTop + this.scrollBrands.clientHeight ===
          this.scrollBrands.scrollHeight;

        if (isAtEnd) {
          this.isScrollBottom = true;
        } else {
          this.isScrollBottom = false;
        }

        if (this.scrollBrands.scrollTop == 0) {
          this.isScrollTop = true;
        } else {
          this.isScrollTop = false;
        }
      }
    },

    getResults(path: string) {
      eventBus.emit("get-results", path);
      eventBus.emit("close-suggestions");

      this.$router.push(path).catch((err) => {
        if (err.name !== "NavigationDuplicated") {
          console.error(err);
        }
      });
    },
  },
};
</script>
<style lang="sass" scoped>
.link-brand
    height: 24px
</style>
