<script lang="ts">
import loggedInUserImage from "@/assets/images/auth/customer-group-1.png";

export default {
  props: {
    senderType: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "Customer",
    },
    message: {
      type: String,
      required: true,
    },
    createdAt: {
      type: String,
      required: true,
    },
    seen: {
      type: Boolean,
      required: true,
    },
    selectedOrderId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      loggedInUserImage: loggedInUserImage,
    };
  },
  computed: {
    formattedMessage(): string {
      return this.message;
    },
  },
};
</script>

<template>
  <div :class="['message-wrapper', senderType]">
    <img
      v-if="senderType === 'user'"
      :src="loggedInUserImage"
      class="img-user-chat rounded-circle"
      width="24"
      height="24"
    />

    <div
      :class="[
        'message',
        senderType,
        senderType === 'customer' ? 'arrow-right' : 'arrow-left',
      ]"
    >
      <div class="message-content">
        <p :style="{ whiteSpace: 'pre-wrap' }">{{ formattedMessage }}</p>
        <div
          class="d-flex fs-8 mt-2 align-items-center"
          :class="
            senderType === 'user'
              ? 'justify-content-between'
              : 'justify-content-end'
          "
        >
          <span class="text-secondary me-2">
            {{ name }} <small class="fst-italic"></small>
          </span>

          <span class="d-flex align-items-center">
            <small>{{ createdAt }}</small>
            <i
              v-if="senderType === 'customer'"
              :class="[
                'bi',
                'bi-check2-all',
                'ms-2',
                'fs-6',
                seen ? 'text-blue' : 'text-secondary',
              ]"
            />
          </span>
        </div>
      </div>
    </div>

    <img
      v-if="senderType === 'customer'"
      :src="loggedInUserImage"
      class="img-customer-chat rounded-circle mb-1"
      width="24"
      height="24"
    />
  </div>
</template>
