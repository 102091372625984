const router = [
  {
    path: ":transactionId/pago-exitoso",
    name: "success-payment",
    component: () => import("@/components/cart/payment/SuccessPayment.vue"),
    props: true,
    beforeEnter: () => {
      window.scrollTo(0, 0);
    },
  },
  {
    path: ":transactionId/pago-pendiente",
    name: "pending-payment",
    component: () => import("@/components/cart/payment/PendingPayment.vue"),
    props: true,
    beforeEnter: () => {
      window.scrollTo(0, 0);
    },
  },
  {
    path: ":transactionId/procesar-pago",
    name: "process-payment",
    component: () => import("@/components/cart/payment/ProcessPayment.vue"),
    props: true,
    beforeEnter: () => {
      window.scrollTo(0, 0);
    },
  },
  {
    path: ":transactionPaymentId/pago-paypal",
    name: "paypal-payment",
    component: () => import("@/components/cart/payment/PaypalPayment.vue"),
    props: true,
    beforeEnter: () => {
      window.scrollTo(0, 0);
    },
  },
  {
    path: ":transactionPaymentId/pago-apple-pay",
    name: "apple-pay-payment",
    component: () => import("@/components/cart/payment/ApplePayment.vue"),
    props: true,
  },
  {
    path: ":transactionPaymentId/pago-google-pay",
    name: "google-pay-payment",
    component: () => import("@/components/cart/payment/GooglePayment.vue"),
    props: true,
    beforeEnter: () => {
      window.scrollTo(0, 0);
    },
  },
  {
    path: ":transactionPaymentId/pago-oxxo-pay",
    name: "oxxo-pay-payment",
    component: () => import("@/components/cart/payment/OxxoPayment.vue"),
    props: true,
    beforeEnter: () => {
      window.scrollTo(0, 0);
    },
  },
];

export default router;
