<template lang="">
  <div class="col px-1">
    <div
      class="d-flex flex-column justify-content-between align-items-start px-2 p-0 h-100"
      :class="{ 'border border-light-subtle shadow': !mobile }"
    >
      <div v-if="!mobile" class="border-bottom border-light-subtle p-2 w-100">
        <h5 class="m-0 fs-5 text-secondary text-start">
          <span class="text-blue fw-bold">{{
            getTitle("suggestions", "products")
          }}</span>
          {{ getTitle("suggestions", "recommended") }}
        </h5>
      </div>
      <div
        class="d-flex flex-column py-2 overflow-y-hidden overflow-x-hidden"
        :style="{ height: '40vh !important' }"
      >
        <product-suggestion-search
          v-for="product in productsFinal"
          :key="product.id"
          :product="product"
        />
      </div>
      <div
        class="flex-grow-1 d-flex justify-content-end align-items-end py-2 w-100"
        @click="closeSuggestion()"
      >
        <base-link
          class="text-end text-danger fs-8 p-0"
          @click="getResults(getSeeMore())"
        >
          {{ $t("See all results") }}
        </base-link>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import ProductSuggestionSearch from "@/components/navigation/ProductSuggestionSearch.vue";
import BaseLink from "./../buttons/BaseLink.vue";
import { getTitleTranslate } from "../../utils/general";
import eventBus from "@/event-bus";
export default {
  components: {
    ProductSuggestionSearch,
    BaseLink,
  },
  props: {
    products: {
      type: Array,
      default() {
        return [];
      },
    },
    seeMore: {
      type: String,
      default: "/",
    },
    mobile: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["close-suggestions"],
  computed: {
    productsFinal() {
      return this.products.slice(0, 3);
    },
  },
  methods: {
    getTitle(section: string, name: string) {
      return getTitleTranslate(section, name);
    },

    getSeeMore() {
      return "/" + this.seeMore;
    },

    closeSuggestion() {
      eventBus.emit("close-suggestions");
    },

    getResults(path: string) {
      eventBus.emit("get-results", path);
      eventBus.emit("close-suggestions");

      this.$router.push(path).catch((err) => {
        if (err.name !== "NavigationDuplicated") {
          console.error(err);
        }
      });
    },
  },
};
</script>
<style lang="sass" scoped></style>
