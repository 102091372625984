<template lang="">
  <nav class="row navbar navbar-expand-lg bg-body-tertiary py-0 px-5">
    <ul class="col-6 navbar-nav row-cols-6 h-100 px-0 gap-3">
      {{
        getActiveLink()
      }}
      <li
        class="col position-relative"
        :class="{ 'bg-danger': getActiveLink('categories') }"
      >
        <drop-down-button
          unique-id="categories"
          :text="'Categorías'"
          :active="active"
          :is-open="isOpen"
          @active-menu="activeMenu"
        />
      </li>
      <!-- menú desplegable de marcas <li
        class="col position-relative"
        :class="{ 'bg-danger': getActiveLink('brands') }"
      >
        <drop-down-button
          unique-id="brands"
          :text="getTitle('header', 'brands')"
          :active="active"
          :is-open="isOpen"
          @active-menu="activeMenu"
        />
      </li> -->
      <li class="col">
        <link-nav
          :to="'/marcas'"
          :text="'Marcas'"
          color-bg-hover="bg-body-secondary"
          class="border-0 text-uppercase"
        />
      </li>
      <li class="col">
        <link-nav
          :to="getLink('header', 'offers') + `?random_seed=${randomSeed}`"
          :text="'Ofertas'"
          color-bg-hover="bg-body-secondary"
          class="border-0 text-uppercase"
        />
      </li>
      <li class="col">
        <link-nav
          :to="getLink('header', 'new') + `?random_seed=${randomSeed}`"
          :text="'Nuevos'"
          color-bg-hover="bg-body-secondary"
          class="border-0 text-uppercase"
        />
      </li>
    </ul>
    <ul
      class="col-6 navbar-nav row-cols-3 row-cols-xl-6 justify-content-end h-100 px-0 gap-3"
    >
      <li class="col py-0">
        <button-zip-code />
      </li>
      <!-- OCULTO HASTA QUE FUNCIONE -->
      <li v-if="false" class="col py-0">
        <button-notifications />
      </li>
      <li v-if="false" class="col py-0">
        <link-nav-transaction
          to="/about"
          tag="router-link"
          icon="bi-heart"
          :text="getTitle('header', 'wish_list')"
          color-hover="#018786"
        />
      </li>
    </ul>
  </nav>
  <div class="row p-0">
    <drop-down-menu
      v-if="active == 'categories'"
      tab="categories"
      layout="list"
      :is-open="isOpen"
      :can-close="canClose"
      @change-open="changeOpen"
    />
    <drop-down-menu
      v-if="active == 'brands'"
      tab="brands"
      layout="grid"
      :is-open="isOpen"
      :can-close="canClose"
      @change-open="changeOpen"
    />
  </div>
</template>
<script lang="ts">
import LinkNav from "@/components/buttons/LinkNav.vue";
import LinkNavTransaction from "@/components/buttons/LinkNavTransaction.vue";
import DropDownButton from "./Menu/DropDownButton.vue";
import DropDownMenu from "./Menu/DropDownMenu.vue";
import ButtonZipCode from "../buttons/ButtonZipCode.vue";
import ButtonNotifications from "../buttons/ButtonNotifications.vue";
import {
  getTitleTranslate,
  getEshopLinkTranslate,
  getLinkSelfTranslate,
} from "../../utils/general";
import { mapState } from "vuex";
import { store } from "@/store";
import eventBus from "@/event-bus";
import { useRoute } from "vue-router";

enum catalogsEnum {
  Categories = "categories",
  Brands = "brands",
}

export default {
  components: {
    LinkNav,
    LinkNavTransaction,
    DropDownButton,
    DropDownMenu,
    ButtonZipCode,
    ButtonNotifications,
  },
  emits: ["get-results"],
  data: function () {
    return {
      isOpen: false,
      canClose: false,
      eshopLinks: [],
      active: catalogsEnum.Categories,
      route: useRoute(),
      randomSeed: parseInt((Math.random() * 10000).toFixed(0)),
    };
  },
  computed: {
    ...mapState(["canCloseMenu"]),
    translations() {
      return store.getters["translations/getSections"];
    },
    titleNotification() {
      return this.getTitle("header", "notification");
    },
  },
  watch: {
    canCloseMenu(newValue) {
      this.isOpen = !newValue;
      this.canClose = newValue;
    },
    // Deja abierto el menú de manera fija estando en Home, lo cierra al ir a otro lado.
    "$route.params.path": {
      deep: true,
      handler() {
        this.restartValues();
      },
    },
  },
  created() {
    this.restartValues();
    eventBus.on("close-menu", () => {
      this.isOpen = false;
    });
  },
  mounted() {
    //
  },
  beforeUnmount() {
    eventBus.off("close-menu");
  },
  methods: {
    restartValues() {
      this.canClose = this.$route.path !== "/";
      this.isOpen = this.$route.path === "/";
      this.eshopLinks = [];
      this.active = catalogsEnum.Categories;
    },
    activeMenu(data: catalogsEnum) {
      console.log("activeMenu", data);
      this.active = data;
      this.isOpen = true;
    },
    changeOpen(open: boolean) {
      this.isOpen = open;
    },
    getTitle(section: string, name: string) {
      return getTitleTranslate(section, name);
    },
    getEshopLink(section: string, name: string) {
      return getEshopLinkTranslate(section, name);
    },
    getLink(section: string, name: string) {
      return getLinkSelfTranslate(section, name);
    },
    getResults(path: string) {
      eventBus.emit("get-results", path);
      this.$router.push(path).catch((err) => {
        if (err.name !== "NavigationDuplicated") {
          console.error(err);
        }
      });
    },
    getActiveLink(option: string) {
      if (this.active === option && this.isOpen) {
        return true;
      }
    },
  },
};
</script>
<style lang="sass" scoped>
.navbar
    height: 64px

.navbar-nav
    flex-direction: row !important
</style>
