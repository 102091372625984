import axios, { AxiosError, AxiosResponse, AxiosRequestConfig } from "axios";
import { VITE_API_ENDPOINT } from "@/config/constants.js";
import Jsona from "jsona";
import { Address } from "@/interfaces/Address.js";

const dataFormatter = new Jsona();

export class CustomerAddressService {
  protected type = "customer-addresses";

  public async storeCustomerAddress(attributes: Address) {
    const body = {
      type: this.type,
      ...attributes,
    };
    const newJsonBody = dataFormatter.serialize({
      stuff: body,
    });
    const options: AxiosRequestConfig = {
      baseURL: VITE_API_ENDPOINT,
      headers: {
        Accept: "application/vnd.api+json",
        "Content-Type": "application/vnd.api+json",
      },
    };
    const response = await axios.post(
      `/shop-v1/${this.type}`,
      newJsonBody,
      options,
    );

    if (!(response instanceof AxiosError)) {
      return response;
    } else if (response.response) {
      const resp: AxiosResponse = response.response;
      return resp;
    } else {
      return null;
    }
  }

  public async updateCustomerAddress(id: number, attributes: object) {
    const body = {
      type: this.type,
      ...attributes,
    };
    const newJsonBody = dataFormatter.serialize({
      stuff: body,
    });
    const options: AxiosRequestConfig = {
      baseURL: VITE_API_ENDPOINT,
      headers: {
        Accept: "application/vnd.api+json",
        "Content-Type": "application/vnd.api+json",
      },
    };

    const response = await axios.patch(
      `/shop-v1/${this.type}/${id}`,
      newJsonBody,
      options,
    );
    if (!(response instanceof AxiosError)) {
      return response;
    } else if (response.response) {
      const resp: AxiosResponse = response.response;
      return resp;
    } else {
      return null;
    }
  }

  public async deleteCustomerAddress(idAssembly: string) {
    const options: AxiosRequestConfig = {
      baseURL: VITE_API_ENDPOINT,
      headers: {
        Accept: "application/vnd.api+json",
      },
    };
    const url = `/shop-v1/${this.type}/${encodeURIComponent(idAssembly)}`;
    const response = await axios.delete(url, options);
    if (!(response instanceof AxiosError)) {
      return response;
    } else if (response.response) {
      const resp: AxiosResponse = response.response;
      return resp;
    } else {
      return null;
    }
  }
}
