<template>
  <notifications position="top right">
    <template #body="props">
      <div :class="['py-3 px-1', 'custom-notifications', props.item.type]">
        <div class="row gx-2">
          <div class="col-2 d-flex align-items-center justify-content-center">
            <button class="bg-transparent border-0">
              <i
                v-if="props.item.type === 'success'"
                class="bi bi-check-circle-fill fs-3 success"
              />
              <i
                v-if="props.item.type === 'error'"
                class="bi bi-exclamation-circle-fill fs-3 error"
              />
              <i
                v-if="props.item.type === 'warning'"
                class="bi bi-exclamation-circle-fill fs-3 warning"
              />
            </button>
          </div>
          <div class="col-8 d-flex flex-column text-white align-items-start">
            <p class="title text-start fw-semibold m-0">
              {{ props.item.title }}
            </p>
            <div class="description mt-2 text-start" v-html="props.item.text" />
          </div>
          <div class="col-2 d-flex align-items-center justify-content-center">
            <button class="close bg-transparent border-0" @click="props.close">
              <i class="bi bi-x-lg fs-5"></i>
            </button>
          </div>
        </div>
      </div>
    </template>
  </notifications>
</template>
