import { store } from "@/store";
import { VITE_OLD_ESHOP } from "@/config/constants.js";
import { AxiosError } from "axios";
import { ErrorItem } from "@/interfaces/ErrorItem";

interface Translate {
  name: string;
}

export function formatPrice(price: number) {
  price = price * 1;
  const formatPrice = price.toLocaleString("es-MX", {
    style: "currency",
    currency: "MXN", // Código de moneda para pesos mexicanos
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  return formatPrice;
}

export function getFinalEshopUrl(path: string) {
  return VITE_OLD_ESHOP + path;
}

export function getTranslate(section: string, componentName: string) {
  const translations = store.getters["translations/getSections"];

  if (translations[section]) {
    const translation = translations[section].find(
      (component: Translate) => component.name == componentName,
    );

    if (translation) {
      return translation;
    }
  }
  return null;
}

export function getTitleTranslate(section: string, componentName: string) {
  const translate = getTranslate(section, componentName);
  if (translate) {
    return translate.title;
  }
  return "";
}

export function getTitleTranslateWithOptions(
  section: string,
  componentName: string,
  options: any,
) {
  const translate = getTranslate(section, componentName);
  if (translate) {
    let finalTranslate = translate.title;
    Object.keys(options).forEach((key) => {
      finalTranslate = finalTranslate.replace(`${key}`, `${options[key]}`);
    });
    return finalTranslate;
  }
  return "";
}

export function getLinkSelfTranslate(section: string, componentName: string) {
  const translate = getTranslate(section, componentName);
  let linkSelf = "/";
  if (translate && translate.links && translate.links.self) {
    linkSelf = translate.links.self;
  }
  return linkSelf;
}

export function getEshopLinkTranslate(section: string, componentName: string) {
  const linkSelf = getLinkSelfTranslate(section, componentName);
  return getFinalEshopUrl(linkSelf);
}

export function generateUUID(): string {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0;
    const v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export function errorsToString(reason: AxiosError): string {
  let text = "";
  if (reason.response && reason.response.data) {
    const data: { errors?: ErrorItem[]; message?: unknown } =
      reason.response.data;
    if (data.errors) {
      const list = data.errors
        .map((error: ErrorItem) => error.detail)
        .join("</li><li>");
      text = `<ul><li>${list}</li></ul>`;
    } else if (data.message && typeof data?.message == "string") {
      text = data.message;
    }
  }
  return text != "" ? text : "Ocurrio un error inesperado";
}

export function loadScript(src: string) {
  return new Promise((resolve, reject) => {
    const exists = !!document.querySelector(`script[src="${src}"]`);
    if (!exists) {
      // insert only one time
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => resolve(src);
      script.onerror = () =>
        reject(new Error(`Error al cargar el script: ${src}`));
      document.head.appendChild(script);
    } else {
      resolve(src);
    }
  });
}
