<template>
  <section class="search-by-category px-2 px-sm-5 px-md-4 px-lg-5">
    <nav class="breadcrumb-by-category ps-3 ps-sm-0">
      <a href="/" class="text-decoration-none text-secondary"> Inicio </a>
      >
      <router-link
        :to="'/busqueda-por-categoria'"
        class="text-danger fw-semibold text-decoration-none"
        >Categorías</router-link
      >
    </nav>
    <div class="row mt-4">
      <div
        v-for="(category, index) in categories"
        :key="index"
        class="col-4 col-sm-3 col-lg-12"
      >
        <div
          class="box-title"
          @click="isLargeScreen ? toggle(index) : showLevelTwho(category)"
        >
          <div class="d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center flex-column flex-lg-row">
              <div class="div-all-categories border border-light pointer">
                <img
                  v-if="!imageErrors[category.id] && category.image"
                  :src="category.image"
                  :alt="category.name"
                  class="icon p-1 pointer"
                  @error="imageErrors[category.id] = true"
                />
                <div
                  v-if="imageErrors[category.id] || !category.image"
                  class="box text"
                ></div>
              </div>

              <button
                class="title-category text-lg-start py-3 rounded-2 ms-lg-2"
              >
                {{ category.name }}
              </button>
            </div>
            <i
              v-if="category.isOpen"
              class="bi bi-chevron-up text-dark d-none d-lg-block"
            ></i>
            <i
              v-if="!category.isOpen"
              class="bi bi-chevron-down text-dark d-none d-lg-block"
            ></i>
          </div>
        </div>

        <div id="content-to-expand" :class="{ expanded: category.isOpen }">
          <div class="inside">
            <div class="px-2 px-lg-0 mb-lg-3 mt-3">
              <div class="row gx-2">
                <div
                  v-for="(subcategory, index2) in category.categories"
                  :key="index2"
                  class="col-12 col-sm-6 col-md-4 col-xl-3"
                >
                  <a
                    class="subcategory d-flex align-items-center bg-light mb-3 pointer text-decoration-none"
                    @click="getResults(subcategory.links.self)"
                  >
                    <div
                      class="border-light border-top border-bottom border-start"
                    >
                      <img
                        v-if="!imageErrors[subcategory.id] && subcategory.image"
                        :src="subcategory.image"
                        :alt="subcategory.name"
                        class="image"
                        @error="imageErrors[subcategory.id] = true"
                      />
                      <div
                        v-if="imageErrors[subcategory.id] || !subcategory.image"
                      >
                        <div class="no-image ps-3"></div>
                      </div>
                    </div>
                    <p class="py-3 ps-2 ps-lg-2 pe-2 m-0 name text-no-wrap">
                      {{ subcategory.name }}
                    </p>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import eventBus from "@/event-bus";
import Category from "@/models/Category";
import { store } from "@/store";

export default {
  emits: ["get-results"],
  data: function () {
    return {
      isOpen: true,
      imageErrors: [] as any,
      categories: [] as any,
      isLargeScreen: false,
    };
  },

  computed: {
    categoriesFromStore() {
      return store.getters["catalogs/getDeserealizeCategories"];
    },
  },

  watch: {
    categoriesFromStore(newVal) {
      if (newVal.length > 0) {
        this.categories = newVal.map((category: any) => ({
          ...category,
          isOpen: true,
        }));
      }
    },
  },

  created() {
    store.commit("setCanCloseMenu", true);
  },

  mounted() {
    window.scrollTo(0, 0);
    this.updateScreenSize();
    window.addEventListener("resize", this.updateScreenSize);
    if (this.categoriesFromStore.length > 0) {
      this.categories = this.categoriesFromStore.map((category: any) => ({
        ...category,
        isOpen: true,
      }));
    }
  },

  beforeMount() {
    window.removeEventListener("resize", this.updateScreenSize);
  },

  methods: {
    toggle(index: number) {
      this.categories[index].isOpen = !this.categories[index].isOpen;
    },

    getResults(path: string) {
      eventBus.emit("get-results", path);
      this.$router.push(path).catch((err) => {
        if (err.name !== "NavigationDuplicated") {
          console.error(err);
        }
      });
    },
    updateScreenSize() {
      this.isLargeScreen = window.innerWidth >= 992;
    },
    showLevelTwho(category: Category) {
      if (category.categories === undefined) {
        return this.getResults(
          category?.links?.self ? category.links.self : "/",
        );
      }
      this.categories = category.categories;
    },
  },
};
</script>

<style lang="scss" scoped>
@use "@/scss/search";
</style>
