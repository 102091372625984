export default [
  {
    id: 1,
    name: "Pedidos",
    description: "Información de tus nuevos o viejos pedidos",
    icon: "bi bi-cart-plus",
    to: "pedidos",
  },
  {
    id: 2,
    name: "Información de cuenta",
    description: "Configura tus datos personales y contacto",
    icon: "bi bi-person",
    to: "informacion",
  },
  // OCULTO HASTA QUE FUNCIONE
  // {
  //   "id": 3,
  //   "name": "Dispositivos autorizados",
  //   "description": "Autoriza tus dispositivos propios",
  //   "icon": "bi bi-display",
  //   "to": "dispositivos"
  // },
  // OCULTO HASTA QUE FUNCIONE
  {
    id: 4,
    name: "Monedero electrónico",
    description: "Conoce tus puntos y úsalos en la tienda",
    icon: "bi bi-currency-dollar",
    to: "wallet",
  },
  {
    id: 5,
    name: "Direcciones",
    description: "Agrega o modifica tu dirección de entrega",
    icon: "bi bi-geo-alt",
    to: "direcciones",
  },
  {
    id: 6,
    name: "Tarjetas de crédito y débito",
    description: "Administra tus métodos de pago",
    icon: "bi bi-credit-card",
    to: "metodos-pago/tarjeta",
  },
  // OCULTO HASTA QUE FUNCIONE
  // {
  //   "id": 7,
  //   "name": "Garantías",
  //   "description": "Administra tus métodos de pago y conoce",
  //   "icon": "bi bi-shield-check",
  //   "to": "garantias"
  // }
];
