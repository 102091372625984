<template>
  <a href="/">
    <div class="digitalife-logo-logina">
      <img
        :src="logoDigitalife"
        alt="Digitalife logo"
        class="digitalife-logo-login"
      />
    </div>
  </a>
</template>

<script lang="ts">
import logoDigitalife from "@/assets/images/logo_digitalife.png";

export default {
  data() {
    return {
      logoDigitalife: logoDigitalife,
    };
  },
};
</script>

<style scoped lang="scss">
.digitalife-logo-login {
  width: 443px;

  @media (max-width: 1700px) {
    width: 280px;
  }

  @media (max-width: 992px) {
    width: 300px;
  }
  @media (max-width: 576px) {
    width: 250px;
  }
}
</style>
