import MainLayoutVue from "@/components/layouts/MainLayout.vue";
import AssemblySection from "@/components/assembly/AssemblySection.vue";
import { AssemblyService } from "@/services/AssemblyService";
import { CartService } from "@/services/CartService";
import { store } from "@/store";
import eventBus from "@/event-bus";
import Jsona from "jsona";
import auth from "@/services/AuthService";

const dataFormatter = new Jsona();

const router = [
  {
    path: "/arma-tu-equipo",
    name: "assembly-new",
    component: AssemblySection,
    meta: { layout: MainLayoutVue },
    beforeEnter: () => {
      store.commit("assembly/resetAssemblyConfig");
      eventBus.emit("change-filter-assembly", null);
      return true;
    },
  },
  {
    path: "/arma-tu-equipo/:id",
    component: AssemblySection,
    name: "assembly-edit",
    props: true,
    meta: { layout: MainLayoutVue },
    beforeEnter: (to: any) => {
      if (to.params.id) {
        const assemblyService = new AssemblyService();
        const split = to.params.id.split("-");
        const assemblyConfigId = split.length > 0 ? split[0] : null;
        store.commit("assembly/setSelectedAssemblyConfig", assemblyConfigId);
        if (assemblyService) {
          assemblyService.showAssembly(assemblyConfigId).then((response) => {
            if (response) {
              const data = dataFormatter.deserialize(response);
              if (data) {
                store.dispatch("assembly/addProducts", data);
              }
              eventBus.emit("change-filter-assembly", null);
            }
          });
        }
      }
      return true;
    },
  },
  {
    path: "/arma-tu-equipo/:assemblyId/:qty/:assemblyConfig?",
    component: AssemblySection,
    name: "assembly-edit-with-cart",
    props: true,
    meta: { layout: MainLayoutVue },
    beforeEnter: (to: any) => {
      const cartService = new CartService();
      if (to.params.assemblyConfig) {
        const split = to.params.assemblyConfig.split("-");
        const assemblyConfigId = split.length > 0 ? split[0] : null;
        store.commit("assembly/setSelectedAssemblyConfig", assemblyConfigId);
      }
      const assemblyId = to.params.assemblyId;
      const isUserAuthenticated = auth.isUserLogged();
      if (cartService && isUserAuthenticated) {
        cartService.showAssembly(assemblyId).then((response) => {
          if (response) {
            const data = dataFormatter.deserialize(response);
            if (data) {
              store.dispatch("assembly/addProductsFromCart", data);
            }
            eventBus.emit("change-filter-assembly", null);
          }
        });
      } else {
        const localStorageProducts =
          store.getters["cart/getLocalStorageProducts"];
        const products = localStorageProducts.filter((product: any) => {
          return product.assembly_id == to.params.assemblyId;
        });
        store.dispatch("assembly/addProductsFromCart", products);
      }
      return true;
    },
  },
];

export default router;
