// define your typings for the store state

import { StateCfdi } from "@/interfaces/StateCfdi";

const state = (): StateCfdi => ({
  uses: [],
  regimes: [],
});

// mutations
const mutations = {
  setUses(state: StateCfdi, newUses: any[]) {
    console.log(state);

    state.uses = newUses;
  },

  setRegimes(state: StateCfdi, newRegimes: any[]) {
    state.regimes = newRegimes;
  },
};

const getters = {
  getUses(state: StateCfdi): any[] {
    return state.uses;
  },
  getRegimes(state: StateCfdi): any[] {
    return state.regimes;
  },
};

export const cfdi = {
  namespaced: true,
  state,
  mutations,
  getters,
};
