<script lang="ts">
import { TransactionService } from "@/services/TransactionService";

export default {
  props: {
    orderId: {
      type: String,
      required: true,
    },
  },
  emits: ["product-selected"],
  data() {
    return {
      selectedProductId: null as string | null,
      products: {} as Record<
        string,
        {
          id: string;
          name: string;
          coverImage: string;
          quantity: number;
          totalPaid: number;
        }
      >,
      loadingProducts: true,
      apiError: null as string | null,
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    this.fetchProductsForOrder();
  },
  methods: {
    async fetchProductsForOrder() {
      try {
        const response = await new TransactionService().showTransaction(
          this.$props.orderId,
        );
        console.log(response);
        this.products = response.data.relationships.transactionDetails.meta;
      } catch (error) {
        this.apiError = error as string;
        console.error("Error fetching products:", error);
      } finally {
        this.loadingProducts = false;
      }
    },
    onContinueClick() {
      if (this.selectedProductId) {
        this.$emit(
          "product-selected",
          this.products[parseInt(this.selectedProductId) - 1],
        );
      }
    },
    setDefaultImage(event: Event) {
      if (event.target instanceof HTMLImageElement) {
        event.target.src = "/images/imagen-no-disponible.png";
      }
    },
  },
};
</script>

<template>
  <div class="warranty-selection">
    <h3 class="warranty-title">
      ¿Cuál producto de este pedido presenta problemas?
    </h3>
    <div v-if="loadingProducts" class="loading-message">
      Cargando productos...
    </div>
    <div v-else>
      <div class="product-list">
        <div v-for="product in products" :key="product.id" class="product-item">
          <input
            :id="product.id"
            v-model="selectedProductId"
            type="radio"
            :value="product.id"
            name="warrantyProduct"
          />
          <label :for="product.id" class="product-label">
            <div class="img-product">
              <img
                :src="product.coverImage"
                :alt="product.name"
                class="img-fluid rounded img-order"
                @error="setDefaultImage"
              />
            </div>
            <div class="product-info">
              <p class="product-name">{{ product.name }}</p>
              <p class="product-quantity">
                {{ product.quantity }} PIEZA{{
                  product.quantity > 1 ? "S" : ""
                }}
              </p>
              <p class="product-price">${{ product.totalPaid }}</p>
            </div>
          </label>
        </div>
      </div>
      <button
        class="continue-button"
        :disabled="!selectedProductId"
        @click="onContinueClick"
      >
        Continuar
      </button>
    </div>
  </div>
</template>

<style scoped>
.warranty-selection {
  margin-top: 20px;
}

.warranty-title {
  font-weight: bold;
  margin-bottom: 10px;
}

.loading-message {
  font-size: 14px;
  color: #555;
}

.header-row {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  margin-bottom: 10px;
}

.header-column {
  flex: 1;
  text-align: right;
}

.product-list {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.product-item {
  display: flex;
  align-items: center;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 5px;
}

.product-label {
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
  padding-left: 15px;
}

.img-product {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

.img-order {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.product-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  text-align: right;
}

.product-name {
  flex: 1;
  padding-left: 10px;
  text-align: left;
}

.product-quantity {
  flex: 1;
  text-align: left;
  padding-left: 40px;
}

.product-price {
  flex: 1;
  text-align: right;
}

.product-name {
  font-size: 14px;
}

.product-price {
  font-weight: bold;
  color: #007185;
}

.continue-button {
  background-color: #f0c14b;
  border: 1px solid;
  margin-top: 15px;
  padding: 8px 16px;
  border-color: #a88734 #9c7e31 #846a29;
  color: #111;
  cursor: pointer;
}

.continue-button:disabled {
  background-color: #ccc;
  cursor: default;
}
</style>
