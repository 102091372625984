import { VITE_API_ENDPOINT } from "@/config/constants";
import { PaginatedTransParams } from "@/interfaces/PaginatedTransParams";
import { store } from "@/store";
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import Jsona from "jsona";

const dataFormatter = new Jsona();

export class UserProfileService {
  protected type = "customers";

  public async getPersonalInformation() {
    const customer = store.getters["auth/getCustomer"];
    if (customer) {
      const response = await axios.get(
        `shop-v1/customers/${customer.id}?include=creditMovements,transactions&sort=id`,
      );
      if (!(response instanceof AxiosError)) {
        const data = dataFormatter.deserialize(response.data);
        return data;
      } else if (response.response) {
        const resp: AxiosResponse = response.response;
        return resp.data;
      }
    }
  }

  public async getPersonalInfoWithCredit() {
    const customer = store.getters["auth/getCustomer"];
    const fields =
      `name,` +
      `last_name,` +
      `mother_last_name,` +
      `email,` +
      `phone,` +
      `has_credit,` +
      `phone_verified_at,` +
      `rfc,` +
      `trade_name,` +
      `wallet_amount,` +
      `business_name,` +
      `credit_amount,` +
      `creditMovements`;
    if (customer) {
      const options: AxiosRequestConfig = {
        baseURL: VITE_API_ENDPOINT,
        headers: {
          Accept: "application/vnd.api+json",
        },
      };
      const url = `/shop-v1/customers/${customer.id}?fields[customers]=${fields}`;
      const response = await axios.get(url, options);
      if (!(response instanceof AxiosError)) {
        return response.data;
      } else if (response.response) {
        const resp: AxiosResponse = response.response;
        return resp.data;
      } else {
        return null;
      }
    }
  }

  public async updateInformation(attributes: object) {
    const customer = store.getters["auth/getCustomer"];
    const body = {
      id: customer.id,
      type: this.type,
      ...attributes,
    };

    const newJsonBody = dataFormatter.serialize({
      stuff: body,
    });

    const options: AxiosRequestConfig = {
      baseURL: VITE_API_ENDPOINT,
      headers: {
        Accept: "application/vnd.api+json",
        "Content-Type": "application/vnd.api+json",
      },
    };
    const response = await axios.patch(
      `/shop-v1/${this.type}/${customer.id}`,
      newJsonBody,
      options,
    );
    if (!(response instanceof AxiosError)) {
      return response;
    } else if (response.response) {
      const resp: AxiosResponse = response.response;
      return resp;
    } else {
      return null;
    }
  }

  public async updatePassword(attributes: object) {
    const customer = store.getters["auth/getCustomer"];
    const body = {
      id: customer.id,
      type: this.type,
      ...attributes,
    };
    const newJsonBody = dataFormatter.serialize({
      stuff: body,
    });
    const options: AxiosRequestConfig = {
      baseURL: VITE_API_ENDPOINT,
      headers: {
        Accept: "application/vnd.api+json",
        "Content-Type": "application/vnd.api+json",
      },
    };

    const response = await axios.patch(
      `/shop-v1/${this.type}/${customer.id}/-actions/updatePassword`,
      newJsonBody,
      options,
    );
    if (!(response instanceof AxiosError)) {
      return response;
    } else if (response.response) {
      const resp: AxiosResponse = response.response;
      return resp;
    } else {
      return null;
    }
  }

  public async getSortOrder(sort: string) {
    const customer = store.getters["auth/getCustomer"];
    if (customer) {
      const response = await axios.get(
        `shop-v1/customers/${customer.id}/transactions?sort=${sort}`,
      );
      if (!(response instanceof AxiosError)) {
        const data = dataFormatter.deserialize(response.data);
        return data;
      } else if (response.response) {
        const resp: AxiosResponse = response.response;
        return resp.data;
      }
    }
  }

  public async getPaginatedTransactions({
    pageNumber,
    filters,
  }: PaginatedTransParams) {
    const customer = store.getters["auth/getCustomer"];
    let url = `shop-v1/customers/${customer.id}/transactions?page[number]=${pageNumber}&page[size]=10`;

    if (filters?.filtrar_por !== 0) {
      if (filters?.filtrar_por !== undefined) {
        url += `&filter[status]=${filters.filtrar_por}`;
      }

      if (filters?.filtrar_por === 2) {
        url += `&filter[status]=${filters.filtrar_por},4`;
      }

      if (filters?.ordenar_por) {
        url += `&sort=${filters.ordenar_por}`;
      }
    }
    // console.log(url)
    if (customer) {
      const response = await axios.get(url);
      if (!(response instanceof AxiosError)) {
        return response;
      } else if (response.response) {
        const resp: AxiosResponse = response.response;
        return resp.data;
      }
    }
  }
  public async wallet() {
    const customer = store.getters["auth/getCustomer"];
    if (customer) {
      const response = await axios.get(
        `shop-v1/customers/${customer.id}?fields[customers]=wallet_movements_detail,has_wallet,wallet_amount`,
      );
      if (!(response instanceof AxiosError)) {
        const data = dataFormatter.deserialize(response.data);
        return data;
      } else if (response.response) {
        const resp: AxiosResponse = response.response;
        return resp.data;
      }
    }
  }
}

export const userProfileService = new UserProfileService();
