import CartDeliveryOptionSection from "@/components/cart/CartDeliveryOptionSection.vue";
import CartDetailSection from "@/components/cart/CartDetailSection.vue";
import CartPaymentMethodSection from "@/components/cart/CartPaymentMethodSection.vue";
import CartMonthlyPaymentOptionSection from "@/components/cart/CartMonthlyPaymentOptionSection.vue";
import CartReviewSection from "@/components/cart/CartReviewSection.vue";
import { store } from "@/store";
import { NavigationGuardNext, RouteLocationNormalized } from "vue-router";

function checkStep(code: string) {
  let activeStep = store.getters["checkout/getActiveStep"];
  if (activeStep.code != code) {
    store.commit("checkout/setStep", code);
    activeStep = store.getters["checkout/getActiveStep"];
  }
  const prevStep = store.getters["checkout/getPrevStep"];

  if (prevStep.isCompleted || activeStep.isCompleted) {
    return true;
  } else {
    store.commit("checkout/setStep", "detail");
    return false;
  }
}

const router = [
  {
    path: "carrito/detalle",
    name: "cart-detail",
    component: CartDetailSection,
    beforeEnter: () => {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      store.commit("checkout/setStep", "detail");
    },
  },
  {
    path: "elegir-pago",
    name: "cart-payment",
    component: CartPaymentMethodSection,
    beforeEnter: (
      _to: RouteLocationNormalized,
      _from: RouteLocationNormalized,
      next: NavigationGuardNext,
    ) => {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      return checkStep("payment") ? next() : next({ name: "cart-detail" });
    },
  },
  {
    path: "elegir-envio",
    name: "cart-shipping",
    component: CartDeliveryOptionSection,
    beforeEnter: (
      _to: RouteLocationNormalized,
      _from: RouteLocationNormalized,
      next: NavigationGuardNext,
    ) => {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      return checkStep("shipment") ? next() : next({ name: "cart-detail" });
    },
  },
  {
    path: "elegir-mensualidad",
    name: "cart-monthly",
    component: CartMonthlyPaymentOptionSection,
    beforeEnter: (
      _to: RouteLocationNormalized,
      _from: RouteLocationNormalized,
      next: NavigationGuardNext,
    ) => {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      return checkStep("monthly-payment")
        ? next()
        : next({ name: "cart-detail" });
    },
  },
  {
    path: "revisar",
    name: "cart-review",
    component: CartReviewSection,
    beforeEnter: (
      _to: RouteLocationNormalized,
      _from: RouteLocationNormalized,
      next: NavigationGuardNext,
    ) => {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      return checkStep("review") ? next() : next({ name: "cart-detail" });
    },
  },
];

export default router;
