import axios from "axios";
import Jsona from "jsona";
import Brand, { IBrand } from "./Brand";
import Category, { ICategory } from "./Category";
import ProductImage, { IProductImage } from "./ProductImage";
import OfferMonthDetail, { IOfferMonthDetail } from "./OfferMonthDetail";
import Coupon, { ICoupon } from "./Coupon";
import SpecialCharacteristic, {
  ISpecialCharacteristic,
} from "./SpecialCharacteristic";
import { v4 as uuidv4 } from "uuid";
import ProductReview from "./ProductReview";
import { Router } from "vue-router";

export interface IAttributeGroupMap {
  group: {
    id: string | null;
    name: string | null;
  };
  attributes: {
    is_web_filter: boolean;
    attribute_id: string | null;
    attribute_name: string | null;
    value_id: string | null;
    value: string | null;
  }[];
}

export interface IStoreWithStock {
  store_id: string | null;
  store_name: string;
  qty: number;
  can_other_stores_take_stock: boolean;
  fast_pickup: boolean;
}

export interface IProduct {
  id: string | null;
  name: string | null;
  slug: string | null;
  part_number: string | null;
  category: ICategory | null;
  brand: IBrand | null;
  images: IProductImage[];
  rating: number;
  reviews_count: number;
  price_with_tax: number;
  current_price_with_tax: number;
  mwi_one_price_with_tax: number;
  set_base_price_with_tax: number;
  mwi_one_disc_percent: number;
  is_kit: boolean;
  is_assembly: boolean;
  is_component: boolean;
  offer_months: IOfferMonthDetail[];
  stock_stores: number;
  stock_providers: number;
  wallet_promotion_amount: number;
  has_shipping_promotion: boolean;
  has_last_stock: boolean;
  stock_total: number;
  price_valid_until_utc: string | null;
  coupon: ICoupon | null;
  short_description: string | null;
  description: string | null;
  description_file: string | null;
  description_file_is_pdf: boolean;
  description_file_is_image: boolean;
  description_landing: string | null;
  video_link: string | null;
  manual_file: string | null;
  specs_file: string | null;
  manufacturer_link: string | null;
  meta_description: string | null;
  attribute_group_map: Map<string, IAttributeGroupMap>;
  characteristics: ISpecialCharacteristic[];
  bullet_points: string[];
  any_related: {
    related: IProduct[];
    substitutes: IProduct[];
    sets: IProduct[];
    components: IProduct[];
  };
  stores_with_stock: IStoreWithStock[];
  upc_gtin: string | null;
  created_utc: string | null;
  warranty_days: number;
}

export default class Product implements IProduct {
  public id: string | null = null;
  public name: string | null = null;
  public slug: string | null = null;
  public part_number: string | null = null;
  public category: Category | null = null;
  public brand: Brand | null = null;
  public images: ProductImage[] = [];
  public rating: number = 0;
  public reviews_count: number = 0;
  public price_with_tax: number = 0;
  public current_price_with_tax: number = 0;
  public mwi_one_price_with_tax: number = 0;
  public set_base_price_with_tax: number = 0;
  public mwi_one_disc_percent: number = 0;
  public is_kit: boolean = false;
  public is_assembly: boolean = false;
  public is_component: boolean = false;
  public offer_months: OfferMonthDetail[];
  public stock_stores: number = 0;
  public stock_providers: number = 0;
  public wallet_promotion_amount: number = 0;
  public has_shipping_promotion: boolean = false;
  public has_last_stock: boolean = false;
  public stock_total: number = 0;
  public price_valid_until_utc: string | null = null;
  public coupon: Coupon | null = null;
  public short_description: string | null = null;
  public description: string | null = null;
  public description_file: string | null = null;
  public description_file_is_pdf: boolean = false;
  public description_file_is_image: boolean = false;
  public description_landing: string | null = null;
  public meta_description: string | null = null;
  public attribute_group_map: Map<string, IAttributeGroupMap> = new Map();
  public video_link: string | null = null;
  public manual_file: string | null = null;
  public specs_file: string | null = null;
  public manufacturer_link: string | null = null;
  public characteristics: SpecialCharacteristic[] = [];
  public bullet_points: string[] = [];
  public any_related: {
    related: Product[];
    substitutes: Product[];
    sets: Product[];
    components: Product[];
  };
  public stores_with_stock: IStoreWithStock[];
  public related: Product[] = [];
  public substitutes: Product[] = [];
  public sets: Product[] = [];
  public components: Product[] = [];
  public page: number = 1;
  public upc_gtin: string | null = null;
  public reviews: ProductReview[] = [];
  public created_utc: string | null = null;
  public warranty_days: number = 0;

  public reviews_page: number = 0; // 0 porque se suma antes de hacer fetch

  /** ID para el componente general del producto, sirve cómo buena práctica de programación al mantener la esencia de reciclar componentes incluso los de tipo Page. */
  public readonly componentId: string;

  public constructor({
    id,
    name,
    slug,
    part_number,
    category,
    brand,
    images,
    rating,
    reviews_count,
    price_with_tax,
    current_price_with_tax,
    mwi_one_price_with_tax,
    set_base_price_with_tax,
    mwi_one_disc_percent,
    is_kit,
    is_assembly,
    is_component,
    offer_months,
    stock_stores,
    stock_providers,
    wallet_promotion_amount,
    has_shipping_promotion,
    has_last_stock,
    coupon,
    short_description,
    description,
    description_file,
    description_file_is_pdf,
    description_file_is_image,
    description_landing,
    meta_description,
    attribute_group_map,
    video_link,
    manual_file,
    specs_file,
    manufacturer_link,
    characteristics,
    bullet_points,
    any_related,
    stores_with_stock,
    upc_gtin,
    price_valid_until_utc,
    created_utc,
    warranty_days,
  }: IProduct) {
    this.id = id;
    this.name = name;
    this.slug = slug;
    this.part_number = part_number;
    this.brand = brand ? new Brand(brand as IBrand) : null;
    this.category = category ? new Category(category as ICategory) : null;
    this.images = Array.isArray(images) ? ProductImage.fromArray(images) : [];
    this.rating = rating || 0;
    this.reviews_count = reviews_count * 1 || 0;
    this.price_with_tax = price_with_tax * 1 || 0;
    this.current_price_with_tax = current_price_with_tax || 0;
    this.mwi_one_price_with_tax = mwi_one_price_with_tax || 0;
    this.set_base_price_with_tax = set_base_price_with_tax || 0;
    this.mwi_one_disc_percent = mwi_one_disc_percent || 0;
    this.is_kit = !!is_kit;
    this.is_assembly = !!is_assembly;
    this.is_component = !!is_component;
    this.offer_months = Array.isArray(offer_months)
      ? OfferMonthDetail.fromArray(offer_months)
      : [];
    this.stock_stores = stock_stores || 0;
    this.stock_providers = stock_providers || 0;
    this.stock_total = this.stock_stores + this.stock_providers;
    this.price_valid_until_utc = price_valid_until_utc || null;
    this.wallet_promotion_amount = wallet_promotion_amount * 1 || 0;
    this.has_shipping_promotion = !!has_shipping_promotion;
    this.has_last_stock = !!has_last_stock;
    this.coupon = coupon || null;
    this.short_description = short_description || null;
    this.description = description || null;
    this.description_file = description_file || null;
    this.description_file_is_pdf = !!description_file_is_pdf;
    this.description_file_is_image = !!description_file_is_image;
    this.description_landing = description_landing || null;
    this.meta_description = meta_description;
    this.attribute_group_map = new Map(
      Object.keys(attribute_group_map || {}).map((k) => [
        k,
        (attribute_group_map as never)[k],
      ]),
    );
    this.video_link = video_link || null;
    this.manual_file = manual_file || null;
    this.specs_file = specs_file || null;
    this.manufacturer_link = manufacturer_link || null;
    this.characteristics = Array.isArray(characteristics)
      ? SpecialCharacteristic.fromArray(characteristics)
      : [];
    this.bullet_points = bullet_points || [];
    this.any_related = {
      related: Array.isArray(any_related?.related)
        ? Product.fromArray(any_related.related)
        : [],
      substitutes: Array.isArray(any_related?.substitutes)
        ? Product.fromArray(any_related.substitutes)
        : [],
      sets: Array.isArray(any_related?.sets)
        ? Product.fromArray(any_related.sets)
        : [],
      components: Array.isArray(any_related?.components)
        ? Product.fromArray(any_related.components)
        : [],
    };
    this.stores_with_stock = stores_with_stock || [];
    this.upc_gtin = upc_gtin;
    this.created_utc = created_utc || null;
    this.warranty_days = warranty_days * 1 || 0;

    this.componentId = "product-page-" + uuidv4();
  }

  public static async findBySlug(slug: string): Promise<Product> {
    let path = `/shop-v1/products/-actions/by-slug/${slug}`;
    let fields =
      `fields[products]=` +
      `brand,` +
      `category,` +
      `images,` +
      `name,` +
      `part_number,` +
      `rating,` +
      `reviews_count,` +
      `price_with_tax,` +
      `current_price_with_tax,` +
      `mwi_one_price_with_tax,` +
      `mwi_one_disc_percent,` +
      `is_kit,` +
      `is_assembly,` +
      `is_component,` +
      `offer_months,` +
      `stock_stores,` +
      `stock_providers,` +
      `wallet_promotion_amount,` +
      `has_shipping_promotion,` +
      `coupon,` +
      `short_description,` +
      `description,` +
      `description_file,` +
      `description_file_is_pdf,` +
      `description_file_is_image,` +
      `description_landing,` +
      `meta_description,` +
      `video_link,` +
      `manual_file,` +
      `specs_file,` +
      `manufacturer_link,` +
      `characteristics,` +
      `bullet_points,` +
      `attribute_group_map,` +
      `any_related,` +
      `stores_with_stock,` +
      `has_last_stock,` +
      `upc_gtin,` +
      `created_utc,` +
      `price_valid_until_utc,` +
      `warranty_days`;
    fields += "&fields[categories]=parent,popularBrands,name,slug";
    fields += "&fields[brands]=name,image,slug";
    let sizeFields = "";
    const sizes = Object.values(ProductImage.Sizes);
    sizes.forEach((size: number, index: number) => {
      // product-images fields
      sizeFields +=
        ProductImage.getFieldOfSize(size) +
        (index + 1 < sizes.length ? "," : "");
    });
    fields += `&fields[product-images]=is_cover,${sizeFields}`;
    fields += `&fields[special-characteristics]=name,icon,description`;
    const include =
      "include=brand,category.parent.parent,category.popularBrands,images,characteristics";
    let filters = "filter[withRating]";
    filters += "&filter[withCurrentPrices]";
    path += `?${fields}&${include}&${filters}`;
    const jsona = new Jsona();
    const res = await axios.get(path);
    if (res.status != 200) {
      throw res;
    }
    const data = jsona.deserialize(res.data);
    if (!data) {
      throw new Error("PRODUCT NOT FOUND");
    }
    const instance = new Product(data as IProduct);
    return instance;
  }

  public getImagesOfSize(size: number, isCover?: boolean) {
    if (!this.images.length) {
      return [];
    }
    let firstIsCover = false;
    const result = this.images.map((image, index) => {
      if (isCover && !image.is_cover && index > 0) {
        // take first image always as cover
        return null;
      }
      if (index === 0) {
        firstIsCover = image.is_cover;
      }
      if (size == ProductImage.Sizes.ExtraSmall) {
        return image.getThumbImage();
      } else if (size == ProductImage.Sizes.Large) {
        return image.getLargeImage();
      }
      return image.getOriginalImage();
    });
    // Switch the first image if this is not a cover when cover is required and there are more real covers.
    if (!firstIsCover && result.length > 1) {
      const aux = result[0];
      result[0] = result[1];
      result[1] = aux;
    }
    return result.filter((url) => url != null).map((url) => url as string);
  }

  public getCoverImage(
    size: number = ProductImage.Sizes.Medium,
    useCover404: boolean = false,
  ): string | undefined {
    // Por alguna razón Sentry reporta que a veces esta función no existe, por eso me veo obligado a evaluarla antes de seguir.
    if (typeof this.getImagesOfSize !== "function") {
      return "/images/imagen-no-disponible.png";
    }
    const url = this.getImagesOfSize(size, true).at(0);
    return useCover404 || !url ? "/images/imagen-no-disponible.png" : url;
  }

  public getPercentDiscount(): number {
    return parseInt(
      (
        (1 - this.getFinalCurrentPrice() / this.getFinalCommonPrice()) *
        100
      ).toFixed(0),
    );
  }

  public getPercentDiscountText(): string {
    return this.getPercentDiscount() + "%";
  }

  public static fromArray(items: IProduct[]) {
    return items.map((item) => new Product(item));
  }

  public hasDiscount() {
    return (
      this.current_price_with_tax < this.price_with_tax ||
      this.mwi_one_disc_percent > 0
    );
  }

  public getFinalCommonPrice() {
    return this.mwi_one_disc_percent > 0
      ? this.current_price_with_tax
      : this.price_with_tax;
  }

  public getFinalCurrentPrice() {
    return this.mwi_one_disc_percent > 0
      ? this.mwi_one_price_with_tax
      : this.current_price_with_tax;
  }

  public async fetchMoreReviews() {
    if (!this.id) {
      return this.reviews;
    }
    this.reviews_page += 1;
    const newReviews = await ProductReview.fetchByProduct(
      this.id,
      this.reviews_page,
    );
    this.reviews.push(...newReviews);
    return this.reviews;
  }

  public resolveLink(router: Router) {
    return router.resolve({
      name: "product",
      params: { slug: this.slug },
    });
  }
}
