import axios, { AxiosError, AxiosResponse, AxiosRequestConfig } from "axios";
import { store } from "@/store";
import Jsona from "jsona";
import { VITE_API_ENDPOINT } from "@/config/constants.js";

const dataFormatter = new Jsona();

export class ZipCodeService {
  public async setZipCode(zipCode: string) {
    const body = { postal_code: zipCode };
    const options: AxiosRequestConfig = {
      baseURL: VITE_API_ENDPOINT,
      // headers: {
      //     "X-CSRF-TOKEN": store.getters['auth/getCsrfToken']
      // }
    };
    const response = await axios.post("/eshop/zip-picker", body, options);
    if (!(response instanceof AxiosError)) {
      store.commit("session/setZipCode", response.data.meta.postal_code);
      return response.data;
    } else if (response.response) {
      const resp: AxiosResponse = response.response;
      return resp.data;
    } else {
      return null;
    }
  }

  public async getAddress() {
    // const address = store.getters['session/getAddress'];
    // if (address != null && address.length > 0) {
    //     return address;
    // }

    const customer = store.getters["auth/getCustomer"];
    // const response = await axios.get('/eshop/zip-picker', {});
    if (customer) {
      const response = await axios.get(
        `shop-v1/customers/${customer.id}/customer-addresses?include=city,settlement,state,municipality,cfdiUse,cfdiRegime`,
      );
      if (!(response instanceof AxiosError)) {
        const data = dataFormatter.deserialize(response.data);
        store.commit("session/setAddress", data);
        return data;
      } else if (response.response) {
        const resp: AxiosResponse = response.response;
        return resp.data;
      }
    }
    return null;
  }
}
