export default {
  install: (_app: any, _options: any) => {
    // window.fbAsyncInit = function() {
    //     FB.init({
    //         appId: options.appId,
    //         xfbml: true,
    //         version: 'v19.0'
    //     });

    //     app.config.globalProperties.$fb = window.FB;
    // };

    (function (d, s, id) {
      const fjs: any = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      const js: any = d.createElement(s);
      js.id = id;
      js.src = "https://platform.twitter.com/widgets.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  },
};
