<script setup lang="ts">
import ChatMessage from "../common/ChatMessage.vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import { onMounted, ref, nextTick, onBeforeUnmount } from "vue";
import TransactionChatMessage, {
  ITransactionChatMessage,
} from "../../models/TransactionChatMessage";
import { ErrorResponse } from "../../interfaces/ErrorResponse.ts";
import { useRouter } from "vue-router";

(() => Swiper)();
(() => SwiperSlide)();

const router = useRouter();
const props = defineProps({
  selectedOrderId: {
    type: String,
    default: "",
  },
});
const messages = ref<ITransactionChatMessage[]>([]);
const chatContainer = ref<HTMLDivElement | null>(null);
const newMessage = ref<string>("");
const errorMessage = ref<string>("");
let intervalId: ReturnType<typeof setInterval> | null = null;

onMounted(async () => {
  window.scrollTo(0, 0);
  if (props.selectedOrderId) {
    try {
      messages.value = await TransactionChatMessage.fetchByTransaction(
        props.selectedOrderId,
      );
      messages.value.reverse();
      await nextTick();
      scrollToBottom();

      intervalId = setInterval(async () => {
        const lastMessageId = messages.value.length
          ? messages.value[messages.value.length - 1].id
          : null;
        const newMessages = await TransactionChatMessage.fetchByTransaction(
          props.selectedOrderId,
          lastMessageId ?? undefined,
        );

        if (newMessages.length > 0) {
          messages.value.push(...newMessages);
          await nextTick();
          scrollToBottom();
        }
      }, 3000);
    } catch (error) {
      console.error("Error al obtener los mensajes:", error);
    }
  }
});

onBeforeUnmount(() => {
  if (intervalId) {
    clearInterval(intervalId);
  }
});

const sendMessage = async () => {
  errorMessage.value = "";
  if (newMessage.value.trim()) {
    try {
      let myMessage = newMessage.value;
      newMessage.value = "";
      let response = (await TransactionChatMessage.sendMessage(
        props.selectedOrderId,
        myMessage,
      )) as ITransactionChatMessage;
      messages.value.push({
        id: response.id,
        is_self: response.is_self,
        sender_name: response.sender_name,
        message: response.message,
        created_at_time: response.created_at_time,
        is_seen: response.is_seen,
      });
      await nextTick();
      scrollToBottom();
    } catch (error) {
      console.error("Error al enviar mensaje:", error);
      const errorResponse = error as ErrorResponse;
      if (
        errorResponse.data?.messages?.error == "constants.max_messages_error"
      ) {
        errorMessage.value = "No puedes enviar mensajes tan seguido.";
      } else {
        errorMessage.value = "Ocurrió un error al enviar el mensaje.";
      }
    }
  }
};

const handleKeydown = (event: KeyboardEvent) => {
  if (event.key === "Enter") {
    event.preventDefault();
    newMessage.value += "\n";
  }
};

const scrollToBottom = () => {
  if (chatContainer.value) {
    chatContainer.value.scrollTop = chatContainer.value.scrollHeight;
  }
};

const goToOrderInfo = () => {
  router.push({ name: "order-show", params: { id: props.selectedOrderId } });
};
</script>

<template>
  <div class="container mt-4">
    <div class="card shadow-sm">
      <div
        class="card-header d-flex justify-content-between align-items-center"
      >
        <h5>Chat del Pedido</h5>
        <button class="btn btn-link text-primary" @click="goToOrderInfo">
          Volver a la información del pedido
        </button>
      </div>
      <div class="card-body">
        <div ref="chatContainer" class="container-chat">
          <div v-if="messages.length === 0" class="text-muted text-center my-3">
            Aún no hay mensajes. Escribe el primero y un asesor te contactará
            pronto.
          </div>
          <div
            v-for="message in messages"
            :key="message.id ? Number(message.id) : 0"
            class="mb-3"
          >
            <chat-message
              :sender-type="message.is_self ? 'customer' : 'user'"
              :name="message.sender_name || 'Desconocido'"
              :type="message.is_self ? 'customer' : 'user'"
              :message="message.message || ''"
              :created-at="message.created_at_time || ''"
              :seen="message.is_seen || false"
              :selected-order-id="selectedOrderId"
            />
          </div>
        </div>
      </div>

      <div class="card-footer">
        <div class="input-group">
          <span class="input-group-text">Tú</span>
          <textarea
            v-model="newMessage"
            class="form-control"
            aria-label="Escribe tu mensaje..."
            rows="3"
            placeholder="Escribe tu mensaje..."
            @keydown="handleKeydown"
          ></textarea>
          <button class="btn btn-primary" @click="sendMessage">Enviar</button>
        </div>
        <div v-if="errorMessage" class="text-danger mt-2">
          {{ errorMessage }}
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.container-chat {
  max-height: 400px;
  overflow-y: auto;
  padding-bottom: 1rem;
}
</style>
