<template>
  <div
    class="d-flex flex-row-reverse justify-content-between flex-lg-column w-100"
  >
    <button
      class="btn-delivery-code px-0 link-underline link-underline-opacity-0 fs-9 m-0 p-0"
      type="button"
      @click="showCode = !showCode"
    >
      <div class="toggle-container d-flex flex-column">
        <span
          class="delivery-code pointer text-start m-0 p-0"
          :class="{ active: showCode }"
        >
          {{ code }}
        </span>
        <span
          class="hidden-code d-flex flex-column align-items-end align-items-lg-start negative-top-10"
          :class="{ active: !showCode }"
        >
          <div class="text-dark fs-5 text-blue">**********</div>
          <div class="text-blue">Mostrar</div>
        </span>
      </div>
    </button>
    <span class="text-secondary text-delivery-code text-nowrap">
      Código para entrega
    </span>
  </div>
</template>
<script lang="ts">
export default {
  props: {
    code: {
      type: String,
      default: "",
    },
  },
  data: function () {
    return {
      showCode: false,
    };
  },
};
</script>
