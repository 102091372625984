export interface ISpecialCharacteristic {
  id: string | null;
  name: string | null;
  icon: string | null;
  description: string | null;
}

export default class SpecialCharacteristic implements ISpecialCharacteristic {
  public id: string | null = null;
  public name: string | null = null;
  public icon: string | null = null;
  public description: string | null = null;

  public constructor({ id, name, icon, description }: ISpecialCharacteristic) {
    this.id = id || null;
    this.name = name || null;
    this.icon = icon || null;
    this.description = description || null;
  }

  public static fromArray(items: ISpecialCharacteristic[]) {
    return items.map((item) => new SpecialCharacteristic(item));
  }
}
