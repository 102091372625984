<template>
  <div class="position-relative w-100" :class="{ blur: loading }">
    <div v-if="!showAddBankCard">
      <div class="row g-0">
        <div
          v-for="(card, index) in cardsSavedAndTemporaly"
          :key="index"
          class="col-12 col-md-6 col-xl-4"
          :class="{ 'hide-parent': showAddBankCard }"
        >
          <div
            class="bank-card pointer ms-3 mb-2 mt-3"
            @click="selectTokenizable(card)"
          >
            <div class="p-3 d-flex flex-column h-100">
              <div class="flex-grow-1">
                <div class="row align-items-start">
                  <div class="col-6">
                    <img
                      :style="{ height: '35px' }"
                      :src="card.icon_url"
                      :alt="card.icon_url"
                    />
                  </div>
                  <div class="col-6 text-end">
                    <button class="btn border-0" @click="deleteCard(card)">
                      <font-awesome-icon
                        icon="fa-solid fa-circle-xmark"
                        class="fs-5 me-2 text-danger"
                      />
                    </button>
                  </div>
                </div>
              </div>
              <div class="flex-grow-1">
                <div class="row align-items-center">
                  <div class="col-2 payment-section-radio">
                    <input
                      :id="'bank-card-radio-' + card.id"
                      v-model="bankCardRadio"
                      type="radio"
                      name="bank-card"
                      :value="card.id"
                      :checked="paymentTokenizable == card.id"
                      class="custom-radio-input red-radio-input"
                    />
                    <label
                      :for="'bank-card-radio-' + card.id"
                      class="custom-radio-label"
                    />
                  </div>
                  <div class="col-10">
                    <p class="text-dark fw-bold fs-5">
                      {{ card.payload.card_number }}
                    </p>
                  </div>
                </div>
              </div>
              <div>
                <div class="row align-items-end">
                  <div class="col-2" />
                  <div class="col-10">
                    <p class="fs-7 text-dark fw-normal text-capitalize">
                      {{ card.payload.holder_name }}
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 text-end text-green fs-9">
                    {{ card.payload.expiration_status }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-4" :class="{ 'hide-parent': showAddBankCard }">
          <div
            class="bank-card add-bank-card pointer ms-3 mb-2 mt-3 d-none d-lg-block"
            @click="addBankCard"
          >
            <div class="d-flex align-items-center justify-content-center h-100">
              <i class="bi bi-plus-circle fs-4" />
              <a class="fw-bold mx-2 mt-1 h5 text-decoration-none">
                {{ getTitle("cart", "add_new_card") }}
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="row d-lg-none" :class="{ 'hide-parent': showAddBankCard }">
        <div class="d-flex align-items-center justify-content-end">
          <i class="bi bi-plus-circle-fill text-blue fs-4" />
          <a
            class="ms-1 fs-8 text-blue pointer text-decoration-none align-items-center me-2"
            @click="addBankCard"
          >
            {{ getTitle("cart", "add_new_card") }}
          </a>
        </div>
      </div>
    </div>
    <div v-else class="container">
      <add-bank-card
        :provider="provider"
        :payment-method-code="paymentMethodCode"
        :show-add-bank-card="showAddBankCard"
        :open-pay="openPay"
        @update-show-add-bank-card="updateShowAddBankCard"
        @update-tokenizables="updateTokenizables"
        @is-loading="isLoading"
      />
    </div>
    <div
      v-if="loading"
      class="position-absolute top-0 left-0 h-100 w-100 d-flex justify-content-center align-items-center"
    >
      <div class="spinner-border text-secondary" role="status">
        <span class="visually-hidden mx-auto">Loading...</span>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { getTitleTranslate, errorsToString } from "@/utils/general";
import { CartService } from "@/services/CartService";
import { PaymentMethodService } from "@/services/PaymentMethodService";
import AddBankCard from "./AddBankCard.vue";
import { store } from "@/store";
import { mapGetters } from "vuex";
import { CustomNotifyService } from "@/services/CustomNotifyService";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { BankCard } from "@/interfaces/BankCard";
import { AxiosResponse } from "axios";

const notifyService = new CustomNotifyService();

export default {
  components: {
    AddBankCard,
    FontAwesomeIcon,
  },
  props: {
    cards: {
      type: Array<BankCard>,
      default() {
        return [];
      },
    },
    paymentMethodCode: {
      type: String,
      default: "",
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    provider: {
      type: String,
      default: "openpay_card",
    },
  },
  emits: ["update-tokenizables", "is-loading"],

  data: function () {
    return {
      cartService: null as CartService | null,
      paymentMethodService: null as PaymentMethodService | null,
      showAddBankCard: false,
      bankCardRadio: "",
      openPay: null as null | any,
    };
  },
  computed: {
    ...mapGetters("checkout", {
      paymentTokenizable: "getPaymentTokenizable",
      paymentTokenizableTemporaly: "getPaymentTokenizableTemporaly",
      deviceSessionId: "getDeviceSessionId",
    }),
    temporalyCards() {
      return store.getters[
        "checkout/getPaymentTokenizableTemporalyByMethodPayment"
      ](this.paymentMethodCode);
    },
    cardsSavedAndTemporaly() {
      return [...this.temporalyCards, ...this.cards];
    },
  },
  watch: {
    cards() {
      this.bankCardRadio = "";
      if (this.cardsSavedAndTemporaly.length > 0) {
        this.showAddBankCard = false;
      }
    },
  },
  mounted() {
    this.cartService = new CartService();
    this.paymentMethodService = new PaymentMethodService();

    this.cartService.getCartInitData();
    if (this.cardsSavedAndTemporaly.length == 0) {
      this.showAddBankCard = true;
    }
    store.commit("checkout/removeOldPaymentTokenizableTemporaly");
  },

  methods: {
    getTitle(section: string, name: string) {
      return getTitleTranslate(section, name);
    },
    addBankCard() {
      this.showAddBankCard = true;
    },
    deleteCard(card: BankCard) {
      if (this.paymentMethodService) {
        this.$emit("is-loading", true);
        let exists = false;
        if (this.temporalyCards.length > 0) {
          exists =
            this.temporalyCards.findIndex((cardTemp: BankCard) => {
              return cardTemp.id == card.id;
            }) >= 0;
          if (exists) {
            store.commit("checkout/removePaymentTokenizableTemporaly", card.id);
          }
        }
        if (!exists) {
          this.paymentMethodService
            .deletePaymentMehod(
              card.payment_method_code,
              card.provider_resource_id,
            )
            .then((response: AxiosResponse) => {
              if (response.status == 204) {
                // this.$emit("update-tokenizables", card.payment_method_code);
                store.dispatch("checkout/removePaymentTokenizable", card);
                notifyService.getNotification({
                  title: "¡Eliminación exitosa!",
                  text: `La tarjeta fue eliminada`,
                  time: 3000,
                });
              }
            })
            .catch((reason) => {
              console.log("Error:", reason);
              notifyService.getNotification({
                type: "error",
                title: `Ocurrio un error al eliminar su tarjeta`,
                text: errorsToString(reason),
                time: 3000,
              });
              this.openPay = null;
            })
            .finally(() => {
              this.$emit("is-loading", false);
            });
        } else {
          this.$emit("is-loading", false);
        }
      }
    },
    updateShowAddBankCard(status: boolean) {
      this.showAddBankCard = status;
    },
    updateTokenizables() {
      this.$emit("update-tokenizables", this.paymentMethodCode);
    },
    isLoading(status: boolean) {
      this.$emit("is-loading", status);
    },
    selectTokenizable(card: any) {
      this.bankCardRadio = card.id;
      store.commit("checkout/setPaymentTokenizable", card.id);
    },
  },
};
</script>

<style lang="scss" scoped>
@use "@/scss/cart";
</style>
