<template>
  <Transition name="fade">
    <div v-if="showAddBankCard" class="bank-cards-list my-3 my-lg-0">
      <VeeForm id="main-form" @submit="onAddBankCard">
        <input
          type="hidden"
          name="device_session_id"
          :value="deviceSessionId"
        />
        <input type="hidden" name="token_id" :value="tokenId" />
        <input type="hidden" name="reference" :value="cardDescription" />

        <input
          type="hidden"
          data-openpay-card="holder_name"
          :value="cardholder_name"
        />
        <input
          type="hidden"
          data-openpay-card="expiration_month"
          :value="openpayExpirationMonth"
        />
        <input
          type="hidden"
          data-openpay-card="expiration_year"
          :value="openpayExpirationYear"
        />
        <input
          type="hidden"
          data-openpay-card="card_number"
          :value="card_number"
        />
        <input type="hidden" data-openpay-card="cvv2" :value="cvc" />

        <div class="d-flex justify-content-end align-items-center p-2">
          <button
            type="button"
            class="btn-close focus-ring focus-ring-secondary"
            aria-label="Close"
            @click="closeForm"
          />
        </div>
        <div class="row">
          <div class="col-12 col-lg-6">
            <h5 class="fw-bold text-secondary mt-3 pb-2 ps-3">
              {{ getTitle("cart", "add_new_card") }}
            </h5>
          </div>
          <div class="col-12 col-lg-6">
            <div class="d-flex align-items-center h-100 float-end">
              <div class="checkbox-cart-item px-1 d-none d-lg-block">
                <input
                  id="save_cart"
                  v-model="isCheckedCartItem"
                  type="checkbox"
                />
                <label for="save_cart" class="pe-2">
                  {{ getTitle("cart", "save_this_card") }}
                </label>
              </div>
            </div>
          </div>
          <div class="row mb-3 mx-1">
            <div class="col-12 col-lg-4 mb-2 mb-lg-0">
              <label for="cardholder-name" class="card-input">
                <div class="box-input-card" />
                <Field
                  id="cardholder-name"
                  v-model="cardholder_name"
                  type="text"
                  name="cardholder_name"
                  :placeholder="getTitle('cart', 'cardholder_name')"
                  :rules="[bankCardSchema.cardholder_name]"
                  @keydown.enter.prevent
                />
              </label>
              <ErrorMessage name="cardholder_name" class="error" />
            </div>
            <div class="col-12 col-lg-4 mb-2 mb-lg-0">
              <label class="card-input bg-dan" for="card-number">
                <div class="box-input-card" />
                <Field
                  id="card-number"
                  v-model="card_number"
                  type="text"
                  name="card_number"
                  :placeholder="getTitle('cart', 'card_number')"
                  :rules="[
                    validateOpenpayCardNumber,
                    bankCardSchema.card_number,
                  ]"
                  @keydown.enter.prevent
                />
              </label>
              <ErrorMessage name="card_number" class="error" />
            </div>
            <div class="col-12 col-lg-2 mb-2 mb-lg-0">
              <label for="year-month" class="card-input">
                <div class="box-input-card" />
                <Field
                  id="year-month"
                  v-model="year_month"
                  :placeholder="
                    getTitle('cart', 'month') + ' / ' + getTitle('cart', 'year')
                  "
                  name="year_month"
                  :rules="[validateOpenpayExpiry, bankCardSchema.year_month]"
                  @keyup="formatYearMonth"
                  @keydown.enter.prevent
                />
              </label>
              <ErrorMessage name="year_month" class="error" />
            </div>
            <div class="col-12 col-lg-2">
              <label for="cvc" class="card-input">
                <div class="box-input-card" />
                <Field
                  id="cvc"
                  v-model="cvc"
                  :placeholder="getTitle('cart', 'cvc')"
                  type="password"
                  name="cvc"
                  max-lenght="4"
                  :rules="[validateOpenpayCcv, bankCardSchema.cvc]"
                  class="w-100"
                  autocomplete="off"
                  @keydown.enter.prevent
                />
              </label>
              <ErrorMessage name="cvc" class="error" />
            </div>
          </div>
        </div>
        <div class="row mb-3 mx-1">
          <div class="col-12 col-lg-4 mb-2 mb-lg-0">
            <label class="card-input">
              <div class="box-input-card" />
              <Field
                id="email"
                v-model="email"
                class="input-field"
                type="email"
                name="email"
                :placeholder="getTitle('cart', 'email')"
                :rules="[bankCardSchema.email]"
                @keydown.enter.prevent
              />
            </label>
            <ErrorMessage name="email" class="error" />
          </div>
          <div class="col-12 col-lg-3 mb-2 mb-lg-0">
            <label class="card-input">
              <div class="box-input-card" />
              <Field
                id="phone"
                v-model="phone"
                :placeholder="getTitle('cart', 'cell_phone')"
                type="number"
                name="phone"
                :rules="[bankCardSchema.phone]"
                @keydown.enter.prevent
              />
            </label>
            <ErrorMessage name="phone" class="error" />
          </div>
          <div class="col-12 col-lg-3 mb-2 mb-lg-0">
            <label class="card-input">
              <div class="box-input-card" />
              <Field
                id="postal_code"
                v-model="postal_code"
                :placeholder="getTitle('cart', 'postal_code')"
                type="number"
                name="postal_code"
                :rules="[bankCardSchema.postal_code]"
                @keydown.enter.prevent
              />
            </label>
            <ErrorMessage name="postal_code" class="error" />
          </div>
          <div class="col-12 col-lg-2">
            <button class="btn btn-info h-100 w-100 fs-5" :disabled="loading">
              {{ getTitle("cart", "add_card") }}
            </button>
          </div>
        </div>
      </VeeForm>
    </div>
  </Transition>
</template>
<script lang="ts">
import { CartService } from "@/services/CartService";
import { PaymentMethodService } from "@/services/PaymentMethodService";
import { getTitleTranslate, errorsToString } from "@/utils/general";
import { Form as VeeForm, Field, ErrorMessage, useForm } from "vee-validate";
import { CustomNotifyService } from "@/services/CustomNotifyService";
import Jsona from "jsona";
import { BankCard } from "@/interfaces/BankCard";
import { store } from "@/store";
import { mapGetters } from "vuex";
import { AxiosError } from "axios";
import eventBus from "@/event-bus";

const notifyService = new CustomNotifyService();
const dataFormatter = new Jsona();

export default {
  components: {
    VeeForm,
    Field,
    ErrorMessage,
  },

  props: {
    initialValues: {
      type: Object,
      default() {
        return {};
      },
    },

    showAddBankCard: {
      type: Boolean,
      default: false,
    },

    paymentMethodCode: {
      type: String,
      default: "",
      required: true,
    },

    provider: {
      type: String,
      default: "openpay_card",
    },
  },

  emits: ["update-show-add-bank-card", "update-tokenizables", "is-loading"],

  setup() {
    const getTitle = function (section: string, name: string) {
      return getTitleTranslate(section, name);
    };

    const bankCardSchema: any = {
      email(value: string) {
        const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
        if (!value) {
          return "El campo correo electrónico es necesario";
        } else if (!regex.test(value)) {
          return "Ingresa un correo electrónico válido";
        } else {
          return true;
        }
      },
      cardholder_name(value: string) {
        if (!value || !value.length || value.length < 2) {
          return "El nombre del títular es necesario";
        } else {
          return true;
        }
      },
      card_number(value: string) {
        if (!value) {
          return "El número de tarjeta es necesario";
        } else {
          return true;
        }
      },
      year_month(value: string) {
        const regex = /^(0[1-9]|1[0-2])\/\d{2}$/;

        if (!value) {
          return "El año y mes de vencimiento son necesarios";
        } else if (value.toString().length != 5 || !regex.test(value)) {
          return "Ingresa una vigencia válida. Ejemplo: 10/28";
        } else {
          return true;
        }
      },
      cvc(value: string) {
        const regex = /^\d{3,4}$/;
        if (!value) {
          return "El código de seguridad es necesario";
        } else if (!regex.test(value)) {
          return "Ingresa un cvc válido.";
        } else {
          return true;
        }
      },
      phone(value: string) {
        if (!value) {
          return "El teléfono celular es necesario";
        } else if ((value + "").length < 10) {
          return "Ingresa teléfono celular debe tener 10 dígitos.";
        } else {
          return true;
        }
      },
      postal_code(value: string) {
        if (!value) {
          return "El código postal es necesario";
        } else if ((value + "").length != 5) {
          return "El código postal deben ser 5 caracteres";
        } else {
          return true;
        }
      },
    };

    const { resetForm } = useForm({
      initialValues: {
        email: "",
        cardholder_name: "",
        card_number: "",
        year_month: "",
        cvc: "",
        phone: "",
        postal_code: "",
      },
    });

    return {
      bankCardSchema,
      getTitle,
      resetForm,
    };
  },

  data: function () {
    return {
      isCheckedCartItem: true,
      cartService: null as CartService | null,
      email: "",
      cardholder_name: "",
      card_number: "",
      year_month: "",
      cvc: "",
      phone: "",
      postal_code: "",
      card: null as null | unknown,

      cardDescription: null,
      tokenId: this.initialValues.id || null,
      tokenizationError: null as null | boolean,
      loading: false as boolean,
    };
  },

  computed: {
    ...mapGetters("checkout", {
      deviceSessionId: "getDeviceSessionId",
    }),
    openpayExpirationYear() {
      return this.year_month.slice(-2);
    },
    openpayExpirationMonth() {
      return this.year_month.slice(0, 2);
    },
    isOpenPay() {
      return this.provider.toLowerCase().includes("openpay");
    },
    openPay() {
      return window.OpenPay as { card: any; token: any };
    },
  },
  watch: {
    loading(newLoading) {
      this.$emit("is-loading", newLoading);
    },
  },

  mounted() {
    this.cartService = new CartService();
    this.cartService.getCartInitData();

    if (!window.OpenPay) {
      eventBus.emit("load-openpay-script");
    }

    if (!this.deviceSessionId) {
      store.dispatch("checkout/resetDeviceSessionId");
    }
  },

  methods: {
    async onAddBankCard() {
      if (!this.tokenId) {
        this.loading = true;
        await this.tokekizeCard();
        this.loading = false;
      }

      if (!this.deviceSessionId) {
        store.dispatch("checkout/resetDeviceSessionId");
      }

      const paymentMethodService = new PaymentMethodService();

      if (this.tokenId && paymentMethodService && this.deviceSessionId) {
        this.loading = true;
        paymentMethodService
          .storeTokenizablePaymentMethod(
            this.paymentMethodCode,
            this.card,
            this.tokenId,
            this.deviceSessionId,
            this.isCheckedCartItem,
            this.card_number,
            this.cvc,
            this.email,
          )
          .then((response) => {
            const data: BankCard = dataFormatter.deserialize(
              response,
            ) as BankCard;
            const cardNumber = data.payload.card_number;
            const lastThreeDigits = cardNumber.slice(-3);
            if (response) {
              if (!this.isCheckedCartItem) {
                store.commit("checkout/addPaymentTokenizableTemporaly", data);
              } else {
                store.dispatch("checkout/addPaymentTokenizable", data);
              }
              // this.$emit("update-tokenizables", this.paymentMethodCode);
              this.$emit("update-show-add-bank-card", false);
              store.commit("checkout/setPaymentTokenizable", data.id);
              this.resetForm();
              notifyService.getNotification({
                title: "¡Registro exitoso!",
                text: `La tarjeta <strong>${lastThreeDigits}</strong> fue agregada exitosamente`,
                time: 3000,
              });
            }
            this.loading = false;
            this.$emit("is-loading", false);
            this.tokenId = null;
            this.card = null;
          })
          .catch((err: AxiosError) => {
            this.tokenId = null;
            this.card = null;

            this.loading = false;
            this.$emit("is-loading", false);
            console.log("Error:", err);
            notifyService.getNotification({
              type: "error",
              title: `Ocurrio un error al guardar su tarjeta`,
              text: errorsToString(err),
              time: 3000,
            });
          });
      } else if (!this.tokenId) {
        notifyService.getNotification({
          title: "Tenemos problemas con procesar su tarjeta",
          text: "Falta valor de tokenId, favor de recargar la página",
          type: "error",
        });
      } else if (!paymentMethodService) {
        notifyService.getNotification({
          title: "Tenemos problemas con procesar su tarjeta",
          text: "Falta método de pago, favor de recargar la página",
          type: "error",
        });
      } else if (!this.deviceSessionId) {
        notifyService.getNotification({
          title: "Tenemos problemas con procesar su tarjeta",
          text: "Falta deviceSessionId, favor de recargar la página",
          type: "error",
        });
      }
    },
    async validateOpenpayCardNumber(value: string) {
      if (this.openPay) {
        const valid = await this.openPay.card.validateCardNumber(value);
        return valid || this.$t("Card number is invalid or is not supported.");
      }
    },
    async validateOpenpayCcv(value: string) {
      if (this.openPay) {
        const valid = this.card_number
          ? await this.openPay.card.validateCVC(value, this.card_number)
          : await this.openPay.card.validateCVC(value);
        return valid || this.$t("CCV is invalid.");
      }
    },
    async validateOpenpayExpiry(expiry: string) {
      if (this.openPay && expiry && expiry.length >= 5) {
        const year = "20" + this.openpayExpirationYear;
        const month = this.openpayExpirationMonth;
        const valid = await this.openPay.card.validateExpiry(month, year);
        return valid || this.$t("Expiry is invalid.");
      }
      return true;
    },
    formatYearMonth(event: KeyboardEvent) {
      if (this.year_month.length == 2 && event.key != "Backspace") {
        this.year_month = this.year_month + "/";
      }
    },
    async tokekizeCard() {
      try {
        this.tokenizationError = false;
        // Make a request by Openpay library to get the card's token with form data.
        const result: unknown = await new Promise((resolve, reject) => {
          this.openPay.token.extractFormAndCreate(
            "main-form",
            (r: unknown) => resolve(r),
            (e: unknown) => reject(e),
          );
        });
        if (typeof result === "object" && result !== null && "data" in result) {
          const data = (result as { data: { id: string; card: unknown } }).data;
          this.tokenId = data.id;
          this.card = data.card;
        }
      } catch (err) {
        const error = err as { data: { description: string } };
        notifyService.getNotification({
          title: "Tenemos problemas con procesar su tarjeta",
          text: error.data.description,
          type: "error",
        });
        // Something wrong occurred to get the token.
        // this.tokenizationError = this.$lang('error.openpay_general_error');
      }
    },
    closeForm() {
      this.resetForm();
      this.$emit("update-show-add-bank-card", false);
    },
  },
};
</script>
