import Login from "@/interfaces/Login.js";
import UserRegister from "@/interfaces/UserRegister.js";
import { store } from "@/store/index.js";
import axios, { AxiosError, AxiosRequestConfig } from "axios";
import Jsona from "jsona";
import {
  VITE_CLIENT_ID,
  VITE_CLIENT_PASSWORD,
  VITE_API_ENDPOINT,
} from "@/config/constants.js";
import { ResetPassword } from "@/interfaces/ResetPassword.js";
import { IUserLogged } from "@/interfaces/IUserLogged.js";
import Customer from "@/models/Customer.js";

const dataFormatter = new Jsona();

export class AuthService {
  async register(user: UserRegister) {
    try {
      const response = await axios.post(`/eshop/register`, user);

      if (response.data) {
        const userData: IUserLogged = dataFormatter.deserialize(
          response.data,
        ) as IUserLogged;
        store.commit("auth/setAccessToken", userData.token);
        store.commit("auth/setCustomer", userData);
        store.commit("auth/setIsUserAuthenticated", true);
      }

      return response.data;
    } catch (error) {
      if (error instanceof AxiosError && error.response) {
        return error.response.data;
      } else {
        console.error("Error desconocido durante el registro", error);
        throw new Error("Ocurrió un error durante el registro.");
      }
    }
  }

  async login(user: Login) {
    const productsInAssembly = store.getters["assembly/getFormatedProducts"];
    // const productsInCart = localStorage.getItem("productsId");
    // const parsedProducts = productsInCart ? JSON.parse(productsInCart) : [];
    const parsedProducts = store.getters["cart/getLocalProductInCart"];
    const data = {
      grant_type: "password",
      client_id: VITE_CLIENT_ID,
      client_secret: VITE_CLIENT_PASSWORD,
      username: user.email,
      password: user.password,
      scope: "",
      shopping_cart: parsedProducts,
      assembly_products: productsInAssembly,
    };
    const options: AxiosRequestConfig = {
      baseURL: VITE_API_ENDPOINT,
      headers: {
        "Access-Control-Allow-Origin": "*",
        Accept: "application/json",
      },
    };

    try {
      const response = await axios.post(`/eshop/login`, data, options);
      const userData: IUserLogged = dataFormatter.deserialize(
        response.data,
      ) as IUserLogged;
      if (userData.token) {
        store.commit("auth/setAccessToken", userData.token);
        store.commit("auth/setIsUserAuthenticated", true);
        store.commit("auth/setCustomer", userData);
        store.commit("assembly/resetLists");
        store.commit("cart/clearLocalStorageProducts");

        // localStorage.removeItem("products");
        // localStorage.removeItem("meta");
        // localStorage.removeItem("productsId");
      }

      return userData;
    } catch (error) {
      if (error instanceof AxiosError && error.response) {
        return error.response.data;
      } else {
        console.error("Error desconocido", error);
        throw new Error("Ocurrió un error al intentar iniciar sesión.");
      }
    }
  }

  public getUser() {
    return store.getters["auth/getCustomer"];
  }

  public isUserLogged() {
    return !!this.getUser();
  }

  async session() {
    if (store.getters.getToken) {
      const response = await axios.get(`/eshop/session`);
      return response.data;
    }
  }

  public clearSessionStorage() {
    localStorage.removeItem("token");
    store.commit("auth/setAccessToken", null);
    store.commit("auth/setCustomer", null);
    store.commit("auth/setIsUserAuthenticated", false);
  }

  public async logout() {
    let success = false;
    try {
      await axios.post(`/eshop/logout`);
      store.commit("cart/resetCart");
      store.dispatch("checkout/resetCheckout", true);
      success = true;
    } catch (err) {
      if (err instanceof AxiosError) {
        if (err?.response?.status == 401) {
          success = true;
        }
      }
      throw err;
    } finally {
      if (success) {
        this.clearSessionStorage();
        window.location.href = "/";
      }
    }
  }

  public async getAutenticatedCustomer(forceRemoteInfo: boolean = false) {
    const customer = store.getters["auth/getCustomer"];
    if (customer && !forceRemoteInfo) {
      return customer;
    }
    try {
      const response = await axios.get(`eshop/autenticated-customer`);
      if (response.status == 200) {
        const data = dataFormatter.deserialize(response.data);
        store.commit("auth/setCustomer", data);
        return data;
      } else {
        store.commit("auth/setCustomer", null);
        return [];
      }
    } catch (error) {
      store.commit("auth/setCustomer", null);
      console.error("Error en la solicitud del cliente autenticado:", error); // Maneja el error
    }
  }

  public async getLoginInitData() {
    const translations = store.getters["translations/getSection"]("login");
    if (translations) {
      return translations;
    }
    const response = await axios.get(`/eshop/login/init-data`);
    if (response) {
      if (
        response.data &&
        response.data.meta &&
        response.data.meta.translations
      ) {
        store.commit("translations/setSection", {
          login: response.data.meta.translations,
        });
      }
      return response.data;
    }
  }

  public async getRegisterInitData() {
    const translations = store.getters["translations/getSection"]("register");
    if (translations) {
      return translations;
    }
    const response = await axios.get(`eshop/register/init-data`);
    if (response) {
      if (
        response.data &&
        response.data.meta &&
        response.data.meta.translations
      ) {
        store.commit("translations/setSection", {
          register: response.data.meta.translations,
        });
      }
      return response.data;
    }
  }

  async sendPasswordRecoveryLink(email: string) {
    const options: AxiosRequestConfig = {
      baseURL: VITE_API_ENDPOINT,
      headers: {
        Accept: "application/vnd.api+json",
      },
    };
    const url = "/password/send-email";
    const response = await axios.post(url, { email }, options);
    return response;
    // if (!(response instanceof AxiosError)) {
    //   const data = dataFormatter.deserialize(response.data);
    //   return data;
    // } else if (response) {
    //   return response;
    // } else {
    //   return null;
    // }
  }

  async resetPassword(data: ResetPassword) {
    console.log(data);
    const url = "/password/reset";
    const response = await axios.post(url, data);
    return response;
  }

  private static tokenPromise: Promise<string | null> | null = null;
  private token: string | null = null;

  public async getToken(): Promise<string | null> {
    if (AuthService.tokenPromise) {
      await AuthService.tokenPromise;
    }
    const token: string | null = store.getters["auth/getAccessToken"];
    if (token) {
      return token;
    }
    return await this.requestToken();
  }

  public async requestToken(): Promise<string | null> {
    if (AuthService.tokenPromise) {
      return AuthService.tokenPromise;
    }
    const data = {
      grant_type: "client_credentials",
      client_id: VITE_CLIENT_ID,
      client_secret: VITE_CLIENT_PASSWORD,
      scope: "",
    };
    const options: AxiosRequestConfig = {
      baseURL: VITE_API_ENDPOINT,
      headers: {
        "Access-Control-Allow-Origin": "*",
        Accept: "application/json",
      },
    };
    this.token = null;
    try {
      AuthService.tokenPromise = new Promise((resolve, reject) => {
        (async () => {
          try {
            const req = await axios.post(`/oauth/token`, data, options);
            const token = req.data.access_token;
            resolve(token);
          } catch (err) {
            reject(err);
          }
        })();
      });
      this.token = await AuthService.tokenPromise;
    } catch (err) {
      console.error(err);
    } finally {
      AuthService.tokenPromise = null;
      store.commit("auth/setAccessToken", this.token);
    }
    return this.token;
  }

  public async self(): Promise<Customer> {
    const res = await axios.get("/shop-v1/customers/-actions/self");
    const customer = new Customer(res.data);
    return customer;
  }

  public async isCustomerTokenUnauthorized(): Promise<boolean> {
    if (this.isUserLogged()) {
      try {
        await this.self();
      } catch (err) {
        if (err instanceof AxiosError) {
          if (err.response) {
            return err.response.status === 401;
          }
        }
      }
    }
    return false;
  }
}

const auth = new AuthService();

export default auth;
