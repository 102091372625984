<template lang="">
  <div
    v-if="checkedCheckbox"
    class="mb-2"
    :class="{ 'last-product-detail': isLast - 1 && !showCheckbox }"
  >
    <div class="d-flex flex-column justify-content-between" :class="shadow">
      <div class="row gx-1 mb-md-0 mb-lg-2 box-item">
        <div v-if="showInProductDetail" class="col-2 d-flex align-items-center">
          <change-quantity-item
            :product="product"
            :quantity="quantity"
            :item-loading-state="itemLoadingStates"
            :margin-left="true"
            :disabled="disabled"
            :loading="loading"
            @decrement="decrementQty"
            @increment="incrementQty"
            @set-qty="setQty"
          />
        </div>

        <div class="col-3 col-lg-2 d-flex align-items-center">
          <div
            v-if="showCheckbox"
            class="checkbox-cart-item px-1 d-none d-lg-block"
          >
            <input
              :id="'checkbox-cart-item' + product.id"
              :value="
                product.attributes?.id ? product.attributes.id : product.id
              "
              type="checkbox"
              :checked="checkedCheckbox"
              :disabled="disabled"
              @click="remove(product)"
            />
            <label :for="'checkbox-cart-item' + product.id" />
          </div>

          <div class="my-3 mt-lg-2 mb-0" :class="borderImg">
            <image-product
              :image="smallCoverUrl"
              :max-height="100"
              class="img-cart"
            />
          </div>
        </div>

        <div
          class="col-8"
          :class="{
            'w-50': !showCheckbox && !showAppliesProduct,
            'col-lg-6': showAppliesProduct,
            'col-lg-7': !showAppliesProduct,
          }"
        >
          <base-link
            :to="product.links.self"
            class="name-parent-element mt-3"
            :class="{ 'mt-lg-5': showPriceStepOneSmall && showCheckbox }"
          >
            <p class="item-name pt-3 px-lg-0 pe-sm-5 ps-lg-1 col-md-auto mb-2">
              {{
                formattedProductName(
                  product.attributes?.name
                    ? product.attributes.name
                    : product.name,
                )
              }}
            </p>
          </base-link>
          <div
            v-if="showPriceStepOne || showPriceStepOneSmall"
            class="mt-2 pt-1 mt-xl-0 d-lg-flex align-items-center"
            :class="{ 'd-lg-none': showPriceStepOneSmall }"
            style="width: 270px"
          >
            <span class="promotion text-secondary me-3 px-1 d-none d-lg-block">
              {{ getTitle("cart", "promotion") }}
            </span>
            <span class="me-3 d-lg-none cash-promotion mt-5">
              {{ getTitle("cart", "cash_promotion") }}
            </span>
            <span
              v-if="product.price_detail.promotion_id"
              :class="colorPriceWithTax"
              v-html="product?.promotion_price_with_tax_text"
            />
            <span
              v-else-if="product.mwi_one_disc_percent > 0"
              :class="colorPriceWithTax"
              v-html="product?.common_price_with_tax_text"
            />
            <span
              v-else
              :class="colorPriceWithTax"
              v-html="product?.common_price_with_tax_text"
            />

            <span class="unit-name text-lowercase">
              &nbsp; /
              {{
                product.attributes?.unit_name
                  ? product.attributes.unit_name
                  : product.unit_name
              }}
            </span>
          </div>

          <div v-if="showAppliesProduct" class="row d-none d-lg-block">
            <div class="d-flex">
              <p class="small mt-3 me-5 mb-1 text-secondary d-none d-lg-block">
                {{ getTitle("cart", "applies_to_this_product") }}
              </p>
            </div>
            <div class="ps-4 w-50 cart-item-promotion">
              <product-promotion-list-promotion :product="product" />
            </div>
            <div class="row d-none d-lg-block">
              <div class="d-flex small justify-content-between mt-3 mb-2">
                <a class="text-blue text-decoration-none pointer">
                  {{ getTitle("cart", "view_similar_products") }}
                </a>
                <a
                  class="text-blue text-decoration-none pointer pointer"
                  :class="{ 'active-favorite': product.isFavorite }"
                  @click="toggleFavorite(product)"
                >
                  {{
                    product.isFavorite
                      ? getTitle("cart", "remove_from_wish_list")
                      : getTitle("cart", "save_to_wish_ist")
                  }}
                </a>
                <a
                  class="text-blue text-decoration-none d-none d-lg-block pointer"
                >
                  {{ getTitle("cart", "compare_this_product") }}
                </a>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="showAvailableIn20Min"
          class="col-12 col-lg-1 d-flex align-items-center justify-content-end"
        >
          <p
            v-if="
              (product.available_in_20_minutes &&
                product.available_in_20_minutes != 0) ||
              (product?.attributes?.available_in_20_minutes &&
                product?.attributes?.available_in_20_minutes != 0)
            "
            class="available-in-20-min d-none d-lg-block text-end me-3 text-nowrap"
          >
            {{ getTitle("cart", "available_in_20_minutes") }}
          </p>
        </div>
        <div
          class="col-12"
          :class="{
            'col-lg-2': !showCheckbox,
            'col-lg-3 d-none d-lg-block': showCheckbox,
          }"
        >
          <div class="row h-100 mt-1">
            <div
              v-if="showCheckbox"
              class="col-6 border-light border-start border-end"
            >
              <div class="d-flex flex-column align-items-center mt-5">
                <p v-if="showAppliesProduct" class="pieces text-center pt-4">
                  {{ getTitle("cart", "pieces") }}
                </p>
                <div class="d-flex align-items-center">
                  <div class="d-flex border border-light">
                    <change-quantity-item
                      :product="product"
                      :quantity="quantity"
                      :item-loading-state="itemLoadingStates"
                      change-quantity-item
                      :disabled="disabled"
                      :loading="loading"
                      @decrement="decrementQty"
                      @increment="incrementQty"
                      @set-qty="setQty"
                    />
                  </div>
                </div>
                <small
                  v-if="showAvailablePieces"
                  class="text-lowercase mt-1 text-secondary d-xl-block"
                >
                  {{
                    product.attributes?.stock_total_fixed
                      ? product.attributes.stock_total_fixed
                      : product.stock_total_fixed
                  }}
                  {{ getTitle("cart", "available") }}
                </small>
              </div>
            </div>

            <div
              class="col-6"
              :class="{ 'd-flex align-items-center': !showCheckbox }"
            >
              <div
                v-if="showLinkRemove"
                class="row text-end d-flex justify-content-end"
              >
                <div class="d-none d-lg-block text-lowercase mt-1 me-2">
                  <link-remove-cart-item
                    :product-id="
                      product.attributes?.id
                        ? product.attributes.id
                        : product.id
                    "
                    :link-text="getTitle('cart', 'remove')"
                    :disabled="disabled"
                    @click="
                      removeItem(
                        product.attributes?.id
                          ? product.attributes.id
                          : product.id,
                      )
                    "
                  />
                </div>
              </div>

              <div
                class="row text-end"
                :class="{
                  'mt-5': showAppliesProduct,
                  'mt-3': !showAppliesProduct,
                }"
              >
                <div class="col-12">
                  <div class="d-none d-lg-block d-flex">
                    <span
                      v-if="
                        isNaN(
                          product.attributes?.total_by_item
                            ? product.attributes.total_by_item
                            : product.total_by_item,
                        )
                      "
                      class="me-2"
                      :class="colorPriceWithTax"
                      v-html="product?.total_by_item_text"
                    />
                    <span
                      v-else
                      class="me-2"
                      :class="colorPriceWithTax"
                      v-html="product?.total_by_item_text"
                    />
                    <p v-if="showPieces" class="my-1 text-lowercase me-2">
                      {{ quantity ? quantity : 0 }}
                      {{
                        quantity > 1
                          ? product.unit_name + "s"
                          : product.unit_name
                      }}
                      <!-- {{ quantity > 1 ? product.attributes?.unit_name + 's' : product.attributes?.unit_name }} -->
                    </p>
                    <div
                      v-if="
                        product?.promotion_quantity > 0 &&
                        product?.common_quantity > 0
                      "
                      class="my-2 d-flex flex-row justify-content-end"
                    >
                      <div class="px-2">
                        <div
                          class="border-top border-light-subtle p-1 d-flex justify-content-between"
                        >
                          <small class="text-secondary">
                            {{ product?.promotion_quantity }} x
                            <span
                              v-html="product?.promotion_price_with_tax_text"
                            />
                            <tool-tip
                              class="text-success mx-1"
                              :title="
                                getTitleOptions(
                                  'cart',
                                  'limit_quantity_promotion',
                                  { ':count': product?.promotion_quantity },
                                )
                              "
                            >
                              *
                            </tool-tip>
                          </small>
                        </div>
                        <div
                          v-if="product?.common_quantity > 0"
                          class="border-top border-light-subtle p-1 d-flex justify-content-between"
                        >
                          <small class="text-secondary">
                            {{ product?.common_quantity }} x
                            <span
                              v-html="product?.common_price_with_tax_text"
                            />
                          </small>
                        </div>
                      </div>
                    </div>
                    <p
                      v-if="showVatIncluded"
                      class="vat-included text-lowercase me-2"
                      :class="
                        !showAppliesProduct
                          ? colorTextWithTax
                          : colorTextWithTax
                      "
                    >
                      {{ getTitle("cart", "vat_included") }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="showAppliesProduct" class="row d-md-block d-lg-none">
        <div class="container px-3 text-center">
          <div class="row gx-3">
            <div class="col-8">
              <div class="ps-3 w-100 cart-item-promotion">
                <product-promotion-list-promotion :product="product" />
              </div>
            </div>
            <div class="col-4">
              <change-quantity-item
                :product="product"
                :quantity="quantity"
                :item-loading-state="itemLoadingStates"
                :max-height="'36px'"
                :disabled="disabled"
                :loading="loading"
                @decrement="decrementQty"
                @increment="incrementQty"
                @set-qty="setQty"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        class="d-flex justify-content-center align-items-center d-md-block d-lg-none px-1 mt-1"
      >
        <div v-if="showFavoritesAndSimilar" class="w-100 cart-line" />
      </div>
      <div class="row d-md-block d-lg-none">
        <div class="d-flex">
          <div
            class="p-2 flex-grow-1 text-start"
            :class="{ 'remove-review-small': !showFavoritesAndSimilar }"
          >
            <link-remove-cart-item
              :product-id="
                product.attributes?.id ? product.attributes.id : product.id
              "
              :link-text="getTitle('cart', 'remove')"
              :disabled="disabled"
              @click="
                removeItem(
                  product.attributes?.id ? product.attributes.id : product.id,
                )
              "
            />
          </div>
          <div v-if="showFavoritesAndSimilar" class="d-flex">
            <div class="p-2">
              <a class="text-secondary text-decoration-none text-small">
                {{ getTitle("cart", "view_similar_products") }}
              </a>
            </div>
            <div class="p-2">
              <a
                class="text-secondary text-decoration-none text-small"
                @click="toggleFavorite(product)"
              >
                {{ getTitle("cart", "save_to_wish_ist") }}
              </a>
            </div>
          </div>
          <div v-if="!showFavoritesAndSimilar" class="my-1 me-2">
            <change-quantity-item
              :product="product"
              :quantity="quantity"
              :item-loading-state="itemLoadingStates"
              :max-height="'28px'"
              :disabled="disabled"
              :loading="loading"
              @decrement="decrementQty"
              @increment="incrementQty"
              @set-qty="setQty"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <transition name="modal">
      <div v-if="isOpen" class="modal-container">
        <button
          class="undo-button text-white undo-button-text"
          @click="undoChanges"
        >
          {{ getTitle("cart", "undo_changes") }}
        </button>
      </div>
    </transition>
  </div>
</template>
<script lang="ts">
import ProductPromotionListPromotion from "@/components/common/cart/ProductPromotionListPromotion.vue";
import ChangeQuantityItem from "@/components/cart/ChangeQuantityItem.vue";
import LinkRemoveCartItem from "@/components/cart/LinkRemoveCartItem.vue";
import ImageProduct from "../ui/ImageProduct.vue";
import BaseLink from "../buttons/BaseLink.vue";
import ToolTip from "@/components/utils/TooltipLink.vue";

import { CartService } from "@/services/CartService";

import {
  getTitleTranslate,
  getTitleTranslateWithOptions,
  formatPrice,
} from "../../utils/general";
import { MetaCart } from "@/interfaces/MetaCart";
import { ItemCart } from "@/interfaces/ItemCart";
import eventBus from "@/event-bus";
import { store } from "@/store";
import { debounce } from "lodash";
import auth from "@/services/AuthService";

export default {
  components: {
    ImageProduct,
    ProductPromotionListPromotion,
    ChangeQuantityItem,
    LinkRemoveCartItem,
    BaseLink,
    ToolTip,
  },
  props: {
    product: {
      type: Object,
      default: null,
    },
    quantity: {
      type: Number,
      default: 0,
    },
    showCheckbox: {
      type: Boolean,
      default: true,
    },
    isLast: {
      type: Boolean,
      default: false,
    },
    showInProductDetail: {
      type: Boolean,
      default: false,
    },
    shadow: {
      type: String,
      default: "",
    },
    borderImg: {
      type: String,
      default: "",
    },
    showAppliesProduct: {
      type: Boolean,
      default: false,
    },
    showPriceStepOne: {
      type: Boolean,
      default: false,
    },
    showPriceStepOneSmall: {
      type: Boolean,
      default: false,
    },
    showFavoritesAndSimilar: {
      type: Boolean,
      default: false,
    },
    showLinkRemove: {
      type: Boolean,
      default: true,
    },
    showVatIncluded: {
      type: Boolean,
      default: true,
    },
    colorPriceWithTax: {
      type: String,
      default: "",
    },
    colorTextWithTax: {
      type: String,
      default: "",
    },
    showAvailableIn20Min: {
      type: Boolean,
      default: false,
    },
    showAvailablePieces: {
      type: Boolean,
      default: false,
    },
    showPieces: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    "remove-item",
    "toggle-favorite",
    "update-item-quantity",
    "update-cart-details",
  ],
  data: function () {
    return {
      itemLoadingStates: false as boolean,
      debounceSetQty: undefined as any,

      products: [] as any | null,
      meta: null as MetaCart | null,
      loading: false,
      isCheckedCartItem: true,
      cartService: null as CartService | null,
      isOpen: false,
      selectedProductId: 0,
      removedItem: null,
      removedItemIndex: null,
      checkedCheckbox: true,
      timeOutId: 0 as any,
    };
  },
  computed: {
    isUserAuthenticated() {
      return auth.isUserLogged();
    },
    localStorageProducts() {
      return store.getters["cart/getLocalStorageProducts"];
    },
    smallCoverUrl(): string {
      if (this.product.medium_cover_url) {
        return this.product.medium_cover_url;
      }
      return "/images/imagen-no-disponible.png";
    },
  },
  mounted() {
    this.cartService = new CartService();
    this.cartService.getCartInitData();
  },
  created() {
    // store.commit('cart/localStorageProducts');
    this.debounceSetQty = debounce(this.setQty, 100, {
      leading: true, // Run call inmediatly if there are not more calls.
      trailing: true, // Run last call when timeout is done.
    });
  },
  methods: {
    getTitle(section: string, name: string) {
      return getTitleTranslate(section, name);
    },
    getTitleOptions(section: string, name: string, options: unknown) {
      return getTitleTranslateWithOptions(section, name, options);
    },
    toggleFavorite(product: ItemCart) {
      this.$emit("toggle-favorite", product);
    },
    removeItem(productId: number) {
      this.selectedProductId = productId;

      if (this.isUserAuthenticated && this.cartService) {
        this.cartService
          .removeItem(productId)
          .then((res) => {
            if (res.meta.status === "success") {
              // eventBus.emit("update-cart-total-items", this.products.length - 1);
              this.getCartDetails();
            }
          })
          .catch((res) => {
            console.log("Error al eliminar el producto.");
            console.log(res);
            this.getCartDetails();
          });
      } else {
        store.dispatch("cart/removeFromCart", { productId });
        store.commit("cart/removeProductInLocal", productId);
        this.getCartDetails();
      }
    },
    setQty: function (product: ItemCart, qty: number) {
      const qtyFixed =
        !qty || qty <= 0
          ? parseFloat(product.attributes.step.toString())
          : parseFloat(qty.toString());
      let productQuantity = this.quantity;
      if (productQuantity === qtyFixed) {
        return;
      }
      const oldQty = productQuantity;
      productQuantity = qtyFixed;
      this.loading = true;
      if (this.isUserAuthenticated && this.cartService) {
        this.cartService
          .updateQty(product, qtyFixed)
          .then((response) => {
            if (response.meta.status === "success") {
              // this.$emit('update-item-quantity')
              this.getCartDetails();
            } else {
              productQuantity = oldQty;
            }
            this.loading = false;
          })
          .catch((res) => {
            console.log("Error al eliminar el producto.");
            console.log(res);
            productQuantity = oldQty;
            this.loading = false;
          });
      } else {
        let productId = product.id;
        let newQuantity = qtyFixed;
        store.dispatch("cart/updateProductQuantityLocal", {
          productId,
          newQuantity,
        });
        this.loading = false;
      }
    },
    incrementQty() {
      let productQuantity = this.quantity;
      let productStep = this.product.step
        ? this.product.step
        : this.product.attributes.step;
      this.debounceSetQty(
        this.product,
        (productQuantity as number) + (productStep as number),
        "add",
      );
    },
    decrementQty(product: ItemCart) {
      let productQuantity = this.quantity;
      let productStep = product.step ? product.step : product.attributes.step;
      this.debounceSetQty(
        product,
        (productQuantity as number) - (productStep as number),
        "subtract",
      );
    },
    getCartDetails() {
      eventBus.emit("update-cart-details", this.product.id);
    },
    remove(item: ItemCart) {
      this.isOpen = true;
      this.checkedCheckbox = false;
      this.timeOutId = setTimeout(() => {
        //Si no deshacen la eliminacion del producto, a los 5 segundo se elimina y se cierra el modal automaticamente
        this.removeItem(item.id as number);
        this.isOpen = false;
      }, 5000);

      // if (this.isUserAuthenticated && this.cartService) {
      //   const i = this.products.indexOf(item);
      //   this.selectedProductId = item.id as number;

      //   if (i > -1) {
      //     this.removedItemIndex = i;
      //     this.removedItem = this.products.splice(i, 1)[0];
      //     setTimeout(() => {
      //       //Si no deshacen la eliminacion del producto, a los 5 segundo se elimina y se cierra el modal automaticamente
      //       this.removeItem(item.id as number);
      //       this.isOpen = false;
      //     }, 5000);
      //   }
      // } else {
      //   let qty = 1;
      //   const productId = item.id;
      //   setTimeout(() => {
      //     store.commit("cart/removeFromCart", { productId, qty });
      //     this.isOpen = false;
      //   }, 5000);
      // }
      // this.getCartDetails();
    },
    isSelectedProduct(productId: number) {
      return this.products
        ? this.products.find(
            (product: ItemCart) => product.id === productId,
          ) !== undefined
        : false;
    },
    undoChanges() {
      this.isOpen = false;
      this.checkedCheckbox = true;
      if (this.timeOutId) {
        clearTimeout(this.timeOutId);
      }
      // if (this.removedItem) {
      //   this.products.splice(this.removedItemIndex, 0, this.removedItem);
      //   this.removedItem = null;
      //   this.removedItemIndex = null;
      // }
      // this.getCartDetails();
    },
    formattedProductName(productName: string): string {
      if (typeof productName === "string" && productName.trim().length > 0) {
        return this.capitalizeFirstLetter(productName);
      }
      return "";
    },
    capitalizeFirstLetter(str: string): string {
      if (typeof str !== "string") {
        console.error("Expected a string but received:", str);
        return "";
      }
      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    },
    formattedPrice(price: number) {
      return formatPrice(price);
    },
  },
};
</script>
<style lang=""></style>
