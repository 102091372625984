<template lang="">
  <nav
    class="navbar navbar-expand fixed-bottom bg-white d-block d-lg-none print px-1 vw-100"
    style="z-index: 1000"
  >
    <div class="position-relative vw-100">
      <ul
        ref="navListEl"
        class="navbar-nav height-mobile-menu d-flex justify-content-between align-items-center"
      >
        <li
          v-for="(button, index) in buttons"
          :key="index"
          class="nav-item"
          :style="{ width: getButtonWidth(button) + '%' }"
        >
          <button
            type="button"
            class="nav-link py-1 px-3 w-100"
            :class="{ 'text-white': isButtonActive(button) }"
            aria-current="page"
            @click="pushButtonRoute(button)"
          >
            <i
              v-if="!isButtonActive(button)"
              class="bi fs-1 me-2"
              :class="button.icon"
            />
            <div
              v-else
              class="d-flex align-items-center justify-content-center"
            >
              <i class="bi fs-1 me-2" :class="button.icon_active" />
              <span class="small fw-semibold">
                {{ button.title }}
              </span>
            </div>
          </button>
        </li>
      </ul>
      <div
        v-if="getPositionActive >= 0"
        class="h-100 bg-danger position-absolute px-2 top-0 start-0 rounded-pill pill"
        :style="{
          width: 40 + '%',
          transform: 'translate(' + getPositionActive * translateX + 'px)',
          zIndex: '-1',
        }"
      />
    </div>
  </nav>
</template>
<script lang="ts">
import { ref } from "vue";
import eventBus from "@/event-bus";
import mainSearch from "@/services/MainSearchService";

enum ButtonCode {
  HomeButton,
  CategoryButton,
  SearchButton,
  FavoriteButton,
  MyAccountButton,
}

interface ButtonInfo {
  title: string;
  route: string | (() => string);
  icon: string;
  icon_active: string;
  code: ButtonCode;
  matchedRouteNames: string[] | undefined[];
}

export default {
  emits: ["show-input-search"],
  setup() {
    mainSearch.getPrevLocalPath();
    const buttons: ButtonInfo[] = [
      {
        title: "Inicio",
        route: "/",
        icon: "bi-house-door",
        icon_active: "bi-house-door-fill",
        code: ButtonCode.HomeButton,
        matchedRouteNames: [undefined],
      },
      {
        title: "Categorías",
        route: "/busqueda-por-categoria",
        icon: "bi-grid",
        icon_active: "bi-grid-fill",
        code: ButtonCode.CategoryButton,
        matchedRouteNames: ["search_categories"],
      },
      {
        title: "Buscar",
        route: () => mainSearch.getPrevLocalPath() || "/buscar",
        icon: "bi-search",
        icon_active: "bi-search",
        code: ButtonCode.SearchButton,
        matchedRouteNames: ["search", "search_categories"],
      },
      // OCULTO HASTA QUE FUNCIONE
      // {
      //   title: "Favoritos",
      //   route: "/favoritos",
      //   icon: "bi-heart",
      //   icon_active: "bi-heart-fill",
      //   code: ButtonCode.FavoriteButton,
      //   matchedRouteNames: ["category"],
      // },
      {
        title: "Mi cuenta",
        route: "/perfil",
        icon: "bi-person",
        icon_active: "bi-person-circle",
        code: ButtonCode.MyAccountButton,
        matchedRouteNames: ["profile"],
      },
    ];

    const navListEl = ref<HTMLElement | null>(null);

    return {
      buttons,
      navListEl,
    };
  },
  data: function () {
    return {
      divWidth: 0,
    };
  },
  computed: {
    getPositionActive() {
      return this.buttons.findIndex((button) => {
        return this.isButtonActive(button);
      });
    },
    translateX() {
      const totalRoutes = this.buttons.length + 1;
      return this.divWidth / totalRoutes;
    },
  },
  mounted() {
    this.updateViewportDimensions();
    window.addEventListener("resize", this.updateViewportDimensions);
  },
  unmounted() {
    window.removeEventListener("resize", this.updateViewportDimensions);
  },
  methods: {
    isButtonActive(button: ButtonInfo) {
      return (
        (this.$route.name !== "search_categories" ||
          (button.code === ButtonCode.CategoryButton &&
            !this.$route.query.isMobileMainSearch) ||
          (button.code === ButtonCode.SearchButton &&
            this.$route.query.isMobileMainSearch)) &&
        ((typeof button.route === "function" ? button.route() : button.route) ==
          this.$route.path ||
          button.matchedRouteNames.find(
            (routeName) => routeName === this.$route.name,
          ))
      );
    },
    getButtonWidth(button: ButtonInfo) {
      const totalRoutes = this.buttons.length + 1;
      return this.isButtonActive(button)
        ? (100 / totalRoutes) * 2
        : 100 / totalRoutes;
    },
    updateViewportDimensions() {
      this.divWidth = this.navListEl ? this.navListEl.offsetWidth : 0;
    },
    pushButtonRoute(button: ButtonInfo) {
      this.$router.push(
        typeof button.route === "function" ? button.route() : button.route,
      );
      eventBus.emit(
        "show-input-search",
        button.code === ButtonCode.SearchButton,
      );
    },
  },
};
</script>
<style lang="scss" scoped>
@use "@/scss/custom.scss";

.nav-link {
  transition: all 0.3s ease;
}

.pill {
  transition: all 0.3s ease;
}

.nav-link.active {
  @extend .text-white;
}
</style>
