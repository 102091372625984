<template>
  <div class="row mb-3">
    <div class="col-lg-9 col-xl-9 d-none d-lg-block ms-2">
      <div v-if="withHtml" v-html="description" />
      <div v-else>
        {{ description }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
export default {
  props: {
    description: {
      type: String,
      default: "",
    },
    withHtml: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
