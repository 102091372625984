<script lang="ts">
export default {
  props: {
    selectedProduct: {
      type: Object,
      required: true,
    },
  },
  emits: ["finalizeWarranty"],
  data() {
    return {
      comment: "",
      images: [] as File[],
      maxImages: 4,
      apiError: null,
      errors: [] as string[],
      focusOutDetails: false,
    };
  },
  computed: {
    isCommentValid() {
      return this.comment.length >= 50;
    },
    canSubmit() {
      return this.isCommentValid && this.images.length > 0;
    },
    imageSlots() {
      return Array.from(
        { length: this.maxImages },
        (_, index) => this.images[index] || null,
      );
    },
    imageUrls() {
      return this.images.map((image) => URL.createObjectURL(image));
    },
    canFinalize() {
      return this.images.length > 0 && this.comment.length >= 50;
    },
  },
  methods: {
    handleFileChange(event: Event) {
      if (event.target instanceof HTMLInputElement) {
        const file = event.target.files ? event.target.files[0] : null;
        if (file && this.images.length < this.maxImages) {
          this.images.push(file);
        }
      }
    },
    OnFocusOutDetails() {
      this.focusOutDetails = true;
    },
    removeImage(index: number) {
      this.images.splice(index, 1);
    },
    finalizeWarranty() {
      this.errors = [];

      if (this.images.length === 0) {
        this.errors.push("Debe subir al menos una imagen.");
        return;
      }

      if (this.comment.length < 50) {
        this.errors.push("El comentario debe tener al menos 50 caracteres.");
        return;
      }

      if (this.canSubmit) {
        const warrantyDetails = {
          data: {
            type: "warrantyDetails",
            attributes: {
              comment: this.comment,
              images: this.images.map((image) => ({
                name: image.name,
                type: image.type,
                size: image.size,
              })),
            },
            relationships: {
              product: {
                data: {
                  id: this.selectedProduct.id,
                  type: "product",
                },
              },
            },
          },
        };
        this.$emit("finalizeWarranty", warrantyDetails);
      }
    },
  },
};
</script>

<template>
  <div class="warranty-details">
    <h3 class="warranty-title">
      Detalles de la Garantía para {{ selectedProduct.name }}
    </h3>
    <div class="product-info">
      <img
        :src="selectedProduct.coverImage"
        class="img-fluid rounded img-order"
        style="max-height: 150px"
      />
      <p class="product-name">{{ selectedProduct.name }}</p>
      <p class="product-price">
        Costo unitario: ${{
          selectedProduct.totalPaid / selectedProduct.quantity
        }}
      </p>
    </div>
    <div class="comment-section">
      <label for="comment"
        >Añade comentarios y detalles que nos ayuden a entender el
        problema</label
      >
      <textarea
        id="comment"
        v-model="comment"
        class="comment-textarea"
        :class="{ invalid: !isCommentValid && focusOutDetails }"
        placeholder="Escribe al menos 50 caracteres..."
        @blur="OnFocusOutDetails"
      ></textarea>
      <span v-if="!isCommentValid && focusOutDetails" class="error-message"
        >El comentario debe tener al menos 50 caracteres.</span
      >
    </div>
    <div class="image-upload">
      <label>Adjunta hasta 4 imágenes</label>
      <div class="image-slots">
        <div
          v-for="(image, index) in imageSlots"
          :key="index"
          class="image-slot"
        >
          <div v-if="!image" class="upload-placeholder">
            <input type="file" accept="image/*" @change="handleFileChange" />
            <span class="plus-sign">+</span>
          </div>
          <div v-else class="image-thumbnail">
            <img :src="imageUrls[index]" :alt="'Imagen ' + (index + 1)" />
            <button class="remove-button" @click="removeImage(index)">
              Eliminar
            </button>
          </div>
        </div>
      </div>
    </div>

    <ul v-if="errors.length" class="error-messages">
      <li v-for="(error, index) in errors" :key="index">{{ error }}</li>
    </ul>

    <button
      :disabled="!canSubmit"
      class="finalize-button"
      @click="finalizeWarranty"
    >
      Finalizar
    </button>
  </div>
</template>

<style scoped>
.warranty-details {
  margin-top: 20px;
}

.warranty-title {
  font-weight: bold;
  margin-bottom: 10px;
}

.product-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.product-name {
  font-size: 18px;
  margin-top: 10px;
}

.product-price {
  font-weight: bold;
  color: #007185;
  margin-top: 5px;
}

.comment-section {
  margin-bottom: 20px;
}

.comment-textarea {
  width: 100%;
  min-height: 80px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 14px;
}

.comment-textarea.invalid {
  border-color: red;
}

.error-message {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}

.image-upload {
  margin-bottom: 20px;
}

.image-slots {
  display: flex;
  gap: 10px;
}

.image-slot {
  width: 70px;
  height: 70px;
  border: 1px solid #ddd;
  border-radius: 5px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f9f9f9;
}

.upload-placeholder {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.upload-placeholder input {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.plus-sign {
  font-size: 24px;
  color: #bbb;
}

.image-thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.remove-button {
  position: absolute;
  top: 2px;
  right: 2px;
  background: rgba(255, 255, 255, 0.8);
  border: none;
  padding: 2px 5px;
  cursor: pointer;
  font-size: 10px;
  color: red;
  border-radius: 3px;
}

.finalize-button {
  background-color: #f0c14b;
  border: 1px solid;
  padding: 8px 16px;
  border-color: #a88734 #9c7e31 #846a29;
  color: #111;
  cursor: pointer;
}

.finalize-button:disabled {
  background-color: #ccc;
  cursor: default;
}

.error-messages {
  color: red;
  list-style-type: none;
  padding: 0;
}
</style>
