<template lang="">
  <div class="container payment-method-actived accordion-active review mt-lg-4">
    <div class="row">
      <div class="col-8 d-none d-lg-block">
        <description-shopping-cart
          :description="getTitle('cart', 'full_detail_of_my_order')"
          :with-html="false"
          class="title-accordion-option mt-2 mt-lg-4"
        />
        <p class="ubtitle-payment-methods ms-2">
          {{
            getTitle(
              "cart",
              "download_or_share_your_quote_in_a_dinamic_pdf_file",
            )
          }}
        </p>
      </div>
      <div class="col-4 description-totals mt-2 mt-lg-4 d-none d-xl-block">
        <invoice-order-checkbox
          :id="'rfc-modal'"
          :text-label="getTitle('cart', 'invoice_order')"
          :on="getTitle('cart', 'on')"
          :off="getTitle('cart', 'off')"
          class="float-end"
          :is-checked-from-out="isChecked"
        />
      </div>
    </div>
    <div v-if="meta && meta.monthly_payment && meta.monthly_payment.groups">
      <div
        v-for="(monthlyPayment, index) in meta.monthly_payment.groups"
        :key="index"
      >
        <div
          class="d-flex flex-column border border-light-subtle rounded p-3 gap-2 mb-2"
        >
          <div
            v-if="meta.monthly_payment.groups.length > 1"
            class="d-flex justify-content-between"
          >
            <span class="fw-semibold text-capitalize">
              {{ getTitle("cart", "charge") }} {{ index + 1 }} -
              {{ formattedPrice(monthlyPayment["current_plan"]["amount"]) }}
            </span>
            <small v-if="meta.monthly_payment.groups.length > 1">
              {{
                getTitleOptions("cart", "distributed_offset_amount_detail", {
                  ":amount": formattedPrice(
                    monthlyPayment["current_plan"]["offset_amount"],
                  ),
                })
              }}
            </small>
          </div>
          <span
            v-if="monthlyPayment['current_plan']['months'] > 1"
            class="text-success"
          >
            {{
              getTitleOptions("cart", "option_selected_plan_any", {
                ":months": monthlyPayment["current_plan"]["months"],
                ":price": formattedPrice(
                  monthlyPayment["current_plan"]["price"],
                ),
                ":asterisc": "",
                ":items": monthlyPayment["items"].length,
              })
            }}
          </span>
          <span v-else>
            {{
              getTitleOptions("cart", "option_unselected_plan_any", {
                ":price": formattedPrice(
                  monthlyPayment["current_plan"]["price"],
                ),
                ":asterisc": "",
                ":items": monthlyPayment["items"].length,
              })
            }}
          </span>
        </div>
        <div
          v-for="(product, indexProduct) in monthlyPayment['items']"
          :key="indexProduct"
        >
          <product-cart-item
            v-if="getProduct(product)"
            :show-checkbox="showCheckbox"
            :is-last="index === products.length - 1 && !showCheckbox"
            :show-in-product-detail="showInProductDetail"
            :product="getProduct(product).product"
            :quantity="getProduct(product).quantity"
            :shadow="'border-item-cart-review'"
            :border-img="'short-border-img-cart'"
            :show-applies-product="false"
            :show-price-step-one="false"
            :show-price-step-one-small="true"
            :show-favorites-and-similar="false"
            :color-price-with-tax="'price-with-tax-red'"
            :color-text-with-tax="'vat-included-red'"
            :show-available-in-20-min="false"
            :show-available-pieces="true"
            :show-pieces="false"
            :disabled="true"
          />
        </div>
      </div>
    </div>
    <loading :loading="loading" />
  </div>
  <!-- PAYMENT INFO -->
  <div
    class="container payment-method-actived accordion-active review mt-lg-4"
    :style="{ height: 'auto !important' }"
  >
    <div class="row">
      <div class="col-12 col-md-8">
        <description-shopping-cart
          :description="getTitle('cart', 'payment_information')"
          :with-html="false"
          class="title-accordion-option mt-2 mt-lg-4"
        />
      </div>
      <div
        class="col-12 col-md-4 d-flex justify-content-end align-items-center"
      >
        <router-link
          :to="{ name: 'cart-payment' }"
          class="btn btn-link text-decoration-none text-blue"
        >
          <i class="bi bi-pencil-square mx-1" />
          {{ getTitle("cart", "btn_change_payment") }}
        </router-link>
      </div>
    </div>
    <div
      v-if="selectedTokenizable"
      class="row d-flex flex-row justify-content-start align-items-center p-3"
    >
      <div
        class="col-12 col-md-3 d-flex flex-column justify-content-center align-items-center"
      >
        <img
          :style="{ height: '35px' }"
          :src="
            selectedTokenizable?.icon_url
              ? selectedTokenizable?.icon_url
              : methodPayment?.presentation_icon_url
          "
          :alt="methodPayment.presentation_name"
        />
        <small class="mt-1 fw-semibold">
          {{ methodPayment.presentation_name }}
        </small>
      </div>
      <div class="col-12 col-md-9">
        <strong>{{ selectedTokenizable.reference }}</strong>
        <p v-if="selectedTokenizable?.payload?.expiration_month">
          {{ selectedTokenizable?.payload?.expiration_month }} /
          {{ selectedTokenizable?.payload?.expiration_year }}
          {{ selectedTokenizable?.payload?.bank_name }}
        </p>
        <p>{{ selectedTokenizable?.payload?.holder_name }}</p>
        <div v-if="!isValidCVC" class="d-flex flex-row w-100">
          <label for="cvc" class="col-form-label me-2 fw-semibold">
            Código de seguridad:
          </label>
          <input
            ref="cvcField"
            v-model="cvc"
            name="cvc"
            type="password"
            class="form-control form-control-sm"
            :class="{ 'is-invalid': cvc.toString().length < 3 }"
            placeholder="CVV"
            max-lenght="4"
            autocomplete="off"
            :style="{ width: '70px' }"
            @change="updateCvc"
          />
        </div>
      </div>
    </div>
    <div
      v-else
      class="row d-flex flex-row justify-content-start align-items-center p-3"
    >
      <div
        class="col-12 col-md-3 d-flex flex-column justify-content-center align-items-center"
      >
        <img
          :style="{ height: '35px' }"
          :src="methodPayment?.presentation_icon_url"
          :alt="methodPayment?.presentation_icon_url"
        />
        <div class="fw-semibold mx-2 mt-1">
          {{ methodPayment?.presentation_name }}
        </div>
      </div>
      <div class="col-12 col-md-9" />
    </div>
  </div>
  <!-- SHIPPING INFO -->
  <div
    class="container payment-method-actived accordion-active review mt-lg-4"
    :style="{ height: 'auto !important' }"
  >
    <div class="row">
      <div class="col-12 col-md-8">
        <description-shopping-cart
          :description="getTitle('cart', 'shipment_information')"
          :with-html="false"
          class="title-accordion-option mt-2 mt-lg-4"
        />
      </div>
      <div
        class="col-12 col-md-4 d-flex justify-content-end align-items-center"
      >
        <router-link
          :to="{ name: 'cart-shipping' }"
          class="btn btn-link text-decoration-none text-blue"
        >
          <i class="bi bi-pencil-square mx-1" />
          {{ getTitle("cart", "btn_change_shipping") }}
        </router-link>
      </div>
    </div>
    <div v-if="isShipping" class="d-flex flex-column flex-md-row p-3">
      <div
        class="col-12 col-md-3 d-flex flex-column justify-content-center align-items-center mb-2 mb-md-0"
      >
        <img
          class="w-auto mx-auto"
          :src="courier?.image_url"
          :style="{ height: '30px' }"
          @error="setImageNotAvailable($event)"
        />
        <h4 class="fw-bold mt-2 mb-0">
          {{ courier?.name }}
        </h4>
        <small>{{ courier?.delivery_time_text }}</small>
      </div>
      <div class="col-12 col-md-9">
        <h4 class="fw-bold">
          {{ address?.name }}
        </h4>
        <p class="mb-2 text-capitalize">
          {{ address?.formatted_address }}
        </p>
        <h5 class="fw-semibold mt-3 mb-1">
          {{ getTitle("cart", "Receives") }}:
        </h5>
        <p class="mb-2 text-capitalize">
          {{ address?.person_first_name }} {{ address?.person_last_name }}
          {{ address?.person_mother_last_name }}
        </p>
        <p class="mb-2 text-capitalize">
          {{ address?.telephone }}
        </p>
      </div>
    </div>
    <div v-else class="d-flex flex-column flex-md-row p-3">
      <div
        class="col-12 col-md-3 d-flex flex-column justify-content-center align-items-center"
      >
        <i class="bi bi-map" :style="{ fontSize: '40px' }" />
        <small class="mt-1 fw-semibold">
          {{ getTitle("cart", "show_map") }}
        </small>
      </div>
      <div class="col-12 col-md-9">
        <h4 class="fw-bold">
          {{ branch?.name }}
        </h4>
        <p>{{ branch?.formatted_address }}</p>
        <h5 class="fw-semibold mt-3 mb-1">
          {{ getTitle("cart", "Receives") }}:
        </h5>
        <p class="mb-2 text-capitalize">
          {{ pickupPerson?.name }} {{ pickupPerson?.lastName }}
          {{ pickupPerson?.motherLastName }}
        </p>
        <p class="mb-2 text-capitalize">
          {{ pickupPerson?.telephone }}
        </p>
      </div>
    </div>
  </div>
  <!-- BILLING INFO -->
  <div
    class="container payment-method-actived accordion-active review mt-2 mt-lg-4"
    :style="{ height: 'auto !important' }"
  >
    <div class="row">
      <div class="col-12 col-md-8">
        <description-shopping-cart
          :description="getTitle('cart', 'billing_information')"
          :with-html="false"
          class="title-accordion-option mt-2 mt-lg-4"
        />
      </div>
      <div class="col-12 col-md-4 d-flex justify-content-end">
        <button
          class="btn btn-link text-decoration-none text-blue"
          @click="showRfcModal"
        >
          <i class="bi bi-pencil-square mx-1" />
          {{ getTitle("cart", "btn_change_billing") }}
        </button>
      </div>
    </div>
    <div class="row">
      <div v-if="billingAddress" class="d-flex flex-column flex-md-row p-3">
        <div
          class="col-12 col-md-3 d-flex flex-column justify-content-center align-items-center"
        >
          <i class="bi bi-file-text" :style="{ fontSize: '40px' }" />
        </div>
        <div class="col-12 col-md-9">
          <h4 class="fw-bold">
            {{ billingAddress?.name }}
          </h4>
          <h5 class="my-3 fw-semibold">
            {{ billingAddress?.rfc }} - {{ billingAddress?.business_name }}
          </h5>
          <p>
            <strong>{{ getTitle("cart", "address") }}:</strong>
            {{ billingAddress?.formatted_address }}
          </p>
          <p>
            <strong>{{ getTitle("cart", "telephone") }}:</strong>
            {{ billingAddress?.telephone }}
          </p>
          <p>
            <strong>{{ getTitle("cart", "email") }}:</strong>
            {{ billingAddress?.email }}
          </p>
          <p>
            <strong>{{ getTitle("cart", "tax_regime") }}:</strong>
            {{ billingAddress?.cfdiRegime?.id }} -
            {{ billingAddress?.cfdiRegime?.name }}
          </p>
          <p>
            <strong>{{ getTitle("cart", "invoice_use") }}:</strong>
            {{ billingAddress?.cfdiUse?.id }} -
            {{ billingAddress?.cfdiUse?.name }}
          </p>
        </div>
      </div>
      <h5 v-else class="text-secondary text-center py-3">
        {{ getTitle("cart", "label_not_billing") }}
      </h5>
    </div>
  </div>
</template>
<script lang="ts">
import { CartService } from "@/services/CartService";
import {
  getTitleTranslate,
  getTitleTranslateWithOptions,
  formatPrice,
  errorsToString,
} from "../../utils/general";
import ProductCartItem from "@/components/cart/ProductCartItem.vue";
import DescriptionShoppingCart from "./DescriptionShoppingCart.vue";
import InvoiceOrderCheckbox from "../common/InvoiceOrderCheckbox.vue";
import Loading from "../ui/Loading.vue";
import eventBus from "@/event-bus";
import { mapGetters } from "vuex";
import { store } from "@/store";
import Jsona from "jsona";
import auth from "@/services/AuthService";
import { CustomNotifyService } from "@/services/CustomNotifyService";
import router from "@/router";
import { ref } from "vue";
import { AxiosError, AxiosResponse } from "axios";
import { JsonApiResponse } from "@/interfaces/JsonApiResponse";
import { ProductInCart } from "@/interfaces/ProductInCart";

const dataFormatter = new Jsona();
const notifyService = new CustomNotifyService();

interface MetaCart {
  monthly_payment: {
    groups: Array<Array<string | number | null>>;
  };
}

export default {
  components: {
    DescriptionShoppingCart,
    InvoiceOrderCheckbox,
    ProductCartItem,
    Loading,
  },
  emits: ["show-rfc-modal"],
  setup() {
    const cvcField = ref<HTMLElement | null>(null);
    return {
      cvcField,
    };
  },
  data: function () {
    return {
      isChecked: false,
      products: [] as ProductInCart[] | null,
      loading: false,
      meta: null as MetaCart | null,

      showCheckbox: true,
      showInProductDetail: false,
      cvc: "",
    };
  },
  computed: {
    ...mapGetters("checkout", {
      selectedTokenizable: "getSelectedPaymentTokenizable",
      methodPayment: "getPaymentMethod",
      methodPaymentCode: "getPaymentMethodCode",
      isShipping: "getIsShipping",
      address: "getCustomerAddress",
      branch: "getStore",
      courier: "getCourier",
      pickupPerson: "getPickupPerson",
      billingAddress: "getBillingCustomerAddress",
      isValidCVC: "isValidCVC",
    }),
    imageNotAvailable() {
      return store.getters["getImageNotAvailable"];
    },
    isUserAuthenticated() {
      return auth.isUserLogged();
    },
  },
  created() {
    let cartService = new CartService();
    store.commit("checkout/setIsLoading", true);
    cartService
      .validateReview()
      .then((response: AxiosResponse) => {
        this.getCartDetails();
        console.log(response);
      })
      .catch((response) => {
        const resp = response.response;
        if (resp.data.errors) {
          let first = resp.data.errors[0];
          if (first && first.meta.redirectTo) {
            notifyService.getNotification({
              title: "Tenemos diferencias en el carrito",
              text:
                first.detail ??
                "Algunos datos no coinciden, favor de seleccionarlos nuevamente.",
              time: 5000,
              type: "error",
            });
            router.push({ name: first.meta.redirectTo });
          } else {
            notifyService.getNotification({
              type: "error",
              title: `Tenemos diferencias en el carrito`,
              text: errorsToString(response),
              time: 5000,
            });
          }
        } else {
          notifyService.getNotification({
            type: "error",
            title: `Tenemos diferencias en el carrito`,
            text: errorsToString(response),
            time: 5000,
          });
        }
        store.commit("checkout/setIsLoading", false);
      });
  },
  mounted() {
    store.commit("checkout/setCvcValidate", "");
    this.cvc = "";
    if (this.cvcField) {
      this.cvcField.focus();
    }
  },
  methods: {
    getTitle(section: string, name: string) {
      return getTitleTranslate(section, name);
    },
    getTitleOptions(section: string, name: string, options: unknown) {
      return getTitleTranslateWithOptions(section, name, options);
    },
    setImageNotAvailable(event: Event) {
      if (event.target instanceof HTMLInputElement) {
        event.target.src = this.imageNotAvailable;
      }
    },
    showRfcModal() {
      eventBus.emit("show-rfc-modal", true);
    },
    getCartDetails() {
      const cartService = new CartService();
      if (this.isUserAuthenticated && cartService) {
        this.loading = true;
        store.commit("checkout/setIsLoading", true);
        cartService
          .cartDetails(false)
          .then((response: AxiosResponse<JsonApiResponse>) => {
            if (response && response.data) {
              const productsResponse = dataFormatter.deserialize(
                response.data,
              ) as ProductInCart[];
              this.meta = response.data.meta as MetaCart;
              store.commit(
                "cart/setMonthlyPaymentGroups",
                this.meta?.monthly_payment?.groups,
              );
              this.products = productsResponse;
              store.commit("cart/setItems", this.products);
              this.loading = false;
            }
          })
          .catch((reason: AxiosError) => {
            console.log(reason);
            store.commit("cart/setItems", []);
            notifyService.getNotification({
              type: "error",
              title: `Tenemos problemas para cargar tus productos, por favor intentalo nuevamente`,
              text: errorsToString(reason),
              time: 5000,
            });
          })
          .finally(() => {
            store.commit("checkout/setIsLoading", false);
          });
      }
    },
    formattedPrice(price: number) {
      return formatPrice(price);
    },
    getProduct(id: string) {
      if (this.products) {
        return this.products.find((item: ProductInCart) => {
          return item.id == id;
        });
      }
      return null;
    },
    updateCvc() {
      store.commit("checkout/setCvcValidate", this.cvc);
    },
  },
};
</script>
