// define your typings for the store state
export interface StateAuth {
  accessToken: string | null;
  csrfToken: string;
  count: number;
  isUserAuthenticated: boolean;
  customer: any;
  msjErrorNetwork: null;
}

const state = () => ({
  accessToken: null,
  csrfToken: null,
  count: 1,
  isUserAuthenticated: false,
  customer: null,
  zipCode: null,
  address: [],
  msjErrorNetwork: "" as string,
});

// mutations
const mutations = {
  setAccessToken(state: StateAuth, newToken: string | null) {
    if (newToken) {
      localStorage.setItem("accessToken", newToken);
    } else {
      localStorage.removeItem("accessToken");
    }
    state.accessToken = newToken;
  },
  increment(state: StateAuth) {
    state.count++;
  },
  setCsrfToken(state: StateAuth, newToken: string) {
    state.csrfToken = newToken;
  },
  setIsUserAuthenticated(state: StateAuth, is: boolean) {
    state.isUserAuthenticated = is;
  },
  setCustomer(state: StateAuth, newCustomer: string) {
    localStorage.setItem("customer", JSON.stringify(newCustomer));
    state.customer = newCustomer;
  },
  setErrorNetwork(state: StateAuth, error: any) {
    state.msjErrorNetwork = error;
  },

  clearErrorNetwork(state: StateAuth) {
    state.msjErrorNetwork = null;
  },
};

const getters = {
  getAccessToken(state: StateAuth) {
    const token = localStorage.getItem("accessToken");
    state.accessToken = token || null;
    return state.accessToken;
  },
  getCustomer(state: StateAuth) {
    const customerStr = localStorage.getItem("customer");
    const customer = customerStr ? JSON.parse(customerStr) : null;
    state.customer = customer;
    return state.customer;
  },
  getIsUserAuthenticated(state: StateAuth) {
    return state.isUserAuthenticated;
  },
  getErrorNetwork(state: StateAuth) {
    return state.msjErrorNetwork;
  },
};

export const auth = {
  namespaced: true,
  state,
  mutations,
  getters,
};
