<script lang="ts">
import { ref, onMounted, computed, nextTick } from "vue";
import Dropdown from "v-dropdown";
import menuProfileData from "@/interfaces/menu-profile";
import { useStore } from "@/store";
import auth from "@/services/AuthService";
import BaseLink from "@/components/buttons/BaseLink.vue";
import loggedInUserImage from "@/assets/images/auth/customer-group-1.png";
import { MenuProfileItem } from "@/interfaces/MenuProfileItem";
import { DropdownInstance } from "@/interfaces/DropdownInstance";

export default {
  components: {
    Dropdown,
    BaseLink,
  },
  setup() {
    const dropdownActive = ref(false);
    const activeUrl = ref("");
    const menuProfile = ref<MenuProfileItem[]>([]);
    const dropdownManual = ref<DropdownInstance | null>(null);
    const store = useStore();
    const dataCustomer = computed(() => store.getters["auth/getCustomer"]);
    onMounted(() => {
      menuProfile.value = menuProfileData as MenuProfileItem[];
    });

    const closeDropdown = () => {
      if (dropdownManual.value) {
        dropdownManual.value.close();
      }
    };

    const onLogout = async () => {
      try {
        await auth.logout();
      } catch (error) {
        console.error(error);
      }
    };

    const change = async (val: boolean) => {
      dropdownActive.value = val;

      if (val) {
        await nextTick();
        const dropdown = document.querySelectorAll(
          ".v-dropdown-container",
        )[1] as HTMLElement;

        if (dropdown && window.innerWidth <= 576) {
          dropdown.style.left = "0";
          dropdown.style.right = "0";
          dropdown.style.width = "100vw";
        }
      }
    };

    const activePath = (to: string, name: string) => {
      localStorage.setItem(
        "activeBreadcrumbOrder",
        JSON.stringify({ to: to, name: name }),
      );
      activeUrl.value = to;
      closeDropdown();
    };

    return {
      dropdownActive,
      activeUrl,
      menuProfile,
      loggedInUserImage,
      dropdownManual,
      dataCustomer,
      onLogout,
      change,
      activePath,
      closeDropdown,
    };
  },
};
</script>
<template>
  <div>
    <Dropdown ref="dropdownManual" align="right" @visible-change="change">
      <template #trigger>
        <button
          type="button"
          class="animate-hover-border py-1 px-0 position-relative d-flex justify-content-center align-items-center h-100 box-search-bar btn-dropdown"
          :class="{
            'bg-danger text-white': dropdownActive,
            'no-active': !dropdownActive,
          }"
        >
          <div class="d-flex flex-column align-items-center">
            <i class="bi bi-person-circle d-lg-none fs-4"></i>
            <img
              alt="Usuario logeado"
              :src="loggedInUserImage"
              class="img-user-profile d-none d-lg-block"
            />
            <div class="d-flex justify-content-center">
              <p class="fs-7 d-none d-lg-block name m-0 p-0">
                Hola <span class="fw-semibold">{{ dataCustomer.name }}</span>
              </p>
              <p class="fs-7 d-lg-none name m-0 p-0">
                <span class="fw-semibold">{{ dataCustomer.name }}</span>
              </p>
            </div>
            <div
              class="title-box w-100 text-nowrap text-uppercase d-none d-lg-block"
              :class="{
                'text-white': dropdownActive,
                'text-danger': !dropdownActive,
              }"
            >
              Mi cuenta
            </div>
          </div>
        </button>
      </template>
      <ul class="list-unstyled px-3 pt-4 container-dropdown-profile">
        <li
          v-for="(item, index) in menuProfile"
          :key="index"
          class="pointer mb-3"
        >
          <base-link
            :class="{ 'text-blue': $route.path === '/perfil/' + item.to }"
            class="d-flex ps-2 pe-4 align-items-center title"
            :to="'/perfil/' + item.to"
            @click="activePath(item.to, item.name)"
          >
            <i v-if="item.icon" class="icon" :class="item.icon" />
            <p class="ms-3 p-0 m-0 text-nowrap">{{ item.name }}</p>
          </base-link>
        </li>
      </ul>
      <div class="pointer d-flex bg-light px-4 on-logout" @click="onLogout()">
        <div
          class="content d-flex align-items-center justify-content-center flex-column"
        >
          <i class="bi bi-power fw-semibold icon"></i>
          <p class="text-center mt-1 fw-semibold">Salir</p>
        </div>
      </div>
    </Dropdown>
  </div>
</template>
<style lang="scss" scoped>
@use "@/scss/base/mixins" as mix;
@use "@/scss/_variables" as var;

.container-dropdown-profile {
  .title {
    color: var(--bs-dark);
    @include mix.custom-font(
      $normal-font: var.$normal-font-300,
      $size: 17,
      $line-height: 17
    );
    &:hover {
      color: var(--bs-blue);
    }
    @include mix.extra-extra-large-screen {
      @include mix.custom-font(
        $normal-font: var.$normal-font-300,
        $size: 15,
        $line-height: 15
      );
    }
  }

  .icon {
    font-size: 21px;
    @include mix.extra-extra-large-screen {
      font-size: 19px;
    }
  }
}

.on-logout {
  .content {
    color: var(--bs-dark);

    &:hover {
      color: var(--bs-red);
    }
    p {
      @include mix.custom-font(
        $normal-font: var.$normal-font-300,
        $size: 17,
        $line-height: 17
      );

      @include mix.extra-extra-large-screen {
        @include mix.custom-font(
          $normal-font: var.$normal-font-300,
          $size: 15,
          $line-height: 15
        );
      }
    }
  }
}

.btn-dropdown {
  background-color: transparent;
  @media (min-width: 992px) {
    background-color: var(--bs-white);
  }

  .name {
    width: 6ch;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 10px;
    @media (min-width: 480px) {
      width: 20ch;
    }
    @media (min-width: 768px) {
      font-size: 12px;
    }
    @media (min-width: 992px) {
      display: block;
      width: 100px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  &.no-active {
    color: var(--bs-white);

    @media (min-width: 992px) {
      color: var(--bs-secondary);
    }
  }

  .img-user-profile {
    @include mix.width-and-height(28, 28);
  }
}
</style>
