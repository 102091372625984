import axios, { AxiosError, AxiosResponse } from "axios";
import { store } from "@/store";
export class PaymentMethodService {
  public async getPaymentMehods() {
    let queryParams = {};
    const trackingId = store.getters["checkout/getTrackingId"];
    if (trackingId) {
      queryParams = {
        tracking_id: trackingId,
      };
    }

    const response = await axios.get("/eshop/payment-methods", {
      params: queryParams,
    });
    if (response) {
      return response.data;
    }
  }

  public async getPaymentMehod(paymentMethodCode: string) {
    let queryParams = {};
    const trackingId = store.getters["checkout/getTrackingId"];
    if (trackingId) {
      queryParams = {
        tracking_id: trackingId,
      };
    }

    const url = `/eshop/payment-methods/${encodeURIComponent(paymentMethodCode)}`;
    const response = await axios.get(url, {
      params: queryParams,
    });
    if (response) {
      return response.data;
    }
  }

  public async deletePaymentMehod(
    paymentMethodCode: string,
    tokenizableId: string,
  ) {
    let queryParams = {};
    const trackingId = store.getters["checkout/getTrackingId"];
    if (trackingId) {
      queryParams = {
        tracking_id: trackingId,
      };
    }
    const url = `/eshop/payment-methods/${encodeURIComponent(paymentMethodCode)}/${encodeURIComponent(tokenizableId)}`;
    const response = await axios.delete(url, {
      params: queryParams,
    });
    return response;
  }

  public async storeTokenizablePaymentMethod(
    paymentMethodCode: string,
    card: any,
    tokenId: string,
    deviceSessionId: string,
    saveCard: boolean,
    cardNumber: string,
    cvc: string,
    email: string,
  ) {
    const body = {
      data: {
        type: "payment-methods",
        attributes: {
          ...card,
          token_id: tokenId,
          device_session_id: deviceSessionId,
          save_card: saveCard,
          card_number_complete: cardNumber,
          cvc: cvc,
          email: email,
        },
      },
    };

    const trackingId = store.getters["checkout/getTrackingId"];
    let queryParams = {};
    if (trackingId) {
      queryParams = {
        tracking_id: trackingId,
      };
    }

    const url = `/eshop/payment-methods/${encodeURIComponent(paymentMethodCode)}/tokenizable`;
    const response = await axios.post(url, body, {
      params: queryParams,
    });
    if (!(response instanceof AxiosError)) {
      return response.data;
    } else if (response.response) {
      const resp: AxiosResponse = response.response;
      return resp.data;
    } else {
      return null;
    }
  }

  public async getMonthlyPayment(
    paymentMethodCode: string,
    tokenizableId: string | null,
  ) {
    const trackingId = store.getters["checkout/getTrackingId"];
    const selectedTonkenizable =
      store.getters["checkout/getSelectedPaymentTokenizable"];

    let queryParams = {};

    let url = `/eshop/payment-methods/monthly-plans/${encodeURIComponent(paymentMethodCode)}`;
    if (tokenizableId) {
      url += `/${encodeURIComponent(tokenizableId)}`;
    }
    if (trackingId && paymentMethodCode.includes("paypal")) {
      queryParams = {
        tracking_id: trackingId,
        tokenizable: selectedTonkenizable?.payment_tokenizable_encoded,
      };
    }
    if (selectedTonkenizable?.payment_tokenizable_encoded) {
      queryParams = {
        ...queryParams,
        tokenizable: selectedTonkenizable?.payment_tokenizable_encoded,
      };
    }

    const response = await axios.get(url, {
      params: queryParams,
    });
    if (response) {
      return response.data;
    }
  }

  public async storeMonthlyPayment() {
    const selectedPlans = store.getters["checkout/getMonthlyPlans"];
    const paymentMethodCode = store.getters["checkout/getPaymentMethodCode"];
    const tokenizableId = store.getters["checkout/getPaymentTokenizable"];
    const body = {
      monthly_payment_option_plans: selectedPlans,
    };

    let url = `/eshop/payment-methods/monthly-plans/${encodeURIComponent(paymentMethodCode)}`;
    if (tokenizableId) {
      url += `/${encodeURIComponent(tokenizableId)}`;
    }
    const response = await axios.post(url, body);
    if (!(response instanceof AxiosError)) {
      return response.data;
    } else if (response.response) {
      const resp: AxiosResponse = response.response;
      return resp.data;
    } else {
      return null;
    }
  }
}
