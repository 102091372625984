<template>
  <div class="container-address">
    <div class="address-in-profile pointer">
      <div v-if="loading" class="row">
        <div v-for="quantity in 4" :key="quantity" class="col-12 col-md-6">
          <address-in-profile-placeholder />
        </div>
      </div>
      <div
        v-if="!showAddAddressForm"
        class="w-100 d-flex justify-content-lg-end align-items-center mb-3 mt-2"
      >
        <base-link
          class="btn-add-address-profile border-0 rounded-pill d-flex justify-content-between align-items-center px-2 px-sm-3"
          :to="'/perfil/direcciones/crear'"
          @click="showAddressForm()"
        >
          <span>Agregar nueva direccion</span>
          <i class="bi bi-plus-circle" />
        </base-link>
      </div>
      <no-added-data-yet
        v-if="items.length === 0 && !loading"
        :text="'Aún no agregas una dirección.'"
      />

      <div v-else-if="!loading">
        <div v-if="!showAddAddressForm" class="row g-3">
          <div v-for="item in items" :key="item.id" class="col-12 col-lg-6">
            <div class="card pt-2">
              <div
                class="card-header d-flex justify-content-between align-items-center text-secondary border-0 border-bottom border-light bg-white"
              >
                <p class="py-2 m-0 title">{{ item.name }}</p>
                <p class="py-2 m-0 small">
                  <span
                    v-if="item.is_delivery"
                    class="is-delivery me-2 fw-semibold"
                  >
                    Para entrega
                  </span>
                  <span v-if="item.is_billing" class="text-blue fw-semibold">
                    Para facturación
                  </span>
                </p>
              </div>

              <div class="card-body mb-0 pb-0 text-secondary">
                <div class="row">
                  <div class="col-10">
                    <p class="m-0 p-0 formatted-address">
                      {{ item.formatted_address }}
                    </p>
                    <div class="mt-3 text-secondary">
                      <p class="m-0 p-0 receives">Recibe:</p>
                      <p class="mt-1">
                        {{ item.person_first_name }} {{ item.person_last_name }}
                        {{ item.person_mother_last_name }}
                      </p>
                    </div>
                    <div class="m-0 p-0">
                      <p class="m-0 p-0 phone">Teléfono:</p>
                      <p class="mt-1">{{ item.telephone }}</p>
                    </div>
                  </div>
                  <div class="col-2 d-flex align-items-center">
                    <div class="d-flex flex-column align-items-center">
                      <button
                        class="button edit px-2 py-1"
                        @click="editOrDeleteOtion(item, 'update')"
                      >
                        <i class="bi bi-pencil-square icon red"></i>
                      </button>
                      <button
                        class="button remove px-2 py-1"
                        @click="selectAddressToDelete(item.id, item.name)"
                      >
                        <i class="bi bi-trash-fill icon green"></i>
                      </button>
                    </div>
                  </div>
                </div>
                <div v-if="item.is_billing" class="row">
                  <div class="col-6">
                    <p class="m-0 p-0 phone">RFC:</p>
                    <p class="mt-1">{{ item.rfc }}</p>
                  </div>
                  <div class="col-6">
                    <p class="m-0 p-0 phone">Email para recibir la factura:</p>
                    <p class="mt-1">{{ item.email }}</p>
                  </div>
                  <div class="col-12">
                    <p class="m-0 p-0 phone">Uso CFDI:</p>
                    <p class="mt-1">{{ item.cfdi_use_code }}</p>
                  </div>
                  <div class="col-12">
                    <p class="m-0 p-0 phone">Regimen Fiscal:</p>
                    <p class="mt-1">-falta enviarlo-</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <confirmation-modal
        v-if="showConfirmationModal"
        :modal-id="selectedAddressId"
        :modal-title="'Eliminar dirección'"
        :question="'¿Estás seguro de eliminar la dirección '"
        :name-in-question="selectedAddressName + '?'"
        :event-name="'delete-address'"
      />
      <div v-if="showAddAddressForm">
        <transition name="fade">
          <div>
            <add-address
              :selected-address="selectedAddress"
              :action="action"
              :class-profile="'profile-address'"
              :is-billing="true"
              :is-delivery="false"
              @update-addresses="updateAddresses"
            />
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { Modal } from "bootstrap";
import NoAddedDataYet from "@/components/common/NoAddedDataYet.vue";
import ConfirmationModal from "@/components/common/ConfirmationModal.vue";
import AddressInProfilePlaceholder from "@/components/profile/AddressInProfilePlaceholder.vue";
import { notify } from "@kyvg/vue3-notification";
import AddAddress from "@/components/cart/address/AddAddress.vue";
import BaseLink from "@/components/buttons/BaseLink.vue";
import eventBus from "@/event-bus";
import { CustomerAddressService } from "@/services/CustomerAddressService";
import { ZipCodeService } from "@/services/ZipCodeService";
import TabTitle from "@/interfaces/TabTitle";
import { store } from "@/store";
import { ItemDirecctions } from "@/interfaces/ItemDirecctions";
import auth from "@/services/AuthService";

const zipCodeService = new ZipCodeService();
const customerAddressService = new CustomerAddressService();

export default {
  components: {
    NoAddedDataYet,
    ConfirmationModal,
    AddressInProfilePlaceholder,
    AddAddress,
    BaseLink,
  },
  emits: ["updateAddresses"],
  data() {
    TabTitle("Direcciones");
    return {
      items: [] as ItemDirecctions[],
      deleteAddressModal: null as Modal | null,
      showConfirmationModal: false,
      selectedAddressId: "" as string,
      selectedAddressName: "" as string,
      loading: false,
      toastInstance: null,
      action: "new",
      showAddAddressForm: false,
      actualPath: "",
    };
  },
  computed: {
    isUserAuthenticated() {
      return auth.isUserLogged();
    },
    selectedAddress() {
      return store.getters["checkout/getCustomerAddress"];
    },
  },

  watch: {
    $route(newVal) {
      this.actualPath = newVal.path;
      if (this.actualPath) {
        this.updateAddresses();
      }
    },
  },

  created() {
    eventBus.on("delete-address", () => {
      this.deleteAddress(this.selectedAddressId);
    });
  },

  mounted() {
    window.scrollTo(0, 0);
    this.getAddress();
  },

  methods: {
    getAddress() {
      this.loading = true;
      if (this.isUserAuthenticated && zipCodeService) {
        zipCodeService.getAddress().then((response) => {
          if (response) {
            this.items = response;
            this.loading = false;
          }
        });
      }
    },

    selectAddressToDelete(addressId: string, addressName: string) {
      if (addressId) {
        this.selectedAddressId = addressId;
        this.selectedAddressName = addressName;
        this.showConfirmationModal = false;
        this.$nextTick(() => {
          this.showConfirmationModal = true;
        });
      }
    },

    editOrDeleteOtion(address: any, action: string) {
      this.action = action;
      this.selectAddress(address);
      this.showAddAddressForm = true;
    },

    deleteAddress(addressId: string) {
      customerAddressService
        .deleteCustomerAddress(addressId)
        .then(async (response: any) => {
          if (response && response.status == 204) {
            this.getAddress();
            notify({
              type: "success",
              title: `¡Eliminación satisfactoria!`,
              text: `¡La dirección <strong>${this.selectedAddressName}</strong> fue eliminada!`,
              duration: 3000,
              ignoreDuplicates: true,
            });
            this.showConfirmationModal = false;
          } else {
            eventBus.emit("error-message-show", { errors: response.errors });
          }
        });
    },

    selectAddress(item: any) {
      store.commit("checkout/setCustomerAddress", item);
      this.$emit("updateAddresses");
    },

    updateAddresses() {
      this.showAddAddressForm = false;
      this.getAddress();
    },

    showAddressForm() {
      this.showAddAddressForm = true;
    },
  },
};
</script>
