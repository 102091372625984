import { RouteRecordRaw } from "vue-router";

const home = () => import("../../../src/views/HomePage.vue");
const login = () => import("@/components/auth/LoginComponent.vue");
const loginOrRegister = () =>
  import("@/components/auth/LoginOrRegisterComponent.vue");
const register = () => import("@/components/auth/RegisterComponent.vue");
import SimpleLayout from "../../../src/components/layouts/SimpleLayout.vue";
import ResetPassword from "@/components/auth/ResetPassword.vue";
import ResetPasswordFromEmail from "@/components/auth/ResetPasswordFromEmail.vue";
import auth from "@/services/AuthService";
import MainLayout from "@/components/layouts/MainLayout.vue";
import {
  CUSTOMER_PERSON_TYPE_JURIDICAL,
  CUSTOMER_PERSON_TYPE_NATURAL,
} from "@/config/constants";

const AuthRoutes: RouteRecordRaw[] = [
  {
    path: "/options",
    component: loginOrRegister,
    meta: { layout: MainLayout, withFooter: true },
  },
  {
    meta: { layout: SimpleLayout, withFooter: false },
    path: "/login",
    name: "login",
    component: login,
    beforeEnter: async (_to, _from, next) => {
      if (auth.isUserLogged()) {
        return next("/");
      }
      next();
    },
  },
  {
    path: "/",
    name: "home",
    component: home,
    meta: { layout: MainLayout, withFooter: true },
  },
  {
    meta: { layout: SimpleLayout, withFooter: false },
    path: "/registrarme/:company?",
    props: (route) => {
      const personType = route.params.company
        ? CUSTOMER_PERSON_TYPE_JURIDICAL
        : CUSTOMER_PERSON_TYPE_NATURAL;
      return { personType };
    },
    component: register,
    beforeEnter: async (_to, _from, next) => {
      if (auth.isUserLogged()) {
        return next("/");
      }
      next();
    },
  },
  {
    meta: { layout: SimpleLayout, withFooter: false },
    path: "/contrasena/reestablecer",
    component: ResetPassword,
    beforeEnter: async (_to, _from, next) => {
      if (auth.isUserLogged()) {
        return next("/");
      }
      next();
    },
  },
  {
    meta: { layout: SimpleLayout, withFooter: false },
    path: "/password/reset",
    component: ResetPasswordFromEmail,
    beforeEnter: async (to, _from, next) => {
      if (auth.isUserLogged()) {
        return next("/");
      }

      const { token, email } = to.query;
      if (!token || !email) {
        return;
      }

      next();
    },
  },
];

export default AuthRoutes;
