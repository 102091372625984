<template lang="">
  <base-link
    v-element-hover="onHover"
    :to="to"
    :external="external"
    class="link-underline link-underline-opacity-0 d-flex justify-content-center align-items-center p-1 link-image"
  >
    <image-brand
      :image="src"
      :name="alt"
      :max-height="heightImage"
      :font-size="fontSize"
      :font-size-sm="fontSizeSm"
    />
  </base-link>
</template>
<script lang="ts">
import ImageBrand from "../ui/ImageBrand.vue";
import BaseLink from "./BaseLink.vue";
import { vElementHover } from "@vueuse/components";
export default {
  directives: {
    "element-hover": vElementHover,
  },
  components: {
    ImageBrand,
    BaseLink,
  },
  props: {
    src: {
      type: String,
      default: "",
      required: true,
    },
    alt: {
      type: String,
      default: "",
      required: true,
    },
    heightImage: {
      type: Number,
      default: 20,
    },
    to: {
      type: String,
      default: "/",
    },
    external: {
      type: Boolean,
      default: false,
    },
    fontSize: {
      type: String,
      default: "fs-5",
    },
    fontSizeSm: {
      type: String,
      default: "fs-9",
    },
  },
  data: function () {
    return {
      isHovered: false,
      imageError: false,
    };
  },
  methods: {
    onHover(state: boolean) {
      this.isHovered = state;
    },
  },
};
</script>
<style lang="sass" scoped></style>
