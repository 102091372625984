import { library } from "@fortawesome/fontawesome-svg-core";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons/faLocationDot";
import { faTruck } from "@fortawesome/free-solid-svg-icons/faTruck";
import { faPlus } from "@fortawesome/free-solid-svg-icons/faPlus";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons/faCircleXmark";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons/faWhatsapp";
import { faCircleQuestion } from "@fortawesome/free-solid-svg-icons/faCircleQuestion";
import { faCircleQuestion as farCircleQuestion } from "@fortawesome/free-regular-svg-icons/faCircleQuestion";
import { faQuoteRight } from "@fortawesome/free-solid-svg-icons/faQuoteRight";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons/faChevronLeft";
import { faStar } from "@fortawesome/free-regular-svg-icons/faStar";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons/faAngleDown";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons/faArrowUp";
import { faMagnifyingGlassDollar } from "@fortawesome/free-solid-svg-icons/faMagnifyingGlassDollar";
import { faFaceFrownOpen } from "@fortawesome/free-solid-svg-icons/faFaceFrownOpen";
import { faStoreSlash } from "@fortawesome/free-solid-svg-icons/faStoreSlash";
import { faPencil } from "@fortawesome/free-solid-svg-icons/faPencil";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons/faTrashCan";
import { faXmark } from "@fortawesome/free-solid-svg-icons/faXmark";
import { faCheck } from "@fortawesome/free-solid-svg-icons/faCheck";
import { faUserLock } from "@fortawesome/free-solid-svg-icons/faUserLock";
import { faGavel } from "@fortawesome/free-solid-svg-icons/faGavel";
import { faFileLines } from "@fortawesome/free-solid-svg-icons/faFileLines";
import { faBox } from "@fortawesome/free-solid-svg-icons/faBox";
import { faStore } from "@fortawesome/free-solid-svg-icons/faStore";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons/faEnvelope";
import { faArrowRotateLeft } from "@fortawesome/free-solid-svg-icons/faArrowRotateLeft";
import { faSackDollar } from "@fortawesome/free-solid-svg-icons/faSackDollar";
import { faFileInvoiceDollar } from "@fortawesome/free-solid-svg-icons/faFileInvoiceDollar";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons/faChevronRight";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons/faChevronUp";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons/faChevronDown";
import { faCalendar } from "@fortawesome/free-regular-svg-icons/faCalendar";
import { faUserTie } from "@fortawesome/free-solid-svg-icons/faUserTie";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons/faPaperPlane";

library.add(faLocationDot);
library.add(faTruck);
library.add(faPlus);
library.add(faCircleXmark);
library.add(faWhatsapp);
library.add(faCircleQuestion);
library.add(farCircleQuestion);
library.add(faQuoteRight);
library.add(faChevronLeft);
library.add(faStar);
library.add(faAngleDown);
library.add(faArrowUp);
library.add(faMagnifyingGlassDollar);
library.add(faFaceFrownOpen);
library.add(faStoreSlash);
library.add(faPencil);
library.add(faTrashCan);
library.add(faXmark);
library.add(faCheck);
library.add(faUserLock);
library.add(faGavel);
library.add(faFileLines);
library.add(faBox);
library.add(faStore);
library.add(faEnvelope);
library.add(faArrowRotateLeft);
library.add(faSackDollar);
library.add(faFileInvoiceDollar);
library.add(faChevronRight);
library.add(faChevronUp);
library.add(faChevronDown);
library.add(faCalendar);
library.add(faUserTie);
library.add(faPaperPlane);
