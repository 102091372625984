<!-- eslint-disable vue/no-v-html -->
<template lang="">
  <div
    class="d-flex flex-column flex-md-row justify-content-start align-items-start align-items-md-center gap-1 gap-lg-2 py-2"
  >
    <div class="pb-2">
      <span class="fw-bold text-nowrap fs-7 text-secondary">
        {{ getTitle("suggestions", "most_wanted") }}
      </span>
    </div>
    <div class="d-flex flex-row flex-wrap gap-2">
      <div
        v-for="mostWanted in mostWanteds"
        :key="mostWanted"
        class="border border-light-subtle rounded-pill py-1 px-2"
      >
        <base-link @click="goToProductDetail(mostWanted.links.self)">
          <span
            class="text-nowrap text-truncate fs-7 text-secondary"
            v-html="sentenceStyle(mostWanted.sentence)"
          />
        </base-link>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import eventBus from "@/event-bus";
import { getTitleTranslate, getFinalEshopUrl } from "../../utils/general";
import BaseLink from "../buttons/BaseLink.vue";
export default {
  components: {
    BaseLink,
  },
  props: {
    mostWanteds: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  emits: ["close-suggestions"],
  methods: {
    getFinalUrl(path: string) {
      return getFinalEshopUrl(path);
    },
    sentenceStyle(sentence: string) {
      let words = sentence.split(" ");
      if (words[0]) {
        words[0] = '<span class="fw-semibold">' + words[0] + "</span>";
      }
      return words.join(" ");
    },
    getTitle(section: string, name: string) {
      return getTitleTranslate(section, name);
    },
    goToProductDetail(path: string) {
      eventBus.emit("close-suggestions");
      this.$router.push(path);
    },
  },
};
</script>
<style lang=""></style>
