<script setup lang="ts">
import { ref, onMounted } from "vue";
import TabTitle from "@/interfaces/TabTitle";
import { companyService } from "@/services/CompanyService";
import { Results } from "@/interfaces/Results";

TabTitle("Preguntas frecuentes");
const results = ref<Array<Results>>([]);
const breadCrumbs = ref<{ name: string }>({ name: "" });

onMounted(async () => {
  try {
    const response = await companyService.faq();
    results.value = response.results;
    breadCrumbs.value = response.breadcrumb_links[1];
  } catch (error) {
    console.error("Error fetching questions:", error);
  }
});
</script>
<template>
  <div class="row gx-2 mt-4 mt-lg-5 frequent-questions">
    <fieldset class="d-flex flex-column">
      <legend
        class="col-12 col-lg-9 d-flex justify-content-start p-3 rounded-2"
      >
        <span class="ms-lg-2">{{ breadCrumbs.name }}</span>
      </legend>
      <div class="container">
        <div
          id="accordionPanelsStayOpenExample"
          class="description px-lg-5 mt-1 mt-lg-2 mb-5 accordion accordion-flush"
        >
          <div
            v-for="(question, index) in results"
            :key="index"
            class="accordion-item border border-light"
          >
            <h2 class="accordion-header">
              <button
                class="accordion-button bg-white d-flex align-items-center collapsed"
                type="button"
                data-bs-toggle="collapse"
                :data-bs-target="'#panelsStayOpen-collapse-' + index"
                aria-expanded="true"
                :aria-controls="'panelsStayOpen-collapse-' + index"
              >
                <i :class="'h3 text-danger'" class="bi bi-question-circle" />
                <h4 class="ms-4 text-dark question">{{ question.question }}</h4>
              </button>
            </h2>
            <div
              :id="'panelsStayOpen-collapse-' + index"
              class="accordion-collapse collapse collapse"
            >
              <div class="accordion-body d-flex align-items-center">
                <i class="bi bi-quote h3 p-0 m-0" />
                <span class="ms-4" v-html="question.answer"> </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </fieldset>
  </div>
</template>
