import ProfileInfoSection from "@/components/profile/ProfileInfoSection.vue";
import ProfileDeviceSection from "@/components/profile/ProfileDeviceSection.vue";
import ProfileWalletSection from "@/components/profile/ProfileWalletSection.vue";
import ProfileAddressSection from "@/components/profile/ProfileAddressSection.vue";
import ProfilePaymentMethodSection from "@/components/profile/ProfilePaymentMethodSection.vue";
import ProfileChatSection from "@/components/profile/ProfileChatSection.vue";
import ProfileWarrantySection from "@/views/ProfileWarrantySection.vue";
import AddAddress from "@/components/cart/address/AddAddress.vue";
import OrderList from "@/views/OrderList.vue";
import WarrantyList from "@/views/WarrantyList.vue";
import OrderDetail from "@/views/OrderDetail.vue";

const router = [
  {
    path: "pedidos",
    name: "orders",
    component: OrderList,
  },
  {
    path: "garantias/:id?",
    name: "warranties",
    component: WarrantyList,
  },
  {
    path: "informacion",
    name: "account-info",
    component: ProfileInfoSection,
  },
  {
    path: "dispositivos",
    component: ProfileDeviceSection,
  },
  {
    path: "wallet",
    component: ProfileWalletSection,
  },
  {
    path: "direcciones",
    component: ProfileAddressSection,
  },
  {
    path: "/perfil/direcciones/crear",
    component: AddAddress,
    props: {
      showAddAddress: true,
      classProfile: "profile-address",
      action: "new",
      returnToProfile: true,
    },
  },
  {
    path: "/perfil/direcciones/editar/:id",
    component: AddAddress,
    props: {
      showAddAddress: true,
      classProfile: "profile-address",
      action: "update",
      returnToProfile: true,
    },
  },
  {
    path: "metodos-pago/tarjeta",
    component: ProfilePaymentMethodSection,
  },
  {
    path: "warranty",
    component: ProfileWarrantySection,
  },
  {
    path: "/perfil/pedidos/:id/chat",
    name: "order-chat",
    component: ProfileChatSection,
    props: (route: { params: { id: string } }) => ({
      selectedOrderId: route.params.id,
    }),
  },
  {
    path: `direcciones/direcciones`,
    redirect: "/perfil/direcciones",
  },
  {
    path: `pedidos/:id`,
    name: "order-show",
    component: OrderDetail,
    props: (route: { params: { id: string } }) => ({
      selectedOrderId: route.params.id,
      showOrderDetails: true,
      order: {},
      products: [],
      showWarrantySelection: false,
      byUrl: true,
    }),
  },
];

export default router;
