import MainSearchResult, { IMainSearchResult } from "@/models/MainSearchResult";
import axios, { CanceledError } from "axios";
import { ref } from "vue";

export class MainSearchService {
  private lastResult?: MainSearchResult;
  public readonly reactiveResponse = ref<MainSearchResult>();
  private prevFilters: string[] = [];
  private prevPage?: number;
  private prevLocalPath?: string;
  private loadingPromise?: Promise<MainSearchResult>;
  private abortCtrl?: AbortController;

  public async search(
    page: number,
    filters: string[],
    pageSize: number = 15,
    randomSeed?: number,
    rawSearchText?: string,
  ): Promise<MainSearchResult> {
    // Evita cargar más si ha llegado al final.
    if (
      this.lastResult &&
      !this.lastResult.hasMore() &&
      this.prevPage &&
      page > this.prevPage
    ) {
      console.log("last result");
      return this.lastResult;
    }
    const hasNewFilters = this.hasNewFilters(filters);
    const hasNewPath = this.hasNewPath(page, filters);
    const forceLoad = hasNewFilters;
    // Evita que haya duplicación de solicitud y si es nueva ruta esperará al terminar la actual.
    if (this.loadingPromise && !hasNewPath) {
      return this.loadingPromise;
    }
    const prevAbortCtrl = this.abortCtrl;
    const promise = new Promise<MainSearchResult>((resolve, reject) => {
      (async () => {
        // Limpia los resultados actuales si cambia el filtro de la misma página.
        if (hasNewFilters) {
          this.lastResult = undefined;
        }
        const params: Record<string, string> = {
          per_page: pageSize.toString(),
        };
        if (randomSeed) {
          params.random_seed = randomSeed.toString();
        }
        if (rawSearchText) {
          params.search_text = rawSearchText;
        }
        const fixedPath =
          "/search" + this.buildSearchPath(page, filters, params);
        // Antes de solicitar nueva información se revisa si ya está en la memoría de la aplicación.
        if (this.lastResult && !forceLoad) {
          const pageLoaded = this.lastResult.getPageLoaded(page);
          if (pageLoaded) {
            this.lastResult.appendPage(page, pageLoaded);
            return resolve(this.lastResult);
          }
        }
        // Copia los filtros actuals quitando la referencia.
        this.prevFilters = filters.map((filter) => filter);
        this.prevPage = page;
        this.prevLocalPath = location.pathname + location.search;
        try {
          this.abortCtrl = new AbortController();
          const response = await axios.get<IMainSearchResult>(fixedPath, {
            signal: this.abortCtrl.signal,
          });
          if (this.lastResult && !forceLoad) {
            this.lastResult.errors = response.data.errors || [];
            this.lastResult.setMeta(response.data.meta);
            this.lastResult.appendPage(
              this.lastResult.meta.pagination.current_page,
              response.data.data,
            );
          } else {
            this.lastResult = new MainSearchResult(response.data);
          }
          this.reactiveResponse.value = this.lastResult;
        } catch (err) {
          if (err instanceof CanceledError && this.loadingPromise) {
            return resolve(await this.loadingPromise);
          }
          return reject(err);
        } finally {
          this.abortCtrl = undefined;
        }
        resolve(this.lastResult);
      })();
    });
    // Agrega la promesa pero después si ya había una solitud anterior, la cancela (revisar cómo el catch de axios devuelve la nueva promesa).
    this.loadingPromise = promise;
    if (prevAbortCtrl) {
      prevAbortCtrl.abort();
    }
    promise.finally(() => {
      this.loadingPromise = undefined;
    });
    return promise;
  }

  public buildLocalLink(apiLink: string) {
    return apiLink.replace("/search/", "/buscar/");
  }

  public buildSearchPath(
    page: number,
    filters: string[],
    queryParams?: Record<string, string>,
  ) {
    const obj = { ...queryParams };
    if (queryParams) {
      delete queryParams.page;
    }
    if (page > 0) {
      obj.page = page.toString();
    }
    const query = new URLSearchParams(obj).toString();
    const filtersStr = this.buildFiltersPath(filters);
    const fixedPath =
      (filtersStr ? "/" : "") + filtersStr + (query ? "?" : "") + query;
    return fixedPath;
  }

  public getPrevLocalPath() {
    return this.prevLocalPath;
  }

  public getPrevPage() {
    return this.prevPage;
  }

  public patchPrevFilters(filters: string[]) {
    this.prevFilters = filters;
  }

  public hasNewFilters(filters: string[]) {
    return !this.equalFilters(this.prevFilters, filters);
  }

  public hasNewPage(page: number): boolean {
    return !!this.prevPage && this.prevPage !== page;
  }

  public hasNewPath(page: number, filters: string[]) {
    return this.hasNewFilters(filters) || this.hasNewPage(page);
  }

  public buildFiltersPath(filters: string[]) {
    return filters.join("/");
  }

  /** Devuelve los filtros no vacios de una ruta y quita los parámetros del query si es el caso. */
  public getPathFilters(path: string) {
    return path
      .replace(/(.*)\?.*/, "$1")
      .split("/")
      .filter((f) => f);
  }

  public equalFilters(aFilters: string[], bFilters: string[]) {
    return this.buildFiltersPath(aFilters) === this.buildFiltersPath(bFilters);
  }

  public clear() {
    this.abortCtrl?.abort();
    this.loadingPromise = undefined;
    this.lastResult = undefined;
    this.reactiveResponse.value = undefined;
    this.prevFilters = [];
    this.prevPage = undefined;
    this.prevLocalPath = undefined;
  }

  public getFilterParts(filter: string) {
    const partRegexp = /^\/?(.+)_(.+)$/;
    const filterName = filter.replace(partRegexp, "$1");
    const options = filter.replace(partRegexp, "$2").split(".");
    return { filterName, options };
  }
}

const mainSearch = new MainSearchService();

export default mainSearch;
