// define your typings for the store state
export interface StateSession {
  zipCode: string;
  selectedAddress: StateAddress;
  address: Array<StateAddress>;
}
export interface StateAddress {
  id: number;
  name: string;
  formatted_address: string;
}

const state = () => ({
  zipCode: null,
  selectedAddress: null,
  address: [],
});

// mutations
const mutations = {
  setZipCode(state: StateSession, newZipCode: string) {
    state.zipCode = newZipCode;
  },
  setAddress(state: StateSession, newAddress: Array<StateAddress>) {
    state.address = newAddress;
  },
  setSelectedAddress(state: StateSession, newSelectedAddress: StateAddress) {
    state.selectedAddress = newSelectedAddress;
  },
};

const getters = {
  getZipCode(state: StateSession) {
    return state.zipCode;
  },
  getAddress(state: StateSession) {
    return state.address;
  },
  getSelectedAddress(state: StateSession) {
    return state.selectedAddress;
  },
};

export const session = {
  namespaced: true,
  state,
  mutations,
  getters,
};
