import { Router } from "vue-router";

export default abstract class Model {
  /** Definir para obtener enlace de búsqueda. */
  public getFilterSlug():
    | [filterName: string, filterValue: string]
    | undefined {
    return undefined;
  }

  /** Obtiene el enlace de búsqueda si getFilterSlug() está definido. */
  public resolveSearchRoute(router: Router) {
    const filter = this.getFilterSlug();
    if (filter) {
      const route = router.resolve({
        name: "search",
        params: { filters: [`${filter[0]}_${filter[1]}`] },
      });
      return route;
    }
    return undefined;
  }
}
