export interface IProductImage {
  id: string | null;
  filename: string | null;
  filename_2: string | null;
  filename_3: string | null;
  filename_4: string | null;
  filename_5: string | null;
  is_cover: boolean;
}

export default class ProductImage implements IProductImage {
  public static Sizes = class {
    public static readonly Original = 1;
    public static readonly Small = 2;
    public static readonly Medium = 3;
    public static readonly Large = 4;
    public static readonly ExtraSmall = 5;
  };

  public id: string | null = null;
  public is_cover: boolean = false;
  public filename: string | null = null;
  public filename_2: string | null = null;
  public filename_3: string | null = null;
  public filename_4: string | null = null;
  public filename_5: string | null = null;

  public constructor({
    id,
    filename,
    filename_2,
    filename_3,
    filename_4,
    filename_5,
    is_cover,
  }: IProductImage) {
    this.id = id;
    this.is_cover = is_cover;
    this.filename = filename;
    this.filename_2 = filename_2;
    this.filename_3 = filename_3;
    this.filename_4 = filename_4;
    this.filename_5 = filename_5;
  }

  public static fromArray(items: IProductImage[]): ProductImage[] {
    return items.map((item: IProductImage) => new ProductImage(item));
  }

  public static getFieldOfSize(size: number): string | null {
    if (size == ProductImage.Sizes.Original) {
      return "filename";
    }
    return `filename_${size}`;
  }

  public getImageOfSize(size: number): string | null {
    const field = ProductImage.getFieldOfSize(size);
    for (const key in this) {
      if (key == field) {
        return this[key] as string;
      }
    }
    return null;
  }

  public getThumbImage(): string | null {
    return (
      this.getImageOfSize(ProductImage.Sizes.ExtraSmall) ||
      this.getImageOfSize(ProductImage.Sizes.Small) ||
      this.getImageOfSize(ProductImage.Sizes.Medium)
    );
  }

  public getOriginalImage(): string | null {
    return this.getImageOfSize(ProductImage.Sizes.Original);
  }

  public getLargeImage(): string | null {
    return (
      this.getImageOfSize(ProductImage.Sizes.Large) ||
      this.getImageOfSize(ProductImage.Sizes.Original)
    );
  }
}
