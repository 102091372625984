<template lang="">
  <base-link
    v-element-hover="onHover"
    :to="to"
    class="animate-hover-border text-decoration-none p-2 position-relative d-flex justify-content-center align-items-center h-100 lh-1"
    :class="[
      alignIcon == 'left'
        ? 'flex-row'
        : alignIcon == 'right'
          ? 'flex-row-reverse'
          : 'flex-column',
      isHovered && colorBgHover ? colorBgHover : '',
      getActivePath ? 'link-nav-active' : '',
    ]"
  >
    <div
      v-show="icon || activeComponent"
      :class="[
        alignIcon == 'left'
          ? 'me-2'
          : alignIcon == 'right'
            ? 'ms-2'
            : 'mx-2 mb-2',
        { 'text-white': getActivePath },
      ]"
      style="width: 21px; height: 21px"
    >
      <i
        v-show="icon"
        class="fs-5 bi"
        :class="[
          isHovered && iconHover ? iconHover : icon,
          alignIcon == 'left'
            ? 'me-2'
            : alignIcon == 'right'
              ? 'ms-2'
              : 'mx-2 mb-2',
        ]"
      />
      <component
        :is="activeComponent"
        v-show="activeComponent"
        :height="21"
        :width="21"
      />
    </div>
    <div
      v-if="count >= 0"
      class="position-absolute comments bg-danger px-2_5 py-0 fs-9 rounded-start-2 rounded-bottom-2"
      :class="[getActivePath ? 'bg-white text-danger' : 'text-white']"
    >
      {{ count }}
    </div>
    <div class="text-nowrap link-search-box">
      <span
        class="ml-2 title-box d-xl-block text-center"
        :class="{ 'd-none': icon || component, 'text-white': getActivePath }"
      >
        {{ text }}
        <slot />
      </span>
    </div>
  </base-link>
</template>
<script lang="ts">
import { defineAsyncComponent } from "vue";
import { vElementHover } from "@vueuse/components";
import BaseLink from "../buttons/BaseLink.vue";
export default {
  components: {
    BaseLink,
  },
  directives: {
    "element-hover": vElementHover,
  },
  props: {
    text: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: null,
    },
    iconHover: {
      type: String,
      default: null,
    },
    colorBgHover: {
      type: String,
      default: null,
    },
    component: {
      type: String,
      default: null,
    },
    componentHover: {
      type: String,
      default: null,
    },
    alignIcon: {
      type: String,
      default: "left",
    },
    count: {
      type: Number,
      default: null,
    },
    to: {
      type: String,
      default: "/",
    },
  },
  data: function () {
    return {
      isHovered: false,
    };
  },
  computed: {
    dynamicComponent() {
      if (!this.component) return null;
      return defineAsyncComponent(
        () => import(`../icons/${this.component}.vue`),
      );
    },
    dynamicComponentHover() {
      if (!this.componentHover) return null;
      return defineAsyncComponent(
        () => import(`../icons/${this.componentHover}.vue`),
      );
    },
    activeComponent() {
      if (this.isHovered) {
        return this.dynamicComponentHover;
      }
      return this.dynamicComponent;
    },

    getActivePath() {
      return this.$route.path === this.to && this.$route.path !== "/";
    },
  },
  methods: {
    onHover(state: boolean) {
      this.isHovered = state;
    },
  },
};
</script>
<style scoped lang="sass">
.px-2_5
    padding-right: 0.75rem !important
    padding-left: 0.75rem !important

.comments
    top: 3px
    right: 5px
</style>
