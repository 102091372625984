<template>
  <li class="mb-1 mb-lg-0">
    <div class="row w-100 align-items-center g-0">
      <div class="col-11">
        <span>{{ showData ? (title ?? "") : (value ?? "-") }}</span>
        <span
          v-if="!showData"
          class="custom-badge fs-9 ms-2"
          :class="{
            'badge-turcoise': onlyValue === 'Verificado',
            'badge-red': onlyValue === 'No verificado',
          }"
        >
          {{ onlyValue }}
        </span>
      </div>
      <div class="col-1 col-lg-auto ms-auto">
        <i
          class="bi bi-pencil-fill"
          :class="{
            'text-light': disabled,
            'text-secondary pointer': !disabled,
          }"
          @click="!disabled ? showModal() : null"
        ></i>
      </div>
    </div>
  </li>

  <div :id="idModal" class="modal fade" tabindex="-1" aria-hidden="true">
    <div
      class="modal-dialog modal-dialog-centered modal-md container-confirmation-modal"
    >
      <div class="modal-content py-2">
        <div class="modal-header border-0 border-bottom border-light">
          <h5 class="title">Cambiar {{ title }}</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          />
        </div>
        <div class="modal-body px-3 pb-4">
          <VeeForm
            :validation-schema="informationSchema"
            @submit="saveInformation"
          >
            <div v-if="inputType === 'text'" class="form-group">
              <div class="d-flex flex-column">
                <label :for="fieldName" class="d-flex mb-2" />
                <Field
                  :id="fieldName"
                  v-model="initValue"
                  class="input-field"
                  :type="inputType"
                  :name="fieldName"
                  :rules="rules"
                  :placeholder="customPlaceholderText"
                  @mouseover="onCustomMouseOver"
                  @mouseout="onCustomMouseOut"
                />
              </div>
              <ErrorMessage :name="fieldName" class="error" />
              <span v-if="hasErrorsByName(fieldName)" class="error">
                <p>
                  {{ getFirstErrorByName(fieldName) }}
                </p>
              </span>
            </div>
            <div v-if="inputType === 'password'">
              <div class="form-group">
                <div>
                  <label
                    for="password"
                    class="d-flex justify-content-end password-icon-see-hide"
                    data-cy="password-required"
                  >
                    <div class="me-2 fs-4">
                      <view-hide-password
                        :show-password="showPassword"
                        @toggle="togglePasswordVisibility"
                      />
                    </div>
                  </label>
                  <Field
                    id="password"
                    v-model="password"
                    class="input-field"
                    :type="showPassword ? 'text' : 'password'"
                    name="password"
                    :minlenght="9"
                    :placeholder="newPasswordPlaceholderText"
                    @mouseover="onNewPasswordMouseOver"
                    @mouseout="onNewPasswordMouseOut"
                  />
                </div>
                <ErrorMessage name="password" class="error" />
                <span v-if="hasErrorsByName('password')" class="error">
                  <p>
                    {{ getFirstErrorByName("password") }}
                  </p>
                </span>
              </div>
              <div class="form-group mt-4">
                <div class="d-flex flex-column">
                  <label for="password-confirmation" class="d-flex mb-2" />
                  <Field
                    id="password-confirmation"
                    v-model="password_confirmation"
                    class="input-field"
                    :type="showPassword ? 'text' : 'password'"
                    name="password_confirmation"
                    :placeholder="confirmNewPasswordPlaceholderText"
                    @mouseover="onConfirmNewPasswordMouseOver"
                    @mouseout="onConfirmNewPasswordMouseOut"
                  />
                </div>
                <ErrorMessage name="password_confirmation" class="error" />
                <span
                  v-if="hasErrorsByName('password_confirmation')"
                  class="error"
                >
                  <p>
                    {{ getFirstErrorByName("password_confirmation") }}
                  </p>
                </span>
              </div>
            </div>
          </VeeForm>
        </div>
        <div class="modal-footer border-0">
          <auth-button
            :button-text="'Guardar'"
            :is-submitting="isSubmitting"
            :class-editable="'btn-editable'"
            @click="saveInformation()"
          />
          <button
            type="reset"
            class="button px-5 py-2 btn-cancel rounded-0 custom-badge badge-dark border-0"
            @click="cleanForm()"
          >
            <span class="ms-1"> Limpiar </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Modal } from "bootstrap";
import { getTitleTranslate } from "@/utils/general";
import { ErrorItem } from "@/interfaces/ErrorItem";
import auth from "@/services/AuthService";
import { Form as VeeForm, Field, ErrorMessage } from "vee-validate";
import { CustomNotifyService } from "@/services/CustomNotifyService";
import ViewHidePassword from "@/components/common/ViewHidePassword.vue";
import AuthButton from "@/components/common/AuthButton.vue";
import { computed, ref } from "vue";
import { UserProfileService } from "@/services/UserProfileService";
import { AxiosError } from "axios";

const userProfileService = new UserProfileService();
const notifyService = new CustomNotifyService();

interface DataObject {
  [key: string]: string;
}

export default {
  components: {
    Field,
    VeeForm,
    ErrorMessage,
    ViewHidePassword,
    AuthButton,
  },

  props: {
    title: {
      type: String,
      default: "",
    },

    value: {
      type: String,
      default: "",
    },

    fieldName: {
      type: String,
      default: "",
    },

    showData: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    inputType: {
      type: String,
      default: "text",
    },

    rules: {
      type: String,
      default: "required",
    },

    onlyValue: {
      type: String,
      default: null,
    },
  },
  emits: ["info-saved"],

  setup(props) {
    const name = props.fieldName;
    const title = props.title;
    let password = ref("");

    const getTitle = function (section: string, name: string) {
      return getTitleTranslate(section, name);
    };

    const validatePassword = (value: string) => {
      password.value = value;
      const regex = /^(?=.*[A-Z])(?=.*[\W_]).+$/;

      if (!value) {
        return "Tu nueva contraseña es requerida";
      } else if (value.length < 9) {
        return getTitle("register", "password_min_9");
      } else if (!regex.test(value)) {
        return "Al menos 9 carácteres con 1 letra mayúscula y 1 símbolo es requerido.";
      } else {
        return true;
      }
    };

    const validatePasswordConfirmation = (value: string) => {
      if (!value) {
        return getTitle("register", "confirm_password_text_on_hover");
      }
      if (value.length < 9) {
        return getTitle("register", "password_min_9");
      }
      if (value !== password.value) {
        return getTitle("register", "passwords_must_match");
      }
      return true;
    };

    const informationSchema = computed(() => ({
      [name]: {
        required: { fieldName: title },
      },

      password: validatePassword,
      password_confirmation: validatePasswordConfirmation,
    }));

    return {
      informationSchema,
      getTitle,
    };
  },

  data() {
    return {
      initValue: this.value,
      visibleModal: false,
      informationModal: null as Modal | null,
      isSubmitting: false,
      password: "",
      password_confirmation: "",
      informationErrors: [] as ErrorItem[],
      showPassword: false,
      newPasswordPlaceholderText: "Nueva contraseña",
      confirmNewPasswordPlaceholderText: "Confirmar contraseña",
      customPlaceholderText: this.title,
    };
  },

  computed: {
    idModal() {
      return "modal-information-customer" + Math.random() * 99;
    },
  },

  mounted() {
    auth.getRegisterInitData();
  },

  beforeUnmount() {
    this.hideModal();
  },
  methods: {
    hideData() {
      this.visibleModal = !this.visibleModal;
    },

    showModal() {
      this.informationModal = new Modal("#" + this.idModal);
      this.informationModal.show();
    },

    async saveInformation() {
      try {
        this.isSubmitting = true;

        const data: DataObject = {};
        data[this.fieldName] = this.initValue;

        if (this.password) {
          data["password"] = this.password;
          data["password_confirmation"] = this.password_confirmation;
          const response = await userProfileService.updatePassword(data);

          if (response) {
            this.hideModal();
            this.isSubmitting = false;

            notifyService.getNotification({
              title: "Contraseña actualizada!",
              text: `Tu <strong>${this.title}</strong> ha sido actualizada!`,
              time: 3000,
            });
          }
        }
        const response = await userProfileService.updateInformation(data);
        if (response) {
          this.hideModal();
          this.isSubmitting = false;

          notifyService.getNotification({
            title: "¡Actualización exitosa!",
            text: `Tu <strong>${this.title}</strong> ha sido actualizado!`,
            time: 3000,
          });
        }
        this.$emit("info-saved");
      } catch (error) {
        this.isSubmitting = false;

        if (error instanceof AxiosError && error.response) {
          this.informationErrors = error.response.data.errors;
          console.log(this.informationErrors);
        } else {
          console.error("Error", error);
        }
      }
    },
    hasErrorsByName(field: string) {
      const errors: ErrorItem[] = this.informationErrors.filter(
        (error: ErrorItem) => {
          return error.source.pointer == "/data/attributes/" + field;
        },
      );
      return errors.length > 0;
    },
    getErrorsByName(field: string) {
      return this.informationErrors.filter((error: ErrorItem) => {
        return error.source.pointer == "/data/attributes/" + field;
      });
    },
    getFirstErrorByName(field: string) {
      const errors = this.getErrorsByName(field);
      if (this.hasErrorsByName(field) && errors[0].detail) {
        return errors[0].detail;
      }
      return "";
    },
    hideModal() {
      const modals = document.querySelectorAll(".modal");
      modals.forEach((modalElement) => {
        const modal = Modal.getInstance(modalElement);
        if (modal) {
          modal.hide();
        }
      });
    },

    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },

    onNewPasswordMouseOver() {
      this.newPasswordPlaceholderText = "Escribe aquí tu nueva contraseña";
    },

    onNewPasswordMouseOut() {
      this.newPasswordPlaceholderText = "Nueva contraseña";
    },

    onConfirmNewPasswordMouseOver() {
      this.confirmNewPasswordPlaceholderText =
        "Confirma aquí tu nueva contraseña";
    },

    onConfirmNewPasswordMouseOut() {
      this.confirmNewPasswordPlaceholderText = "Confirmar contraseña";
    },

    onCustomMouseOver() {
      const title = this.title.toLowerCase();
      this.customPlaceholderText = `Escribe aquí tu ${title}`;
    },

    onCustomMouseOut() {
      this.customPlaceholderText = `${this.title}`;
    },

    cleanForm() {
      this.initValue = "";
      if (this.password) {
        this.password = "";
        this.password_confirmation = "";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@use "@/scss/auth";
</style>
