<template>
  <div class="accordion-option mt-3 mb-2">
    <div class="row py-xl-4">
      <div class="d-flex">
        <div class="col-lg-2 col-xl-1 d-none d-lg-block">
          <div
            class="d-flex align-items-center justify-content-end h-100 courier-section-radio px-1"
          >
            <i v-if="!showAccordion" class="bi bi-truck fa-truck fs-3" />
            <font-awesome-icon
              v-if="showAccordion"
              icon="fa-solid fa-truck"
              class="icon-cart me-2"
            />
          </div>
        </div>
        <div class="col-lg-10 col-xl-11 pointer">
          <div class="d-flex" @click="togglePanel">
            <i class="bi bi-truck fa-truck d-lg-none icon-truck-courier-sm" />
            <h3 class="title-accordion-option ms-4 ms-lg-0">
              {{ getTitle("cart", "home_delivery") }}
              <!-- Envío a mi domicilio -->
            </h3>
          </div>
          <p class="text-nowrap text-select-a-courier">
            {{ getTitle("cart", "select_a_courier_and_secure_your_order_now") }}
            <!-- ¡Selecciona una paquetería y asegura tu pedido ahora! -->
          </p>
          <div
            v-if="showAccordion"
            class="row accordion-option-shipping"
            :class="{ closed: isClosed }"
          >
            <ul class="list-unstyled courier-option">
              <li
                v-for="(item, index) in couriers"
                :key="index"
                class="mt-2 p-2 me-3 list-option pointer w-100"
                :class="{ active: item?.id === selectedCourier?.id }"
                @click="selectCourier(item)"
              >
                <div class="d-flex">
                  <div v-if="item.image_url" class="me-2 bg-white rounded">
                    <img
                      class="img-small"
                      :src="item.image_url"
                      @error="setImageNotAvailable($event)"
                    />
                  </div>
                  <div class="row d-flex justify-content-between w-100">
                    <div class="col-6">
                      <h5 class="text-uppercase fw-bold name-courier">
                        {{ item.name }}
                      </h5>
                      <div class="delivery-time text-nowrap">
                        <span v-if="item.prices.length > 0" class="fw-semibold">
                          {{ item.delivery_time_text }}
                        </span>
                      </div>
                    </div>
                    <div class="col-6">
                      <h4 class="text-end text-green fw-semibold free">
                        {{
                          item.total === 0
                            ? getTitle("cart", "free")
                            : item.formatted_total
                        }}
                      </h4>
                      <div
                        v-if="item.prices.length > 0"
                        class="d-none d-lg-block delivery-time fw-semibold text-end"
                      >
                        <span class="fw-semibold text-blue">
                          {{ item.delivery_time_text }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div v-if="showAccordion" class="row">
            <div class="col-12 col-1">
              <div class="row bg-shipping-options pt-3 pb-3">
                <div class="col-lg-4 d-none d-lg-block">
                  <div class="d-flex">
                    <div><i class="bi bi-info-circle-fill text-blue"></i></div>
                    <div class="description ms-2 text-wrap">
                      {{
                        getTitle(
                          "cart",
                          "shipping_payment_is_required_whit_selected_payment_method",
                        )
                      }}
                    </div>
                  </div>
                </div>
                <div
                  class="col-lg-4 d-none d-lg-block description text-end fw-semibold"
                >
                  <span class="text-blue">{{
                    getTitle("cart", "why_should_i_insure_my_package")
                  }}</span>
                </div>
                <div class="col-12 col-lg-4 description">
                  <div class="d-flex float-end">
                    <div class="d-block d-lg-none">
                      <span class="exclamation-circle"> ¡ </span>
                    </div>
                    <div class="d-flex flex-row-reverse flex-lg-row">
                      <div
                        class="checkbox-cart-item px-1 order-lg-first order-sm-last"
                        @click="changeHasShippingInsurance"
                      >
                        <input
                          type="checkbox"
                          class="insurance-package"
                          :checked="hasShippingInsurance"
                        />
                        <label for="checkbox-cart" />
                      </div>
                      <label for="checkbox-cart" class="d-flex">
                        <span class="px-1">
                          {{
                            getTitle(
                              "cart",
                              "whoud_you_like_to_insure_your_package",
                            )
                          }}
                        </span>
                        <span class="fw-semibold text-blue">
                          {{
                            getTitleOptions("cart", "price_of_insurance", {
                              ":price": formattedPrice(calculatedInsuranceCost),
                            })
                          }}
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { CourierService } from "@/services/CourierService";
import { ShippingService } from "@/services/ShippingService";
import {
  getTitleTranslate,
  getTitleTranslateWithOptions,
  formatPrice,
} from "../../utils/general";
// import { ItemCourier } from './interfaces/ItemCourier';
import { store } from "@/store";
import Jsona from "jsona";
import { ItemCourier } from "@/interfaces/ItemCourier";
import eventBus from "@/event-bus";
import { mapGetters } from "vuex";
import auth from "@/services/AuthService";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

const dataFormatter = new Jsona();

export default {
  components: {
    FontAwesomeIcon,
  },
  props: {
    showCouriers: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      courierService: null as CourierService | null,
      isCheckedCartItem: true,
      showAccordion: false,
      couriers: [] as ItemCourier[],
      isClosed: false,
    };
  },
  computed: {
    isUserAuthenticated() {
      return auth.isUserLogged();
    },
    imageNotAvailable() {
      return store.getters["getImageNotAvailable"];
    },
    ...mapGetters("cart", {
      calculatedInsuranceCost: "calculatedInsuranceCost",
    }),
    ...mapGetters("checkout", {
      selectedCustomerAddress: "getCustomerAddress",
      selectedCourier: "getCourier",
      hasShippingInsurance: "getHasShippingInsurance",
    }),
  },
  watch: {
    selectedCustomerAddress() {
      this.getCouriers();
      this.showAccordion = true;
    },
    showCouriers() {
      this.showAccordion = this.showCouriers;
    },
    hasShippingInsurance() {
      this.calculateInsuranceCost();
    },
  },
  mounted() {
    this.courierService = new CourierService();
    this.courierService.getCartInitData();
    if (this.selectedCustomerAddress) {
      this.getCouriers();
      this.showAccordion = true;
    }
    this.calculateInsuranceCost();
    if (this.selectedCourier && this.selectedCourier?.prices.length > 0) {
      store.commit("cart/setShipping", this.selectedCourier.prices[0]);
    }
  },
  methods: {
    setImageNotAvailable(event: Event) {
      if (event.target instanceof HTMLInputElement) {
        event.target.src = this.imageNotAvailable;
      }
    },

    getTitle(section: string, name: string) {
      return getTitleTranslate(section, name);
    },

    getTitleOptions(section: string, name: string, options: unknown) {
      return getTitleTranslateWithOptions(section, name, options);
    },

    formattedPrice(price: number) {
      return formatPrice(price);
    },

    togglePanel() {
      this.showAccordion = !this.showAccordion;
      eventBus.emit("selected-courier", this.showAccordion);
    },

    selectCourier(courier: any) {
      store.commit("checkout/setCourier", courier);
      if (courier) {
        store.commit("cart/setShipping", courier.total);
      }
    },

    getCouriers() {
      const shippingService = new ShippingService();
      if (this.isUserAuthenticated && shippingService) {
        shippingService.getCouriers().then((response) => {
          if (response && response.data) {
            this.couriers = dataFormatter.deserialize(response) as [];
          }
        });
      }
    },

    changeHasShippingInsurance() {
      store.commit("checkout/toggleHasShippingInsurance");
      this.calculateInsuranceCost();
    },
    calculateInsuranceCost() {
      if (this.hasShippingInsurance) {
        store.commit("cart/setShippingInsurance", this.calculatedInsuranceCost);
      } else {
        store.commit("cart/setShippingInsurance", 0);
      }
    },
  },
};
</script>
