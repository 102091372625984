import {
  RouteRecordRaw,
  createRouter,
  createWebHistory,
  NavigationGuardNext,
  RouteLocationNormalized,
} from "vue-router";

import MainLayout from "@/components/layouts/MainLayout.vue";
import AuthRoutes from "./auth/AuthRoutes";
import ProfileRoutes from "./profile";
import CompanyRoutes from "./company";
import SupportRoutes from "./support";
import AssemblyRoutes from "./assembly";
import CartRoutes from "./cart";
import auth from "@/services/AuthService";
import SearchByCategoryPage from "@/views/SearchByCategoryPage.vue";
import PaymentsRoutes from "./payment";

const routes: RouteRecordRaw[] = [
  {
    path: "/",
    name: undefined,
    component: () => import("../views/HomePage.vue"),
    meta: { layout: MainLayout },
  },
  {
    path: "/about",
    name: "About",
    component: () => import("../views/MyAccount.vue"),
    meta: { layout: MainLayout },
  },
  {
    path: "/perfil",
    name: "profile",
    component: () => import("../views/ProfilePage.vue"),
    beforeEnter: async (_to, _from, next) => {
      if (!auth.isUserLogged()) {
        return next("/login");
      }
      next();
    },
    meta: { layout: MainLayout },
    children: [...ProfileRoutes],
  },
  {
    path: "/pagos",
    name: "payments",
    component: () => import("../views/PaymentPage.vue"),
    meta: { layout: MainLayout },
    children: [...PaymentsRoutes],
  },
  {
    path: "/arma-tu-equipo",
    name: "Assembly",
    component: () => import("../views/AssemblyPage.vue"),
    meta: { layout: MainLayout },
    children: [...AssemblyRoutes],
  },
  {
    path: "/favoritos",
    name: "Favorites",
    component: () => import("../views/FavoritePage.vue"),
    meta: { layout: MainLayout, withFooter: false },
  },
  {
    path: "/mi_cuenta",
    name: "MyAccount",
    component: () => import("../views/MyAccount.vue"),
    meta: { layout: MainLayout, withFooter: true },
  },
  {
    path: "/compra",
    name: "Purchase",
    component: () => import("../views/ShoppingCartPage.vue"),
    meta: { layout: MainLayout, withFooter: false },
    children: [...CartRoutes],
  },
  {
    path: "/productos/:slug",
    name: "product",
    component: () => import("../views/ProductPage.vue"),
    meta: { layout: MainLayout, withFooter: true },
  },
  // {
  //   path: "/buscar/:filters*",
  //   name: "search",
  //   component: () => import("../views/GeneralSearchPage.vue"),
  //   props: (route) => ({ search: route.params.slug }),
  //   meta: { layout: MainLayout },
  // },
  {
    path: "/buscar/:filters*",
    name: "search",
    component: () => import("../views/MainSearchPage.vue"),
    meta: { layout: MainLayout, withFooter: true },
  },
  {
    path: "/busqueda-por-categoria",
    name: "search_categories",
    component: SearchByCategoryPage,
    meta: { layout: MainLayout, withFooter: true },
  },
  {
    path: "/",
    name: "company",
    component: () => import("../views/CompanyPage.vue"),
    children: [...CompanyRoutes],
    meta: { layout: MainLayout, withFooter: true },
  },
  {
    path: "/",
    name: "support",
    component: () => import("../views/SupportPage.vue"),
    children: [...SupportRoutes],
    meta: { layout: MainLayout, withFooter: true },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "error_404",
    component: () => import("../views/errors/NotFoundPage.vue"),
    meta: { layout: MainLayout, withFooter: true },
  },
  {
    path: "/marcas",
    name: "all_brands",
    component: () => import("../views/BrandsPage.vue"),
    meta: { layout: MainLayout, withFooter: true },
  },
  {
    path: "/validate-address/:transactionId/:code/:missingPayment/:response",
    name: "validate_address",
    component: () => import("../views/ValidateAddress.vue"),
    meta: { layout: MainLayout, withFooter: true },
    props: true,
  },
  {
    path: "/correo/verificar",
    name: "validate_address",
    component: () => import("../views/ValidateAddress.vue"),
    meta: { layout: MainLayout, withFooter: true },
    props: true,
  },
  {
    path: "/landing/:id",
    name: "landing",
    component: () => import("../views/LandingPage.vue"),
    meta: { layout: MainLayout, withFooter: true },
  },
  ...AuthRoutes,
];

const router = createRouter({
  history: createWebHistory(),
  routes: routes,
});

router.beforeEach(
  (
    to: RouteLocationNormalized,
    _from: RouteLocationNormalized,
    next: NavigationGuardNext,
  ) => {
    let title = "Digitalife";
    if (typeof to.meta.title === "string") {
      title = to.meta.title;
    }
    document.title = title;
    next();
  },
);

router.beforeEach((to, _from, next) => {
  const isUserAuthenticated = auth.isUserLogged();
  //Rutas que necesitan autenticación
  const routesRequiringAuth = [
    "/compra/elegir-pago",
    "/compra/elegir-envio",
    "/compra/elegir-mensualidad",
    "/compra/revisar",
  ];

  if (routesRequiringAuth.includes(to.path) && !isUserAuthenticated) {
    next("/login");
  } else {
    next();
  }
});

export default router;
