<template>
  <div class="row gx-2 mt-4 mt-lg-5">
    <fieldset class="d-flex flex-column">
      <legend
        class="col-12 col-lg-9 d-flex justify-content-start p-3 rounded-2"
      >
        <span class="ms-lg-2">Facturación</span>
      </legend>
      <div class="mt-lg-3 mb-4 form-invoice">
        <div class="px-1 px-lg-5 mt-1 mt-lg-2 mb-4">
          <!-- <strong class="float-start fw-bold">(En construcción, falta funcionalidad)</strong>
                    <br> -->
          <h4 class="fw-bold text-start text-capture">
            Capture los datos solicitados
          </h4>
          <p class="text-start text-required">
            Los campos marcados con
            <span class="text-danger fw-bold">*</span> son obligatorios.
          </p>
          <div class="border border-light p-3 rounded-3">
            <VeeForm :validation-schema="invoiceSchema" @submit="onInvoice">
              <div class="row mb-3 mb-lg-4">
                <div class="col d-flex flex-column">
                  <label class="text-start d-lg-none" for="name"
                    >Folio <small class="fw-bold text-danger">*</small></label
                  >
                  <label
                    for="folio"
                    class="input-contact d-flex align-items-center"
                  >
                    <div class="box-input-card" />
                    <span class="mx-2 col-auto d-none d-lg-block"
                      >Folio <small class="fw-bold text-danger">*</small></span
                    >
                    <Field
                      id="folio"
                      v-model="folio"
                      type="text"
                      name="folio"
                    />
                  </label>
                  <ErrorMessage name="folio" class="error text-start" />
                </div>
              </div>
              <div class="row mb-3 mb-lg-4">
                <div class="col col-lg-6 d-flex flex-column mb-3 mb-lg-0">
                  <label class="text-start d-lg-none" for="date"
                    >Fecha <small class="fw-bold text-danger">*</small></label
                  >
                  <label
                    for="date"
                    class="input-contact d-flex align-items-center"
                  >
                    <div class="box-input-card" />
                    <span class="mx-2 col-auto d-none d-lg-block"
                      >Fecha <small class="fw-bold text-danger">*</small></span
                    >
                    <Field
                      id="date"
                      v-model="date"
                      type="date"
                      name="date"
                      class="pe-2"
                    />
                  </label>
                  <ErrorMessage name="date" class="error text-start m-0" />
                </div>
                <div class="col-12 col-lg-6 d-flex flex-column">
                  <label class="text-start d-lg-none" for="total-amount"
                    >Monto total
                    <small class="fw-bold text-danger">*</small></label
                  >
                  <label
                    for="total-amount"
                    class="input-contact d-flex align-items-center"
                  >
                    <div class="box-input-card" />
                    <span class="mx-2 col-auto d-none d-lg-block"
                      >Monto total
                      <small class="fw-bold text-danger">*</small></span
                    >

                    <Field
                      id="total-amount"
                      v-model="totalAmount"
                      type="text"
                      name="totalAmount"
                    />
                  </label>
                  <ErrorMessage name="totalAmount" class="error text-start" />
                </div>
              </div>
              <div
                class="form-row d-flex flex-column flex-md-row align-items-center align-items-sm-start"
              >
                <div class="col mt-md-0 d-flex">
                  <button type="reset" class="btn-reset me-2">Limpiar</button>
                  <button class="btn-send order-md-1">Enviar mensaje</button>
                </div>
              </div>
            </VeeForm>
          </div>
        </div>
      </div>
    </fieldset>
  </div>
</template>

<script lang="ts">
import { Form as VeeForm, Field, ErrorMessage } from "vee-validate";
import TabTitle from "@/interfaces/TabTitle";

export default {
  components: {
    ErrorMessage,
    Field,
    VeeForm,
  },

  beforeRouteEnter() {
    if (import.meta.env.VITE_ENV === "production") {
      location.href = import.meta.env.VITE_OLD_ESHOP_URL + "/facturacion";
    }
  },

  setup() {
    const invoiceSchema = {
      folio(value: string) {
        if (!value || !value.length || value.length < 2) {
          return "El número de folio es necesario";
        } else {
          return true;
        }
      },
      date(value: string) {
        if (!value) {
          return "La fecha es necesaria";
        } else {
          return true;
        }
      },

      totalAmount(value: string) {
        if (!value) {
          return "El monto total es necesario";
        } else {
          return true;
        }
      },
    };

    return {
      invoiceSchema,
    };
  },

  data: function () {
    TabTitle("Facturación");

    return {
      folio: "",
      date: "",
      totalAmount: "",
    };
  },

  methods: {
    async onInvoice() {
      try {
        console.log("Enviar formulario");
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>
<style lang="scss">
@use "@/scss/company";
</style>
