<template lang="">
  <div class="row py-4 px-5">
    <div class="col-2 d-flex align-items-center">
      <base-link>
        <img src="/images/Digitalife.png" class="img-fluid" />
      </base-link>
    </div>
    <div class="col-4 col-xl-4 offset-xl-1">
      <suggestion-input />
    </div>
    <ul
      class="col-6 col-xl-5 list-unstyled d-flex row-cols-3 row-cols-xl-5 justify-content-end px-0 mb-0 gap-3"
    >
      <li class="col-auto h-100">
        <assemble-your-pc-link />
      </li>
      <li class="col-auto h-100">
        <login-link-nav-component />
      </li>
      <li class="col-auto h-100">
        <link-nav
          :to-object="{ name: 'cart-detail' }"
          :text="'Carrito'"
          component="CartSvg"
          component-hover="CartFillSvg"
          class="box-search-bar text-uppercase"
          align-icon="center"
          :count="totalShoppingCart"
        />
      </li>
    </ul>
  </div>
</template>
<script lang="ts">
import { CartService } from "@/services/CartService";
import LinkNav from "../buttons/LinkNav.vue";
import SuggestionInput from "./SuggestionInput.vue";
import BaseLink from "../buttons/BaseLink.vue";
import LoginLinkNavComponent from "@/components/auth/LoginLinkNavComponent.vue";
import eventBus from "@/event-bus";
import AssembleYourPcLink from "./Menu/AssembleYourPcLink.vue";
import auth from "@/services/AuthService";
import { store } from "@/store";
import { mapGetters } from "vuex";

export default {
  components: {
    LinkNav,
    BaseLink,
    SuggestionInput,
    LoginLinkNavComponent,
    AssembleYourPcLink,
  },
  data() {
    return {
      total: 0,
    };
  },
  computed: {
    loggedInUser() {
      return auth.isUserLogged();
    },
    isUserAuthenticated() {
      return auth.isUserLogged();
    },
    ...mapGetters("cart", {
      totalShoppingCart: "getTotalItems",
    }),
  },
  created() {
    eventBus.on("update-cart-total-items", (total) => {
      if (typeof total === "number") {
        store.commit("cart/setTotalItems", total);
      } else {
        this.totalItems();
      }
    });

    this.totalItems();
  },
  beforeUnmount() {
    eventBus.off("update-cart-total-items");
  },
  methods: {
    totalItems() {
      if (this.isUserAuthenticated) {
        const cartService = new CartService();
        cartService.getTotalItems().then((response) => {
          if (response) {
            const totalShoppingCart = response.total_items;
            store.commit("cart/setTotalItems", totalShoppingCart);
          }
        });
      } else {
        // let products = localStorage.getItem("products");
        // let cartLocalStorage = products ? JSON.parse(products) : [];
        const cartLocalStorage = store.getters["cart/getLocalStorageProducts"];
        console.log(cartLocalStorage);
        const totalShoppingCart =
          cartLocalStorage && cartLocalStorage.length > 0
            ? cartLocalStorage.length
            : 0;
        store.commit("cart/setTotalItems", totalShoppingCart);
      }
    },
  },
};
</script>
