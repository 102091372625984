import axios, { AxiosRequestConfig } from "axios";
import { VITE_API_ENDPOINT } from "@/config/constants.js";

export class WarrantyService {
  private createOptions(
    headers: Record<string, string> = {},
  ): AxiosRequestConfig {
    return {
      baseURL: VITE_API_ENDPOINT,
      headers: {
        Accept: "application/vnd.api+json",
        "Content-Type": "application/vnd.api+json",
        ...headers,
      },
    };
  }

  public async getWarrantyRequests() {
    return this.fetchData(`/shop-v1/warranty-requests?include=transaction`);
  }

  public async getWarrantyRequest(id: string) {
    return this.fetchData(`/shop-v1/warranty-requests/${id}`);
  }

  public async getWarrantyRequestRelease(id: string) {
    const response = await this.fetchData(
      `/shop-v1/warranty-requests/${id}/warranty-request-releases?include=createdBy`,
    );
    return response?.data || null;
  }

  public async getWarrantyRequestProduct(id: string) {
    const response = await this.fetchData(
      `/shop-v1/warranty-requests/${id}/product`,
    );
    return response?.data || null;
  }

  public async getWarrantyRequestTransaction(id: string) {
    const response = await this.fetchData(
      `/shop-v1/warranty-requests/${id}/transaction`,
    );
    return response?.data || null;
  }

  public async getWarrantyRequestsOnTransaction(id: string) {
    return this.fetchData(
      `/shop-v1/warranty-requests?filter[transaction_id]=${id}&filter[status]=10`,
    );
  }

  public async generateGuide(id: string) {
    const options = this.createOptions({
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    });
    return this.postData(
      `/shop-v1/shippings/warranty-request-guide/store/${id}`,
      options,
    );
  }

  public async uploadWarrantyFile(file: File): Promise<string | null> {
    const url = `/shop-v1/warranty-request/upload-file`;
    const formData = new FormData();
    formData.append("file", file);
    try {
      const response = await axios.post(url, formData, {
        baseURL: VITE_API_ENDPOINT,
      });
      return response.data.file_url;
    } catch (error) {
      this.handleError(error, "Error al subir archivo");
      return null;
    }
  }

  public async generateWarrantyRequest(
    caseDetails: string,
    productId: string,
    files: string[],
  ) {
    const url = `/shop-v1/warranty-requests`;
    const data = {
      data: {
        type: "warranty-requests",
        attributes: { case_details: caseDetails },
        relationships: {
          transaction: { data: { type: "transactions", id: "1" } },
          product: { data: { type: "products", id: productId } },
        },
      },
      included: files.map((file) => ({
        type: "warranty-files",
        attributes: { url: file },
      })),
    };

    return this.postData(url, this.createOptions(), data);
  }

  public async getWarrantyRequestGuideFromCustomer(id: string) {
    return this.downloadWarrantyGuide(
      `/shop-v1/shippings/warranty-request-guide-from-customer/${id}`,
      id,
    );
  }

  public async getWarrantyRequestGuideToCustomer(id: string) {
    return this.downloadWarrantyGuide(
      `/shop-v1/shippings/warranty-request-guide-to-customer/${id}`,
      id,
    );
  }

  private async fetchData(url: string) {
    try {
      const response = await axios.get(url, this.createOptions());
      return response?.data || null;
    } catch (error) {
      this.handleError(error, "Error al obtener datos");
      return null;
    }
  }

  private async postData(
    url: string,
    options: AxiosRequestConfig,
    data?: object,
  ) {
    try {
      const response = await axios.post(url, data, options);
      return response.data;
    } catch (error) {
      this.handleError(error, "Error en solicitud de garantía");
      return null;
    }
  }

  private async downloadWarrantyGuide(url: string, id: string) {
    try {
      const response = await axios.get(url, {
        baseURL: VITE_API_ENDPOINT,
        headers: { responseType: "blob" },
      });

      const blobUrl = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = blobUrl;
      link.setAttribute("download", `shipping_label_${id}.pdf`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(blobUrl);
    } catch (error) {
      this.handleError(error, "Error en descarga de guía");
    }
  }

  private handleError(error: unknown, message: string) {
    if (axios.isAxiosError(error)) {
      console.error(`${message}:`, error.response?.data);
    } else {
      console.error(`${message}:`, error);
    }
  }
}
