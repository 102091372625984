import { Component } from "vue";
import OursStores from "@/components/support/OursStores.vue";
import PaymentMethods from "@/components/support/PaymentMethodsCustomers.vue";
import ContactUs from "@/components/support/ContactUs.vue";
import FrequentQuestions from "@/components/support/FrequentQuestions.vue";

const createRoute = (path: string, name: string, component: Component) => [
  {
    path,
    name,
    component,
  },
];

const router = [
  ...createRoute("/sucursales", "support.stores", OursStores),
  ...createRoute("/contactanos", "support.contact", ContactUs),
  ...createRoute("/preguntas-frecuentes", "support.faq", FrequentQuestions),
  ...createRoute("/formas-de-pago", "support.payment_ways", PaymentMethods),
];

export default router;
