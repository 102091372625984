<template lang="">
  <div class="p-0 p-lg-2 d-flex justify-content-center overflow-hidden w-100">
    <img
      v-if="image && !imageError"
      :src="image"
      :class="imgClass"
      :style="styleResponsive"
      @error="imageError = true"
    />
    <span
      v-if="imageError || !image"
      class="text-secondary fw-semibold text-nowrap text-uppercase p-1"
      :class="styleResponsiveText"
    >
      {{ name }}
    </span>
  </div>
</template>
<script lang="ts">
import { ref, onMounted, onUnmounted } from "vue";
export default {
  props: {
    image: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    fontSize: {
      type: String,
      default: "fs-8",
    },
    fontSizeSm: {
      type: String,
      default: "fs-8",
    },
    maxHeight: {
      type: Number,
      default: 20,
    },
    maxSmHeight: {
      type: Number,
      default: null,
    },
    imgClass: {
      type: String,
      default: "image-brand",
    },
  },
  setup() {
    const viewportWidth = ref(window.innerWidth);
    const viewportHeight = ref(window.innerHeight);

    // Función para actualizar las dimensiones del viewport
    const updateViewportDimensions = () => {
      viewportWidth.value = window.innerWidth;
      viewportHeight.value = window.innerHeight;
    };

    // Agregar un listener para el evento 'resize'
    onMounted(() => {
      window.addEventListener("resize", updateViewportDimensions);
    });

    // Eliminar el listener al desmontar el componente
    onUnmounted(() => {
      window.removeEventListener("resize", updateViewportDimensions);
    });

    return {
      viewportWidth,
      viewportHeight,
    };
  },
  data: function () {
    return {
      imageError: false,
    };
  },
  computed: {
    styleResponsive() {
      if (this.viewportWidth <= 992) {
        return {
          maxHeight:
            (this.maxSmHeight > 0 ? this.maxSmHeight : this.maxHeight) + "px",
        };
      } else {
        return {
          maxHeight: this.maxHeight + "px",
        };
      }
    },
    styleResponsiveText() {
      if (this.viewportWidth <= 992) {
        return this.fontSizeSm;
      } else {
        return this.fontSize;
      }
    },
  },
};
</script>
<style lang="sass" scoped>
.image-brand
    width: 100%
    object-fit: contain
</style>
