<script setup lang="ts">
import { ref, onMounted } from "vue";
import TabTitle from "@/interfaces/TabTitle";
import { companyService } from "@/services/CompanyService";
import { Results } from "@/interfaces/Results";

TabTitle("Privacidad");
const description = ref<string>("");
const update_description = ref<string>("");
const results = ref<Array<Results>>([]);
const breadCrumbs = ref<{ name: string }>({ name: "" });
const loading = ref<boolean>(false);

onMounted(async () => {
  try {
    window.scroll(0, 0);
    loading.value = true;
    const response = await companyService.privacy();
    description.value = response.description;
    results.value = response.results;
    update_description.value = response.update_description;
    breadCrumbs.value = response.breadcrumb_links[1];
    loading.value = false;
  } catch (error) {
    loading.value = false;
    console.log("Error fetching stores:", error);
  }
});
</script>

<template>
  <div>
    <div v-if="loading" class="row">
      <p class="placeholder-glow">
        <span class="placeholder col-12 bg-light py-4 mb-3"></span>
        <span class="placeholder col-12 bg-light" style="height: 300px"></span>
      </p>
    </div>
    <div v-else class="row gx-2 mt-4 mt-lg-5">
      <fieldset class="d-flex flex-column">
        <legend
          class="col-12 col-lg-9 d-flex justify-content-start p-3 rounded-2"
        >
          <span class="ms-lg-2">{{ breadCrumbs.name }}</span>
        </legend>
        <div class="container">
          <div class="description px-1 px-lg-5 mt-1 mt-lg-2 mb-5">
            <div v-html="description"></div>
            <div>
              <div v-for="(item, index) in results" :key="index">
                <h3>{{ item.title }}</h3>
                <div class="description" v-html="item.description"></div>
              </div>
            </div>
            <div class="mt-5 fw-semibold" v-html="update_description"></div>
          </div>
        </div>
      </fieldset>
    </div>
  </div>
</template>
