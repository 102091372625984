import Jsona from "jsona";
const dataFormatter = new Jsona();

// define your typings for the store state
interface StateBaseResponse {
  links: string;
  data: string;
  included: string;
  meta: string;
}

export interface StateCatalogs {
  categories: StateBaseResponse;
  brands: Array<string>;
  banners: StateBaseResponse;
}

const state = () => ({
  categories: {
    links: [],
    data: [],
    included: [],
    meta: [],
  },
  brands: [],
  banners: {
    links: [],
    data: [],
    included: [],
    meta: [],
  },
});

// mutations
const mutations = {
  setCategories(state: StateCatalogs, categories: StateBaseResponse) {
    state.categories = categories;
  },
  setBrands(state: StateCatalogs, brands: Array<string>) {
    state.brands = brands;
  },
  setBanners(state: StateCatalogs, banners: StateBaseResponse) {
    state.banners.data = state.banners.data.concat(banners.data);
  },
};

const getters = {
  getCategories(state: StateCatalogs) {
    return state.categories;
  },
  getMetaCategories(state: StateCatalogs) {
    return state.categories.meta;
  },
  getLinksCategories(state: StateCatalogs) {
    return state.categories.links;
  },
  getDeserealizeCategories(state: StateCatalogs) {
    return dataFormatter.deserialize(JSON.stringify(state.categories));
  },
  getBrands(state: StateCatalogs) {
    return state.brands;
  },
  getDeserealizeBrands(state: StateCatalogs) {
    return dataFormatter.deserialize(JSON.stringify(state.brands));
  },
  getBanners(state: StateCatalogs) {
    return state.banners;
  },
  getDeserealizeBanners(state: StateCatalogs) {
    return dataFormatter.deserialize(JSON.stringify(state.banners));
  },
};

export const catalogs = {
  namespaced: true,
  state,
  mutations,
  getters,
};
