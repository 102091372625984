<template>
  <i
    ref="tooltipButton"
    class="bi pointer"
    :class="showPassword ? 'bi-eye' : 'bi-eye-slash'"
    :title="showPassword ? hideText : showText"
    data-bs-toggle="tooltip"
    @click="togglePasswordVisibility"
  />
</template>
<script lang="ts">
import { Tooltip } from "bootstrap";
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    showPassword: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hideText: "Ocultar contraseña",
      showText: "Ver contraseña",
      tooltipInstance: null as Tooltip | null,
    };
  },
  watch: {
    showPassword() {
      this.updateTooltip();
    },
  },
  mounted() {
    this.initializeTooltip();
  },
  beforeUnmount() {
    this.destroyTooltip();
  },
  methods: {
    togglePasswordVisibility() {
      this.$emit("toggle");
    },
    initializeTooltip() {
      if (this.$refs.tooltipButton) {
        this.tooltipInstance = new Tooltip(this.$refs.tooltipButton as Element);
      }
    },
    destroyTooltip() {
      if (this.tooltipInstance) {
        this.tooltipInstance.dispose();
        this.tooltipInstance = null;
      }
    },
    updateTooltip() {
      if (this.tooltipInstance) {
        this.tooltipInstance.setContent({
          ".tooltip-inner": this.showPassword ? this.hideText : this.showText,
        });
      }
    },
  },
});
</script>
