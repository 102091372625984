<template lang="">
  <div
    v-if="promotions"
    :class="{
      row: verticalItems,
      'row row-cols-5 row-gap-3 overflow-hidden promotion-list': !verticalItems,
    }"
  >
    <product-promotion-list-item-promotion
      v-if="promotions.show_wallet_icon"
      image="/images/Purse.png"
      :class="{
        'border-included d-flex align-items-center col-6 col-xl-12':
          verticalItems,
        'promotion-item border border-light-subtle border-start-0 border-end-0':
          !verticalItems,
      }"
      :is-vertical-item="verticalItems"
    >
      <template #text>
        <div
          :class="{
            'd-flex flex-row-reverse mt-1': verticalItems,
            'd-flex flex-column': !verticalItems,
          }"
        >
          <span :class="{ 'ms-1': verticalItems }">
            {{ getTitle("home", "wallet") }}
          </span>
          <span
            :class="{ 'ms-1': verticalItems }"
            v-html="promotions.wallet_promotion_amount"
          />
        </div>
      </template>
    </product-promotion-list-item-promotion>

    <product-promotion-list-item-promotion
      v-if="promotions.show_discount_icon"
      image="/images/Promotion.png"
      :class="{
        'border-included d-flex align-items-center col-6 col-xl-12':
          verticalItems,
        'promotion-item border border-light-subtle border-start-0 border-end-0':
          !verticalItems,
      }"
      :is-vertical-item="verticalItems"
    >
      <template #text>
        <span :class="{ 'mx-2 mt-1': verticalItems }">
          {{ promotions.discount_final_percentage }}
          {{ getTitle("home", "discount_offer") }}
        </span>
      </template>
    </product-promotion-list-item-promotion>

    <product-promotion-list-item-promotion
      v-if="promotions.show_shipping_icon"
      image="/images/Shipping.png"
      :class="{
        'border-included d-flex align-items-center col-6 col-xl-12':
          verticalItems,
        'promotion-item border border-light-subtle border-start-0 border-end-0':
          !verticalItems,
      }"
      :is-vertical-item="verticalItems"
    >
      <template #text>
        <span :class="{ 'mx-2 mt-1': verticalItems }">
          {{ shippingText }}
        </span>
      </template>
    </product-promotion-list-item-promotion>

    <product-promotion-list-item-promotion
      v-if="promotions.show_coupon_icon"
      image="/images/Coupon.png"
      :class="{
        'ms-lg-0 border-included d-flex align-items-center col-6 col-xl-12':
          verticalItems,
        'promotion-item border border-light-subtle border-start-0 border-end-0':
          !verticalItems,
      }"
      :is-vertical-item="verticalItems"
    >
      <template #text>
        <span :class="{ 'mx-2 m-1': verticalItems }">
          {{ getTitle("home", "apply_coupon") }}
        </span>
      </template>
    </product-promotion-list-item-promotion>

    <product-promotion-list-item-promotion
      v-if="promotions.last_pieces"
      image="/images/SoldOut.png"
      :class="{
        'border-included d-flex align-items-center col-6 col-xl-12':
          verticalItems,
        'promotion-item border border-light-subtle border-start-0 border-end-0':
          !verticalItems,
      }"
      :is-vertical-item="verticalItems"
    >
      <template #text>
        <span :class="{ 'mx-2 mt-1': verticalItems }">
          {{ getTitle("home", "last_pieces") }}
        </span>
      </template>
    </product-promotion-list-item-promotion>

    <div
      v-if="verticalItems"
      class="col-6 col-lg-12 col-xl-10 border-included mt-0"
    >
      <button
        class="d-flex justify-content-around align-items-center p-0 m-0 border-0 protection-plan"
      >
        <div class="col-1 mt-1">
          <i
            v-if="isLargeScreen"
            class="bi bi-shield-fill icon-size-product-included mt-1 ms-xxl-1"
          />
          <i
            v-if="!isLargeScreen"
            class="bi bi-shield icon-size-product-included"
          />
        </div>
        <div class="col-10">
          <small
            class="p-0 m-0 text-secondary subtitle-product-includes text-nowrap text-center me-2 me-lg-1 ms-xxl-1 me-xxl-4"
          >
            Agrega plan de protección
          </small>
        </div>
        <div class="col-1 mt-1">
          <i
            v-if="isLargeScreen"
            class="bi bi-plus-circle-fill icon-size-product-included"
          />
          <i
            v-if="!isLargeScreen"
            class="bi bi-plus-circle icon-size-product-included"
          />
        </div>
      </button>
    </div>
  </div>
</template>
<script lang="ts">
import ProductPromotionListItemPromotion from "@/components/common/cart/ProductPromotionListItemPromotion.vue";
import { HomeService } from "@/services/HomeService";
import { getTitleTranslate } from "@/utils/general";

interface Promotion {
  minimum_delivery_time: number;
  wallet_promotion_amount: string;
  show_discount_icon: boolean;
  discount_final_percentage: string;
  show_shipping_icon: boolean;
  show_coupon_icon: boolean;
  last_pieces: boolean;
}

export default {
  components: {
    ProductPromotionListItemPromotion,
  },
  props: {
    product: {
      type: Object,
      default: null,
    },
    maxHeight: {
      type: String,
      default: "",
    },
    verticalItems: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      promotions: null as Promotion | null,
    };
  },
  computed: {
    shippingText() {
      if (this.promotions && this.promotions.minimum_delivery_time) {
        return (
          this.getTitle("home", "arrives_in") +
          this.promotions.minimum_delivery_time
        );
      }

      return (
        this.getTitle("home", "shipping_free") +
        " " +
        this.getTitle("home", "three_five_days")
      );
    },
    isLargeScreen() {
      return window.innerWidth >= 992;
    },
  },
  created() {
    const homeService = new HomeService();
    homeService.getPromotionsByProduct(this.product.id).then((response) => {
      if (response) {
        this.promotions = response.data.attributes;
      }
    });
  },
  mounted() {
    const homeService = new HomeService();
    homeService.getHomeInitData();
  },
  methods: {
    getTitle(section: string, name: string) {
      return getTitleTranslate(section, name);
    },
  },
};
</script>
<style lang="sass" scoped>
.promotion-item:first-child
    border-left: 1px solid var(--bs-light-border-subtle)  !important

.promotion-item:last-child
    border-right: 1px solid var(--bs-light-border-subtle)  !important

.promotion-list
    height: 60px
</style>
