const appDomain = import.meta.env.VITE_APP_DOMAIN;
if (appDomain && location.hostname !== appDomain) {
  location.href = location.protocol + "//" + appDomain;
}

import { createApp } from "vue";
import router from "./router";
import { store, key } from "./store";
import axios from "./axios-config.ts";
import VueAxios from "vue-axios";
import VueLazyload from "vue-lazyload";
import App from "./App.vue";
import initSentry from "./sentry-config.ts";
import "./validate";

import { createI18n } from "vue-i18n";
import esMX from "./locales/es-MX.json";

// Normalice
import "normalize.css";

// Styles
import "bootstrap";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./scss/custom.scss";
import "./scss/styles.scss";
import "./scss/slider.scss";
import "./scss/ux-styles.scss";

// Swiper
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/thumbs";
import "swiper/css/effect-fade";

// Font Awesome Icons
import "@/fa-icons.ts";

import { VITE_FACEBOOK_APP_ID } from "./config/constants.js";

import FacebookSDK from "./plugins/facebook-sdk";
import XTwitterSDK from "./plugins/x-twitter-sdk";

import loadimage from "./assets/images/loading.gif";
import errorimage from "/images/imagen-no-disponible.png";
import Notifications from "@kyvg/vue3-notification";

const facebookAppId = VITE_FACEBOOK_APP_ID;

const i18n = createI18n<[typeof esMX], "es-MX">({
  legacy: false,
  locale: "es-MX",
  messages: {
    "es-MX": esMX,
  },
});

// Hacer los scripts de Bootstrap accesibles en toda la aplicación

const app = createApp(App)
  .use(router)
  .use(store, key)
  .use(VueAxios, axios)
  .use(i18n)
  .use(VueLazyload, {
    preLoad: 0,
    error: errorimage,
    loading: loadimage,
    attempt: 1,
  })
  .use(FacebookSDK, { appId: facebookAppId })
  .use(XTwitterSDK, {})
  .use(Notifications);

initSentry(app);

app.mount("#app");
