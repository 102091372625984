<template>
  <div
    s
    class="no-internet custom-badge w-100 py-2 fs-7 rounded-0"
    :class="{ 'badge-red': !onLine, 'badge-turcoise': onLine }"
    role="alert"
  >
    {{
      !onLine
        ? "Sin conexión a internet."
        : showBackOnline
          ? "¡De nuevo en línea!"
          : null
    }}
  </div>
</template>
<script lang="ts">
export default {
  props: {
    onLine: {
      type: Boolean,
      default: true,
    },
    showBackOnline: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
