<template lang="">
  <div
    v-element-hover="onHover"
    :class="{
      'd-flex mb-lg-2 mb-xxl-4 margin-include': isVerticalItem,
      'btn-promotion d-flex flex-column justify-content-center align-content-center p-1':
        !isVerticalItem,
    }"
  >
    <Transition name="fade-up" mode="out-in">
      <img
        v-if="!isHovered && image"
        v-lazy="image"
        class="image"
        :class="{ 'mx-0': isVerticalItem, 'mx-auto': !isVerticalItem }"
      />
    </Transition>
    <Transition name="fade-down" mode="out-in">
      <span
        v-if="!isVerticalItem ? isHovered : !isHovered"
        class="fs-8 text-secondary"
        :class="{
          'subtitle-product-includes text-nowrap d-flex justify-content-between w-100':
            isVerticalItem,
          'text-center text-wrap': !isVerticalItem,
        }"
      >
        <slot name="text" />
        <i
          v-if="isVerticalItem"
          class="bi bi-plus-circle text-secondary icon-plus-product"
        />
      </span>
    </Transition>
  </div>
</template>
<script lang="ts">
import { vElementHover } from "@vueuse/components";
export default {
  directives: {
    "element-hover": vElementHover,
  },
  props: {
    image: {
      type: String,
      default: null,
      required: true,
    },
    isVerticalItem: {
      type: Boolean,
      default: null,
    },
  },
  data: function () {
    return {
      isHovered: false,
    };
  },
  methods: {
    onHover(state: boolean) {
      if (!this.isVerticalItem) {
        this.isHovered = state;
      }
    },
  },
};
</script>
<style lang="sass" scoped>
.btn-promotion
    height: 60px

.image
    object-fit: contain
    height: 15px
    @media (min-width: 992px)
        height: 20px

.fade-up-enter-active,
.fade-up-leave-active
    transition: all 0.3s ease


.fade-up-enter-from,
.fade-up-leave-to
    opacity: 0
    transform: translateY(-20px)

.fade-down-enter-active,
.fade-down-leave-active
    transition: all 0.3s ease


.fade-down-enter-from,
.fade-down-leave-to
    opacity: 0
    transform: translateY(20px)
</style>
