<script lang="ts">
import {
  defineComponent,
  ref,
  onMounted,
  watch,
  PropType,
  nextTick,
} from "vue";
import OrderDetailMin from "./OrderDetailMin.vue";
import HiddenCode from "@/components/profile/HiddenCode.vue";
import TabTitle from "@/interfaces/TabTitle";
import { TransactionService } from "@/services/TransactionService";
import { ProductOrderDetail } from "@/interfaces/ProductOrderDetail";
import { formatPrice } from "../utils/pricing";

import Jsona from "jsona";
import eventBus from "@/event-bus";
import { useRouter } from "vue-router";
import { TransactionDetail } from "@/models/TransactionDetail";

import TransactionChatMessage, {
  ITransactionChatMessage,
} from "../models/TransactionChatMessage";

import { TRANSACTIONS_ORDERS_STATUS_CANCELLED } from "@/config/transaction";

const transactionService = new TransactionService();
const dataFormatter = new Jsona();

export default defineComponent({
  components: {
    OrderDetailMin,
    HiddenCode,
  },
  props: {
    showOrderDetails: {
      type: Boolean,
      default: false,
    },

    selectedOrderId: {
      type: String,
      default: "",
    },

    order: {
      type: Object,
      default: null,
    },
    products: {
      type: Array as PropType<TransactionDetail[]>,
      default: () => [],
    },

    showWarrantySelection: {
      type: Boolean,
      required: true,
    },
    byUrl: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  emits: ["warrantySelected", "orderSelected"],

  setup(props, { emit }) {
    const imageErrors = ref(false);
    const filteredProducts = ref<ProductOrderDetail[]>([]);
    const showCode = ref(false);
    const productsToShow = ref([...props.products]);
    const orderToShow = ref(props.order);
    const conversation = ref<ITransactionChatMessage[]>([]);
    const loading = ref(false);
    const router = useRouter();
    const isDataLoaded = ref(false);

    // Update the page title
    TabTitle("Detalle del pedido");

    const fetchTransactionDetails = async () => {
      if (props.byUrl) {
        try {
          loading.value = true;
          const response = await transactionService.showTransaction(
            props.selectedOrderId,
          );
          if (response && props.byUrl) {
            const data = dataFormatter.deserialize(response);
            orderToShow.value = data;
          }
          if (response) {
            productsToShow.value =
              response.data.relationships.transactionDetails.meta;
            loading.value = false;
          }
        } catch (error) {
          console.error("Error fetching transaction details:", error);
        }
      }
    };

    const getConversation = async () => {
      conversation.value = await TransactionChatMessage.fetchByTransaction(
        props.selectedOrderId,
      );
    };

    const loadData = () => {
      setTimeout(() => {
        isDataLoaded.value = true;
      }, 1000);
    };

    onMounted(() => {
      loadData();
      if (router.currentRoute.value.query.print === "true") {
        watch(isDataLoaded, (newVal) => {
          if (newVal) {
            nextTick(() => {
              printOrder2();
            });
          }
        });
      }
      getConversation();
      fetchTransactionDetails();
    });

    const warrantySelected = () => {
      emit("warrantySelected");
    };

    const orderSelected = () => {
      emit("orderSelected");
    };

    const beforeEnter = (el: Element) => {
      const element = el as HTMLElement;
      element.style.opacity = "0";
    };

    const enter = (el: Element, done: () => void) => {
      const element = el as HTMLElement;
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      element.offsetHeight; // Trigger reflow
      element.style.transition = "opacity 1s";
      element.style.opacity = "1";
      done();
    };

    const leave = (el: Element, done: () => void) => {
      const element = el as HTMLElement;
      element.style.transition = "none";
      element.style.opacity = "0";
      done();
    };

    const activePath = (to: string, name: string) => {
      const migas = { to: to, name: name };
      localStorage.setItem("activeBreadcrumbOrder", JSON.stringify(migas));
      eventBus.emit("show-breadcrumb", migas);
      router.push(to);
    };

    const printOrder = (to: string, print: boolean) => {
      router.push({
        path: to,
        query: { print: print ? "true" : "false" },
      });
    };

    const printOrder2 = () => {
      window.print();
    };
    watch(
      () => props.products,
      (newProducts) => {
        productsToShow.value = [...newProducts];
      },
    );

    watch(
      () => props.order,
      (newOrder) => {
        orderToShow.value = newOrder;
      },
    );

    return {
      TRANSACTIONS_ORDERS_STATUS_CANCELLED,
      imageErrors,
      filteredProducts,
      showCode,
      productsToShow,
      orderToShow,
      warrantySelected,
      orderSelected,
      beforeEnter,
      enter,
      leave,
      formatPrice,
      conversation,
      loading,
      activePath,
      printOrder,
      loadData,
    };
  },
});
</script>

<template>
  <!-- <div class="order-detail-container d-lg-flex align-items-center"> -->
  <div>
    <div v-if="!showOrderDetails && !showWarrantySelection" class="row g-0">
      <div
        class="col-12 d-flex justify-content-between align-items-center d-lg-none mt-1"
      >
        <p class="text-success creation-date p-0 m-0">
          {{ orderToShow.created_simple }}
        </p>
        <a
          class="text-success see-order-detail d-flex align-items-center text-decoration-none p-0 m-0"
          @click="
            activePath(
              'pedidos' + '/' + orderToShow.id,
              `Detalle del pedido ${orderToShow.id}`,
            )
          "
        >
          <span>Ver detalle</span>
          <i class="bi bi-plus-circle fs-6 ms-1"></i>
        </a>
      </div>
      <hr class="line w-100 mx-auto d-lg-none" />
      <div class="col-12 col-lg-1 d-none d-lg-flex align-items-center">
        <!-- Ir al detalle de pedido que es éste mismo componente -->
        <a
          @click="
            activePath(
              'pedidos' + '/' + orderToShow.id,
              `Detalle del pedido ${orderToShow.id}`,
            )
          "
        >
          <div class="rounded bg-light" style="width: 60px; height: 60px" />
        </a>
      </div>
      <div class="col-12 col-lg-2 mb-2 mb-lg-0">
        <div
          class="border-medium d-flex flex-lg-column flex-row-reverse justify-content-between align-items-center align-items-lg-start px-0 px-xl-0"
        >
          <p class="text-blue order-number mx-2 m-lg-0">
            {{ orderToShow.folio }}
          </p>
          <p class="text-secondary text-order-number text-nowrap ms-2 ms-lg-0">
            Número del pedido
          </p>
        </div>
        <div class="d-none d-lg-block">
          <p class="text-secondary text-creation-date mt-3">
            Fecha de creación
          </p>
          <p class="text-secondary creation-date mt-2">
            {{ orderToShow.created_simple }}
          </p>
        </div>
      </div>
      <div class="col-12 col-lg-1 d-lg-flex align-items-center mb-2 mb-lg-0">
        <div
          class="border-medium d-flex flex-row-reverse justify-content-between align-items-center flex-lg-column align-items-lg-start px-2 px-xl-0"
        >
          <p
            class="status-pending"
            :class="{
              'text-danger':
                orderToShow.simple_payment_status_text === 'Pendiente',
              'text-success':
                orderToShow.simple_payment_status_text === 'Pagado',
            }"
          >
            {{ orderToShow.simple_payment_status_text }}
          </p>
          <p class="text-secondary payment">Pago</p>
        </div>
      </div>
      <div class="col-12 col-lg-2 d-lg-flex align-items-center mb-2 mb-lg-0">
        <div
          class="border-medium d-flex flex-row-reverse justify-content-between align-items-center flex-lg-column align-items-lg-start px-2 px-xl-0"
        >
          <hidden-code :code="orderToShow?.delivery_code?.code" />
        </div>
      </div>
      <div class="col-12 col-lg-2 d-lg-flex align-items-center mb-2 mb-lg-0">
        <div
          class="border-medium d-flex flex-row-reverse justify-content-between align-items-center flex-lg-column align-items-lg-start px-2 px-xl-0"
        >
          <p class="text-secondary courier-name p-0 m-0">
            {{ orderToShow.courier || "SIN PAQUETERÍA" }}
          </p>
          <p class="text-secondary selected-shipping mt-md-2">
            Envio seleccionado
          </p>
        </div>
      </div>
      <div class="col-lg-2 d-none d-lg-flex flex-column justify-content-center">
        <template v-if="orderToShow.simple_payment_status_text !== 'Pendiente'">
          <div v-if="orderToShow.delivery_date">
            <p class="text-dark courier-name p-0 m-0">
              {{ orderToShow.delivery_date }}
            </p>
            <p class="text-secondary selected-shipping p-0">Fecha de entrega</p>
          </div>
          <div class="mt-1">
            <p class="text-secondary date-order-placed p-0 m-0">
              {{ orderToShow.created_simple }}
            </p>
            <p class="text-secondary order-placed-on p-0">
              Pedido realizado el
            </p>
          </div>
        </template>
      </div>
      <div class="col-12 col-lg-1 d-lg-flex align-items-center">
        <div
          class="border-medium d-flex flex-row-reverse justify-content-between align-items-center flex-lg-column align-items-lg-start px-2 px-xl-0"
        >
          <div class="d-flex align-items-center">
            <p class="p-0 fs-8 fw-bold">
              {{ orderToShow.payment_method || "N/D" }}
            </p>
            <i
              class="bi bi-file-earmark-arrow-down-fill d-lg-none ms-2 text-blue fs-4"
            ></i>
          </div>

          <div class="text-secondary small-xs text-nowrap">Método de pago</div>
          <button
            class="pointer text-decoration-none d-none d-xl-block text-nowrap border-0 bg-white"
            @click="printOrder('pedidos' + '/' + orderToShow.id, true)"
          >
            <i class="bi bi-download text-blue small-sm"></i>
            <span class="text-blue ms-1 small-xs">Descargar</span>
          </button>
        </div>
      </div>
      <div
        v-if="!showOrderDetails && !showWarrantySelection"
        class="col-12 col-lg-1 detail-links d-flex flex-column align-items-end justify-content-center"
      >
        <a
          class="text-blue see-order-detail text-decoration-none d-none d-lg-block"
          @click="
            activePath(
              'pedidos' + '/' + orderToShow.id,
              `Detalle del pedido ${orderToShow.id}`,
            )
          "
        >
          Ver detalle
        </a>
        <a
          v-if="orderToShow.status != TRANSACTIONS_ORDERS_STATUS_CANCELLED"
          class="text-blue see-order-detail text-decoration-none ms-lg-6 d-block mt-1 me-1"
          @click.prevent.stop="warrantySelected()"
        >
          Garantía
        </a>
      </div>
    </div>
    <!-- Detalle activado o activo -->
    <div
      class="row g-0"
      :class="{ 'border border-light border': showOrderDetails }"
    >
      <div
        :class="{
          'col-12 col-lg-2': !showOrderDetails && !showWarrantySelection,
          'col-12 col-lg-10': showOrderDetails,
        }"
      >
        <Transition
          name="fade"
          @before-enter="beforeEnter"
          @enter="enter"
          @leave="leave"
        >
          <div v-if="showOrderDetails" class="container-detail-order p-2">
            <div class="row g-0">
              <div class="col-12 d-lg-none">
                <div
                  class="d-flex justify-content-between align-items-center d-lg-none p-2"
                >
                  <p class="text-secondary creation-date p-0 m-0 fw-bold">
                    {{ orderToShow.created_simple }}
                  </p>
                  <a
                    class="text-gray see-order-detail d-flex align-items-center text-decoration-none p-0 m-0"
                  >
                    <span>Ver detalle</span>
                    <i class="bi bi-plus-circle fs-6 ms-1"></i>
                  </a>
                </div>
                <div class="px-2">
                  <hr class="line d-lg-none" />
                </div>
              </div>
              <div v-if="loading">
                <p class="card-text placeholder-glow">
                  <span
                    v-for="n in 7"
                    :key="n"
                    class="placeholder col-12 rounded-2 mb-2 bg-light"
                    style="height: 60px"
                  ></span>
                  <span
                    class="placeholder col-12 rounded-2 bg-light"
                    style="height: 195px"
                  ></span>
                </p>
              </div>
              <div
                v-if="!loading"
                class="order-detail-titles bg-light px-3 py-3 py-lg-4 rounded text-secondary mb-2"
              >
                <div class="row">
                  <div class="col-6 col-md-9">
                    <router-link
                      :to="'/perfil/pedidos'"
                      class="text-decoration-none"
                    >
                      <p class="p-0 m-0 text-nowrap">Artículos del pedido</p>
                    </router-link>
                  </div>
                  <div class="col-6 col-lg-3 d-flex justify-content-between">
                    <p class="p-0 m-0">Piezas</p>
                    <p class="p-0 m-0">Precio</p>
                  </div>
                </div>
              </div>

              <div v-if="!loading" class="col-12">
                <div class="d-flex flex-column print-order">
                  <div
                    v-for="(product, index) in productsToShow"
                    :key="index"
                  >
                  <router-link
                    :to="{
                      name: 'product',
                      params: { slug: product?.slug },
                    }"
                    class="px-0 img-and-product-name container-fluid d-flex rounded mb-2 text-decoration-none"
                  >
                    <div
                      class="col-7 col-lg-9 d-flex flex-row align-items-center gap-0"
                    >
                      <div class="d-flex justify-content-end align-items-center img-product ms-2 ms-lg-1"
                      >
                        <img
                          v-if="!imageErrors"
                          v-lazy="product.coverImage"
                          class="img-fluid rounded img-order"
                          @error="imageErrors = true"
                        />
                      </div>
                      <div>
                        <p class="product-name text-secondary ms-1 ms-lg-0">
                          {{ product.name }}
                        </p>
                      </div>
                    </div>
                    <div
                      class="col-5 col-lg-3 row align-items-center my-2 border-left"
                    >
                      <p class="col-6 col-lg-6 text-end piece">
                        <span class="border-right d-flex align-items-center">
                          {{ product.quantity }}
                        </span>
                      </p>
                      <p
                        class="col-6 col-lg-6 text-end price"
                        v-html="formatPrice(product.totalPaid)"
                      ></p>
                    </div>
                  </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Transition>
      </div>
      <div class="col-12 col-lg-2">
        <div v-if="!loading" class="px-4 px-lg-0">
          <order-detail-min v-if="showOrderDetails" :order="orderToShow" />
        </div>
        <p v-else class="placeholder-glow mt-2">
          <span
            class="placeholder col-11 rounded-2 bg-light"
            style="height: 100vh"
          ></span>
        </p>
      </div>

      <div v-if="!loading" class="col-12 col-lg-10">
        <div class="container-fluid pb-3">
          <div
            v-if="showOrderDetails"
            class="container container-detail-order mt-2 bg-light py-3 py-lg-4 rounded-3 totals"
          >
            <div class="row">
              <div
                class="col-12 d-flex justify-content-between text-success mb-2"
              >
                <p class="shipping">Envío</p>
                <p
                  class="shipping-price"
                  v-html="orderToShow.shipping_cost"
                ></p>
              </div>
              <div class="col-12 d-flex justify-content-between text-success">
                <p class="package-insurance">Seguro de paquete</p>
                <p
                  class="package-insurance-amount"
                  v-html="orderToShow.shipping_insurance_cost"
                ></p>
              </div>
              <div
                v-if="orderToShow.discount > 0"
                class="col-12 d-flex justify-content-between text-blue mt-4 mt-lg-3 mb-2"
              >
                <p class="discount">Descuento</p>
                <p
                  class="discount-amount"
                  v-html="orderToShow.discount_html"
                ></p>
              </div>
              <div
                class="col-12 d-flex justify-content-between mb-4"
                :class="{ 'mt-3': orderToShow.discount < 1 }"
              >
                <p class="vat">IVA</p>
                <p class="vat-amount" v-html="orderToShow.iva"></p>
              </div>
              <div class="col-12 d-flex justify-content-between text-dark">
                <p class="text-total text-dark">Total</p>
                <p
                  class="total-amount text-dark"
                  v-html="orderToShow.total"
                ></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Transition v-if="true" name="fade">
      <div v-if="showOrderDetails && !loading">
        <div class="row g-0 mt-3 container-contact-advisor">
          <router-link
            v-if="conversation.length === 0"
            :to="{ name: 'order-chat', params: { orderId: selectedOrderId } }"
            class="btn-contact-advisor mb-5 text-white d-flex align-items-center px-3"
          >
            Contacta un asesor
          </router-link>
          <div v-if="conversation.length > 0" class="col-12 container-chat">
            <div class="content-chat d-flex justify-content-center">
              <div class="text-center w-100 none-overflow">
                <chat-content :conversation="conversation" />
              </div>
            </div>
            <router-link
              :to="{ name: 'order-chat', params: { orderId: selectedOrderId } }"
              class="py-2 d-flex align-items-center justify-content-center text-danger"
            >
              <span class="text-center">Ver o escribir más mensajes</span>
              <i class="bi bi-box-arrow-in-up-right ms-1"></i>
            </router-link>
          </div>
        </div>
      </div>
    </Transition>
  </div>
</template>

<style>
@media print {
  body .container-contact-advisor {
    display: none;
  }
}
</style>
