<template>
  <div class="d-grid gap-2 bg-dark">
    <button
      class="btn-auth"
      :class="classEditable"
      data-cy="button-auth"
      :disabled="isSubmitting"
    >
      <div class="d-flex align-items-center justify-content-center">
        <custom-spinner v-if="isSubmitting" />
        <span class="ms-2">{{ buttonText }}</span>
      </div>
    </button>
  </div>
</template>

<script lang="ts">
import CustomSpinner from "./CustomSpinner.vue";

export default {
  components: {
    CustomSpinner,
  },
  props: {
    buttonText: {
      type: String,
      default: "Enviar",
    },
    isSubmitting: {
      type: Boolean,
      default: false,
    },
    classEditable: {
      type: String,
      default: null,
    },
  },
  computed: {
    text() {
      return this.buttonText || this.$t("Send");
    },
  },
};
</script>

<style lang="scss" scoped>
@use "@/scss/auth";
</style>
