import axios, { AxiosError, AxiosResponse, AxiosRequestConfig } from "axios";
import { store } from "@/store";
import {
  SHIPPING_DELIVERY,
  SHIPPING_PICK_UP,
  VITE_API_ENDPOINT,
} from "@/config/constants.js";

export class TransactionService {
  public async getTransactionInitData() {
    const translations =
      store.getters["translations/getSection"]("transaction");

    if (translations) {
      return translations;
    }
    const response = await axios.get("/eshop/transaction/init-data");
    if (response) {
      if (
        response.data &&
        response.data.meta &&
        response.data.meta.translations
      ) {
        store.commit("translations/setSection", {
          transaction: response.data.meta.translations,
        });
      }
      return response.data;
    }
  }

  public async createTransaction() {
    const customer = store.getters["auth/getCustomer"];
    const cart = store.getters["cart/getCart"];
    const items = store.getters["cart/getItems"];
    const monthlyPaymentGroups =
      store.getters["cart/getMonthlyPaymentGroups"] ?? [];
    const billingCustomerAddress =
      store.getters["checkout/getBillingCustomerAddress"];
    const customerAddress = store.getters["checkout/getCustomerAddress"];
    const paymentMethodCode = store.getters["checkout/getPaymentMethodCode"];
    const tokenizable = store.getters["checkout/getSelectedPaymentTokenizable"];
    const tokenizableId = store.getters["checkout/getPaymentTokenizable"];
    const courier = store.getters["checkout/getCourier"];
    const monthlyPlans = store.getters["checkout/getMonthlyPlans"];
    const isShipping = store.getters["checkout/getIsShipping"];
    const couponCode = store.getters["cart/getCouponCode"];
    const cvcValidate = store.getters["checkout/getCvcValidate"];
    let deviceSessionId = store.getters["checkout/getDeviceSessionId"];
    const trackingId = store.getters["checkout/getTrackingId"];

    let finalDeviceSessionId = null;
    if (paymentMethodCode.toLowerCase().includes("openpay")) {
      if (!deviceSessionId) {
        store.dispatch("checkout/resetDeviceSessionId");
        deviceSessionId = store.getters["checkout/getDeviceSessionId"];
      }
      finalDeviceSessionId = deviceSessionId;
    } else if (paymentMethodCode.toLowerCase().includes("paypal")) {
      finalDeviceSessionId = trackingId;
    }

    const groups = monthlyPaymentGroups.map((group: any) => {
      return {
        items: group.items,
        current_plan: group.current_plan,
        amount: group.amount,
      };
    });

    const jsonItems: Array<any> = [];
    items.forEach((item: any) => {
      const jsonItem = {
        id: item.id,
        product_id: item.product.id,
        // product_name: item.product.name,
        price: item.price,
        quantity: item.quantity,
        // subtotal: item.subtotal,
        // discount: item.discount,
        // tax: item.tax,
        total: item.total,
        // price_detail: {
        //   promotion_id: item.product.price_detail.promotion_id,
        //   promotion_quantity: item.product.price_detail.promotion_quantity,
        //   // promotion_price: item.product.price_detail.promotion_price,
        //   // promotion_discount: item.product.price_detail.promotion_discount,
        //   promotion_total: item.product.price_detail.promotion_total,
        //   promotion_type: item.product.price_detail.promotion_type,
        //   // standard_cost: item.product.price_detail.standard_cost,
        //   total: item.product.price_detail.total,
        //   // common_total: item.product.price_detail.common_total,
        //   // common_quantity: item.product.price_detail.common_quantity,
        //   // common_price: item.product.price_detail.common_price,
        //   offer_month_interest_amount:
        //     item.product.price_detail.offer_month_interest_amount,
        //   offer_month_discount_amount:
        //     item.product.price_detail.offer_month_discount_amount,
        //   offer_month_wallet_amount:
        //     item.product.price_detail.offer_month_wallet_amount,
        // },
      };
      jsonItems.push(jsonItem);
    });

    const body = {
      shipping_type: isShipping ? SHIPPING_DELIVERY : SHIPPING_PICK_UP,
      shipping_required: cart.shippingRequired,
      payment_method_code: paymentMethodCode,
      skip_billing_address: billingCustomerAddress ? 0 : 1,
      shipping_promotion_id: cart.shippingPromotionId,
      shipping_promotion_products: cart.shippingPromotionProducts,
      pickup_store_id: cart.pickupStoreId,
      pickup_person: cart.pickupPerson,
      total: cart.total,
      credit_to_use: cart.creditToUse,
      wallet_to_use: cart.walletToUse,
      delivery_address_id: customerAddress ? customerAddress.id : null,
      billing_address_id: billingCustomerAddress
        ? billingCustomerAddress.id
        : null,
      shipping_insurance: cart.shippingInsurance,
      courier_id: courier ? courier.id : null,
      order_comments: null,
      coupon_code: couponCode,
      coupon_discount: cart.couponDiscount,
      customer_id: customer ? customer.id : null,
      items: jsonItems,
      monthly_payment: {
        plans: monthlyPlans,
        groups: groups,
      },
      subtotal: cart.subtotal,
      tax: cart.tax,
      promotion_discount: cart.promotionDiscount,
      total_discount: cart.totalDiscount,
      shipping: cart.shipping,
      shipping_discount: cart.shippingDiscount,
      payment_tokenizable: tokenizable
        ? tokenizable.payment_tokenizable_encoded
        : tokenizableId
          ? tokenizableId
          : null,
      payment_device_session_id: finalDeviceSessionId
        ? finalDeviceSessionId
        : null,
      cvc: cvcValidate,
      wallet_promotion_amount: cart.walletPromotionAmount,
      wallet_promotion_details: JSON.stringify(cart.walletPromotionDetails),
    };
    const response = await axios.post("/eshop/transaction/create", body);
    if (!(response instanceof AxiosError)) {
      store.dispatch("checkout/resetCheckout", true);
      store.commit("cart/resetCart");
      return response;
    } else if (response.response) {
      const resp: AxiosResponse = response.response;
      return resp.data;
    } else {
      return null;
    }
  }

  public async showTransaction(transactionId: string) {
    const options: AxiosRequestConfig = {
      baseURL: VITE_API_ENDPOINT,
      headers: {
        Accept: "application/vnd.api+json",
      },
    };
    const url = `/shop-v1/transactions/${encodeURIComponent(transactionId)}`;
    const response = await axios.get(url, options);
    if (!(response instanceof AxiosError)) {
      return response.data;
    } else if (response.response) {
      const resp: AxiosResponse = response.response;
      return resp.data;
    } else {
      return null;
    }
  }

  public async getTransactions() {
    const options: AxiosRequestConfig = {
      baseURL: VITE_API_ENDPOINT,
      headers: {
        Accept: "application/vnd.api+json",
      },
    };
    const url = `/shop-v1/transactions`;
    const response = await axios.get(url, options);
    if (!(response instanceof AxiosError)) {
      return response.data;
    } else if (response.response) {
      const resp: AxiosResponse = response.response;
      return resp.data;
    } else {
      return null;
    }
  }

  public async showTransactionPayments(transactionId: string) {
    const options: AxiosRequestConfig = {
      baseURL: VITE_API_ENDPOINT,
      headers: {
        Accept: "application/vnd.api+json",
      },
    };
    const url = `/shop-v1/transactions/${encodeURIComponent(transactionId)}/payments`;
    const response = await axios.get(url, options);
    if (!(response instanceof AxiosError)) {
      return response.data;
    } else if (response.response) {
      const resp: AxiosResponse = response.response;
      return resp.data;
    } else {
      return null;
    }
  }

  public async showTransactionPayment(
    transactionPaymentId: string,
    include: string,
  ) {
    const options: AxiosRequestConfig = {
      baseURL: VITE_API_ENDPOINT,
      headers: {
        Accept: "application/vnd.api+json",
      },
    };
    let url = `/shop-v1/transaction-payments/${encodeURIComponent(transactionPaymentId)}`;
    if (include) {
      url += `?include=${include}`;
    }
    const response = await axios.get(url, options);
    if (!(response instanceof AxiosError)) {
      return response.data;
    } else if (response.response) {
      const resp: AxiosResponse = response.response;
      return resp.data;
    } else {
      return null;
    }
  }
}
