<template>
  <div class="accordion-option mt-3 mb-2">
    <div class="row py-xl-4">
      <div class="d-flex">
        <div class="col-lg-2 col-xl-1 d-none d-lg-block">
          <div
            class="d-flex align-items-center justify-content-around h-100 courier-section-radio"
          >
            <input
              id="select-direction"
              type="radio"
              name="radio-group"
              class="custom-radio-input green-radio-input"
              :checked="isShipping"
            />
            <label for="select-direction" class="custom-radio-label" />
            <i v-if="!showAccordion" class="bi bi-geo-alt fs-3" />
            <font-awesome-icon
              v-if="showAccordion"
              icon="fa-solid fa-location-dot"
              class="icon-cart me-2 text-success fs-1"
            />
          </div>
        </div>
        <div class="col-lg-10 col-xl-8" :class="{ 'col-xl-11': showAccordion }">
          <div class="d-flex pointer" @click="togglePanel">
            <i class="bi bi-geo-alt d-lg-none mt-1" />
            <h3 class="title-accordion-option ms-2 ms-lg-0">
              {{ getTitle("cart", "my_address") }}
              <!-- Mis direcciones -->
            </h3>
          </div>
          <p class="text-nowrap text-select-a-courier">
            {{ getTitle("cart", "select_or_create_new_directions") }}
            <!-- Selecciona o crea nuevas direcciones para recibir tu paquete. -->
          </p>
          <div
            v-if="showAccordion"
            class="accordion-option-shipping overflow-auto"
            :class="{
              closed: isCourierActive || isBranchActive,
              'hide-parent': showAddAddressForm,
            }"
          >
            <div
              v-for="(item, index) in items"
              :key="index"
              class="row direction-actived py-xl-3 mb-2 me-1 mx-2"
              :class="{
                'active-available-products': selectedAddress?.id == item.id,
              }"
              @click="selectAddress(item)"
            >
              <div class="col-12 my-2">
                <div class="text-end d-xl-none">
                  <link-remove-cart-item
                    :product-id="item.id"
                    :link-text="getTitle('cart', 'remove')"
                    class="small"
                    @click="deleteAddress(item)"
                  />
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-6 col-xl-2 pointer">
                <div
                  class="d-flex flex-lg-column flex-row-reverse justify-content-between"
                >
                  <span class="fw-bold mb-1 line-clamp-2">
                    {{ item?.name }}
                  </span>
                  <span>
                    {{ getTitle("cart", "location_name") }}
                  </span>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-6 col-xl-2">
                <div
                  class="d-flex flex-lg-column flex-row-reverse justify-content-between text-lg-end"
                >
                  <span class="fw-bold mb-1 line-clamp-2">
                    {{ item.street_name }}
                  </span>
                  <span>
                    {{ getTitle("cart", "street") }}
                  </span>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-6 col-xl-2">
                <div
                  class="d-flex flex-lg-column flex-row-reverse justify-content-between"
                >
                  <span class="fw-bold mb-1 line-clamp-2">
                    {{ item?.settlement?.name }}
                  </span>
                  <span>
                    {{ getTitle("cart", "neighborhood_name") }}
                  </span>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-6 col-xl-2">
                <div
                  class="d-flex flex-lg-column flex-row-reverse justify-content-between text-lg-end"
                >
                  <span class="fw-bold mb-1 line-clamp-1">
                    {{ item.postal_code }}
                  </span>
                  <span>
                    {{ getTitle("cart", "postal_code") }}
                  </span>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-6 col-xl-2">
                <div
                  class="d-flex flex-lg-column flex-row-reverse justify-content-between"
                >
                  <span class="fw-bold mb-1 line-clamp-1">
                    {{ item.telephone }}
                  </span>
                  <span>
                    {{ getTitle("cart", "telephone_contact") }}
                  </span>
                </div>
              </div>
              <div
                class="col-xl-2 d-none d-lg-flex flex-row justify-content-end align-items-center gap-2"
              >
                <button class="btn btn-danger" @click="deleteAddress(item)">
                  <i class="bi bi-trash fs-5" />
                </button>
                <button
                  class="btn btn-info text-white"
                  @click="editOrDeleteOtion(item, 'update')"
                >
                  <i class="bi bi-pencil text-white fs-5" />
                </button>
              </div>
              <div
                class="col-12 mt-3 d-xl-none mb-3"
                :class="{ 'd-lg-none': !showAccordion }"
              >
                <div class="d-flex align-items-center h-75 small float-end">
                  <button
                    class="btn text-blue text-decoration-none"
                    @click="editOrDeleteOtion(item, 'update')"
                  >
                    <i class="bi bi-pencil text-blue me-1" />
                    {{ getTitle("cart", "edit") }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div :class="{ 'row mt-2 mb-2': shouldShowAccordion }">
        <div class="col-lg-1" />
        <div
          class="col-12 col-lg-4 col-xl-3 d-flex justify-content-between text-secondary float-start float-md-end negative-top-35 pointer"
          :class="{ 'd-none d-lg-block': !showAccordion }"
          @click="addNewAddress"
        >
          <div class="d-flex">
            <i
              class="bi bi-plus-circle-fill me-2"
              :class="{ 'text-blue': shouldShowAccordion }"
            />
            <a
              class="text-decoration-none text-add-address"
              :class="[shouldShowAccordion ? 'text-blue' : 'text-secondary']"
            >
              {{ getTitle("cart", "add_new_address") }}
              <!-- Agregar nueva dirección -->
            </a>
          </div>
        </div>
      </div>
    </div>
    <!-- se oculta mientras tanto en pantallas grandes porque no hay diseño, pero en diseño movil si está activo-->
    <div v-if="showAccordion" class="">
      <div v-if="showAddAddressForm" class="container">
        <add-address
          :selected-address="selectedAddress"
          :action="action"
          @update-addresses="updateAddresses"
          @update-show-address="updateShowAddress"
        />
      </div>
      <div v-if="selectedAddress && !showAddAddressForm" class="row">
        <couriers-accordion :show-couriers="showCouriers" />
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import eventBus from "@/event-bus";
import { getTitleTranslate } from "../../utils/general";
import AddAddress from "./address/AddAddress.vue";
import CouriersAccordion from "./CouriersAccordion.vue";
import { ItemDirecctions } from "@/interfaces/ItemDirecctions";
import LinkRemoveCartItem from "./LinkRemoveCartItem.vue";
import { ZipCodeService } from "@/services/ZipCodeService";
import { CustomerAddressService } from "@/services/CustomerAddressService";
import { store } from "@/store";
import auth from "@/services/AuthService";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
  components: {
    AddAddress,
    LinkRemoveCartItem,
    CouriersAccordion,
    FontAwesomeIcon,
  },
  props: {
    isCourierActive: {
      type: Boolean,
      default: false,
    },
    isBranchActive: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      showAccordion: false,
      items: [] as ItemDirecctions[],
      showAddAddressForm: false,
      showEditOrDeleteOptions: false,
      action: "new",
      showCouriers: false,
      shippingInsurance: false,
    };
  },
  computed: {
    shouldShowAccordion() {
      return this.showAccordion;
    },
    isUserAuthenticated() {
      return auth.isUserLogged();
    },
    selectedAddress() {
      return store.getters["checkout/getCustomerAddress"];
    },
    isShipping() {
      return store.getters["checkout/getIsShipping"];
    },
  },
  watch: {
    isShipping(newIsShipping) {
      this.showAccordion = newIsShipping;
    },
    selectedAddress(newSelectedAddress) {
      if (newSelectedAddress != null) {
        this.showAccordion = true;
      }
    },
  },
  mounted() {
    this.getAddress();
    if (this.selectedAddress != null) {
      this.showAccordion = true;
    }
  },
  methods: {
    getTitle(section: string, name: string) {
      return getTitleTranslate(section, name);
    },
    togglePanel() {
      this.showAccordion = !this.showAccordion;
      store.commit("checkout/setIsShipping");
    },

    addNewAddress() {
      this.action = "new";
      this.showAddAddressForm = !this.showAddAddressForm;
      this.showAccordion = this.showAddAddressForm;
    },

    editOrDeleteOtion(address: any, action: string) {
      this.action = action;
      // this.showEditOrDeleteOptions = !this.showEditOrDeleteOptions; // desconozco la funcionalidad
      this.selectAddress(address);
      this.showAddAddressForm = true;
    },
    getAddress() {
      const zipCodeService = new ZipCodeService();
      if (this.isUserAuthenticated && zipCodeService) {
        zipCodeService.getAddress().then((response) => {
          if (response) {
            this.items = response.filter((address: any) => {
              return address.is_delivery;
            });
          }
        });
      }
    },
    updateAddresses() {
      this.showAddAddressForm = false;
      this.getAddress();
    },
    deleteAddress(item: any) {
      const customerAddressService = new CustomerAddressService();
      customerAddressService
        .deleteCustomerAddress(item.id)
        .then(async (response: any) => {
          if (response && response.status == 204) {
            this.updateAddresses();
          } else {
            eventBus.emit("error-message-show", { errors: response.errors });
          }
        });
    },
    selectAddress(item: any) {
      store.commit("checkout/setCustomerAddress", item);
      this.showCouriers = true;
    },
    updateShowAddress(show: boolean) {
      this.showAddAddressForm = show;
    },
  },
};
</script>
