<script lang="ts">
import { defineComponent } from "vue";
import { useRoute } from "vue-router";
import WarrantyRequestDetails from "./WarrantyRequestDetails.vue";
import { WarrantyService } from "@/services/WarrantyService";
import { Warranty } from "@/interfaces/Warranties";

interface WarrantyData {
  data: Warranty[];
}

export default defineComponent({
  components: {
    WarrantyRequestDetails,
  },
  data() {
    return {
      warranties: {} as WarrantyData,
      loadingWarranties: true,
      showDetails: false,
      selectedWarranty: {} as Warranty,
      apiError: null as Error | null,
      routeId: null as string | null,
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    const route = useRoute();
    this.routeId = route.params.id ? String(route.params.id) : null;

    this.fetchWarranties();
  },
  methods: {
    getStatusClass(status: string) {
      switch (status) {
        case "Folio generado":
          return "text-bg-warning";
        case "Diagnostico en proceso":
        case "Finalizado":
          return "text-bg-success";
        case "Pago requerido":
        case "Esperando envío":
          return "text-bg-info";
        default:
          return "text-bg-danger";
      }
    },
    convertStatus(status: number): string {
      switch (status) {
        case 0:
          return "Esperando envío";
        case 1:
          return "Recibido";
        case 2:
        case 3:
        case 4:
        case 5:
        case 6:
          return "Diagnostico en proceso";
        case 7:
          return "Pago requerido";
        case 8:
          return "Liberación aceptada, esperando resultado";
        case 9:
          return "Declinado";
        case 10:
          return "Reparación completada";
        case 11:
          return "Finalizado";
        case 12:
          return "Declinado";
        default:
          return "Rechazado";
      }
    },
    viewDetails(warranty: Warranty) {
      if (!this.routeId) {
        this.$router.push({ name: "warranties", params: { id: warranty.id } });
      }
      this.selectedWarranty = warranty;
      this.showDetails = true;
    },
    async fetchWarranties() {
      try {
        const responseData = await new WarrantyService().getWarrantyRequests();
        this.warranties = { data: responseData.data };
        for (const warranty of this.warranties.data) {
          if (warranty.relationships && warranty.relationships.transaction) {
            const transactionData =
              await new WarrantyService().getWarrantyRequestTransaction(
                warranty.id,
              );
            warranty.relationships.transaction = transactionData;
          }
        }

        if (this.routeId) {
          const selectedWarranty = this.warranties.data.find(
            (warranty) => warranty.id === this.routeId,
          );
          if (selectedWarranty) {
            this.viewDetails(selectedWarranty);
          }
        }
      } catch (error) {
        this.apiError = error as Error;
        console.error("Error fetching products:", error);
      } finally {
        this.loadingWarranties = false;
      }
    },
    getCoverImage(id: number) {
      return this.warranties.data[id].relationships.transaction.relationships
        .transactionDetails.meta[0].coverImage;
    },
    getProductName(id: number) {
      return this.warranties.data[id].relationships.transaction.relationships
        .transactionDetails.meta[0].name;
    },
    getCreationDate(id: number): string {
      const date = new Date(this.warranties.data[id].attributes.created_at);
      return new Intl.DateTimeFormat("es-MX", {
        day: "numeric",
        month: "long",
        year: "numeric",
      }).format(date);
    },
    getCaseDetails(id: number) {
      return this.warranties.data[id].attributes.case_details;
    },
    getCaseStatus(id: number) {
      const statusNumber = this.warranties.data[id].attributes.status;
      const statusText = this.convertStatus(statusNumber);
      return statusText;
    },
    onImageError(event: Event) {
      const target = event.target as HTMLImageElement;
      target.src = "/images/imagen-no-disponible.png";
    },
    closeDetails() {
      this.showDetails = false;
    },
  },
});
</script>

<template>
  <div class="container-fluid my-4">
    <div class="col-12">
      <warranty-request-details
        v-if="showDetails"
        :selected-warranty="selectedWarranty"
        @close-details="closeDetails"
      />
    </div>
    <div v-if="!showDetails" class="row">
      <h3 class="warranty-title">Listado de garantías en seguimiento</h3>
      <div v-if="loadingWarranties" class="loading-message">
        Cargando garantías en proceso...
      </div>
      <div
        v-if="!loadingWarranties && warranties.data.length == 0"
        class="loading-message"
      >
        No se encontaron garantías en seguimiento
      </div>
      <div v-else class="product-list">
        <div
          v-for="(warranty, index) in warranties.data"
          :key="index"
          class="col-12 mb-3"
        >
          <div
            v-if="warranty.relationships.transaction.relationships"
            class="card h-100"
          >
            <div class="card-body d-flex align-items-center">
              <img
                :src="getCoverImage(index)"
                alt="Imagen del producto"
                class="img-fluid rounded me-3"
                style="width: 100px; height: 100px; object-fit: cover"
                @error="onImageError"
              />
              <div class="flex-grow-1">
                <h5 class="card-title mb-1">Solicitud: #{{ warranty.id }}</h5>
                <p class="card-text mb-2 text-muted">
                  Fecha de solicitud: {{ getCreationDate(index) }}
                </p>
                <span
                  :class="['badge', getStatusClass(getCaseStatus(index))]"
                  >{{ getCaseStatus(index) }}</span
                >
              </div>
              <div class="flex-grow-1">
                <h5 class="card-title mb-1">{{ getProductName(index) }}</h5>
                <p class="card-text mb-2 text-muted">
                  Detalles añadidos: {{ getCaseDetails(index) }}
                </p>
              </div>
              <button
                class="btn btn-primary ms-3"
                @click="viewDetails(warranty)"
              >
                Ver detalles
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
