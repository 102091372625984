import axios from "axios";
import Model from "./Model";
import Jsona from "jsona";

export interface IProductReview {
  id: string | null;
  customer_name: string | null;
  rating: number;
  opinion: string | null;
  short_opinion: string | null;
  created_utc: string | null;
}

export default class ProductReview extends Model implements IProductReview {
  public id: string | null = null;
  public customer_name: string | null = null;
  public rating: number = 0;
  public opinion: string | null = null;
  public short_opinion: string | null = null;
  public created_utc: string | null = null;

  public constructor({
    id,
    customer_name,
    rating,
    opinion,
    short_opinion,
    created_utc,
  }: IProductReview) {
    super();

    this.id = id || null;
    this.customer_name = customer_name || null;
    this.rating = rating || 0;
    this.opinion = opinion || null;
    this.short_opinion = short_opinion || null;
    this.created_utc = created_utc || null;
  }

  public static async fetchByProduct(productId: string, page: number = 1) {
    const path = `/shop-v1/products/${productId}/reviews`;
    const query: Record<string, string> = {
      "page[number]": page.toString(),
      "fields[product-reviews]":
        "customer_name,rating,opinion,short_opinion,created_utc",
    };
    const queryStr = new URLSearchParams(query).toString();
    const url = path + "?" + queryStr;
    const res = await axios.get(url);
    if (res.status != 200) {
      throw res;
    }
    const jsona = new Jsona();
    const data = jsona.deserialize(res.data);
    const instance = ProductReview.fromArray(data as IProductReview[]);
    return instance;
  }

  public static fromArray(items: IProductReview[]): ProductReview[] {
    return items.map((item: IProductReview) => new ProductReview(item));
  }

  public getCreatedDate() {
    if (!this.created_utc) {
      return null;
    }
    const date = new Date(this.created_utc);
    if (!(date instanceof Date)) {
      return null;
    }
    const Y = date.getFullYear();
    const m = date.getMonth() + 1;
    const d = date.getDate();
    return `${Y}-${m}-${d}`;
  }
}
