<template>
  <div class="position-relative">
    <div
      class="d-flex update-quantity justify-content-center"
      :class="{ 'ms-2': marginLeft }"
      :style="{ 'max-height': maxHeight }"
    >
      <button
        class="border-0 bg-white d-flex justify-content-center align-items-center"
        type="button"
        :disabled="isDecreaseDisabled || disabled || loading"
        @click="decrementQty"
      >
        <span class="icon-change-qty"> - </span>
      </button>
      <input
        type="text"
        min="1"
        class="text-center input-pieces-number border-0"
        :value="formattedQuantity"
        :disabled="disabled || loading"
        @blur="debounceSetQty"
      />
      <button
        class="border-0 bg-white d-flex justify-content-center align-items-center"
        type="button"
        :disabled="isIncreaseDisabled || disabled || loading"
        @click="incrementQty"
      >
        <span class="icon-change-qty"> + </span>
      </button>
    </div>
    <div
      v-if="loading"
      class="position-absolute top-0 start-50 translate-middle-x"
    >
      <div class="spinner-border text-secondary opacity-25" role="status">
        <span class="visually-hidden mx-auto">Loading...</span>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
export default {
  props: {
    product: {
      type: Object,
      required: true,
    },
    quantity: {
      type: Number,
      default: 0,
    },
    itemLoadingState: {
      type: Boolean,
      default: false,
    },
    maxHeight: {
      type: String,
      default: "",
    },
    marginLeft: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["decrement", "increment", "setQty"],

  computed: {
    formattedQuantity() {
      if (
        this.product &&
        this.product.attributes &&
        typeof this.product.attributes.quantity !== "undefined"
      ) {
        const quantity = this.product.attributes.quantity;
        return parseFloat(quantity) === parseInt(quantity)
          ? quantity < 10
            ? "0" + quantity
            : quantity
          : quantity.toFixed(2);
      } else if (typeof this.quantity !== "undefined") {
        return parseFloat(this.quantity.toString()) ===
          parseInt(this.quantity.toString())
          ? this.quantity < 10
            ? "0" + this.quantity
            : this.quantity
          : this.quantity.toFixed(2);
      } else {
        return null;
      }
    },
    isDecreaseDisabled() {
      return this.itemLoadingState || this.quantity <= this.product.step;
    },
    isIncreaseDisabled() {
      return (
        this.itemLoadingState ||
        this.quantity >=
          (this.product.can_purchase_without_stock
            ? this.product.not_required_stock_max_qty
            : this.product.stock_total_fixed)
      );
    },
  },
  methods: {
    decrementQty() {
      this.$emit("decrement", this.product, this.quantity - this.product.step);
    },
    incrementQty() {
      this.$emit("increment", this.product, this.quantity + this.product.step);
    },
    debounceSetQty(event: Event) {
      if (event.target instanceof HTMLInputElement) {
        this.$emit("setQty", this.product, event.target.value);
      }
    },
  },
};
</script>
