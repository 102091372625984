<template>
  <div
    class="cart-height position-relative"
    :class="{ 'overflow-hidden': !showCheckbox }"
    :style="{ minHeight: '100px' }"
  >
    <!-- Componente compartido en ProductSection, CartReviewSection, CartDetailSection, cambia el diseño de acuerdo a los props que se le pasan -->
    <div
      v-for="(assembly, assemblyIndex) in assemblies"
      :key="assemblyIndex"
      :class="{ 'opacity-50': loading }"
    >
      <div v-if="assemblyIndex != 0" class="bg-blue rounded-top p-3">
        <div class="d-flex flex-row justify-content-between align-items-center">
          <h2 class="text-white fs-3 mb-0">
            <span class="fw-bold">{{ getTitle("cart", "components") }}</span>
            {{ getTitle("cart", "of_your_assembly") }}
          </h2>
          <ul
            class="d-flex flex-row align-items-center mb-0 text-white list-buttons-assembly fs-7"
            style="list-style-type: none"
          >
            <li class="px-1">
              <router-link
                v-if="assembly['assembly_config_id'] != '0'"
                :to="{
                  name: 'assembly-edit-with-cart',
                  params: {
                    assemblyId: assembly['assembly_id'],
                    qty: assembly['qty'],
                    assemblyConfig: assembly['assembly_config_slug'],
                  },
                }"
                class="text-white"
              >
                {{ getTitle("cart", "link_modify_assembly") }}
              </router-link>

              <router-link
                v-else
                :to="{
                  name: 'assembly-edit-with-cart',
                  params: {
                    assemblyId: assembly['assembly_id'],
                    qty: assembly['qty'],
                  },
                }"
                class="text-white"
              >
                {{ getTitle("cart", "link_modify_assembly") }}
              </router-link>
            </li>
          </ul>
        </div>
        <div class="my-2">
          <div class="form-check form-switch text-white">
            <input
              id="isAssembly"
              class="form-check-input"
              type="checkbox"
              role="switch"
            />
            <label class="form-check-label" for="isAssembly">
              {{ getTitle("cart", "label_assemble") }}
            </label>
          </div>
        </div>
        <div class="d-flex flex-row justify-content-between align-items-center">
          <span class="text-white">
            {{
              getTitleOptions("cart", "label_assembly_quantity", {
                ":qty": assembly["qty"],
              })
            }}
          </span>
          <div class="d-flex flex-row align-items-center">
            <div
              class="d-flex update-quantity justify-content-center position-relative"
            >
              <button
                class="border-0 bg-blue d-flex justify-content-center align-items-center"
                type="button"
                :disabled="
                  loadingQtyAssembly[assemblyIndex] ||
                  qtyAssembly[assemblyIndex] <= 1
                "
                @click="incrementOrDecrementAssemblyQty(assemblyIndex, -1)"
              >
                <span class="icon-change-qty text-white">
                  <i class="bi bi-dash" />
                </span>
              </button>
              <input
                v-model="qtyAssembly[assemblyIndex]"
                type="text"
                min="1"
                class="text-center input-pieces-number border border-top-0 border-bottom-0 bg-blue rounded-0 text-white"
                style="width: 50px"
                @change="
                  updateAssemblyQty(assemblyIndex, qtyAssembly[assemblyIndex])
                "
              />
              <button
                class="border-0 bg-blue"
                type="button"
                :disabled="loadingQtyAssembly[assemblyIndex]"
                @click="incrementOrDecrementAssemblyQty(assemblyIndex, 1)"
              >
                <span class="icon-change-qty text-white">
                  <i class="bi bi-plus" />
                </span>
              </button>
              <div
                v-if="loadingQtyAssembly[assemblyIndex]"
                class="position-absolute top-0 start-50 translate-middle-x"
              >
                <div
                  class="spinner-border text-secondary opacity-25"
                  role="status"
                >
                  <span class="visually-hidden mx-auto">Loading...</span>
                </div>
              </div>
            </div>
            <div>
              <button
                class="btn border-white rounded-0 text-white ms-2"
                @click="deleteAssembly(assemblyIndex)"
              >
                <i class="bi bi-trash" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        v-for="(item, index) in assembly['products']"
        :key="item"
        class="mb-2"
      >
        <product-cart-item
          :show-checkbox="showCheckbox"
          :is-last="index === products.length - 1 && !showCheckbox"
          :show-in-product-detail="showInProductDetail"
          :product="item.product"
          :quantity="item.quantity"
          :shadow="shadow"
          :border-img="borderImg"
          :show-applies-product="showAppliesProduct"
          :show-price-step-one="showPriceStepOne"
          :show-price-step-one-small="showPriceStepOneSmall"
          :show-favorites-and-similar="showFavoritesAndSimilar"
          :show-link-remove="showLinkRemove"
          :show-vat-included="showVatIncluded"
          :color-price-with-tax="colorPriceWithTax"
          :color-text-with-tax="colorTextWithTax"
          :show-available-in-20-min="showAvailableIn20Min"
          :show-available-pieces="showAvailablePieces"
          :show-pieces="showPieces"
          :disabled="assemblyIndex != 0 && !item.product.is_accessory"
        />
      </div>
    </div>
    <div
      v-if="!showCheckbox"
      class="row totals-for-product-detail mx-2 mb-2"
      :class="{ 'opacity-50': loading }"
    >
      <div class="d-flex">
        <div class="col-4 d-flex align-items-center">
          <span v-if="meta?.count" class="ms-2 text-lowercase">
            {{ meta.count }}
            {{
              meta.count > 1
                ? getTitle("cart", "unit_name") + "s"
                : getTitle("cart", "unit_name")
            }}
          </span>
        </div>
        <div class="col-8 d-flex justify-content-between align-items-center">
          <span> {{ getTitle("cart", "total") }}: </span>
          <p v-if="!totalIsNumber" class="text-danger" v-html="meta?.total" />
          <p v-if="totalIsNumber" class="text-danger">
            {{ totalInPoductDetail }}
          </p>
        </div>
      </div>
    </div>
    <loading
      class="position-absolute top-50 start-50 translate-middle"
      :loading="loading"
    />
  </div>
</template>
<script lang="ts">
import { CartService } from "@/services/CartService";
import {
  formatPrice,
  getTitleTranslate,
  getTitleTranslateWithOptions,
} from "../../utils/general";
import { MetaCart } from "@/interfaces/MetaCart";
import { ShoppingCartBrand } from "@/interfaces/ShoppingCartBrand";
import ProductCartItem from "@/components/cart/ProductCartItem.vue";
import Loading from "../ui/Loading.vue";
import { store } from "@/store";
import eventBus from "@/event-bus";
import Jsona from "jsona";
import auth from "@/services/AuthService";

const dataFormatter = new Jsona();

interface order {
  id: number;
}

export default {
  components: {
    Loading,
    ProductCartItem,
  },
  props: {
    showCheckbox: {
      type: Boolean,
      default: true,
    },
    showPriceStepOne: {
      type: Boolean,
      default: false,
    },
    showPriceStepOneSmall: {
      type: Boolean,
      default: false,
    },
    showAppliesProduct: {
      type: Boolean,
      default: false,
    },
    showAvailableIn20Min: {
      type: Boolean,
      default: false,
    },
    showPieces: {
      type: Boolean,
      default: false,
    },
    colorPriceWithTax: {
      type: String,
      default: "",
    },
    colorTextWithTax: {
      type: String,
      default: "",
    },
    showAvailablePieces: {
      type: Boolean,
      default: false,
    },
    shadow: {
      type: String,
      default: "",
    },
    borderImg: {
      type: String,
      default: "",
    },
    showFavoritesAndSimilar: {
      type: Boolean,
      default: false,
    },
    showLinkRemove: {
      type: Boolean,
      default: true,
    },
    showVatIncluded: {
      type: Boolean,
      default: true,
    },
    showInProductDetail: {
      type: Boolean,
      default: false,
    },
    resetOptionsCart: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["remove-item", "toggle-favorite", "update-item-quantity"],
  data: function () {
    return {
      orders: [] as order[],
      products: [] as any | null,
      meta: null as MetaCart | null,
      brands: null as ShoppingCartBrand | null,
      loading: false,
      cartService: null as CartService | null,
      loadingQtyAssembly: [] as Array<boolean>,
      qtyAssembly: [] as Array<number>,
    };
  },
  computed: {
    commonPriceWithTax(): string {
      return formatPrice(this.products.common_price_with_tax);
    },
    totalByItem(): string {
      return !isNaN(this.products.total_by_item)
        ? formatPrice(this.products.total_by_item)
        : this.products.total_by_item;
    },
    smallCoverUrl(): string {
      if (this.products && this.products.small_cover_url) {
        return this.products.small_cover_url;
      }
      return "/images/imagen-no-disponible.png";
    },
    isUserAuthenticated() {
      return auth.isUserLogged();
    },
    localStorageProducts() {
      return store.getters["cart/getLocalStorageProducts"];
    },
    assemblies() {
      return this.products.reduce((assemblies: any, item: any) => {
        const assemblyId = item.assembly_id ? item.assembly_id : "0";
        const assemblyConfigId =
          item.assembly_config && item.assembly_config.id
            ? item.assembly_config.id
            : "0";
        const assemblyConfigSlug =
          item.assembly_config && item.assembly_config.slug
            ? item.assembly_config.slug
            : "";
        if (assemblies[assemblyId] == undefined) {
          assemblies[assemblyId] = [];
          assemblies[assemblyId]["products"] = [];
          assemblies[assemblyId]["qty"] = 0;
          assemblies[assemblyId]["assembly_id"] = assemblyId;
          assemblies[assemblyId]["assembly_config_id"] = assemblyConfigId;
          assemblies[assemblyId]["assembly_config_slug"] = assemblyConfigSlug;
        }
        if (item.product.is_accessory == 0) {
          assemblies[assemblyId]["qty"] = item.quantity / item.qty_by_assembly;
          this.qtyAssembly[assemblyId] = assemblies[assemblyId]["qty"];
        }
        assemblies[assemblyId]["products"].push(item);

        return assemblies;
      }, {});
    },
    totalIsNumber() {
      const total = this.meta?.total;
      return typeof total === "number" && !isNaN(total);
    },
    totalInPoductDetail() {
      if (this.totalIsNumber && typeof this.meta?.total === "number") {
        const total = this.meta?.total || 0;
        return formatPrice(total);
      } else {
        return 0;
      }
    },
  },
  watch: {
    assemblies(newAssemblies) {
      if (newAssemblies && newAssemblies.length > 0) {
        newAssemblies.forEach((assembly: any, index: number) => {
          this.qtyAssembly[index] = assembly.qty;
        });
      }
    },
  },
  mounted() {
    this.cartService = new CartService();
    this.cartService.getCartInitData();
    this.getCartDetails();
  },
  created() {
    eventBus.on("update-cart-details", (productId: any) => {
      this.getCartDetails(productId);
    });
  },
  methods: {
    getTitle(section: string, name: string) {
      return getTitleTranslate(section, name);
    },
    getTitleOptions(section: string, name: string, options: unknown) {
      return getTitleTranslateWithOptions(section, name, options);
    },
    getCartDetails(productIds: any = null) {
      if (this.isUserAuthenticated && this.cartService) {
        this.loading = true;
        this.cartService.cartDetails(this.resetOptionsCart).then((response) => {
          if (response && response.data) {
            const productsResponse: any = dataFormatter.deserialize(
              response.data,
            );
            if (productIds == null) {
              this.products = productsResponse;
              store.commit("cart/setItems", this.products);
              this.meta = response.data.meta;
              if (response?.data?.included) {
                this.brands = response.data.included.filter((item: any) => {
                  return item.type == "brands";
                });
                eventBus.emit("shopping-cart-brands", this.brands); // Revisar, estan causando que genere Maximum recursive updates exceeded
              }
              if (productsResponse.length > 0) {
                eventBus.emit(
                  "update-shopping-cart-totals",
                  productsResponse[0].cart,
                );
              }
              this.loading = false;
            } else {
              let ids = [];
              if (productIds instanceof Array) {
                ids = productIds;
              } else {
                ids = [productIds];
              }
              ids.forEach((productId: string) => {
                const exists = this.products.findIndex((item: any) => {
                  return item.product.id == productId;
                });
                const updatedProduct = productsResponse.find((item: any) => {
                  return item.product.id == productId;
                });
                if (exists >= 0 && updatedProduct) {
                  this.products[exists].quantity = updatedProduct.quantity;
                  this.products[exists].product.total_by_item =
                    updatedProduct.product.total_by_item;
                  this.products[exists].product.total_by_item_text =
                    updatedProduct.product.total_by_item_text;
                  this.products[exists].product.common_quantity =
                    updatedProduct.product.common_quantity;
                  this.products[exists].product.promotion_quantity =
                    updatedProduct.product.promotion_quantity;
                  this.products[exists].product.promotion_price_with_tax_text =
                    updatedProduct.product.promotion_price_with_tax_text;
                  this.products[exists].product.common_price_with_tax_tex =
                    updatedProduct.product.common_price_with_tax_tex;
                } else {
                  this.products = productsResponse.filter((item: any) => {
                    return item.product.id != productId;
                  });
                }
              });
              store.commit("cart/setItems", this.products);
              if (response && response.data && response.data.meta) {
                this.meta = response.data.meta;
              }
              if (productsResponse.length > 0) {
                eventBus.emit(
                  "update-shopping-cart-totals",
                  productsResponse[0].cart,
                );
              }
              eventBus.emit("update-cart-total-items", this.products.length);
              this.loading = false;
            }
          }
        });
      } else {
        // const storedMeta = localStorage.getItem("meta");
        this.meta = store.getters["cart/getLocalCart"];
        if (this.localStorageProducts !== null) {
          this.products = this.localStorageProducts;
        }
        // if (storedMeta !== null) {
        //   this.meta = JSON.parse(storedMeta);
        // }
        eventBus.emit("update-cart-total-items", this.products.length);
        eventBus.emit("update-shopping-cart-totals", this.meta);
        this.loading = false;
      }
    },
    availableIn20Min() {
      return (
        this.products.available_in_20_minutes != 0 ||
        this.products.attributes.available_in_20_minutes != 0
      );
    },
    incrementOrDecrementAssemblyQty(indexAssembly: number, qty: number) {
      const newQty = this.assemblies[indexAssembly].qty + qty;
      this.updateAssemblyQty(indexAssembly, newQty);
    },
    deleteAssembly(indexAssembly: number) {
      if (this.isUserAuthenticated && this.cartService) {
        this.cartService.deleteAssembly(indexAssembly).then((response) => {
          if (response.meta.status === "success") {
            const idsUpdated = this.assemblies[indexAssembly].products.map(
              (product: any) => {
                return product.id;
              },
            );
            this.getCartDetails(idsUpdated);
          }
          this.loadingQtyAssembly[indexAssembly] = false;
        });
      } else {
        // falta sin sessión
      }
    },
    updateAssemblyQty(indexAssembly: number, qty: number) {
      this.loadingQtyAssembly[indexAssembly] = true;
      this.assemblies[indexAssembly].products.forEach((product: any) => {
        const newQty = qty * product.qty_by_assembly * 1;
        const qtyFixed =
          !newQty || newQty <= 0
            ? parseFloat(product.product.step.toString())
            : parseFloat(newQty.toString());
        let productQuantity = product.quantity
          ? product.quantity
          : product.quantity;
        if (productQuantity === qtyFixed) {
          return;
        }
      });

      if (this.isUserAuthenticated && this.cartService) {
        this.cartService
          .updateAssemblyQty(indexAssembly, qty)
          .then((response) => {
            if (response.data) {
              const idsUpdated = this.assemblies[indexAssembly].products.map(
                (product: any) => {
                  return product.id;
                },
              );
              this.getCartDetails(idsUpdated);
            } else if (response.errors) {
              eventBus.emit("error-message-show", { errors: response.errors });
            }
            this.loadingQtyAssembly[indexAssembly] = false;
          });
      } else {
        this.assemblies[indexAssembly].products.forEach((product: any) => {
          let productId = product.product.id;
          const qtyFixed =
            !qty || qty <= 0
              ? parseFloat(product.product.step.toString())
              : parseFloat(qty.toString());
          let newQuantity = qtyFixed;
          const assemblyId = indexAssembly;
          store.dispatch("cart/updateProductQuantityLocal", {
            productId,
            newQuantity,
            assemblyId,
          });
        });
        this.loadingQtyAssembly[indexAssembly] = false;
      }

      if (this.assemblies[indexAssembly]) {
        this.assemblies[indexAssembly].qty = qty;
      }
    },
  },
};
</script>
<style lang="sass" scoped>
@use '@/scss/custom.scss'
.list-buttons-assembly li:not(:last-child)::after
    content: '|'
    margin-left: 5px
    color: var(--bs-blue)
</style>
