import { store } from "@/store";
import axios, { AxiosError, AxiosResponse, AxiosRequestConfig } from "axios";
import Jsona from "jsona";
import { VITE_API_ENDPOINT } from "@/config/constants.js";
import auth from "@/services/AuthService";

const dataFormatter = new Jsona();
export class AssemblyService {
  public async getAssemblyGroups() {
    const assemblyGroups = store.getters["assembly/getAssemblyGroups"];
    if (assemblyGroups.length > 0) {
      return [...assemblyGroups];
    }
    const response = await axios.get("/eshop/assembly/assembly-groups", {});
    if (response && response.data) {
      const data = dataFormatter.deserialize(response.data);
      store.dispatch("assembly/setAssemblyGroups", data);
      return data;
    }
    return [];
  }

  public async getAssemblyInitData() {
    const translations = store.getters["translations/getSection"]("assembly");
    if (translations) {
      return translations;
    }
    const response = await axios.get("/eshop/assembly/init-data");
    if (response) {
      if (
        response.data &&
        response.data.meta &&
        response.data.meta.translations
      ) {
        store.commit("translations/setSection", {
          assembly: response.data.meta.translations,
        });
      }
      return response.data;
    }
  }

  public async getProducts(filters: string, page: number) {
    const response = await axios.get("/eshop/buscar" + filters, {
      params: {
        page: page,
      },
    });
    if (response) {
      return response.data;
    }
  }

  public async setProductSelection() {
    const products = store.getters["assembly/getFormatedProducts"];
    const body = { products: products };
    const response = await axios.post(
      "/eshop/assembly/assembly-product-selections",
      body,
    );
    if (!(response instanceof AxiosError)) {
      return response.data;
    } else if (response.response) {
      const resp: AxiosResponse = response.response;
      return resp.data;
    } else {
      return null;
    }
  }

  public async getAssemblyConfigsByCustomer() {
    const customer = await auth.getAutenticatedCustomer();
    if (customer) {
      const options: AxiosRequestConfig = {
        baseURL: VITE_API_ENDPOINT,
        headers: {
          Accept: "application/vnd.api+json",
        },
      };
      const url =
        "/shop-v1/customers/" +
        encodeURIComponent(customer.id) +
        "/assembly-configs";

      const response = await axios.get(url, options);
      if (!(response instanceof AxiosError)) {
        const data = dataFormatter.deserialize(response.data);
        store.commit("assembly/setAssemblyConfigs", data);
        return response.data;
      } else if (response.response) {
        const resp: AxiosResponse = response.response;
        return resp.data;
      }
    }
    return null;
  }

  public async showAssembly(idAssembly: string) {
    const options: AxiosRequestConfig = {
      baseURL: VITE_API_ENDPOINT,
      headers: {
        Accept: "application/vnd.api+json",
      },
    };
    const url =
      "/shop-v1/assembly-configs/" +
      encodeURIComponent(idAssembly) +
      "/assembly-config-details?include=assemblyConfig,product";
    const response = await axios.get(url, options);
    if (!(response instanceof AxiosError)) {
      return response.data;
    } else if (response.response) {
      const resp: AxiosResponse = response.response;
      return resp.data;
    } else {
      return null;
    }
  }

  public async storeAssembly(nameAssembly: string) {
    const body = {
      type: "assembly-configs",
      name: nameAssembly,
    };
    const newJsonBody = dataFormatter.serialize({
      stuff: body,
    });
    const options: AxiosRequestConfig = {
      baseURL: VITE_API_ENDPOINT,
      headers: {
        Accept: "application/vnd.api+json",
        "Content-Type": "application/vnd.api+json",
      },
    };

    const response = await axios.post(
      "/shop-v1/assembly-configs",
      newJsonBody,
      options,
    );
    if (!(response instanceof AxiosError)) {
      return response.data;
    } else if (response.response) {
      const resp: AxiosResponse = response.response;
      return resp.data;
    } else {
      return null;
    }
  }

  public async updateAssembly(idAssembly: string, nameAssembly: string) {
    const body = {
      type: "assembly-configs",
      id: idAssembly,
      name: nameAssembly,
    };
    const newJsonBody = dataFormatter.serialize({
      stuff: body,
    });
    const options: AxiosRequestConfig = {
      baseURL: VITE_API_ENDPOINT,
      headers: {
        Accept: "application/vnd.api+json",
        "Content-Type": "application/vnd.api+json",
      },
    };

    const url = "/shop-v1/assembly-configs/" + encodeURIComponent(idAssembly);
    const response = await axios.patch(url, newJsonBody, options);
    if (!(response instanceof AxiosError)) {
      return response.data;
    } else if (response.response) {
      const resp: AxiosResponse = response.response;
      return resp.data;
    } else {
      return null;
    }
  }

  public async deleteAssembly(idAssembly: string) {
    const options: AxiosRequestConfig = {
      baseURL: VITE_API_ENDPOINT,
      headers: {
        Accept: "application/vnd.api+json",
      },
    };
    const url = "/shop-v1/assembly-configs/" + encodeURIComponent(idAssembly);
    const response = await axios.delete(url, options);
    if (!(response instanceof AxiosError)) {
      return response.data;
    } else if (response.response) {
      const resp: AxiosResponse = response.response;
      return resp.data;
    } else {
      return null;
    }
  }

  public async storeAssemblyDetails(assembly: any) {
    const products = store.getters["assembly/getFormatedProducts"];
    for (let index = 0; index < products.length; index++) {
      const product = products[index];
      const body = {
        type: "assembly-config-details",
        qty: product.qty,
        product: {
          type: "products",
          id: product.product_id,
        },
        assemblyConfig: {
          type: "assembly-configs",
          id: assembly.id,
        },
        relationshipNames: ["product", "assemblyConfig"],
      };
      const newJsonBody = dataFormatter.serialize({
        stuff: body,
      });
      const options: AxiosRequestConfig = {
        baseURL: VITE_API_ENDPOINT,
        headers: {
          Accept: "application/vnd.api+json",
          "Content-Type": "application/vnd.api+json",
        },
      };

      const response = await axios.post(
        "/shop-v1/assembly-config-details",
        newJsonBody,
        options,
      );
      if (response instanceof AxiosError || response.status != 201) {
        this.deleteAssembly(assembly.id);
        return false;
      }
    }
    return true;
  }
}
