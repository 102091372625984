import { store } from "@/store";
import axios from "axios";

export class CourierService {
  public async getCartInitData() {
    const translations = store.getters["translations/getSection"]("cart");
    if (translations) {
      return translations;
    }
    const response = await axios.get("/eshop/cart/init-data");
    if (response) {
      if (
        response.data &&
        response.data.meta &&
        response.data.meta.translations
      ) {
        store.commit("translations/setSection", {
          cart: response.data.meta.translations,
        });
      }
      return response.data;
    }
  }

  public async getCouriers() {
    const response = await axios.get("eshop/compra/elegir-paqueteria");
    if (response) {
      return response.data;
    }
  }
}
