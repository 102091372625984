<template>
  <div class="row">
    <div
      class="col-12 border-medium mb-2 mb-lg-3 d-flex flex-column justify-content-center"
    >
      <div
        class="mt-0 mt-lg-2 d-flex flex-row-reverse justify-content-between align-items-center w-100 flex-lg-column align-items-lg-start"
      >
        <p class="text-blue order-number m-0">#{{ order.folio }}</p>
        <p class="text-secondary text-order-number m-0">Número del pedido</p>
      </div>

      <div class="d-none d-xl-flex flex-column mt-2">
        <span class="text-secondary text-creation-date">
          Fecha de creación
        </span>
        <span class="text-secondary creation-date">
          {{ order.created_simple }}
        </span>
      </div>
    </div>

    <div
      class="col-12 border-medium d-flex flex-row-reverse justify-content-between align-items-center text-success flex-lg-column align-items-lg-start mb-2 mb-lg-3"
    >
      <spa
        class="status-pending m-0"
        :class="{
          'text-danger': order.simple_payment_status_text === 'Pendiente',
          'text-success': order.simple_payment_status_text === 'Pagado',
        }"
      >
        {{ order.simple_payment_status_text }}
      </spa>
      <p class="text-secondary payment m-0">Pago</p>
    </div>

    <div
      class="d-lg-none col-12 border-medium d-flex flex-row-reverse justify-content-between align-items-center text-success flex-lg-column align-items-lg-start mb-2 mb-lg-4"
    >
      <hidden-code code="2K-FD-94" class="no-affect" />
    </div>
    <div
      v-for="(payment, index) in order.payment_details"
      :key="index"
      class="payment-method text-wrap p-0 p-lg-3"
    >
      <p
        class="line me-lg-2 d-flex justify-content-center d-none d-lg-block"
      ></p>

      <div
        class="d-flex flex-lg-column justify-content-between border-medium align-items-center align-items-lg-start"
      >
        <p class="ps-2 px-lg-0 m-0 fw-semibold text-secondary">
          Importe: <span v-html="payment.amount" class="m-0 p-0"></span>
        </p>
        <span v-if="payment.monthly_payment_months === 1" class="text-success">
          {{ payment.monthly_payment_months }} pago
        </span>
        <span
          v-if="payment.monthly_payment_months > 1"
          :class="[
            payment.monthly_payment_with_interest
              ? 'text-warning'
              : 'text-success',
            'text-condensed small',
          ]"
        >
          {{ formatMessage(payment) }}
        </span>
      </div>

      <div
        class="col-12 border-medium d-flex flex-row-reverse justify-content-between align-items-center text-success flex-lg-column align-items-lg-start mb-2 mb-lg-0 mt-2 mt-lg-3"
      >
        <div class="d-flex align-items-center">
          <p class="p-0 fs-8 text-dark fw-bold m-0">
            {{ payment.provider_payment_reference }}
          </p>
          <i
            class="bi bi-file-earmark-arrow-down-fill d-lg-none ms-2 text-blue fs-4"
          ></i>
        </div>
        <p class="text-secondary payment-method py-0 m-0 ps-2 ps-lg-0">
          Método de pago
        </p>
      </div>
    </div>
    <div
      class="col-12 border-medium d-flex flex-row-reverse justify-content-between align-items-center text-success flex-lg-column align-items-lg-start mb-lg-3 mt-lg-3"
    >
      <p class="courier-name m-0 text-secondary">
        {{ order.courier || "SIN PAQUETERÍA" }}
      </p>
      <p class="text-secondary selected-shipping m-0 mt-lg-1">
        Envío seleccionado
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import HiddenCode from "@/components/profile/HiddenCode.vue";

export default {
  components: {
    HiddenCode,
  },

  props: {
    order: {
      type: Object,
      default: null,
    },
  },
  methods: {
    formatMessage(paymentDetail: {
      monthly_payment_with_interest: number;
      monthly_payment_months: number;
      monthly_payment_price: number;
    }) {
      const {
        monthly_payment_with_interest,
        monthly_payment_months,
        monthly_payment_price,
      } = paymentDetail;

      if (monthly_payment_with_interest) {
        return this.$t(
          "Plan selected for {monthly_payment_months} month(s) at {price}",
          {
            months: monthly_payment_months,
            price: this.formatPrice(monthly_payment_price),
          },
        );
      } else {
        return this.$t(
          "Plan selected with interest for {monthly_payment_months} month(s) at {price}",
          {
            months: monthly_payment_months,
            price: this.formatPrice(monthly_payment_price),
          },
        );
      }
    },
    formatPrice(price: number | bigint) {
      return new Intl.NumberFormat("en-MX", {
        style: "currency",
        currency: "MXN",
      }).format(price);
    },
  },
};
</script>
