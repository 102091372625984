import axios, {
  AxiosError,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from "axios";
import { VITE_API_ENDPOINT } from "./config/constants";
import auth from "./services/AuthService";
import { CustomNotifyService } from "@/services/CustomNotifyService";
import { store } from "./store";

const notifySrv = new CustomNotifyService();

const appTokenRouteBlacklist = ["/oauth/token"];

const clientTokenRouteBlacklist = [
  "/eshop/login",
  "/eshop/register",
  "/shop-v1/customers/-actions/self",
];

let checkedCustomerToken = false;
let previousTokenWasSuccessful = false;
let unableToGetNewTokenShown = false;

const requestApplyToken = async (
  config: InternalAxiosRequestConfig,
): Promise<InternalAxiosRequestConfig> => {
  if (!appTokenRouteBlacklist.find((route) => route == config.url)) {
    const token = await auth.getToken();
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
  }
  return config;
};

const requestInterceptor = async (
  config: InternalAxiosRequestConfig,
): Promise<InternalAxiosRequestConfig> => {
  await requestApplyToken(config);
  return config;
};

const sucessResponseInterceptor = async (response: AxiosResponse) => {
  return response;
};

const responseErrorInterceptor = async (error: AxiosError) => {
  const accessToken = store.getters["auth/getAccessToken"];
  if (!(error instanceof AxiosError)) {
    notifySrv.getNotification({
      type: "error",
      title: "Error inesperado",
      text: error,
    });
  }
  const originalRequest: InternalAxiosRequestConfig | undefined = error.config;
  // Get the token after the request gets 401 and try again.
  if (
    error.response?.status === 401 &&
    !clientTokenRouteBlacklist.find((route) => route == originalRequest?.url)
  ) {
    if (!checkedCustomerToken) {
      checkedCustomerToken = true;
      if (await auth.isCustomerTokenUnauthorized()) {
        auth.clearSessionStorage();
        notifySrv.getNotification({
          type: "error",
          title: "Sesión expirada",
          text: "Tu sesión ha expirado, vuelve a iniciar sesión si así lo deseas.",
          time: 3000,
        });
        return Promise.reject(error);
      }
    }
    // evita que siga solicitando tokens si el anterior fue generado con éxito (sería rectificar la autenticación del backend si esto sucede))
    else if (previousTokenWasSuccessful) {
      if (!unableToGetNewTokenShown) {
        unableToGetNewTokenShown = true;
        notifySrv.getNotification({
          type: "error",
          title: "Problemas de configuración de token [DEV]",
          text:
            "Favor de verificar la autenticación de rutas del sistema: " +
            originalRequest?.url,
          time: 3000,
        });
      }
    } else {
      try {
        await auth.requestToken();
        previousTokenWasSuccessful = true;
        if (originalRequest) {
          const configWithToken = await requestApplyToken(originalRequest);
          return axios(configWithToken);
        }
        return error;
      } catch (error) {
        console.error(
          "Error de autenticación al interceptar la solicitud actual.",
          error,
        );
      }
    }
  }
  if (error.status === 429) {
    notifySrv.getNotification({
      type: "error",
      title: "Intentos de reconexión excedidos",
      text: "Por favor espera un poco y vuelve a recargar la página.",
    });
  } else if (
    (error.code && error.code === "ERR_NETWORK") ||
    accessToken === null
  ) {
    notifySrv.getNotification({
      type: "error",
      title: "Sin respuesta del servidor",
      text: "Por favor verfica la conexión del servidor y luego recarga esta página.",
    });
  } else if (error.response && error.response.data) {
    const message = (error.response.data as { message: string | null }).message;
    if (message && message.includes("SQLSTATE[HY000] [2002]")) {
      notifySrv.getNotification({
        type: "error",
        title: "Conexión denegada.",
        text: "Por favor verfica la conexión a la base de datos y luego recarga esta página",
      });
    }
  }
  return Promise.reject(error);
};

axios.defaults.baseURL = VITE_API_ENDPOINT;
axios.interceptors.request.use(requestInterceptor);
axios.interceptors.response.use(
  sucessResponseInterceptor,
  responseErrorInterceptor,
);

export default axios;
