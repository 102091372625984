<template lang="">
  <component
    :is="tag"
    ref="linkNavTransaction"
    v-element-hover="onHover"
    :to="to"
    class="text-decoration-none d-flex justify-content-center align-items-center h-100 w-100 border-0 bg-body-tertiary px-0"
  >
    <Transition name="fade-scale" mode="out-in">
      <div
        v-if="!isHovered && !isActive"
        class="d-flex flex-column justify-content-center align-items-center"
      >
        <i
          v-if="icon && !isHovered && !isActive"
          class="bi text-primary fs-5"
          :class="icon"
        />
        <component
          :is="dynamicComponent"
          v-else-if="component && !isHovered && !isActive"
          class="text-primary"
        />
        <span v-if="textInactive" class="fs-8 pt-2 text-danger line-clamp-1">
          {{ textInactive }}
        </span>
      </div>
      <div
        v-else
        class="h-100 d-flex justify-content-center align-items-center"
        :style="{ backgroundColor: colorHover }"
      >
        <span class="fs-7 text-center text-white px-4 text-uppercase">
          {{ text }}
        </span>
      </div>
    </Transition>
  </component>
</template>
<script lang="ts">
import { defineAsyncComponent, ref } from "vue";
import { vElementHover } from "@vueuse/components";
import eventBus from "@/event-bus";
export default {
  directives: {
    "element-hover": vElementHover,
  },
  props: {
    to: {
      type: String,
      default: "/",
    },
    text: {
      type: String,
      default: "",
    },
    textInactive: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: null,
    },
    component: {
      type: String,
      default: null,
    },
    colorHover: {
      type: String,
      default: "red",
    },
    tag: {
      type: String,
      default: "/",
    },
  },
  setup() {
    const linkNavTransaction = ref<HTMLElement | null>(null);
    return {
      linkNavTransaction,
    };
  },
  data: function () {
    return {
      isHovered: false,
      isActive: false,
    };
  },
  computed: {
    dynamicComponent() {
      return defineAsyncComponent(
        () => import(`../../components/icons/${this.component}.vue`),
      );
    },
  },
  created() {
    eventBus.on("set-is-hovered", (isHover: any) => {
      const idElement =
        this.linkNavTransaction && this.linkNavTransaction.id
          ? this.linkNavTransaction.id
          : "";
      if (idElement == isHover.id) {
        this.isActive = isHover.status;
      }
    });
  },
  beforeUnmount() {
    eventBus.off("set-is-hovered");
  },
  methods: {
    onHover(state: boolean) {
      this.isHovered = state;
    },
  },
};
</script>
<style lang="sass" scoped>
.fade-scale-enter-active,
.fade-scale-leave-active
    transition: all 0.15s ease

.fade-scale-enter-from,
.fade-scale-leave-to
    opacity: 0
    transform: scale(0.5)
</style>
