<template>
  <div class="container-authorized-devices">
    <div class="row">
      <ul class="list-unstyled">
        <li
          v-for="item in devices"
          :key="item.id"
          class="mb-3 d-flex flex-column flex-md-row"
        >
          <div class="d-flex">
            <div
              class="flex-shrink-0 d-flex align-items-center justify-content-start"
            >
              <i :class="item.image" class="image-device"></i>
            </div>
            <div
              class="flex-grow-1 d-flex flex-column justify-content-around flex-md-row align-items-md-center ms-3 me-5 right-content"
            >
              <div class="col-12 col-md-4">
                <p class="mb-2 title">Dispositivo</p>
                <p class="description m-0">{{ item.device }}</p>
              </div>
              <div class="col-12 col-md-6">
                <p class="mb-2 title">Fecha de autorización</p>
                <p class="description m-0">{{ item.authorization_date }}</p>
              </div>
              <div class="col-12 col-md-3">
                <p class="mb-1 mb-md-2 title">Última sesión</p>
                <p class="description text-nowrap m-0">
                  {{ item.last_session }}
                </p>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script lang="ts">
import TabTitle from "@/interfaces/TabTitle";
import { AuthorizedDevices } from "@/interfaces/AuthorizedDevices";
export default {
  data() {
    TabTitle("Dispositivos");
    return {
      devices: [] as AuthorizedDevices[],
    };
  },
  mounted() {
    this.devices = [
      {
        id: 1,
        image: "bi bi-android",
        device: "Teléfono Samsung / SO Android",
        authorization_date: "Lunes 24 de septiembre del 2022 / 04:53 pm",
        last_session: "Activa desde este dispositivo",
      },
      {
        id: 2,
        image: "bi bi-android",
        device: "Teléfono Samsung / SO Android",
        authorization_date: "Lunes 24 de septiembre del 2022 / 04:53 pm",
        last_session: "Activa desde este dispositivo",
      },
      {
        id: 3,
        image: "bi bi-android",
        device: "Teléfono Samsung / SO Android",
        authorization_date: "Lunes 24 de septiembre del 2022 / 04:53 pm",
        last_session: "Activa desde este dispositivo",
      },
    ];
  },
};
</script>
<style></style>
./interfaces/AuthorizedDevices
