import axios, { AxiosError, AxiosResponse, AxiosRequestConfig } from "axios";
import { store } from "@/store";
import { ItemCart } from "@/interfaces/ItemCart.js";
import Jsona from "jsona";
import {
  VITE_API_ENDPOINT,
  SHIPPING_DELIVERY,
  SHIPPING_PICK_UP,
} from "@/config/constants.js";

const dataFormatter = new Jsona();

export class CartService {
  public async getCartInitData() {
    const translations = store.getters["translations/getSection"]("cart");
    const isLoading = store.getters["translations/hasLoading"]("cart");

    if (translations || isLoading) {
      return translations;
    }
    store.commit("translations/addLoading", "cart");
    const response = await axios.get("/eshop/cart/init-data");
    if (response) {
      if (
        response.data &&
        response.data.meta &&
        response.data.meta.translations
      ) {
        store.commit("translations/setSection", {
          cart: response.data.meta.translations,
        });
        store.commit("translations/removeLoading", "cart");
      }
      return response.data;
    }
  }

  public async addProduct(id: number, qty: number = 1) {
    let params = encodeURIComponent(id);
    const body = {};
    if (qty > 0) {
      params += "/" + encodeURIComponent(qty);
    }
    const response = await axios.get("/eshop/carrito/agregar/" + params, {
      params: body,
    });
    if (!(response instanceof AxiosError)) {
      store.dispatch("checkout/resetCheckout");
      return response.data;
    } else if (response.response) {
      return response.response.data;
    }
  }

  public async validateAddProduct(id: number, qty: number = 0) {
    let params = encodeURIComponent(id);
    const body = {};
    if (qty !== 0) {
      params += "/" + encodeURIComponent(qty);
    }
    const response = await axios.get("/eshop/carrito/valida/" + params, {
      params: body,
    });
    if (!(response instanceof AxiosError)) {
      return response.data;
    } else if (response.response) {
      return response.response.data;
    }
  }

  public async getTotalItems() {
    const response = await axios.get("/eshop/carrito/total-items");
    if (response && response.data) {
      if (response.data && response.data.meta) {
        return response.data.meta;
      }
      return response.data;
    }
  }

  public async getTotals() {
    const response = await axios.get("/eshop/carrito/totales");
    if (response && response.data) {
      return response.data;
    }
  }

  public async cartDetails(resetOptions: boolean) {
    let body = {};
    const couponCode = store.getters["cart/getCouponCode"];
    const trackingId = store.getters["checkout/getTrackingId"];
    if (resetOptions != undefined) {
      body = {
        visited: encodeURIComponent(!resetOptions),
        coupon_code: couponCode,
      };
      const paymentMethodCode = store.getters["checkout/getPaymentMethodCode"];
      const paymentTokenizable =
        store.getters["checkout/getPaymentTokenizable"];
      const monthlyPlans = store.getters["checkout/getMonthlyPlans"];

      if (!resetOptions && paymentMethodCode && monthlyPlans) {
        body = {
          ...body,
          payment_method_code: paymentMethodCode,
          tokenizable_id: paymentTokenizable,
          monthly_payment_option_plans: monthlyPlans,
          tracking_id: trackingId,
        };
      }
    }
    const response = await axios.get("eshop/carrito/detalle", {
      params: body,
    });
    return response;
  }

  public async removeItem(productId: number) {
    const response = await axios.delete("eshop/carrito/quitar/" + productId);
    if (response) {
      store.dispatch("checkout/resetCheckout");
      return response.data;
    } else {
      console.log("errror al eliminar");
    }
  }

  public async clearCart() {
    const response = await axios.delete("eshop/carrito/clear-all");
    if (response) {
      store.dispatch("checkout/resetCheckout", true);
      return response.data;
    } else {
      console.log("errror al vaciar el carrito");
    }
  }

  public async updateQty(product: ItemCart, qty: number) {
    const response = await axios.post(
      "/eshop/carrito/actualizar/" + product.id + "/" + qty,
    );
    if (response) {
      store.dispatch("checkout/resetCheckout");
      return response.data;
    } else {
      console.log("Error al actualizar la cantidad del artículo");
    }
  }

  public async showAssembly(assemblyId: number) {
    const response = await axios.get(
      "/eshop/carrito/ensamble/" + encodeURIComponent(assemblyId),
    );
    if (!(response instanceof AxiosError)) {
      return response.data;
    } else if (response.response) {
      const resp: AxiosResponse = response.response;
      return resp.data;
    } else {
      return null;
    }
  }

  public async addAssemblyProduct() {
    const products = store.getters["assembly/getFormatedProducts"];
    const selectedAssemblyConfigId =
      store.getters["assembly/getSelectedAssemblyConfigId"];
    const body = {
      products: products,
      assembly_config_id: selectedAssemblyConfigId,
    };
    const response = await axios.post("/eshop/carrito/ensamble", body);
    if (!(response instanceof AxiosError)) {
      const data: any = dataFormatter.deserialize(response.data);
      if (data && data.length > 0) {
        store.dispatch("checkout/resetCheckout");
        store.commit("assembly/setAssemblyId", data[0].assembly_id);
      }
      return response.data;
    } else if (response.response) {
      const resp: AxiosResponse = response.response;
      return resp.data;
    } else {
      return null;
    }
  }

  public async updateAssemblyQty(
    assemblyId: any,
    qty: number,
    products: Array<any> | null = null,
  ) {
    const body = {
      type: "assemblies",
      id: assemblyId,
      qty: qty,
      products: products ? products : null,
    };
    const newJsonBody = dataFormatter.serialize({
      stuff: body,
    });
    const options: AxiosRequestConfig = {
      baseURL: VITE_API_ENDPOINT,
      headers: {
        Accept: "application/vnd.api+json",
        "Content-Type": "application/vnd.api+json",
      },
    };

    const response = await axios.patch(
      "/eshop/carrito/ensamble",
      newJsonBody,
      options,
    );
    if (!(response instanceof AxiosError)) {
      store.dispatch("checkout/resetCheckout");
      return response.data;
    } else if (response.response) {
      store.dispatch("checkout/resetCheckout");
      const resp: AxiosResponse = response.response;
      return resp.data;
    } else {
      console.log("Error al actualizar la cantidad del ensamble");
      return null;
    }
  }

  public async deleteAssembly(assemblyId: number) {
    const options: AxiosRequestConfig = {
      baseURL: VITE_API_ENDPOINT,
      headers: {
        Accept: "application/vnd.api+json",
      },
    };

    const response = await axios.delete(
      "/eshop/carrito/ensamble/" + encodeURIComponent(assemblyId),
      options,
    );
    if (!(response instanceof AxiosError)) {
      return response.data;
    } else if (response.response) {
      store.dispatch("checkout/resetCheckout");
      const resp: AxiosResponse = response.response;
      return resp.data;
    } else {
      console.log("Error al eliminar el ensamble");
      return null;
    }
  }

  public async validateReview() {
    let body = {};
    const paymentMethodCode = store.getters["checkout/getPaymentMethodCode"];
    const paymentTokenizable = store.getters["checkout/getPaymentTokenizable"];
    const monthlyPlans = store.getters["checkout/getMonthlyPlans"];
    const trackingId = store.getters["checkout/getTrackingId"];
    const shippingType = store.getters["checkout/getIsShipping"]
      ? SHIPPING_DELIVERY
      : SHIPPING_PICK_UP;

    if (paymentMethodCode && monthlyPlans) {
      body = {
        payment_method_code: paymentMethodCode,
        tokenizable_id: paymentTokenizable,
        monthly_payment_option_plans: monthlyPlans,
        shipping_type: shippingType,
        tracking_id: trackingId,
      };
    }
    const response = await axios.get("eshop/carrito/validar-revision", {
      params: body,
    });
    if (!(response instanceof AxiosError)) {
      return response.data;
    } else if (response.response) {
      const resp: AxiosResponse = response.response;
      return resp.data;
    } else {
      return null;
    }
  }

  public async addCoupon(couponCode: string) {
    const body = {
      coupon_code: couponCode,
    };
    const response = await axios.post("/eshop/carrito/agregar-cupon", body);
    if (!(response instanceof AxiosError)) {
      return response.data;
    } else if (response.response) {
      const resp: AxiosResponse = response.response;
      return resp.data;
    } else {
      return null;
    }
  }

  public async deleteCoupon() {
    const response = await axios.post("/eshop/carrito/eliminar-cupon");
    if (!(response instanceof AxiosError)) {
      return response;
    } else if (response.response) {
      const resp: AxiosResponse = response.response;
      return resp;
    } else {
      console.log("Error al eliminar el cupón");
      return null;
    }
  }

  public async addWallet(amount: number) {
    const body = {
      wallet_to_use: amount,
    };
    const response = await axios.post("/eshop/carrito/agregar-monedero", body);
    if (!(response instanceof AxiosError)) {
      return response.data;
    } else if (response.response) {
      const resp: AxiosResponse = response.response;
      return resp.data;
    } else {
      return null;
    }
  }

  public async deleteWallet() {
    const response = await axios.post("/eshop/carrito/eliminar-monedero");
    if (!(response instanceof AxiosError)) {
      return response;
    } else if (response.response) {
      const resp: AxiosResponse = response.response;
      return resp;
    } else {
      console.log("Error al eliminar el cupón");
      return null;
    }
  }
}
