import { Links } from "@/interfaces/ItemCart";
import Model from "./Model";

export interface IBrand {
  id: string | null;
  name: string | null;
  slug: string | null;
  image: string | null;
  isOpen: boolean;
  links: Links;
}

export default class Brand extends Model implements IBrand {
  public id: string | null = null;
  public name: string | null = null;
  public slug: string | null = null;
  public image: string | null = null;
  public isOpen: boolean = false;
  public links: Links = { self: "" };

  public getFilterSlug(): [string, string] | undefined {
    return ["marca", this.slug || ""];
  }

  public constructor({ id, name, slug, image, isOpen }: IBrand) {
    super();

    this.id = id || null;
    this.name = name || null;
    this.slug = slug || null;
    this.image = image || null;
    this.isOpen = isOpen || false;
  }

  public static fromArray(items: IBrand[]): Brand[] {
    return items.map((item: IBrand) => new Brand(item));
  }
}
