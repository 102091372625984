<template>
  <footer class="container-footer">
    <div
      v-if="withFooter"
      class="bg-body-tertiary px-5 mt-2 mt-lg-5 d-flex flex-column"
    >
      <div class="row py-3 pt-lg-6 order-lg-1 g-5">
        <div
          class="col-12 col-md-9 col-xl-3 mb-6 mb-xl-0 d-none d-lg-block mb-0"
        >
          <base-link>
            <logo-digitalife class="text-black w-100 h-auto" />
          </base-link>
        </div>
        <div
          v-for="(list, index) in listMainLinks"
          :key="index"
          class="col-5 col-lg-4 col-xl-2 offset-xl-1"
        >
          <h4 class="title fw-bold text-primary">
            {{ list.title }}
          </h4>
          <ul class="list-unstyled mt-0 mt-lg-4">
            <li
              v-for="(item, index2) in list.links"
              :key="index2"
              class="py-2 p-1"
            >
              <base-link
                class="subtitle text-secondary opacity-75 link-underline link-underline-opacity-0"
                :to="item.to"
              >
                {{ item.title }}
              </base-link>
            </li>
          </ul>
        </div>
        <div
          v-for="(list, index) in listInformationLinks"
          :key="index"
          class="col-5 col-lg-4 col-xl-2"
        >
          <h4 class="title fw-bold text-primary">
            {{ list.title }}
          </h4>
          <ul class="list-unstyled mt-0 mt-lg-4">
            <li
              v-for="(item, index2) in list.links"
              :key="index2"
              class="py-2 p-1"
            >
              <base-link
                class="subtitle text-secondary opacity-75 link-underline link-underline-opacity-0"
                :to="item.to"
              >
                {{ item.title }}
              </base-link>
            </li>
          </ul>
        </div>
        <div class="col-3 d-none d-lg-block">
          <h4 class="fs-4 fw-bold text-primary">Contacto</h4>
          <ul class="list-unstyled mt-2 mt-lg-4">
            <li class="my-3 p-1">
              <base-link
                class="fs-5 text-secondary link-underline link-underline-opacity-0 d-flex justify-content-start align-items-center"
                :to="'/contactanos'"
              >
                <i class="bi bi-telephone text-danger me-2" />
                (33) 3630 0660
              </base-link>
            </li>
            <li class="my-3 p-1">
              <router-link
                class="fs-5 text-secondary link-underline link-underline-opacity-0 d-flex justify-content-start align-items-center"
                :to="'/contactanos'"
              >
                <i class="bi bi-envelope text-danger me-2" />
                contacto@digitalife.com.mx
              </router-link>
            </li>
          </ul>
        </div>
      </div>
      <div
        class="row py-2 order-first order-lg-2 mx-2"
        :class="styleResponsive"
      >
        <div
          class="col-2 col-lg-12 d-flex justify-content-lg-start justify-content-lg-end align-items-center px-0"
        >
          <img src="/images/Logo_Digital_Life.png" class="img-fluid" />
        </div>
        <div class="col-10 d-block d-lg-none">
          <ul class="list-unstyled mt-2 mt-lg-4">
            <li class="my-3 p-1">
              <base-link
                class="fs-5 text-secondary link-underline link-underline-opacity-0 d-flex justify-content-start align-items-center"
                :to="'/contactanos'"
              >
                <i class="bi bi-telephone text-danger me-2" />
                +(33) 3630 0660
              </base-link>
            </li>
            <li class="my-3 p-1">
              <base-link
                class="fs-5 text-secondary link-underline link-underline-opacity-0 d-flex justify-content-start align-items-center"
                :to="'/contactanos'"
              >
                <i class="bi bi-envelope text-danger me-2" />
                contacto@digitalife.com.mx
              </base-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div
      v-if="withFooter"
      class="bg-body-tertiary d-flex d-lg-none flex-column pb-4 px-3"
    >
      <div class="row">
        <div class="col-12 col-sm-10 col-md-8">
          <p class="fs-9 text-primary">
            * Precios en efectivo / Hasta agotar existencias
          </p>
          <p class="fs-8 text-secondary lh-base">
            Nota: Digitalife no garantiza la precisión de la información,
            incluyendo el precio, imágenes, presentación o especificaciones del
            producto las cuales pueden cambiar sin previo aviso.
          </p>
        </div>
      </div>
      <!-- OCULTO HASTA QUE FUNCIONE-->
      <!-- <h6 class="text-center fs-8 text-primary fw-bold my-3 text-uppercase">
        Próximamente
      </h6>
      <div class="row px-2 g-2 d-flex justify-content-center mb-4 mb-lg-0">
        <div class="col-6 col-sm-4 col-md-3">
          <img src="/images/APPStore.png" class="w-100 img-fluid" />
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <img src="/images/GooglePlay.png" class="w-100 img-fluid" />
        </div>
      </div> -->
    </div>
    <div
      class="row mb-3 mb-lg-0 pb-lg-6 px-5 d-flex justify-content-center align-items-center mx-0 copyright"
      :class="styleBackgroundResponsive"
    >
      <p class="p-0 pt-lg-4 pb-lg-3 m-0">
        Copyright © {{ new Date().getFullYear() }} Digitalife, todos los
        derechos reservados.
      </p>
    </div>
  </footer>
</template>
<script lang="ts">
import { ref, onMounted, onUnmounted } from "vue";
import LogoDigitalife from "../icons/LogoDigitalife.vue";
import BaseLink from "../buttons/BaseLink.vue";
import { HomeService } from "@/services/HomeService";
import {
  getEshopLinkTranslate,
  getLinkSelfTranslate,
} from "../../utils/general";

export default {
  components: {
    LogoDigitalife,
    BaseLink,
  },
  props: {
    withFooter: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const viewportWidth = ref(window.innerWidth);
    const viewportHeight = ref(window.innerHeight);

    // Función para actualizar las dimensiones del viewport
    const updateViewportDimensions = () => {
      viewportWidth.value = window.innerWidth;
      viewportHeight.value = window.innerHeight;
    };

    // Agregar un listener para el evento 'resize'
    onMounted(() => {
      window.addEventListener("resize", updateViewportDimensions);
    });

    // Eliminar el listener al desmontar el componente
    onUnmounted(() => {
      window.removeEventListener("resize", updateViewportDimensions);
    });

    return {
      viewportWidth,
      viewportHeight,
    };
  },
  data: function () {
    return {
      lists: [],
      currentYear: 0,
      isLargeScreen: false,
    };
  },
  computed: {
    styleResponsive() {
      if (this.viewportWidth <= 992) {
        return "border-0";
      } else {
        return "border-bottom border-light-subtle";
      }
    },
    styleBackgroundResponsive() {
      if (this.viewportWidth <= 992) {
        return "bg-danger text-white text-center";
      } else {
        return "bg-body-tertiary text-primary";
      }
    },
    listMainLinks() {
      return [
        {
          title: "Principal",
          links: [
            {
              title: "Inicio",
              to: "/",
            },
            {
              title: "Categorías",
              to: "/busqueda-por-categoria",
            },
            {
              title: "Marcas",
              to: "/marcas",
            },
            {
              title: "Nuestras tiendas",
              to: "/sucursales",
            },
            {
              title: "Aviso legal",
              to: "/aviso-legal",
            },
            {
              title: "Términos y condiciones",
              to: "/terminos-y-condiciones",
            },
            {
              title: "Garantías y devoluciones",
              to: "/garantias-y-devoluciones",
            },
            // {
            // comentado hasta exista información
            //   title: "Productos",
            //   to: '/products',
            // },

            // {
            // comentado hasta exista información
            //   title: "Mayorístas"
            //   to: "/",
            // },
          ],
        },
      ];
    },
    listInformationLinks() {
      return [
        {
          title: "Información",
          links: [
            {
              title: "Aviso de privacidad",
              to: "/privacidad",
            },
            {
              title: "Métodos de pago",
              to: "/formas-de-pago",
            },
            {
              title: this.isLargeScreen
                ? "Política de monedero, devolución y cancelación"
                : "Política de monedero...",
              to: "/politicas-de-devolucion",
            },
            {
              title: "Preguntas frecuentes",
              to: "/preguntas-frecuentes",
            },
            {
              title: "Facturación",
              to: "/facturacion",
            },
            // OCULTO HASTA QUE FUNCIONEN
            // {
            //   title: "Prime",
            //   to: "/",
            // },
            // {
            //   title: "Sobre garantía extendida",
            //   to: "/",
            // },
            // {
            //   "Sobre ofertas relámpago",
            //   to: "/",
            // },
          ],
        },
      ];
    },
  },
  created() {
    const date = new Date();
    this.currentYear = date.getFullYear();
  },
  mounted() {
    const homeService = new HomeService();
    homeService.getFooterInitData();
    this.updateScreenSize();
    window.addEventListener("resize", this.updateScreenSize);
  },
  beforeMount() {
    window.removeEventListener("resize", this.updateScreenSize);
  },
  methods: {
    getEshopLink(section: string, name: string) {
      return getEshopLinkTranslate(section, name);
    },
    getLink(section: string, name: string) {
      return getLinkSelfTranslate(section, name);
    },
    updateScreenSize() {
      this.isLargeScreen = window.innerWidth >= 992;
    },
  },
};
</script>
<style lang="scss" scoped>
@media print {
  body .container-footer {
    display: none;
  }
}
</style>
