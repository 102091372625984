export interface ICustomer {
  id: string | null;
  type: string | null;
  name: string | null;
  last_name: string | null;
  mother_last_name: string | null;
  email: string | null;
  phone: string | null;
}

export default class Customer implements ICustomer {
  public id: string | null;
  public type: string | null;
  public name: string | null;
  public last_name: string | null;
  public mother_last_name: string | null;
  public email: string | null;
  public phone: string | null;

  public constructor({
    id,
    type,
    name,
    last_name,
    mother_last_name,
    email,
    phone,
  }: ICustomer) {
    this.id = id || null;
    this.type = type || null;
    this.name = name || null;
    this.last_name = last_name || null;
    this.mother_last_name = mother_last_name || null;
    this.email = email || null;
    this.phone = phone || null;
  }
}
