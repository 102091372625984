<script lang="ts">
import { defineComponent, PropType } from "vue";
import {
  Warranty,
  WarrantyRelease,
  WarrantyRequestTransactionDetails,
} from "@/interfaces/Warranties";
import {
  WARRANTY_REQUEST_RELEASE_DECISION_RETURN,
  WARRANTY_REQUEST_RELEASE_DECISION_CHANGE,
  WARRANTY_REQUEST_RELEASE_DECISION_CREDIT_NOTE,
  WARRANTY_REQUEST_RELEASE_DECISION_REPAIR,
  WARRANTY_REQUEST_RELEASE_DECISION_DENIED,
} from "@/config/constants";
import { WarrantyService } from "@/services/WarrantyService";

export default defineComponent({
  props: {
    selectedWarranty: {
      type: Object as PropType<Warranty>,
      required: false,
      default: () => ({}),
    },
    warrantyRequestStatus: {
      type: Object as PropType<Warranty>,
      required: false,
      default: () => ({}),
    },
  },
  emits: ["closeDetails"],
  data() {
    return {
      warranty: {} as Warranty,
      warrantyRequestRelease: {} as WarrantyRelease,
      warrantyRequestTransactionDetails:
        {} as WarrantyRequestTransactionDetails,
      isGeneratingGuide: false,
      showModal: false,
      selectedFile: null as string | null,
      WARRANTY_REQUEST_RELEASE_DECISION_RETURN:
        WARRANTY_REQUEST_RELEASE_DECISION_RETURN as number | null,
      WARRANTY_REQUEST_RELEASE_DECISION_CHANGE:
        WARRANTY_REQUEST_RELEASE_DECISION_CHANGE as number | null,
      WARRANTY_REQUEST_RELEASE_DECISION_CREDIT_NOTE:
        WARRANTY_REQUEST_RELEASE_DECISION_CREDIT_NOTE as number | null,
      WARRANTY_REQUEST_RELEASE_DECISION_REPAIR:
        WARRANTY_REQUEST_RELEASE_DECISION_REPAIR as number | null,
      WARRANTY_REQUEST_RELEASE_DECISION_DENIED:
        WARRANTY_REQUEST_RELEASE_DECISION_DENIED as number | null,
    };
  },
  async mounted() {
    window.scrollTo(0, 0);
    this.warranty = this.$props.selectedWarranty || ({} as Warranty);
    this.warranty.relationships.warrantyRequestReleases =
      await new WarrantyService().getWarrantyRequestRelease(this.warranty.id);
    this.warranty.relationships.product =
      await new WarrantyService().getWarrantyRequestProduct(this.warranty.id);
    this.warrantyRequestRelease =
      this.warranty.relationships.warrantyRequestReleases[
        this.warranty.relationships.warrantyRequestReleases.length - 1
      ];
    this.warranty.relationships.transaction.relationships.transactionDetails.meta.forEach(
      (warrantyDetails) => {
        if (
          warrantyDetails.productId ==
          this.warranty.relationships.product.data.id.toString()
        ) {
          this.warrantyRequestTransactionDetails = warrantyDetails;
        }
      },
    );
  },
  methods: {
    getCaseId() {
      return this.warranty.id;
    },
    onImageError(event: Event) {
      const target = event.target as HTMLImageElement;
      target.src = "/images/imagen-no-disponible.png";
    },
    getCreationDate(): string {
      const date = new Date(this.warranty.attributes.created_at);
      return new Intl.DateTimeFormat("es-MX", {
        day: "numeric",
        month: "long",
        year: "numeric",
      }).format(date);
    },
    getCaseStatus() {
      const statusNumber = this.warranty.attributes.status;
      const statusText = this.convertStatus(statusNumber);
      return statusText;
    },
    getStatusClass(status: string) {
      switch (status) {
        case "Folio generado":
          return "text-bg-warning";
        case "Diagnostico en proceso":
        case "Finalizado":
          return "text-bg-success";
        case "Pago requerido":
        case "Esperando envío":
          return "text-bg-info";
        default:
          return "text-bg-danger";
      }
    },
    convertStatus(status: number): string {
      switch (status) {
        case 0:
          return "Esperando envío";
        case 1:
          return "Recibido";
        case 2:
        case 3:
        case 4:
        case 5:
        case 6:
          return "Diagnostico en proceso";
        case 7:
          return "Pago requerido";
        case 8:
          return "Liberación aceptada, esperando resultado";
        case 9:
          return "Declinado";
        case 10:
          return "Reparación completada";
        case 11:
          return "Finalizado";
        case 12:
          return "Declinado";
        default:
          return "Rechazado";
      }
    },
    getCoverImage() {
      return this.warranty.relationships.transaction.relationships
        .transactionDetails.meta[0].coverImage;
    },
    getProductName() {
      return this.warranty.relationships.transaction.relationships
        .transactionDetails.meta[0].name;
    },
    getCaseDetails() {
      return this.warranty.attributes.case_details;
    },
    canGetTransactions() {
      return this.warranty.attributes.case_details;
    },
    goBack() {
      this.$emit("closeDetails");
    },
    async downloadLabelFromCustomer() {
      await new WarrantyService().getWarrantyRequestGuideFromCustomer(
        this.warranty.id,
      );
    },
    async downloadLabelToCustomer() {
      await new WarrantyService().getWarrantyRequestGuideToCustomer(
        this.warranty.id,
      );
    },
    async generateShippingLabel() {
      this.isGeneratingGuide = true;
      try {
        await new WarrantyService().generateGuide(this.warranty.id);
        window.location.reload();
      } catch (error) {
        console.error("Error generando la guía:", error);
      } finally {
        this.isGeneratingGuide = false;
      }
    },
    openFile(file: { url: string }) {
      if (file.url.endsWith(".pdf")) {
        this.showPDF(file.url);
      } else {
        this.showImage(file.url);
      }
    },
    showPDF(fileURL: string) {
      if (fileURL && typeof fileURL === "string") {
        const link = document.createElement("a");
        link.href = fileURL;
        link.download = "";
        link.style.display = "none";

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        console.error("URL inválida o no proporcionada.");
      }
    },
    showImage(fileURL: string) {
      this.selectedFile = fileURL;
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
      this.selectedFile = null;
    },
    isPDF(file: string | null) {
      if (file) {
        return file.endsWith(".pdf");
      }
    },
    downloadFile(file: string | null) {
      if (file) {
        window.open(file, "_blank");
      }
    },
    processPayment() {},
  },
});
</script>

<template>
  <div class="container-fluid my-4">
    <div class="card mb-3">
      <div class="card-body">
        <div class="d-flex justify-content-between align-items-center mb-3">
          <h2 class="card-title">
            Detalles de la Garantía: #{{ getCaseId() }}
          </h2>
          <button class="btn btn-primary" @click="goBack()">Atrás</button>
        </div>
        <div v-if="warranty != null && warranty.relationships" class="row">
          <div class="col-md-4">
            <h5 class="card-subtitle mb-2">Información del Producto</h5>
            <div class="col-md-10 d-flex align-items-center">
              <img
                :src="getCoverImage()"
                alt="Imagen del Producto"
                class="img-fluid img-thumbnail me-3"
                style="max-width: 100px"
                @error="onImageError"
              />
              <h5 class="card-subtitle mb-2">{{ getProductName() }}</h5>
            </div>
          </div>
          <div class="col-md-7">
            <p><strong>Fecha de Solicitud:</strong> {{ getCreationDate() }}</p>
            <p>
              <strong>Estado: </strong>
              <span :class="['badge', getStatusClass(getCaseStatus())]">{{
                getCaseStatus()
              }}</span>
            </p>
            <p><strong>Detalles del Caso:</strong> {{ getCaseDetails() }}</p>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="
        warranty != null &&
        warranty.relationships &&
        !warranty.relationships.shipmentFromCustomer.data &&
        getCaseStatus() == 'Recibido'
      "
      class="card mb-3"
    >
      <div class="card-body">
        <h5 class="card-title mb-4">Enviar por Paquete Express</h5>
        <p>
          Puedes elegir enviar tu producto por el servicio de Paquete Express o
          utilizar los centros de servicio mostrados abajo. <br />Sugeto a
          disponibilidad del servicio en la localidad.
        </p>
        <strong>
          <p>¿Deseas generar una etiqueta de envío?</p>
        </strong>
        <button class="btn btn-danger" disabled="true">
          El paquete fue recibido en tienda
        </button>
        <p v-if="isGeneratingGuide">Generando guía....</p>
      </div>
    </div>

    <div
      v-if="
        warranty != null &&
        warranty.relationships &&
        !warranty.relationships.shipmentFromCustomer.data &&
        getCaseStatus() == 'Esperando envío'
      "
      class="card mb-3"
    >
      <div class="card-body">
        <h5 class="card-title mb-4">Enviar por Paquete Express</h5>
        <p>
          Puedes elegir enviar tu producto por el servicio de Paquete Express o
          utilizar los centros de servicio mostrados abajo. <br />Sugeto a
          disponibilidad del servicio en la localidad.
        </p>
        <strong>
          <p>¿Deseas generar una etiqueta de envío?</p>
        </strong>
        <button
          v-if="warranty.relationships.warranty.meta.shipping_allowed"
          class="btn btn-success"
          :disabled="isGeneratingGuide"
          @click="generateShippingLabel"
        >
          Generar Guía de Envío
        </button>
        <button
          v-if="!warranty.relationships.warranty.meta.shipping_allowed"
          class="btn btn-danger"
          disabled="true"
        >
          Servicio no disponible
        </button>
        <p v-if="isGeneratingGuide">Generando guía....</p>
      </div>
    </div>

    <div
      v-if="
        warranty != null &&
        warranty.relationships &&
        warranty.relationships.shipmentFromCustomer.data &&
        getCaseStatus() == 'Esperando envío'
      "
      class="card mb-3"
    >
      <div class="card-body">
        <h5 class="card-title mb-4">Etiqueta de Envío</h5>
        <p>
          <strong>Número de Guía:</strong>
          {{
            warranty.relationships.shipmentFromCustomer.data.meta.tracking_code
          }}
        </p>
        <p><strong>Paquetería:</strong> Paquete express</p>
        <button class="btn btn-success" @click="downloadLabelFromCustomer">
          Descargar Etiqueta de Envío
        </button>
      </div>
    </div>
    <div
      v-if="
        warranty != null &&
        warranty.relationships &&
        getCaseStatus() == 'Pago requerido'
      "
      class="card mb-3"
    >
      <div class="card-body">
        <h5 class="card-title mb-4">Realizar pago</h5>
        <p>
          Este pedido genero un cargo, es necesario hacer el pago para liberar
          la garantía.
        </p>
        <p>
          Cargo generado:
          <strong
            >${{
              warranty.relationships.warrantyRequestReleases[
                warranty.relationships.warrantyRequestReleases.length - 1
              ].meta.cost
            }}</strong
          >
        </p>
        <p>Detalles:</p>
        <strong>{{
          warranty.relationships.warrantyRequestReleases[
            warranty.relationships.warrantyRequestReleases.length - 1
          ].meta.details
        }}</strong>

        <div v-if="warranty.relationships.warrantyRequestReleases.length">
          <h6 class="mt-4">Archivos adjuntos:</h6>
          <div class="d-flex flex-wrap">
            <div
              v-for="(file, index) in warranty.relationships
                .warrantyRequestReleases[
                warranty.relationships.warrantyRequestReleases.length - 1
              ].meta.files"
              :key="index"
            >
              <div @click="openFile(file)">
                <img
                  v-if="!isPDF(file.url)"
                  :src="file.url"
                  alt="file preview"
                  class="img-fluid img-thumbnail me-3"
                  style="max-width: 150px"
                />
                <div v-if="isPDF(file.url)" class="pdf-icon">PDF</div>
              </div>
            </div>
          </div>
        </div>

        <button class="btn btn-success" @click="processPayment">
          Realizar pago
        </button>
      </div>
    </div>

    <div
      v-if="showModal"
      class="modal fade show"
      tabindex="-1"
      role="dialog"
      style="display: block"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Vista Previa</h5>
            <button
              type="button"
              class="close"
              aria-label="Close"
              @click="closeModal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body text-center">
            <img
              v-if="!isPDF(selectedFile)"
              :src="selectedFile || ''"
              alt="Archivo"
              class="img-fluid"
              style="max-height: 80vh; width: auto"
            />
            <div
              v-else
              class="d-flex justify-content-center align-items-center"
              style="height: 60vh"
            >
              <button
                class="btn btn-primary"
                @click="downloadFile(selectedFile)"
              >
                Descargar PDF
              </button>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="closeModal">
              Cerrar
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="
        warranty != null &&
        warranty.relationships &&
        getCaseStatus() == 'Finalizado'
      "
      class="card"
    >
      <div class="card-body">
        <h5 class="card-title mb-4">Resultados del caso</h5>
        <div
          v-if="
            warrantyRequestRelease.meta.decision ==
            WARRANTY_REQUEST_RELEASE_DECISION_CHANGE
          "
        >
          Este producto ha sido reemplazado por uno similar, podrás ver los
          detalles de la nueva transacción en tus pedidos
        </div>
        <div
          v-if="
            warrantyRequestRelease.meta.decision ==
              WARRANTY_REQUEST_RELEASE_DECISION_RETURN ||
            warrantyRequestRelease.meta.decision ==
              WARRANTY_REQUEST_RELEASE_DECISION_CREDIT_NOTE
          "
        >
          <p>Se ha devuelto el total de tu compra a tu monedero</p>
          <span
            >Monto devuelto:
            <b
              >${{
                +warrantyRequestTransactionDetails.totalPaid /
                warrantyRequestTransactionDetails.quantity
              }}</b
            ></span
          >
        </div>
        <div
          v-if="
            warrantyRequestRelease.meta.decision ==
            WARRANTY_REQUEST_RELEASE_DECISION_REPAIR
          "
        >
          <p>Se ha reparado tu producto</p>
          <span
            >Monto devuelto:
            <b
              >${{
                +warrantyRequestTransactionDetails.totalPaid /
                warrantyRequestTransactionDetails.quantity
              }}</b
            ></span
          >
        </div>

        <br />
        <div
          v-if="warranty != null && warranty.relationships"
          class="card mb-3"
        >
          <div class="card-body">
            <h5 class="card-title mb-4">Rastrea tu cambio o reparación</h5>
            <p>
              <strong>Número de Guía:</strong>
              {{
                warranty.relationships.shipmentToCustomer?.data?.meta
                  .tracking_code
              }}
            </p>
            <p><strong>Paquetería:</strong> Paquete express</p>
            <button class="btn btn-success" @click="downloadLabelToCustomer">
              Descargar Etiqueta de Envío
            </button>
          </div>
        </div>
      </div>
    </div>
    <br />
    <div
      v-if="
        warranty != null &&
        warranty.relationships &&
        warranty.relationships.serviceCenters
      "
      class="card"
    >
      <div class="card-body">
        <h5 class="card-title mb-4">Centros de Servicio Disponibles</h5>
        <div
          v-for="(serviceCenter, index) in warranty.relationships.serviceCenters
            .meta"
          :key="index"
          class="mb-4"
        >
          <h6 class="card-subtitle mb-2 text-primary">
            {{ serviceCenter.name }}
          </h6>
          <p class="card-text mb-1">
            <strong>Dirección:</strong>
            {{
              serviceCenter.street_name + " #" + serviceCenter.street_exterior
            }}
          </p>
          <p class="card-text">
            {{ serviceCenter.municipality }}, {{ serviceCenter.state }}
          </p>
          <div class="map-container mb-2">
            <iframe
              width="100%"
              height="200"
              style="
                border: 0;
                border-radius: 8px;
                box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
              "
              loading="lazy"
              allowfullscreen
              referrerpolicy="no-referrer-when-downgrade"
              :src="`https://www.google.com/maps/embed/v1/place?key=API_KEY&q=`"
            >
            </iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.modal {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1050;
}

.modal-dialog {
  max-width: 90%;
}

.modal-content {
  border-radius: 0.5rem;
  overflow: hidden;
}

.modal-header {
  background-color: #f8f9fa;
  border-bottom: 1px solid #dee2e6;
}

.modal-body {
  position: relative;
}

.close {
  font-size: 1.5rem;
  color: #000;
}

img {
  border-radius: 0.5rem;
}

.pdf-icon {
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 0.5rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  font-size: 1.2rem;
  color: #000;
  font-weight: bold;
}
</style>
