// src/composables/useTitleMixin.js

import { ref, onMounted } from "vue";

export default function TabTitle(translatedTitle: string) {
  const title = ref(`${translatedTitle} - Digitalife eShop`);

  onMounted(() => {
    document.title = title.value;
  });
}
