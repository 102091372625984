<template>
  <div
    class="min-h-screen d-flex flex-column justify-content-between margin-bottom-mobile-menu container-3xl mx-auto"
    :data-bs-theme="theme"
  >
    <header-page />

    <main class="mb-3 px-0 px-lg-5 flex-grow-1 min-menu container-fluid">
      <slot />
    </main>

    <footer-page :with-footer="footer" />
  </div>
</template>
<script lang="ts">
import HeaderPage from "./HeaderPage.vue";
import FooterPage from "./FooterPage.vue";
import { store } from "@/store";
export default {
  components: {
    HeaderPage,
    FooterPage,
  },
  props: {
    footer: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    theme() {
      return store.getters.getTheme;
    },
  },
};
</script>
<style lang="scss">
//
</style>
