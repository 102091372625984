export const VITE_NAME_APP = import.meta.env.VITE_NAME_APP
  ? import.meta.env.VITE_NAME_APP
  : "";
export const VITE_API_ENDPOINT = import.meta.env.VITE_API_ENDPOINT
  ? import.meta.env.VITE_API_ENDPOINT
  : "";
export const VITE_CLIENT_ID = import.meta.env.VITE_CLIENT_ID
  ? import.meta.env.VITE_CLIENT_ID
  : "";
export const VITE_CLIENT_PASSWORD = import.meta.env.VITE_CLIENT_PASSWORD
  ? import.meta.env.VITE_CLIENT_PASSWORD
  : "";
export const VITE_PUSHER_APP_KEY = import.meta.env.VITE_PUSHER_APP_KEY
  ? import.meta.env.VITE_PUSHER_APP_KEY
  : "";
export const VITE_PUSHER_APP_CLUSTER = import.meta.env.VITE_PUSHER_APP_CLUSTER
  ? import.meta.env.VITE_PUSHER_APP_CLUSTER
  : "";
export const VITE_APP_WEBSOCKETS_KEY = import.meta.env.VITE_APP_WEBSOCKETS_KEY
  ? import.meta.env.VITE_APP_WEBSOCKETS_KEY
  : "";
export const VITE_APP_WEBSOCKETS_SERVER = import.meta.env
  .VITE_APP_WEBSOCKETS_SERVER
  ? import.meta.env.VITE_APP_WEBSOCKETS_SERVER
  : "";

export const VITE_BANNER_CAROUSEL_V2 = import.meta.env.VITE_BANNER_CAROUSEL_V2
  ? import.meta.env.VITE_BANNER_CAROUSEL_V2
  : "";
export const VITE_BANNER_CAROUSEL_SECONDARY = import.meta.env
  .VITE_BANNER_CAROUSEL_SECONDARY
  ? import.meta.env.VITE_BANNER_CAROUSEL_SECONDARY
  : "";
export const VITE_BANNER_BRAND_THIN = import.meta.env.VITE_BANNER_BRAND_THIN
  ? import.meta.env.VITE_BANNER_BRAND_THIN
  : "";
export const VITE_BANNER_BRAND_SQUARE = import.meta.env.VITE_BANNER_BRAND_SQUARE
  ? import.meta.env.VITE_BANNER_BRAND_SQUARE
  : "";
export const VITE_BANNER_FASH_OFFER = import.meta.env.VITE_BANNER_FASH_OFFER
  ? import.meta.env.VITE_BANNER_FASH_OFFER
  : "";
export const VITE_BANNER_SUGGEST = import.meta.env.VITE_BANNER_SUGGEST
  ? import.meta.env.VITE_BANNER_SUGGEST
  : "";
export const VITE_BANNER_MENU_CATEGORIES = import.meta.env
  .VITE_BANNER_MENU_CATEGORIES
  ? import.meta.env.VITE_BANNER_MENU_CATEGORIES
  : "";
export const VITE_BANNER_MENU_CATEGORIES_SMALL = import.meta.env
  .VITE_BANNER_MENU_CATEGORIES_SMALL
  ? import.meta.env.VITE_BANNER_MENU_CATEGORIES_SMALL
  : "";
export const VITE_BANNER_LOGIN = import.meta.env.VITE_BANNER_LOGIN
  ? import.meta.env.VITE_BANNER_LOGIN
  : "";
export const VITE_OLD_ESHOP = import.meta.env.VITE_OLD_ESHOP
  ? import.meta.env.VITE_OLD_ESHOP
  : "";
export const VITE_BANNER_CART = import.meta.env.VITE_BANNER_CART
  ? import.meta.env.VITE_BANNER_CART
  : "";

export const VITE_FACEBOOK_APP_ID = import.meta.env.VITE_FACEBOOK_APP_ID
  ? import.meta.env.VITE_FACEBOOK_APP_ID
  : "";
export const VITE_OPENPAY_MERCHANT_ID = import.meta.env.VITE_OPENPAY_MERCHANT_ID
  ? import.meta.env.VITE_OPENPAY_MERCHANT_ID
  : "";
export const VITE_OPENPAY_PUBLIC_API_KEY = import.meta.env
  .VITE_OPENPAY_PUBLIC_API_KEY
  ? import.meta.env.VITE_OPENPAY_PUBLIC_API_KEY
  : "";
export const VITE_OPENPAY_IS_SANDBOX =
  import.meta.env.VITE_OPENPAY_IS_SANDBOX === "0" ||
  import.meta.env.VITE_OPENPAY_IS_SANDBOX === "false"
    ? false
    : true;

export const SHIPPING_DELIVERY = import.meta.env.SHIPPING_DELIVERY
  ? import.meta.env.SHIPPING_DELIVERY
  : 1;
export const SHIPPING_PICK_UP = import.meta.env.SHIPPING_PICK_UP
  ? import.meta.env.SHIPPING_PICK_UP
  : 2;

export const VITE_PAYPAL_CLIENT_ID = import.meta.env.VITE_PAYPAL_CLIENT_ID
  ? import.meta.env.VITE_PAYPAL_CLIENT_ID
  : "";

export const VITE_PAYPAL_MERCHANT_ID = import.meta.env.VITE_PAYPAL_MERCHANT_ID
  ? import.meta.env.VITE_PAYPAL_MERCHANT_ID
  : "";

export const VITE_PAYPAL_FRAUDNET_ID = import.meta.env.VITE_PAYPAL_FRAUDNET_ID
  ? import.meta.env.VITE_PAYPAL_FRAUDNET_ID
  : "";

export const VITE_PAYPAL_SANDBOX = import.meta.env.VITE_PAYPAL_SANDBOX
  ? import.meta.env.VITE_PAYPAL_SANDBOX
  : true;

export const VITE_STRIPE_CLIENT_ID = import.meta.env.VITE_STRIPE_CLIENT_ID
  ? import.meta.env.VITE_STRIPE_CLIENT_ID
  : "";

export const TRANSACTIONS_ORDERS_PAYMENT_STATUS_PENDING = 1;
export const TRANSACTIONS_ORDERS_PAYMENT_STATUS_PARTIALLY_PAYED = 2;
export const TRANSACTIONS_ORDERS_PAYMENT_STATUS_PAYED = 3;
export const TRANSACTIONS_ORDERS_PAYMENT_STATUS_CANCELLED = 4;
export const TRANSACTIONS_ORDERS_PAYMENT_STATUS_PAYED_UNCONFIRMED = 5;

export const WARRANTY_REQUEST_RELEASE_DECISION_RETURN = 1;
export const WARRANTY_REQUEST_RELEASE_DECISION_CHANGE = 2;
export const WARRANTY_REQUEST_RELEASE_DECISION_CREDIT_NOTE = 3;
export const WARRANTY_REQUEST_RELEASE_DECISION_REPAIR = 4;
export const WARRANTY_REQUEST_RELEASE_DECISION_DENIED = 5;

export const CUSTOMER_PERSON_TYPE_NATURAL = 1;
export const CUSTOMER_PERSON_TYPE_JURIDICAL = 2;

export const STORE_TYPE_BRANCH = 2;
