<template lang="">
  <a
    :id="id"
    href="#"
    data-bs-toggle="tooltip"
    :data-bs-title="title"
    class="text-decoration-none"
  >
    <slot />
  </a>
</template>
<script lang="ts">
import { Tooltip } from "bootstrap";
import { generateUUID } from "../../utils/general";

export default {
  props: {
    title: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      id: "",
    };
  },
  created() {
    this.id = "tooltip_" + generateUUID();
  },
  mounted() {
    new Tooltip("#" + this.id);
  },
};
</script>
<style lang=""></style>
