<template>
  <div class="d-flex justify-content-between justify-content-md-start">
    <div
      class="invoice-order d-flex align-items-center me-3"
      :class="{ 'text-green': requiredBilling }"
    >
      {{ textLabel }}
    </div>
    <div class="px-1" @change="toggleCheckbox">
      <label
        for="checkbox-invoice-{{ id }}"
        class="custom-checkbox"
        :class="{ checked: requiredBilling }"
      >
        <input
          id="checkbox-invoice-{{ id }}"
          :value="id"
          type="checkbox"
          :checked="requiredBilling"
          class="checkbox-invoice pointer"
        />
        <div class="checkbox-text">
          {{ requiredBilling ? on : off }}
        </div>
      </label>
    </div>
  </div>
</template>

<script lang="ts">
import eventBus from "@/event-bus";
import { store } from "@/store";
import { mapGetters } from "vuex";
export default {
  props: {
    textLabel: {
      type: String,
      default: "",
    },
    on: {
      type: String,
      default: "",
    },
    off: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
    isCheckedFromOut: {
      type: Boolean,
      default: false,
    },
    showInMovil: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      isChecked: false,
      checkedInvoice: this.id,
    };
  },
  computed: {
    ...mapGetters("checkout", {
      requiredBilling: "getRequiredBilling",
    }),
  },
  watch: {
    isCheckedFromOut(newValue) {
      this.isChecked = newValue;
    },
  },
  methods: {
    toggleCheckbox(event: Event) {
      if (event) {
        const target = event.target as HTMLInputElement;
        this.updateCheckbox(target.checked);
        eventBus.emit("show-rfc-modal", target.checked);
      }
    },
    updateCheckbox(checked: boolean) {
      store.commit("checkout/setRequiredBilling", checked);
      if (!checked) {
        store.commit("checkout/setBillingCustomerAddress", null);
      }
    },
  },
};
</script>
