<template lang="">
  <pop-over :id="popOverId">
    <template #buttonText>
      <link-nav-transaction
        tag="button"
        icon="bi-bell"
        :text="getTitle('header', 'notifications')"
        color-hover="#DC0D15"
      />
    </template>
    <template #title>
      {{ getTitle("header", "notification") }}
    </template>
    <template #default>
      <div class="d-flex flex-column">
        <p class="fs-9">
          {{ getTitle("header", "no_notifications_at_this_time") }}
        </p>
        <base-link
          to="/"
          class="link-underline link-underline-opacity-0 text-danger fs-9 text-end"
        >
          {{ getTitle("header", "go_to_notification_center") }}
        </base-link>
      </div>
    </template>
  </pop-over>
</template>
<script lang="ts">
import { getTitleTranslate, getEshopLinkTranslate } from "../../utils/general";
import PopOver from "@/components/utils/PopOver.vue";
import LinkNavTransaction from "./LinkNavTransaction.vue";
import BaseLink from "./BaseLink.vue";
export default {
  components: {
    PopOver,
    BaseLink,
    LinkNavTransaction,
  },
  computed: {
    popOverId() {
      return "pop-over-notifications" + Math.random().toString(36).substring(7);
    },
  },
  methods: {
    getTitle(section: string, name: string) {
      return getTitleTranslate(section, name);
    },
    getEshopLink(section: string, name: string) {
      return getEshopLinkTranslate(section, name);
    },
  },
};
</script>
<style lang=""></style>
