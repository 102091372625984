<template lang="">
  <div class="container-fluid">
    <div>
      <description-shopping-cart
        class="d-none d-lg-block"
        :description="getTitle('cart', 'learn_about_all_bank_promotions')"
      />
      <div
        class="payment-method-actived overflow-hidden"
        :class="
          isAccordionActive || collapseAllMethods || paymentMethodCode
            ? 'accordion-active not-small-screens'
            : ''
        "
      >
        <div class="row pointer">
          <div class="col-12" @click="collapsePaymentMethods">
            <h3 class="title-accordion-option mt-4 ms-3">
              <!-- Elige un método de pago -->
              {{ getTitle("cart", "choose_a_pay_method") }}
            </h3>
            <p class="subtitle-payment-methods mt-0 mt-lg-3 ms-3">
              <!-- ¡Ya casi es tuyo, elige la forma de pago que más te guste! -->
              {{
                getTitle(
                  "cart",
                  "its_almost_yours_choose_the_payment_method_you_like_the_mosth",
                )
              }}
            </p>
          </div>
          <div
            v-if="collapseAllMethods || paymentMethodCode"
            id="paymentMethodsAccordion"
            class="accordion px-4 position-relative"
          >
            <div
              v-for="(item, index) in fixedPaymentMethods"
              :key="index"
              class="bank-cards-list mb-2 payment-method-hover"
              :class="{
                'active-available-products': item.code === paymentMethodCode,
              }"
            >
              <div
                class="d-flex align-items-center py-2"
                @click="selectedPaymentMethod(item.code)"
              >
                <label class="fw-600 text-secondary pointer" for="_1">
                  <div class="d-flex">
                    <div class="mx-2">
                      <div class="box-light p-2 d-flex align-items-center">
                        <img
                          :style="{ width: '25px' }"
                          class="mx-auto"
                          :src="item.presentation_icon_url"
                          :alt="item.presentation_icon_url"
                        />
                      </div>
                    </div>
                    <div class="flex-column d-flex justify-content-center">
                      <h6 class="my-2 name-payment-method">
                        {{ item.presentation_name }}
                      </h6>
                    </div>
                  </div>
                </label>
              </div>
              <div
                v-if="
                  item.code === paymentMethodCode &&
                  item.tokenizer &&
                  item.code.includes('card')
                "
                class="mb-2"
              >
                <div class="mt-3">
                  <bank-card
                    :provider="paymentMethodCode"
                    :payment-method-code="item.code"
                    :cards="item.tokenizables"
                    :loading="isLoadingCard"
                    @update-tokenizables="updateTokenizables"
                    @is-loading="isLoading"
                  />
                </div>
                <div
                  v-if="item.code === mainCardMethod?.code"
                  class="mt-3 container"
                >
                  <button
                    class="btn btn-outline-secondary btn-sm"
                    @click.stop="setShowAltCardMethod(true)"
                  >
                    <i class="bi bi-exclamation-circle-fill" />
                    <span
                      >&nbsp;&nbsp;¿Tu pago con tarjeta fue rechazado? Prueba
                      con un método de tarjeta alternativo.</span
                    >
                  </button>
                </div>
              </div>
              <div
                v-if="
                  item.code === paymentMethodCode && item.presentation_template
                "
              >
                <div v-html="item.presentation_template" />
              </div>
              <div
                v-if="
                  item.code === paymentMethodCode &&
                  item.code == 'paypal_account'
                "
              >
                <paypal-payment-login />
              </div>
            </div>
            <loading :loading="loading" />
          </div>
        </div>
      </div>
    </div>
    <div id="modal-paypal" class="modal fade" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-md">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="fw-bold">
              {{ getTitle("cart", "pickup_not_available_with_paypal") }}
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div class="modal-body">
            <p class="text-justify lh-sm fs-5">
              {{ getTitle("cart", "if_you_select_with_paypal") }}
            </p>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary px-3 py-2"
              data-bs-dismiss="modal"
              @click="paypalStatus(false)"
            >
              {{ getTitle("cart", "cancel") }}
            </button>

            <button
              type="button"
              class="btn btn-danger px-3 py-2"
              @click="paypalStatus(true)"
            >
              {{ getTitle("cart", "continue_with_paypal") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { Modal } from "bootstrap";

import Jsona from "jsona";
import { CartService } from "@/services/CartService";
import { PaymentMethodService } from "@/services/PaymentMethodService";
import { store } from "@/store";
import { getTitleTranslate, errorsToString } from "../../utils/general";
import BankCard from "@/components/cart/payment/BankCard.vue";
import DescriptionShoppingCart from "./DescriptionShoppingCart.vue";
import Loading from "../ui/Loading.vue";
import { mapGetters } from "vuex";
import auth from "@/services/AuthService";
import { AxiosError, AxiosResponse } from "axios";
import { CustomNotifyService } from "@/services/CustomNotifyService";
import PaypalPaymentLogin from "@/components/cart/payment/PaypalPaymentLogin.vue";

const dataFormatter = new Jsona();
const notifyService = new CustomNotifyService();

export default {
  components: {
    DescriptionShoppingCart,
    BankCard,
    Loading,
    PaypalPaymentLogin,
  },

  data: function () {
    return {
      items: [] as any,
      loading: false,
      cartService: null as CartService | null,
      paymentMethodService: null as PaymentMethodService | null,
      activeIndex: null,
      isAccordionActive: true,
      collapseAllMethods: true,
      showConfirmModalPaypal: false,
      paypalModal: null as Modal | null,
      handleClickPaypal: false as string | false,
      isLoadingCard: false,
      showTokenizables: [],
      showAltCardMethod: false,
    };
  },

  computed: {
    isUserAuthenticated() {
      return auth.isUserLogged();
    },
    altCardMethod() {
      return this.items.find(
        (item: { code: string }) => item.code === "openpay_card",
      );
    },
    mainCardMethod() {
      return this.items.find(
        (item: { code: string }) => item.code === "paypal_card_v2",
      );
    },
    fixedPaymentMethods() {
      return this.items.filter(
        (item: { code: string }) =>
          item.code !== this.altCardMethod.code || this.showAltCardMethod,
      );
    },
    ...mapGetters("checkout", {
      paymentMethodCode: "getPaymentMethodCode",
      paymentTokenizable: "getPaymentTokenizable",
    }),
  },
  mounted() {
    this.cartService = new CartService();
    this.paymentMethodService = new PaymentMethodService();

    this.cartService.getCartInitData();
    this.loading = true;
    this.paymentMethodService
      .getPaymentMehods()
      .then((response: AxiosResponse) => {
        if (response) {
          this.items = dataFormatter.deserialize(response);
          if (this.items) {
            store.commit("checkout/setPaymentMethods", this.items);
            // Muestra el método alternativo de tarjeta al iniciar si el principal no está activo.
            if (!this.mainCardMethod && this.altCardMethod) {
              this.setShowAltCardMethod(true);
            }
          }
        }
        this.loading = false;
      })
      .catch((error: AxiosError) => {
        console.log(error);
        this.items = [];
        this.loading = false;

        notifyService.getNotification({
          type: "error",
          title: `Ocurrio un error`,
          text: errorsToString(error),
          time: 3000,
        });
      });
  },
  beforeUnmount() {
    const modals = document.querySelectorAll(".modal");
    modals.forEach((modalElement) => {
      const modal = Modal.getInstance(modalElement);
      if (modal) {
        modal.hide();
      }
    });
  },
  methods: {
    getTitle(section: string, name: string) {
      return getTitleTranslate(section, name);
    },

    selectedPaymentMethod(code: string | null) {
      store.commit("checkout/setPaymentMethodCode", code);
      store.commit("checkout/setPaymentTokenizable", null);
      this.isAccordionActive = !this.isAccordionActive;

      // if (index === this.activeIndex) {
      //     setTimeout(() => {
      //         this.activeIndex = null;
      //     }, 300);
      // } else {
      //     this.activeIndex = index;
      // }

      if (code === "paypal") {
        this.showConfirmModalPaypal = true;
        this.paypalModal = new Modal("#" + "modal-paypal");
        this.paypalModal.show();
      }

      if (code !== this.altCardMethod.code && this.mainCardMethod) {
        this.showAltCardMethod = false;
      }
    },
    collapsePaymentMethods() {
      this.collapseAllMethods = !this.collapseAllMethods;
      if (!this.collapseAllMethods) {
        this.isAccordionActive = false;
      }
    },
    paypalStatus(status: boolean) {
      if (status === false) {
        this.activeIndex = null;
      } else {
        if (this.paypalModal) {
          this.paypalModal.hide();
        }
      }
    },
    updateTokenizables(paymentMethodCode: string) {
      if (this.paymentMethodService) {
        this.isLoadingCard = true;
        this.paymentMethodService
          .getPaymentMehod(paymentMethodCode)
          .then((response: AxiosResponse) => {
            if (response) {
              const index = this.items.findIndex((item: any) => {
                return item.code == paymentMethodCode;
              });
              const data: any = dataFormatter.deserialize(response);
              if (index >= 0 && data) {
                this.items[index].tokenizables = data.tokenizables;
              }
              this.isLoadingCard = false;
            } else {
              this.isLoadingCard = false;
            }
          })
          .catch((error: AxiosError) => {
            console.log(error);
            this.isLoadingCard = false;

            notifyService.getNotification({
              type: "error",
              title: `Ocurrio un error al actualizar tu información`,
              text: errorsToString(error),
              time: 3000,
            });
          });
      }
    },
    isLoading(status: boolean) {
      this.isLoadingCard = status;
    },
    setShowAltCardMethod(show: boolean) {
      this.showAltCardMethod = show;
      if (show) {
        this.selectedPaymentMethod(this.altCardMethod.code);
      }
    },
  },
};
</script>
<style lang="scss">
@use "@/scss/cart";
@use "@/scss/variables";

.payment-method-hover:hover:not(.active-available-products) {
  border: 1px solid var(--bs-turcoise);
  border-right: 7px solid var(--bs-turcoise);
}
</style>
