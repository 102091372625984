import Brand, { IBrand } from "./Brand";
import { Links } from "@/interfaces/ShoppingCartBrand";
import Model from "./Model";

export interface ICategory {
  id: string | null;
  name: string | null;
  slug: string | null;
  image: string | null;
  parent: ICategory | null;
  popularBrands: IBrand[];
  has_subcategories: number;
  categories: [];
  links: Links;
  attributes: Attribute;
  isOpen: boolean | false;
  icon: string | null;
}

export interface Attribute {
  name: string;
  image?: string;
}
export default class Category extends Model implements ICategory {
  public id: string | null = null;
  public name: string | null = null;
  public slug: string | null = null;
  public image: string | null = null;
  public parent: Category | null = null;
  public popularBrands: Brand[] = [];
  public has_subcategories: number = 0;
  public categories: [] = [];
  public links: Links = { self: "" };
  public attributes: Attribute = { name: "" };
  public isOpen: boolean | false = false;
  public icon: string | null = null;

  public getFilterSlug(): [string, string] | undefined {
    return ["categoria", this.slug || ""];
  }

  public constructor({ id, name, slug, parent, popularBrands }: ICategory) {
    super();
    this.id = id || null;
    this.name = name || null;
    this.slug = slug || null;
    this.parent = parent ? new Category(parent as ICategory) : null;
    this.popularBrands = Array.isArray(popularBrands)
      ? Brand.fromArray(popularBrands)
      : [];
  }
}
