<template lang="">
  <div class="w-100 position-relative">
    <div
      v-if="type == 'mobile'"
      ref="inputContainer"
      class="input-group mb-1 mb-lg-3 btn-suggestion"
    >
      <input
        ref="searchInput"
        v-model="search"
        type="text"
        class="form-control rounded-start-5 border-0 ring-none"
        :placeholder="getTitle('header', 'what_are_you_looking_for')"
        :aria-label="getTitle('header', 'what_are_you_looking_for')"
        @keyup="searchSuggestions"
        @blur="search.length === 0 ? $emit('close') : null"
      />
      <span class="input-group-text rounded-end-5 bg-white border-0">
        <!-- <i class="bi bi-sliders2 bg-danger p-2 rounded-circle text-white" /> comentado por ahora por lo que comentó Eduardo en el grupo de UI -->
      </span>
    </div>
    <div v-else class="input-group btn-suggestion">
      <input
        v-model="search"
        type="text"
        class="input-search-home bg-light"
        :placeholder="getTitle('header', 'what_are_you_looking_for')"
        :aria-label="getTitle('header', 'what_are_you_looking_for')"
        aria-describedby="serch_product"
        @keyup="searchSuggestions"
      />
      <button
        id="serch_product"
        type="button"
        class="z-1 btn btn-danger rounded-0 bg-danger"
        @click="fullSearch"
      >
        <i class="bi bi-search px-2 fs-5" />
      </button>
    </div>
    <base-transition>
      <OnClickOutside
        :options="{ ignore: ['.btn-suggestion'] }"
        @trigger="closeSuggestions"
      >
        <div
          v-if="showSuggestion"
          class="menu-suggestion position-absolute start-0 border border-light-subtle shadow p-2 bg-white rounded-3"
        >
          <div
            v-if="loading"
            class="d-flex justify-content-center align-items-center p-4"
          >
            <div class="spinner-border text-secondary" role="status">
              <span class="visually-hidden">{{ $t("Loading") }}...</span>
            </div>
          </div>
          <div v-else-if="thereWasAproblem" class="text-danger mt-3">
            <p class="ms-2">
              Hubo un problema con la solicitud, intenta de nuevo más tarde.
              <a
                class="ms-1 text-danger fw-semibold text-decoration-none"
                @click="getSuggestion()"
              >
                Volver a intentar.
              </a>
            </p>
          </div>

          <div v-else-if="!haveRecords" class="p-4 text-secondary text-center">
            {{ $t("There are no records for this search") }}
          </div>
          <div v-else>
            <suggestion-input-most-wanted
              :most-wanteds="mostWanteds"
              class="px-1"
            />
            <div class="row row-cols-3 gx-2 d-none d-md-flex">
              <suggestion-input-categories :categories="categories" />
              <suggestion-input-products
                :products="products"
                :see-more="seeMoreProduct"
              />
              <suggestion-input-brands :brands="brands" />
            </div>
            <hr />
            <div class="d-md-none border border-light-subtle shadow">
              <h5 class="m-0 fs-6 text-secondary text-start px-4 py-2">
                <span class="text-primary fw-bold">{{
                  getTitle("suggestions", "suggestions")
                }}</span>
                <span class="text-secondary ms-1"
                  >{{ getTitle("suggestions", "recommended") }}
                  {{ getTitle("suggestions", "by") }}</span
                >
              </h5>
              <swiper
                :pagination="pagination"
                :modules="modules"
                class="mySwiper pt-5"
                :style="{
                  '--swiper-navigation-color': '#fff',
                  '--swiper-pagination-color': '#F3F3F3',
                  '--swiper-pagination-bullet-size': '100%',
                  '--swiper-pagination-bullet-width': '30%',
                  '--swiper-pagination-bullet-active-width': '30%',
                  '--swiper-pagination-bullet-height': '100%',
                  '--swiper-pagination-bullet-inactive-color': '#FFF',
                  '--swiper-pagination-bullet-inactive-opacity': '1',
                  '--swiper-pagination-bullet-opacity': '1',
                  '--swiper-pagination-bullet-horizontal-gap': '2px',
                  '--swiper-pagination-bullet-vertical-gap': '2px',
                  '--swiper-pagination-bullet-border-radius': '10px',
                  '--swiper-pagination-top': '10px',
                  '--swiper-pagination-bottom': 'none',
                }"
              >
                <swiper-slide>
                  <suggestion-input-categories
                    :categories="categories"
                    :mobile="true"
                  />
                </swiper-slide>
                <swiper-slide>
                  <suggestion-input-products
                    :products="products"
                    :see-more="seeMoreProduct"
                    :mobile="true"
                  />
                </swiper-slide>
                <swiper-slide>
                  <suggestion-input-brands :brands="brands" :mobile="true" />
                </swiper-slide>
              </swiper>
            </div>
          </div>
          <div class="row row-cols-3 gx-2 d-none d-md-flex">
            <div v-for="(banner, index) in banners" :key="index" class="py-2">
              <div
                class="d-flex flex-column justify-content-center align-items-center border border-light-subtle"
              >
                <img
                  v-lazy="banner.image"
                  :alt="banner.name"
                  class="img-fluid image-banner"
                />
              </div>
            </div>
          </div>
        </div>
      </OnClickOutside>
    </base-transition>
  </div>
</template>
<script lang="ts">
import SuggestionInputMostWanted from "./SuggestionInputMostWanted.vue";
import SuggestionInputCategories from "./SuggestionInputCategories.vue";
import SuggestionInputProducts from "./SuggestionInputProducts.vue";
import SuggestionInputBrands from "./SuggestionInputBrands.vue";
import BaseTransition from "@/components/common/BaseTransition.vue";
import { HomeService } from "@/services/HomeService";
import { OnClickOutside } from "@vueuse/components";
import { getTitleTranslate } from "../../utils/general";
import { Swiper, SwiperSlide } from "swiper/vue";
import Jsona from "jsona";
import { VITE_BANNER_SUGGEST } from "@/config/constants.js";

// import required modules
import { Pagination } from "swiper/modules";
import eventBus from "@/event-bus";
import { nextTick } from "vue";
import mainSearch from "@/services/MainSearchService.js";
import { useWindowScroll } from "@vueuse/core";
import { CanceledError } from "axios";

const dataFormatter = new Jsona();
export default {
  components: {
    SuggestionInputMostWanted,
    SuggestionInputCategories,
    SuggestionInputProducts,
    SuggestionInputBrands,
    BaseTransition,
    OnClickOutside,
    Swiper,
    SwiperSlide,
  },
  props: {
    type: {
      type: String,
      default: "desktop",
    },
  },
  emits: ["get-results", "get-tabTitle", "close"],
  setup() {
    const tabs = [
      getTitleTranslate("suggestions", "categories"),
      getTitleTranslate("suggestions", "products"),
      getTitleTranslate("suggestions", "brands"),
    ];
    return {
      suggessTimeoutId: null as number | null,
      homeService: new HomeService(),
      pagination: {
        clickable: true,
        renderBullet: function (index: number, className: string) {
          return (
            '<span class="pagination-tabs ' +
            className +
            '">' +
            tabs[index] +
            "</span>"
          );
        },
      },
      modules: [Pagination],
    };
  },
  data() {
    return {
      search: this.setSearchedText() as string,
      seeMoreProduct: "/",
      showSuggestion: false,
      preventNextSuggest: false,
      loading: false,
      categories: [],
      products: [],
      brands: [],
      mostWanteds: [],
      banners: [],
      newPath: "" as string,
      thereWasAproblem: false,
      reactiveResponse: mainSearch.reactiveResponse,
      windowScroll: useWindowScroll(),
      blurTid: undefined as number | undefined,
      blurScrollY: 0,
      initialMainMarginTop: 0,
      elementOffsetHeight: 0,
      loadedBanners: false,
    };
  },
  computed: {
    haveRecords() {
      if (
        !this.loading &&
        (this.categories.length > 0 ||
          this.products.length > 0 ||
          this.brands.length > 0 ||
          this.mostWanteds.length > 0)
      ) {
        return true;
      }
      return false;
    },
  },
  watch: {
    type(newType) {
      if (newType === "mobile") {
        this.setFocus();
      }
    },
    reactiveResponse() {
      this.setSearchedText();
    },
    "$route.params.path": {
      deep: true,
      handler() {
        this.setSearchedText();
      },
    },
    // Solicita por evento cerrar el input de búsqueda cuando el usuario scrollea.
    "windowScroll.y"(y) {
      const distanceToClose = 70;
      if (!this.blurTid) {
        this.blurTid = window.setTimeout(() => {
          const distance = Math.abs(this.blurScrollY - y);
          if (distance > distanceToClose && this.search.length === 0) {
            this.$emit("close");
          }
          this.blurTid = undefined;
        }, 1000); // tiempo para cerrar el input de búsqueda si el usuario siguió scrolleando
      }
      this.blurScrollY = y;
    },
  },
  mounted() {
    if (this.type === "mobile") {
      this.setFocus();
    }
    this.setSearchedText();
  },
  beforeUnmount() {
    eventBus.off("close-suggestions");
    // Revierte el desplazamiento del contenido principal antes de cerrar el input.
    const main = document.querySelector("main");
    if (main) {
      main.style.marginTop = this.initialMainMarginTop + "px";
    }
  },
  created() {
    this.homeService.getSuggestionInitData();
    eventBus.on("close-suggestions", (status = false) => {
      this.showSuggestion = status as boolean;
    });
  },
  methods: {
    setSearchedText() {
      const prevRes = mainSearch.reactiveResponse.value;
      if (
        !this.search &&
        this.$route.name === "search" &&
        prevRes?.meta.searchText
      ) {
        this.search = prevRes.meta.searchText || "";
      } else if (this.$route.name !== "search") {
        this.search = "";
      }
    },
    getSuggestion() {
      if (this.suggessTimeoutId) {
        clearTimeout(this.suggessTimeoutId);
        this.homeService.lastSuggestSignal?.abort();
      }
      this.suggessTimeoutId = window.setTimeout(async () => {
        if (this.preventNextSuggest) {
          this.preventNextSuggest = false;
          return;
        }
        this.loading = true;
        this.thereWasAproblem = false;
        this.showSuggestion = true;
        try {
          const response = await this.homeService.getSuggestions(this.search);
          const suggestions = dataFormatter.deserialize(response);
          this.seeMoreProduct = "buscar/t_" + this.search;
          this.categories = suggestions.filter(
            (item: any) => item.type == "categories",
          );
          this.products = suggestions.filter(
            (item: any) => item.type == "products",
          );
          this.brands = suggestions.filter(
            (item: any) => item.type == "brands",
          );
          this.mostWanteds = suggestions.filter(
            (item: any) => item.type == "product_search_suggestions",
          );
          this.loading = false;
          this.thereWasAproblem = false;
          if (!this.loadedBanners) {
            this.homeService
              .getBanners(VITE_BANNER_SUGGEST)
              .then((response) => {
                if (response) {
                  this.banners = response;
                }
              });
            this.loadedBanners = true;
          }
        } catch (err) {
          if (!(err instanceof CanceledError)) {
            this.loading = false;
            this.thereWasAproblem = true;
            console.error(err);
          }
        }
      }, 500);
    },
    searchSuggestions(ev: KeyboardEvent) {
      if (this.search.length > 2) {
        if (ev.code == "Enter") {
          this.fullSearch();
        } else {
          this.getSuggestion();
        }
      } else {
        this.showSuggestion = false;
      }
    },
    closeSuggestions() {
      this.showSuggestion = false;
    },
    getTitle(section: string, name: string) {
      return getTitleTranslate(section, name);
    },
    fullSearch() {
      this.newPath = `${"/buscar?search_text=" + this.search}`; // manda el texto original para dejar que el backend lo corrija adecuadamente
      this.$router.push(this.newPath);
      this.preventNextSuggest = true;
      this.homeService.lastSuggestSignal?.abort();
      this.showSuggestion = false;
      if (this.search.length === 0) {
        this.$emit("close");
      }
    },
    formatText(input: string) {
      const words = input.trim().split(/\s+/);
      return words.length > 1 ? words.join("-") : input;
    },
    setFocus() {
      nextTick(() => {
        const input = this.$refs.searchInput as HTMLInputElement;
        if (input) {
          input.focus();
        }
        // Mueve el contenido principal hacia abajo para que el input no tape el contenido.
        const main = document.querySelector("main");
        if (main) {
          this.$nextTick(() => {
            this.initialMainMarginTop = parseFloat(main.style.marginTop) || 0;
            this.elementOffsetHeight = (
              this.$refs.inputContainer as HTMLElement
            ).clientHeight;
            main.style.marginTop =
              this.initialMainMarginTop + this.elementOffsetHeight + 6 + "px";
          });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.menu-suggestion {
  top: calc(100% + 1px);
  z-index: 9999;
  height: auto;
  max-height: 75vh;
  width: 100%;
  overflow: scroll;
  position: absolute;
  width: auto;
  @media (min-width: 992px) {
    width: 65vw;
  }
  @media (min-width: 992px) {
    top: calc(100% + 20px) !important;
  }
}

.ring-none {
  box-shadow: unset !important;
}

.menu-suggestion-tabs {
  width: 300%;

  @media (min-width: 768px) {
    width: 100%;
  }
}

.input-search-home {
  width: 379px;
  border: 1px solid #e5e5e5;
  outline: none;
  padding: 10px;

  @media (min-width: 992px) {
    max-width: 75%;
  }

  @media (min-width: 1200px) {
    max-width: 80%;
  }

  @media (min-width: 1400px) {
    max-width: 85%;
    height: 57px;
  }

  @media (min-width: 1700px) {
    max-width: 100%;
    height: 64px;
  }

  &::placeholder {
    font: normal normal normal 16px/18px Titillium Web;
  }
}
</style>
