<template>
  <section class="container-login">
    <div class="bg-auth">
      <div
        class="d-flex justify-content-center align-items-center height-border-options"
      >
        <div class="card border-login">
          <div class="card-body py-5">
            <div class="d-flex justify-content-center">
              <digitalife-logo />
            </div>
            <div class="card-subtitle mt-5 mb-3" data-cy="login-text">
              Reestablecer contraseña
            </div>
            <div>
              <VeeForm
                :validation-schema="passwordSchema"
                @submit="resetPassword"
              >
                <div class="mt-4">
                  <div class="form-group">
                    <label for="email" />
                    <Field
                      id="email"
                      v-model="email"
                      class="input-field"
                      type="email"
                      name="email"
                      data-cy="email-field"
                      :placeholder="emailPlaceholderText"
                      readonly="true"
                      @mouseover="onEmailMouseOver"
                      @mouseout="onEmailMouseOut"
                    />
                    <ErrorMessage name="email" class="error" />
                    <span v-if="hasErrorsByName('email')" class="error">
                      <p>
                        {{ getFirstErrorByName("email") }}
                      </p>
                    </span>
                  </div>
                </div>
                <div class="mb-4">
                  <label
                    for="password"
                    class="d-flex justify-content-end password-icon-see-hide"
                    data-cy="password-confirmation-required"
                  >
                    <view-hide-password
                      :show-password="showPassword"
                      @toggle="togglePasswordVisibility"
                    />
                  </label>
                  <div class="d-flex">
                    <Field
                      id="password"
                      v-model="password"
                      class="input-field"
                      :type="showPassword ? 'text' : 'password'"
                      name="password"
                      data-cy="password-field"
                      :minlenght="9"
                      :placeholder="passwordPlaceholderText"
                      @mouseover="onPasswordMouseOver"
                      @mouseout="onPasswordMouseOut"
                    />
                  </div>
                  <ErrorMessage
                    name="password"
                    class="error"
                    data-cy="password-error"
                  />
                </div>
                <div class="form-group mb-4">
                  <label for="passwordConfirmation" />
                  <Field
                    id="passwordConfirmation"
                    v-model="password_confirmation"
                    class="input-field"
                    :type="showPassword ? 'text' : 'password'"
                    name="password_confirmation"
                    data-cy="password-confirmation-field"
                    :minlenght="9"
                    :placeholder="passwordConfirmationPlaceholderText"
                    @mouseover="onPasswordConfirmationMouseOver"
                    @mouseout="onPasswordConfirmationMouseOut"
                  />
                  <ErrorMessage name="password_confirmation" class="error" />
                  <span
                    v-if="hasErrorsByName('password_confirmation')"
                    class="error"
                  >
                    <p>{{ getFirstErrorByName("password_confirmation") }}</p>
                  </span>
                </div>
                <div class="d-flex justify-content-center mt-5">
                  <auth-button
                    :button-text="'Reestablecer contraseña'"
                    :is-submitting="isSubmitting"
                  />
                </div>
              </VeeForm>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script lang="ts">
import { defineComponent, ref, computed } from "vue";
import AuthButton from "@/components/common/AuthButton.vue";
import DigitalifeLogo from "@/components/common/DigitalifeLogo.vue";
import TabTitle from "@/interfaces/TabTitle";
import { Form as VeeForm, Field, ErrorMessage } from "vee-validate";
import { ErrorItem } from "@/interfaces/ErrorItem";
import { CustomNotifyService } from "@/services/CustomNotifyService";
import auth from "@/services/AuthService";
import { ResetPassword } from "@/interfaces/ResetPassword";
import ViewHidePassword from "@/components/common/ViewHidePassword.vue";
import { useRoute } from "vue-router";

const notifyService = new CustomNotifyService();

export default defineComponent({
  components: {
    AuthButton,
    DigitalifeLogo,
    VeeForm,
    Field,
    ErrorMessage,
    ViewHidePassword,
  },
  setup() {
    const route = useRoute();
    const email = route.query.email as string;
    const token = route.query.token as string;
    const password = ref("");
    const password_confirmation = ref("");

    const emailPlaceholderText = ref("Correo electrónico");
    const passwordPlaceholderText = ref("Contraseña");
    const passwordConfirmationPlaceholderText = ref("Confirmar contraseña");

    const isSubmitting = ref(false);
    const showPassword = ref(false);
    const loginErrors = ref([] as ErrorItem[]);

    TabTitle("Reestablecer contraseña");

    const validateEmail = (value: string) => {
      const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
      if (!value) {
        return "El correo electrónico es necesario";
      } else if (!regex.test(value)) {
        return "Ingresa un correo electrónico válido";
      } else {
        return true;
      }
    };

    const validatePassword = (value: string) => {
      password.value = value;
      const regex = /^(?=.*[A-Z])(?=.*[\W_]).+$/;

      if (!value) {
        return "Tu contraseña es necesaria";
      } else if (value.length < 9) {
        return "La contraseña debe tener al menos 9 caracteres";
      } else if (!regex.test(value)) {
        return "Al menos 9 caracteres con 1 letra mayúscula y 1 símbolo es requerido.";
      } else {
        return true;
      }
    };

    const validatePasswordConfirmation = (value: string) => {
      if (!value) {
        return "Confirma aquí tu contraseña";
      }
      if (value.length < 9) {
        return "La contraseña debe tener al menos 9 caracteres";
      }
      if (value !== password.value) {
        return "Las contraseñas deben coincidir";
      }
      return true;
    };

    const passwordSchema = computed(() => ({
      email: validateEmail,
      password: validatePassword,
      password_confirmation: validatePasswordConfirmation,
    }));

    const resetPassword = async () => {
      const data: ResetPassword = {
        token,
        email,
        password: password.value,
        password_confirmation: password_confirmation.value,
      };
      try {
        isSubmitting.value = true;
        const response = await auth.resetPassword(data);
        console.log(response);
        if (response) {
          setTimeout(() => {
            notifyService.getNotification({
              title: "¡Reestablecimiento exitoso!",
              text: "¡Tu contraseña ha sido restablecida!",
            });
          }, 1500);

          setTimeout(() => {
            window.location.href = "/";
            isSubmitting.value = false;
          }, 2000);
        } else {
          notifyService.getNotification({
            type: "error",
            title: "¡Ha ocurrido un error!",
            text: "¡Por favor revisa e intenta de nuevo!",
          });
        }
      } catch (error) {
        console.error(error);
      }
    };

    const onEmailMouseOver = () => {
      emailPlaceholderText.value = "Escribe aquí tu correo electrónico";
    };

    const onEmailMouseOut = () => {
      emailPlaceholderText.value = "Correo electrónico";
    };

    const onPasswordMouseOver = () => {
      passwordPlaceholderText.value = "Escribe aquí tu contraseña";
    };

    const onPasswordMouseOut = () => {
      passwordPlaceholderText.value = "Contraseña";
    };

    const onPasswordConfirmationMouseOver = () => {
      passwordConfirmationPlaceholderText.value = "Confirma aquí tu contraseña";
    };

    const onPasswordConfirmationMouseOut = () => {
      passwordConfirmationPlaceholderText.value = "Confirmar contraseña";
    };

    const hasErrorsByName = (field: string) => {
      return loginErrors.value.some(
        (error: ErrorItem) =>
          error.source.pointer === "/data/attributes/" + field,
      );
    };

    const getErrorsByName = (field: string) => {
      return loginErrors.value.filter(
        (error: ErrorItem) =>
          error.source.pointer === "/data/attributes/" + field,
      );
    };

    const getFirstErrorByName = (field: string) => {
      const errors = getErrorsByName(field);
      if (hasErrorsByName(field) && errors[0].detail) {
        return errors[0].detail;
      }
      return "";
    };

    const togglePasswordVisibility = () => {
      showPassword.value = !showPassword.value;
    };

    return {
      passwordSchema,
      resetPassword,
      email,
      emailPlaceholderText,
      passwordPlaceholderText,
      passwordConfirmationPlaceholderText,
      password,
      password_confirmation,
      isSubmitting,
      showPassword,
      loginErrors,
      onEmailMouseOver,
      onEmailMouseOut,
      onPasswordMouseOver,
      onPasswordMouseOut,
      onPasswordConfirmationMouseOver,
      onPasswordConfirmationMouseOut,
      hasErrorsByName,
      getErrorsByName,
      getFirstErrorByName,
      togglePasswordVisibility,
      token,
    };
  },
});
</script>

<style lang="scss" scoped>
@use "@/scss/auth/auth";
</style>
