<script lang="ts">
import {
  defineComponent,
  ref,
  computed,
  onMounted,
  onBeforeUnmount,
  watch,
} from "vue";
import OrderDetail from "./OrderDetail.vue";
import { Modal } from "bootstrap";
import { userProfileService } from "@/services/UserProfileService";
import Transaction from "@/models/Transaction";
import WarrantySelection from "@/components/warranty/WarrantySelection.vue";
import WarrantyDetails from "@/components/warranty/WarrantyDetails.vue";
import NoAddedDataYet from "@/components/common/NoAddedDataYet.vue";
import { useRoute, useRouter } from "vue-router";
import { FilterOption } from "@/interfaces/FilterOption";
import eventBus from "@/event-bus";
import { BrandImage } from "@/interfaces/BrandImage";
import Jsona from "jsona";
import {
  PageResponse,
  PaginationLinks,
  PaginationOrder,
} from "@/interfaces/PaginationOrder";
const dataFormatter = new Jsona();

export default defineComponent({
  components: {
    OrderDetail,
    WarrantySelection,
    WarrantyDetails,
    NoAddedDataYet,
  },
  emits: ["random-brands-profile"],
  setup() {
    const showWarrantySelection = ref(false);
    const showWarrantyDetails = ref(false);
    const selectedOrderId = ref<string | null>(null);
    const selectedOrder = ref<Transaction | null>(null);
    const orders = ref<Transaction[]>([]);
    const modalTitle = ref("");
    const filters = ref<FilterOption[]>([]);
    const orderBy = ref<FilterOption[]>([]);
    const exitingOrderId = ref<string | null>(null);
    const isNavVisible = ref(false);
    const selectedWarrantyProduct = ref<any>({});
    const loading = ref(false);
    const route = useRoute();
    const router = useRouter();
    const selectedFilter = ref<FilterOption[]>([]);
    let textSearchBy = ref("");
    const textOrderBy = ref("");
    const randomBrands = ref<BrandImage[]>([]);

    const currentPage = ref<number>(1);
    const totalPages = ref<number[]>([]);
    const paginationLinks = ref<PaginationLinks>({
      first: "",
      prev: "",
      last: "",
      next: "",
      self: "",
    });

    const sortBy = ref(route.query.sort_by || route.query.ordenar_por || 0);
    const filterBy = ref(route.query.filter_by || route.query.filtrar_por || 0);
    let paginationInformation = ref<PaginationOrder | null>(null);
    let storedTransactionUrl = localStorage.getItem("transactionUrl");
    const storedTextOrderBy = localStorage.getItem("textOrderBy");
    const storedTextSearchBy = localStorage.getItem("textSearchBy");

    const idModal = computed(
      () => "modal-filters-orders-" + Math.random() * 99,
    );
    const isLargeScreen = ref(window.innerWidth >= 992);

    const updateScreenSize = () => {
      isLargeScreen.value = window.innerWidth >= 992;
    };

    const getOrders = async (
      pageNumber: number,
      filters?: { filtrar_por: number; ordenar_por: string },
    ) => {
      try {
        loading.value = true;

        let storedTransactionUrl = localStorage.getItem("transactionUrl");
        let storedQuery = storedTransactionUrl
          ? JSON.parse(storedTransactionUrl)
          : {};

        storedQuery.page = pageNumber;

        localStorage.setItem("transactionUrl", JSON.stringify(storedQuery));

        if (pageNumber > 1) {
          const url = new URL(window.location.href);
          url.searchParams.set("page", pageNumber.toString());
          window.history.pushState({}, "", url);
        }

        const response = await userProfileService.getPaginatedTransactions({
          pageNumber: pageNumber,
          filters: filters,
        });

        if (response) {
          const data = dataFormatter.deserialize(response.data);

          currentPage.value = pageNumber;
          const meta: PageResponse = response.data.meta;
          paginationInformation.value = meta.page;

          const totalPagesCount = Math.ceil(
            Number(paginationInformation.value.total) /
              Number(paginationInformation.value.perPage),
          );

          totalPages.value = Array.from(
            { length: totalPagesCount },
            (_, i) => i + 1,
          );
          paginationLinks.value = response.data.links;

          orders.value = data as Transaction[];

          let brandImages: BrandImage[] = [];

          response.data.data.forEach((res: Transaction) => {
            if (
              res.relationships &&
              res.relationships.transactionDetails.meta
            ) {
              res.relationships.transactionDetails.meta.forEach(
                (detail: { brand_image: string; brand_name: string }) => {
                  if (detail.brand_image) {
                    brandImages.push({
                      image: detail.brand_image,
                      name: detail.brand_name,
                    });
                  }
                },
              );
            }
          });

          const uniqueBrandImages = Array.from(
            new Map(brandImages.map((item) => [item.name, item])).values(),
          );

          const shuffleArray = (array: BrandImage[]) => {
            return array.sort(() => Math.random() - 0.5);
          };

          const shuffledBrands = shuffleArray(uniqueBrandImages);
          randomBrands.value = shuffledBrands.slice(0, 3);
          eventBus.emit("random-brands-profile", randomBrands.value);
        }
        loading.value = false;
      } catch (error) {
        console.error(error);
        loading.value = false;
      }
    };

    const showFilters = (type: string) => {
      if (type === "filterBy") {
        modalTitle.value = "Filtrar por";
        getFilter();
        selectedFilter.value = filters.value;
      } else {
        getOrderBy();
        modalTitle.value = "Ordenar por";
        selectedFilter.value = orderBy.value;
      }
      const modalFilters = new Modal("#" + idModal.value);
      modalFilters.show();
    };

    const getFilter = () => {
      filters.value = [
        { to: "filtrar_por=pendiente", name: "Pendiente", value: 1 },
        { to: "filtrar_por=confirmado", name: "Confirmado", value: 2 },
        { to: "filtrar_por=completado", name: "Completado", value: 6 },
        { to: "filtrar_por=cancelado", name: "Cancelado", value: 5 },
        { to: "filtrar_por=todos", name: "Todos", value: 0 },
      ];
    };

    const getOrderBy = () => {
      orderBy.value = [
        {
          to: "ordenar_por=reciente",
          name: "Más reciente",
          value: "-created_at",
        },
        {
          to: "ordenar_por=antiguo",
          name: "Más antiguo",
          value: "created_at",
        },
      ];
    };

    const handleWarrantySelection = (
      orderId: string | null,
      order: Transaction,
    ) => {
      selectedOrder.value = order;
      selectedOrderId.value = orderId;
      showWarrantySelection.value = true;
    };

    const handleWarrantyDetails = (product: Transaction) => {
      selectedWarrantyProduct.value = product;

      showWarrantySelection.value = false;
      showWarrantyDetails.value = true;
    };

    const finalizeWarranty = () => {
      showWarrantySelection.value = false;
      showWarrantyDetails.value = false;
      router.push({ name: "warranties" });
    };

    const goBackFromWarrantyDetails = () => {
      selectedWarrantyProduct.value = {};
      showWarrantySelection.value = true;
      showWarrantyDetails.value = false;
    };

    const goBack = () => {
      showWarrantySelection.value = false;
      showWarrantyDetails.value = false;
    };

    const paginatedOders = async () => {
      const response = await userProfileService.getPaginatedTransactions({
        pageNumber: 1,
      });
      orders.value = response;
    };

    const destroyModal = () => {
      const modals = document.querySelectorAll(".modal");
      modals.forEach((modalElement) => {
        const modal = Modal.getInstance(modalElement);
        if (modal) {
          modal.hide();
        }
      });
    };

    const initialFilters = {
      filtrar_por: filterBy.value ? Number(filterBy.value) : 0,
      ordenar_por: String(sortBy.value),
    };

    const goToFirstPage = () => {
      window.scrollTo(0, 0);
      getOrders(1);
    };

    const goToPreviousPage = () => {
      window.scrollTo(0, 0);
      if (paginationLinks.value.prev) {
        if (storedTransactionUrl) {
          getOrders(currentPage.value - 1, JSON.parse(storedTransactionUrl));
        } else {
          getOrders(currentPage.value - 1, initialFilters);
        }
      }
    };

    const goToNextPage = () => {
      window.scrollTo(0, 0);
      if (paginationLinks.value.next) {
        if (storedTransactionUrl) {
          getOrders(currentPage.value + 1, JSON.parse(storedTransactionUrl));
        } else {
          getOrders(currentPage.value + 1, initialFilters);
        }
      }
    };

    const goToLastPage = () => {
      window.scrollTo(0, 0);
      if (storedTransactionUrl) {
        getOrders(totalPages.value.length, JSON.parse(storedTransactionUrl));
      } else {
        getOrders(totalPages.value.length, initialFilters);
      }
    };

    const goToPage = (page: number) => {
      window.scrollTo(0, 0);
      getOrders(page);
    };

    const updateUrl = () => {
      const query = {
        ordenar_por: sortBy.value as string,
        filtrar_por: parseInt(filterBy.value as string),
      };
      const transactionUrl = JSON.stringify(query);

      localStorage.setItem("transactionUrl", transactionUrl);
      getOrders(1, query);

      router.push({ query });
      if (!isLargeScreen.value) {
        destroyModal();
      }
    };

    const updateUrlMobile = (filter: number | string, name: string) => {
      if (typeof filter === "string") {
        sortBy.value = filter;
        textOrderBy.value = name;
        localStorage.setItem("textOrderBy", textOrderBy.value);
      } else {
        filterBy.value = filter;
        textSearchBy.value = name;
        localStorage.setItem("textSearchBy", textSearchBy.value);
      }
      updateUrl();
    };

    onMounted(() => {
      window.scrollTo(0, 0);

      if (storedTransactionUrl) {
        const localStorageUrl = JSON.parse(storedTransactionUrl);
        getOrders(localStorageUrl.page, localStorageUrl);
      } else {
        getOrders(1);
      }

      if (storedTextOrderBy) textOrderBy.value = storedTextOrderBy;
      if (storedTextSearchBy) textSearchBy.value = storedTextSearchBy;

      getFilter();
      getOrderBy();
      updateScreenSize();
      window.addEventListener("resize", updateScreenSize);
    });

    onBeforeUnmount(() => {
      localStorage.removeItem("transactionUrl");
      localStorage.removeItem("textOrderBy");
      localStorage.removeItem("textSearchBy");
      destroyModal();
      window.removeEventListener("resize", updateScreenSize);
    });

    watch(route, (newVal) => {
      if (newVal && newVal.fullPath) {
        selectedOrderId.value = "";
        isNavVisible.value = false;
      }
    });

    watch([sortBy, filterBy], () => {
      updateUrl();
    });

    return {
      showWarrantySelection,
      showWarrantyDetails,
      selectedOrderId,
      selectedOrder,
      orders,
      modalTitle,
      filters,
      orderBy,
      exitingOrderId,
      isNavVisible,
      selectedWarrantyProduct,
      loading,
      idModal,
      isLargeScreen,
      showFilters,
      handleWarrantySelection,
      handleWarrantyDetails,
      finalizeWarranty,
      goBackFromWarrantyDetails,
      goBack,
      selectedFilter,
      textSearchBy,
      textOrderBy,
      randomBrands,
      paginatedOders,
      currentPage,
      totalPages,
      paginationLinks,
      goToFirstPage,
      goToPreviousPage,
      goToNextPage,
      goToLastPage,
      goToPage,
      sortBy,
      filterBy,
      updateUrl,
      paginationInformation,
      updateUrlMobile,
    };
  },
});
</script>

<template>
  <div>
    <div
      v-if="!showWarrantySelection && !showWarrantyDetails"
      class="d-lg-none"
    >
      <div v-if="!loading" class="d-flex mb-2 ps-0 ps-md-3 ps-lg-5">
        <button
          type="button"
          class="btn-filter d-flex justify-content-between align-items-center px-2 text-secondary"
          :class="{ 'w-50': textSearchBy }"
          @click="showFilters('filterBy')"
        >
          <div>
            Filtrar por <span class="text-lowercase">{{ textSearchBy }}</span>
          </div>
          <i class="bi bi-chevron-right text-end"></i>
        </button>
        <button
          type="button"
          class="btn-filter d-flex justify-content-between align-items-center ms-2 px-2 text-secondary"
          :class="{ 'w-50': textOrderBy }"
          @click="showFilters('orderBy')"
        >
          <div>
            Ordenar por <span class="text-lowercase">{{ textOrderBy }}</span>
          </div>
          <i class="bi bi-chevron-right text-end"></i>
        </button>
      </div>
    </div>
    <div
      v-if="!loading && !showWarrantySelection && !showWarrantyDetails"
      class="row d-none d-lg-flex justify-content-lg-end g-2"
    >
      <div class="col-3">
        <select
          v-model="filterBy"
          class="form-select orders bg-light"
          aria-label="Filtrar por"
          @change="updateUrl"
        >
          <option value="0" selected disabled>Filtrar por</option>
          <option
            v-for="(filter, index) in filters"
            :key="index"
            :value="filter.value"
          >
            {{ filter.name }}
          </option>
        </select>
      </div>
      <div class="col-3">
        <select
          v-model="sortBy"
          class="form-select orders bg-light"
          aria-label="Ordenar por"
          @change="updateUrl"
        >
          <option value="0" selected disabled>Ordenar por</option>
          <option
            v-for="(filter, index) in orderBy"
            :key="index"
            :value="filter.value"
          >
            {{ filter.name }}
          </option>
        </select>
      </div>
    </div>
    <div v-if="orders.length === 0 && !loading" class="mt-2">
      <no-added-data-yet :text="'Sin resultados para mostrar'" />
    </div>

    <div v-if="loading">
      <div class="card border-0" aria-hidden="true">
        <p
          class="card-text placeholder-glow mb-2 d-flex justify-content-lg-end"
        >
          <span
            class="placeholder col-4 col-lg-3 rounded-5 bg-light py-3"
          ></span>
          <span
            class="placeholder col-4 col-lg-3 rounded-5 bg-light py-3 ms-1 ms-lg-3"
          ></span>
        </p>
      </div>

      <p v-for="index in 6" :key="index" class="placeholder-glow">
        <span class="placeholder col-12 bg-light" style="height: 65px"></span>
      </p>
    </div>

    <div
      v-if="!showWarrantySelection && !showWarrantyDetails"
      class="d-flex flex-column gap-2 gap-xl-3 profile-order-section px-md-4 px-lg-0"
    >
      <div
        v-for="(order, index) in orders"
        :key="index"
        class="container-orders pointer mb-0 order-list p-2 p-md-3 p-lg-2 pointer h-100"
        :class="{
          'move-to-left': isNavVisible,
        }"
      >
        <div class="row">
          <div class="col-12">
            <order-detail
              :show-warranty-selection="showWarrantySelection"
              :order="order"
              @warranty-selected="handleWarrantySelection(order.id, order)"
            />
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="
        orders.length > 0 &&
        !loading &&
        !showWarrantySelection &&
        !showWarrantyDetails
      "
      class="pagination mt-3 float-lg-end d-flex flex-column align-items-center flex-lg-row"
    >
      <div class="information px-2 small text-secondary">
        {{ paginationInformation?.total }} pedidos
      </div>
      <div class="d-flex">
        <button
          class="d-flex"
          :disabled="!paginationLinks.first"
          @click="goToFirstPage"
        >
          <i class="bi bi-chevron-bar-left"></i
          ><span class="d-none d-lg-block">Primera</span>
        </button>
        <button
          class="d-flex"
          :disabled="!paginationLinks.prev"
          @click="goToPreviousPage"
        >
          <i class="bi bi-chevron-left"></i>
          <span class="d-none d-lg-block">Anterior</span>
        </button>
        <button
          v-for="page in totalPages"
          :key="page"
          :class="{ 'bg-danger text-white': currentPage === page }"
          @click="goToPage(page)"
        >
          {{ page }}
        </button>

        <button
          class="d-flex"
          :disabled="!paginationLinks.next"
          @click="goToNextPage"
        >
          <span class="d-none d-lg-block">Siguiente</span>
          <i class="bi bi-chevron-right"></i>
        </button>
        <button
          class="d-flex"
          :disabled="!paginationLinks.last"
          @click="goToLastPage"
        >
          <span class="d-none d-lg-block">Última</span>
          <i class="bi bi-chevron-bar-right"></i>
        </button>
      </div>
    </div>
    <div class="col-12">
      <warranty-selection
        v-if="showWarrantySelection"
        :order-id="selectedOrderId || ''"
        @warranty-details="handleWarrantyDetails"
        @go-back="goBack"
        @product-selected="handleWarrantyDetails"
      />
    </div>
    <div class="col-12">
      <warranty-details
        v-if="showWarrantyDetails"
        :selected-product="selectedWarrantyProduct"
        @finalize-warranty="finalizeWarranty"
        @go-back-from-warranty-details="goBackFromWarrantyDetails"
      />
    </div>
    <div :id="idModal" class="modal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-fullscreen-sm-down modal-dialog-centered">
        <div class="modal-content modal-filters p-0 mx-auto">
          <div class="modal-header border-0">
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div class="content text-center p-0 mx-3 filter-content">
            <p class="modal-title mb-3 text-start ms-3">{{ modalTitle }}</p>
            <ul class="list-unstyled">
              <li
                v-for="(filter, index) in selectedFilter"
                :key="index"
                @click="updateUrlMobile(filter.value, filter.name)"
              >
                <div
                  class="d-flex align-items-center justify-content-between px-3 text-decoration-none pointer"
                  :class="{
                    active:
                      filter.name === textOrderBy ||
                      filter.name === textSearchBy,
                  }"
                >
                  <span class="text-secondary">
                    {{ filter.name }}
                  </span>
                  <i class="bi bi-chevron-right"></i>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.menu a {
  z-index: 1;
  position: relative;
}

.order-detail {
  position: absolute;
  right: 0px;
  height: 100vh;
  background-color: var(--bs-white);
  transform: translateX(100%);
  transition: transform 0.3s ease-in;
  /* overflow: scroll; */
  overflow-y: auto;
}

.order-detail::-webkit-scrollbar {
  display: none;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  overflow: -moz-scrollbars-none; /* Firefox versiones anteriores */
}

.order-detail-view {
  width: 95%;
  border: 1px solid var(--bs-gray-300);
  transform: translateX(0);
  border-radius: 8px;
  margin: 10px;
}

.move-to-left {
  transform: translateX(-120%);
}

.slide-enter-active,
.slide-leave-active {
  transition: transform 0.3s ease-in-out;
}

.slide-enter {
  transform: translateX(100%);
}

.slide-leave-to {
  transform: translateX(100%);
}

.modal-filters {
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.modal-filters::-webkit-scrollbar {
  display: none;
}

.modal-content.modal-filters {
  position: fixed;
  top: auto;
  bottom: 0;
}

.modal .modal-fullscreen-sm-down .modal-filters {
  border: 0px;
  border-radius: 0px;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  height: 492px;
  bottom: 0px;
}
</style>
