<template lang="">
  <div>
    <show-monthly-payment
      v-if="show"
      :paid-amount="paidAmount"
      :init-monthly-payment-options="monthlyPaymentOptions"
      :items="items"
      :mwi-banks-type="mwiBanksType"
      :mwi-products-type="mwiProductsType"
      :payment-method-code="paymentMethodCode"
    />
    <loading :loading="!show" />
  </div>
</template>
<script lang="ts">
import { PaymentMethodService } from "@/services/PaymentMethodService";
import ShowMonthlyPayment from "./ShowMonthlyPayment.vue";
import Loading from "../ui/Loading.vue";
import { store } from "@/store";

export default {
  components: {
    Loading,
    ShowMonthlyPayment,
  },
  data: function () {
    return {
      show: false,
      paidAmount: Number,
      monthlyPaymentOptions: Array<any>,
      items: Array<any>,
      mwiBanksType: String,
      mwiProductsType: String,
    };
  },
  computed: {
    paymentMethodCode() {
      return store.getters["checkout/getPaymentMethodCode"];
    },
    tokenizable() {
      return store.getters["checkout/getPaymentTokenizable"];
    },
  },
  mounted() {
    const paymentMethodService = new PaymentMethodService();
    this.show = false;
    paymentMethodService
      .getMonthlyPayment(this.paymentMethodCode, this.tokenizable)
      .then((response: any) => {
        if (response.meta) {
          this.show = true;
          this.paidAmount = response.meta.paid_amount;
          this.monthlyPaymentOptions = response.meta.monthly_payment_options;
          this.items = response.meta.items;
          this.mwiBanksType = response.meta.mwi_banks_type;
          this.mwiProductsType = response.meta.mwi_products_type;
        } else {
          this.show = false;
        }
      });
  },
};
</script>
<style lang=""></style>
