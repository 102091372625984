<template>
  <div :id="modalId" class="modal fade" tabindex="-1" aria-hidden="true">
    <div
      class="modal-dialog modal-dialog-centered modal-md container-confirmation-modal"
    >
      <div class="modal-content">
        <div
          class="modal-header bg-success text-white border-0 d-flex justify-content-between"
        >
          <h5 class="title p-0 m-0">
            {{ modalTitle }}
          </h5>
          <button
            type="button"
            class="button icon-close"
            data-bs-dismiss="modal"
          >
            <i class="bi bi-x-lg"></i>
          </button>
        </div>
        <div class="modal-body">
          <p class="text-justify question m-0 py-2">
            {{ question }}
            <span
              class="fst-italic fw-semibold"
              :class="{ 'd-block ms-1 mt-2': lineBreak }"
            >
              {{ nameInQuestion }}
            </span>
          </p>
        </div>
        <div class="modal-footer border-light">
          <button
            type="button"
            class="button px-4 py-2 btn-delete rounded-1 custom-badge badge-red"
            @click="onDelete(eventName)"
          >
            <i class="bi bi-check-lg"></i>
            <span class="ms-1"> Eliminar </span>
          </button>
          <button
            type="button"
            class="button px-5 py-2 btn-cancel rounded-1 custom-badge badge-dark"
            data-bs-dismiss="modal"
          >
            <i class="bi bi-x-lg"></i>
            <span class="ms-1"> Cancelar </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { Modal } from "bootstrap";
import eventBus from "@/event-bus";

export default {
  props: {
    modalId: {
      type: String,
      default: "",
    },

    modalTitle: {
      type: String,
      default: "Modal de confirmación",
    },

    question: {
      type: String,
      default: "",
    },

    nameInQuestion: {
      type: String,
      default: "",
    },

    lineBreak: {
      type: Boolean,
      default: false,
    },

    eventName: {
      type: String,
      default: "",
    },
  },
  emits: ["delete-address", "delete-bank-card"], //Nombre de los enventos que se pasan por props: eventName
  data() {
    return {
      modal: null as Modal | null,
    };
  },
  watch: {
    modalId() {
      if (this.modal) {
        this.modal.hide();
        this.modal = new Modal(this.$el);
        this.modal.show();
      }
    },
  },
  mounted() {
    const modalEl = this.$el;
    this.modal = new Modal(modalEl);
    modalEl.addEventListener("hidden.bs.modal", this.onModalHidden);
    this.modal.show();
  },
  beforeUnmount() {
    if (this.modal) {
      this.modal.hide();
      this.modal.dispose();
    }
  },
  methods: {
    onDelete(eventName: string) {
      eventBus.emit(eventName);
      this.onModalHidden();
    },

    onModalHidden() {
      document.body.classList.remove("modal-open");
      document.body.style.overflow = "";
    },
  },
};
</script>
