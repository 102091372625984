<template>
  <section class="container-login">
    <div class="bg-auth">
      <div
        class="d-flex justify-content-center align-items-center height-border-options"
      >
        <div class="card border-login">
          <div class="card-body py-5">
            <div class="d-flex justify-content-center">
              <digitalife-logo />
            </div>
            <div class="card-subtitle mt-4 mb-3" data-cy="login-text">
              {{ getTitle("login", "button_reset_password") }}
            </div>
            <div>
              <VeeForm :validation-schema="passwordSchema" @submit="sendLink">
                <div class="mt-4 mb-2">
                  <label for="email" />
                  <Field
                    id="email"
                    v-model="email"
                    class="input-field"
                    type="email"
                    name="email"
                    data-cy="email-field"
                    :placeholder="emailPlaceholderText"
                    @mouseover="onEmailMouseOver"
                    @mouseout="onEmailMouseOut"
                  />
                  <ErrorMessage name="email" class="error" />
                  <span v-if="hasErrorsByName('email')" class="error">
                    <p>
                      {{ getFirstErrorByName("email") }}
                    </p>
                  </span>
                </div>
                <div
                  v-if="loginErrors.length > 0"
                  class="text-center error"
                  data-cy="credentials-error"
                >
                  <ul class="p-0 list-unstyled">
                    <li
                      v-for="(error, index) in loginErrors"
                      :key="index"
                      class="text-danger text-start"
                    >
                      <i class="bi bi-exclamation-circle"></i>
                      {{ error.detail }}
                    </li>
                  </ul>
                </div>
                <div class="d-flex justify-content-center mt-5">
                  <auth-button
                    :button-text="'Enviar enlace de recuperación'"
                    :is-submitting="isSubmitting"
                  />
                </div>
              </VeeForm>
              <div class="mt-3 float-end">
                <base-link :to="'/login'" class="text-reset-password">
                  Regresar al inicio de sesión
                </base-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import AuthButton from "@/components/common/AuthButton.vue";
import DigitalifeLogo from "@/components/common/DigitalifeLogo.vue";
import TabTitle from "@/interfaces/TabTitle";
import { getTitleTranslate } from "@/utils/general";
import { Form as VeeForm, Field, ErrorMessage } from "vee-validate";
import { ErrorItem } from "@/interfaces/ErrorItem";
import { CustomNotifyService } from "@/services/CustomNotifyService";
import auth from "@/services/AuthService";
import BaseLink from "@/components/buttons/BaseLink.vue";
import { AxiosError } from "axios";

const notifyService = new CustomNotifyService();

export default {
  components: {
    AuthButton,
    DigitalifeLogo,
    VeeForm,
    Field,
    ErrorMessage,
    BaseLink,
  },
  setup() {
    const getTitle = function (section: string, name: string) {
      return getTitleTranslate(section, name);
    };

    const passwordSchema = {
      email(value: string) {
        const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
        if (!value) {
          return getTitle("login", "email_required");
        } else if (!regex.test(value)) {
          return getTitle("register", "enter_a_valid_email");
        } else {
          return true;
        }
      },
    };

    return {
      passwordSchema,
      getTitle,
    };
  },

  data() {
    TabTitle("Reestablecer contraseña");
    return {
      email: "",
      emailPlaceholderText: this.getTitle("login", "email_placeholder"),
      loginErrors: [] as ErrorItem[],
      isSubmitting: false,
    };
  },

  mounted() {
    auth.getLoginInitData();
    auth.getRegisterInitData();
  },
  methods: {
    async sendLink() {
      try {
        this.isSubmitting = true;
        const response = await auth.sendPasswordRecoveryLink(this.email);
        console.log(response);
        if (response) {
          setTimeout(() => {
            notifyService.getNotification({
              title: "Link de recuperación de contraseña enviado!",
              text: "¡Revisa tu correo electrónico para continuar!",
            });
          }, 1000); //SetTimeout sólo para simular consumo de la api

          setTimeout(() => {
            this.$router.push("/");
            this.isSubmitting = false;
          }, 1500);
        }
      } catch (error) {
        this.isSubmitting = false;
        if (error instanceof AxiosError && error.response) {
          this.loginErrors = error.response.data.errors;
        } else {
          console.error("Error", error);
        }
      }
    },

    onEmailMouseOver() {
      this.emailPlaceholderText = this.getTitle("login", "email_text_on_hover");
    },

    onEmailMouseOut() {
      this.emailPlaceholderText = this.getTitle("login", "email_placeholder");
    },
    hasErrorsByName(field: string) {
      const errors: ErrorItem[] = this.loginErrors.filter(
        (error: ErrorItem) => {
          return error.source.pointer == "/data/attributes/" + field;
        },
      );
      return errors.length > 0;
    },
    getErrorsByName(field: string) {
      return this.loginErrors.filter((error: ErrorItem) => {
        return error.source.pointer == "/data/attributes/" + field;
      });
    },
    getFirstErrorByName(field: string) {
      const errors = this.getErrorsByName(field);
      if (this.hasErrorsByName(field) && errors[0].detail) {
        return errors[0].detail;
      }
      return "";
    },
  },
};
</script>

<style lang="scss" scoped>
@use "@/scss/auth/auth";
</style>
