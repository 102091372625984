<template>
  <div>
    <div v-if="detailedMode" class="card mt-3">
      <h6 class="text-secondary m-3 font-weight-normal">
        {{ getTitle("cart", "selection_indication") }}
      </h6>
      <div class="m-3">
        <div
          v-for="item in itemsWithPlanInfo"
          :key="item.id"
          class="d-flex mt-2"
        >
          <div
            class="col-2 px-1 d-flex justify-content-center align-items-center"
          >
            <image-product
              :image="item.attributes.small_cover_url"
              :max-height="80"
              class="img-cart"
            />
          </div>
          <div
            class="me-3 d-flex justify-content-between align-items-center flex-grow-1"
          >
            <div>
              <base-link :to="item.links.self" class="name-parent-element">
                <p class="item-name px-lg-0 pe-sm-5 ps-lg-1 col-md-auto m-0">
                  {{ formattedProductName(item.name) }}
                </p>
              </base-link>
              <div
                v-if="item.max_months <= 1"
                class="small text-info mt-2 font-weight-light"
              >
                {{
                  getTitleOptions("cart", "product_without_months", {
                    ":price": formattedPrice(item.selected_plan.price),
                  })
                }}
              </div>
              <div
                v-else-if="
                  item.selected_plan.with_interest ||
                  item.selected_plan.with_discount
                "
                class="small text-success mt-2 font-weight-light"
              >
                {{
                  getTitleOptions(
                    "cart",
                    item.selected_plan.with_interest
                      ? "product_selected_plan_interest"
                      : "product_selected_plan",
                    {
                      ":months": item.selected_plan.months,
                      ":price": formattedPrice(item.selected_plan.price),
                    },
                  )
                }}
              </div>
              <div
                v-else-if="item.selected_plan.with_wallet"
                class="small text-success mt-2 font-weight-light"
              >
                {{
                  getTitleOptions("cart", "short_wallet_amount", {
                    ":amount": formattedPrice(item.selected_plan.wallet_amount),
                  })
                }}
              </div>
              <div v-else class="small text-info mt-2 font-weight-light">
                {{
                  getTitleOptions(
                    "cart",
                    item.selected_plan.with_interest
                      ? "product_unselected_plan_interest"
                      : "product_unselected_plan",
                    {
                      ":price": formattedPrice(item.selected_plan.price),
                      ":max_months": item.max_months,
                    },
                  )
                }}
              </div>
            </div>
            <div class="text-end d-flex justify-content-between flex-grow-1">
              <span
                v-if="
                  isNaN(
                    item.attributes?.total ? item.attributes.total : item.total,
                  )
                "
                :class="'price-with-tax-green'"
              >
                {{
                  formattedPrice(
                    item.attributes?.total ? item.attributes.total : item.total,
                  )
                }}
              </span>
              <span v-else :class="'price-with-tax-green'">
                {{
                  formattedPrice(
                    item.attributes?.total ? item.attributes.total : item.total,
                  )
                }}
              </span>
              <p class="my-1 text-lowercase">
                {{ item.quantity ? item.quantity : 0 }}
                {{
                  item.attributes.quantity > 1
                    ? item.attributes.unit_name + "s"
                    : item.attributes.unit_name
                }}
              </p>
              <p
                class="vat-included text-lowercase"
                :class="'vat-included-green'"
              >
                {{ getTitle("cart", "vat_included") }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="alert alert-warning border"
      :class="{ 'text-danger': recalculationEffectTid }"
      :style="[
        recalculationEffectTid ? { 'border-color': '#f00 !important' } : '',
      ]"
    >
      <span
        v-html="
          getTitleOptions('cart', 'distributed_offset_amount_description', {
            ':count': selectedPlans.length,
          })
        "
      />
    </div>
    <template
      v-for="option in monthlyPaymentOptionsFixed"
      :key="option.initialIndex"
    >
      <div class="card mt-3">
        <div class="m-3">
          <div
            v-for="item in getOptionItems(option.initialIndex)"
            :key="item.id"
            class="d-flex mt-2"
          >
            <div class="col-2 px-1">
              <image-product
                :image="item.attributes.small_cover_url"
                :max-height="80"
                class="img-cart"
              />
            </div>
            <div class="me-3 d-flex justify-content-between flex-grow-1">
              <router-link
                :to="{
                  name: 'product',
                  params: { slug: item.attributes.slug },
                }"
                class="name-parent-element mt-3 text-decoration-none"
              >
                <p class="item-name px-lg-0 pe-sm-5 ps-lg-1 col-md-auto m-0">
                  {{ formattedProductName(item.name) }}
                </p>
              </router-link>
              <div class="text-end d-none d-lg-block d-flex">
                <span
                  v-if="
                    isNaN(
                      item.attributes?.total
                        ? item.attributes.total
                        : item.total,
                    )
                  "
                  :class="'price-with-tax-green'"
                >
                  {{
                    formattedPrice(
                      item.attributes?.total
                        ? item.attributes.total
                        : item.total,
                    )
                  }}
                </span>
                <span v-else :class="'price-with-tax-green'">
                  {{
                    formattedPrice(
                      item.attributes?.total
                        ? item.attributes.total
                        : item.total,
                    )
                  }}
                </span>
                <p class="my-1 text-lowercase">
                  {{ item.quantity ? item.quantity : 0 }}
                  {{
                    item.attributes.quantity > 1
                      ? item.attributes.unit_name + "s"
                      : item.attributes.unit_name
                  }}
                </p>
                <p
                  class="vat-included text-lowercase"
                  :class="'vat-included-green'"
                >
                  {{ getTitle("cart", "vat_included") }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <h6
          v-if="hasSelectedAnotherPlanWithDuplicatedItem(option.initialIndex)"
          class="alert alert-warning m-3"
        >
          {{ getTitle("cart", "warn_duplicated_plan_selection") }}
        </h6>
        <div class="list-group m-3">
          <a
            v-for="(plan, planIndex) in option.plans"
            :key="planIndex"
            href="#"
            class="list-group-item list-group-item-action overflow-hidden position-relative"
            :style="[
              plan.disabled_by_amount
                ? { 'pointer-events': 'none', color: '#777' }
                : '',
            ]"
            @click.prevent="choosePlan(option.initialIndex, planIndex)"
          >
            <div
              v-if="plan.disabled_by_amount"
              class="bg-secondary position-absolute w-100 h-100 start-0 top-0"
              style="opacity: 0.1"
            />
            <div
              class="px-1 h-100 bg-turcoise position-absolute start-0 top-0"
              :class="{
                invisible: !isPlanSelected(option.initialIndex, planIndex),
              }"
            />
            <div class="d-flex align-items-center">
              <div
                class="d-flex align-items-center justify-content-around h-100 courier-section-radio"
              >
                <input
                  type="radio"
                  class="custom-radio-input green-radio-input"
                  :checked="isPlanSelected(option.initialIndex, planIndex)"
                />
                <label for="select-direction" class="custom-radio-label" />
              </div>
              <!-- <div 
                                class="rounded-circle border shadow-sm p-1 flex-shrink-0 shape-small" 
                                :class="{ 'border-primary': isPlanSelected(option.initialIndex, planIndex), 'invisible': plan.disabled_by_amount }"
                            >
                                <div 
                                    class="w-100 h-100 rounded-circle shadow-sm" 
                                    :class="[ isPlanSelected(option.initialIndex, planIndex) ? 'bg-primary' : 'bg-white' ]"
                                />
                            </div> -->
              <div
                class="me-lg-3 d-flex justify-content-between align-items-center flex-grow-1"
              >
                <div class="row w-50">
                  <div
                    class="col-12 col-lg-4 d-flex align-items-start align-items-lg-center"
                  >
                    <div
                      class="text-danger spinner-border spinner-border-sm fade text-start"
                      :class="{
                        show:
                          isPlanSelected(option.initialIndex, planIndex) &&
                          recalculationEffectTid,
                      }"
                    />
                    <div style="width: 40px; text-align: right">
                      <strong> {{ plan.months }} x </strong>
                    </div>
                    <div
                      v-if="
                        plan.months == 1 &&
                        plan.discount_percent < 0.1 &&
                        plan.original_discount_percent > 0.1
                      "
                      class="text-nowrap"
                    >
                      <span
                        class="mx-1"
                        v-html="formattedPrice(plan.original_price)"
                      />
                    </div>
                    <div v-else class="text-nowrap">
                      <span class="mx-1" v-html="formattedPrice(plan.price)" />
                    </div>
                  </div>
                  <div
                    class="col-12 col-lg-8 flex-nowrap pl-3 d-none d-lg-block"
                  >
                    <small>
                      {{ getTitle("cart", "plan_description") }}
                    </small>
                    <div
                      v-if="plan.min_amount > 0"
                      class="text-condensed small pt-1"
                      :class="[
                        plan.disabled_by_amount
                          ? 'text-danger strong'
                          : 'text-warning',
                      ]"
                    >
                      <i
                        v-if="plan.disabled_by_amount"
                        class="fa fa-exclamation"
                      />
                      <span
                        v-html="
                          'Monto mínimo: ' + formattedPrice(plan.min_amount)
                        "
                      />
                    </div>
                  </div>
                </div>
                <div class="text-end text-nowrap">
                  <div
                    v-if="
                      plan.months == 1 &&
                      plan.discount_percent < 0.1 &&
                      plan.original_discount_percent > 0.1
                    "
                    class="text-muted text-condensed"
                  >
                    <strong v-html="formattedPrice(plan.original_amount)" />
                  </div>
                  <div v-else class="text-muted text-condensed">
                    <strong v-html="formattedPrice(plan.amount)" />
                  </div>
                  <div
                    v-if="
                      plan.months == 1 &&
                      (plan.with_interest ||
                        plan.discount_percent > 0.1 ||
                        plan.original_discount_percent > 0.1)
                    "
                    class="text-danger text-condensed small"
                  >
                    <span>
                      {{ getTitle("cart", "one_payment_price") }}
                    </span>
                  </div>
                  <div
                    v-if="plan.months == 1 && plan.with_wallet"
                    class="text-danger text-condensed small"
                  >
                    <span>
                      {{
                        getTitleOptions("cart", "short_wallet_amount", {
                          ":amount": formattedPrice(plan.wallet_amount),
                        })
                      }}
                    </span>
                  </div>
                  <div
                    v-else-if="plan.months > 1 && !plan.with_interest"
                    class="text-success text-condensed small"
                  >
                    <span>
                      {{ getTitle("cart", "mwi_payment_price") }}
                    </span>
                  </div>
                  <div
                    v-else-if="plan.months > 1 && plan.with_interest"
                    class="text-success text-condensed small"
                  >
                    <span>
                      {{ getTitle("cart", "mi_payment_price") }}
                    </span>
                  </div>
                  <div
                    v-if="plan.removed_shipping_promotion"
                    class="text-warning text-condensed small"
                  >
                    No aplica envío gratis
                    <a
                      href="#"
                      onclick="return false;"
                      class="text-warning position-relative z-index-10"
                      data-toggle="tooltip"
                      data-html="true"
                      :title="
                        'Aplica solo en compras mayores a ' +
                        formattedPrice(initialShippingMinAmount)
                      "
                    >
                      *
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex d-lg-none mt-1">
              <div class="d-flex flex-row justify-content-between w-100">
                <div>
                  <small>
                    {{ getTitle("cart", "plan_description") }}
                  </small>
                </div>
                <div
                  v-if="plan.min_amount > 0"
                  class="text-condensed small pt-1"
                  :class="[
                    plan.disabled_by_amount
                      ? 'text-danger strong'
                      : 'text-warning',
                  ]"
                >
                  <i v-if="plan.disabled_by_amount" class="fa fa-exclamation" />
                  <span
                    v-html="'Monto mínimo: ' + formattedPrice(plan.min_amount)"
                  />
                </div>
              </div>
            </div>
            <small
              v-if="
                plan.months == 1 &&
                (paymentMethodCode == 'paypal_v2' ||
                  paymentMethodCode == 'paypal_card_v2')
              "
              class="text-info"
            >
              {{
                getTitleOptions("cart", "discount_one_payment", {
                  ":price": formattedPrice(plan.wallet_amount_one_pay),
                })
              }}
            </small>
          </a>
        </div>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import ImageProduct from "../ui/ImageProduct.vue";
import BaseLink from "../buttons/BaseLink.vue";
import {
  formatPrice,
  getTitleTranslate,
  getTitleTranslateWithOptions,
} from "../../utils/general";
import { mapGetters } from "vuex";
import { store } from "@/store";
import { nextTick } from "vue";

export default {
  components: {
    BaseLink,
    ImageProduct,
  },
  props: {
    paidAmount: {
      type: Number,
      default: 0,
    },
    initMonthlyPaymentOptions: {
      type: Array<any>,
      default: () => [],
    },
    isTransaction: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: () => [],
    },
    mwiBanksType: {
      type: Number,
      default: 0,
    },
    mwiProductsType: {
      type: Number,
      default: 0,
    },
    paymentMethodCode: {
      type: String,
      default: "",
    },
    // Change to true if you want to show detailed monthly plan selection.
    detailedMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // Current selected plans where index is monthly option index and value is the selected option plan index.
      // selectedPlans: this.initMonthlyPaymentOptions.map((_op:any) => null) as Array<any>,
      // Items that have been inside in a selected plan.
      dirtyItems: null as null | any,
      initialSubtotal: 0,
      initialTotal: 0,
      initialInsurance: 0,
      initialShipping: 0,
      initialShippingMinAmount: 0,
      monthlyPaymentOptions: [...this.initMonthlyPaymentOptions],
      recalculationEffectTid: null as any | null,
      itemsWithAnyOption: this.items.filter((it: any) =>
        this.initMonthlyPaymentOptions.find((op) =>
          op.items.find((thatId: number) => thatId == it.id),
        ),
      ),
    };
  },
  computed: {
    monthlyPaymentOptionsIndexed() {
      return this.monthlyPaymentOptions.map((op, initialIndex) =>
        Object.assign({ initialIndex }, op),
      );
    },
    // Filter plans without duplicated items between them.
    monthlyPaymentOptionsWithoutDuplicatedItems() {
      const addedItems: Array<any> = [];
      return this.monthlyPaymentOptionsIndexed.filter((op: any) => {
        if (
          !addedItems.find((thisId) =>
            op.items.find((thatId: string) => thatId === thisId),
          )
        ) {
          addedItems.push(...op.items);
          return true;
        }
      });
    },
    monthlyPaymentOptionsFixed() {
      return this.detailedMode
        ? this.monthlyPaymentOptionsIndexed
        : this.monthlyPaymentOptionsWithoutDuplicatedItems;
    },
    monthlyPaymentOptionsWithManyMonths() {
      return this.monthlyPaymentOptionsFixed.filter(
        (op: any) => op.plans.length,
      );
    },
    monthlyPaymentOptionsWithOneMonth() {
      return this.monthlyPaymentOptionsFixed.filter(
        (op: any) => op.plans.length == 1 && op.plans[0].months == 1,
      );
    },
    itemsWithOneMonth() {
      return this.monthlyPaymentOptionsWithOneMonth
        .flatMap((op) => this.getOptionItems(op.initialIndex)) // get all items flattened
        .filter(
          (item) =>
            !this.monthlyPaymentOptionsWithManyMonths.find((op: any) =>
              op.items.find((thatId: string) => thatId === item.id),
            ),
        );
    },
    itemsWithPlanInfo() {
      return this.itemsWithAnyOption.map((item: any) => {
        let selectedOptionIndex = this.selectedPlans.findIndex(
          (pIndex: number, oIndex: number) =>
            pIndex !== null &&
            this.monthlyPaymentOptions[oIndex].items.find(
              (thatId: string) => thatId === item.id,
            ),
        );
        const selectedPlanIndex: any =
          selectedOptionIndex !== -1
            ? this.selectedPlans[selectedOptionIndex]
            : -1;
        const selectedOption =
          selectedPlanIndex !== -1
            ? this.monthlyPaymentOptions[selectedOptionIndex]
            : null;
        const selectedPlan =
          selectedOption !== null
            ? selectedOption.plans[selectedPlanIndex]
            : null;
        const selectedOptionMonths = selectedPlan ? selectedPlan.months : 1;
        const interestRate =
          1 +
          (selectedPlan && selectedPlan.with_interest
            ? selectedPlan.interest_percent / 100
            : 0);
        const discountRate =
          1 -
          (selectedPlan && selectedPlan.with_discount
            ? selectedPlan.discount_percent / 100
            : 0);
        return {
          selected_plan: {
            item_id: item.id,
            months: selectedOptionMonths,
            price: parseFloat(
              (
                (item.total * discountRate * interestRate) /
                selectedOptionMonths
              ).toFixed(2),
            ),
          },
          ...item,
        };
      });
    },
    moduleState() {
      return this.isTransaction ? "transaction" : "cart";
    },
    ...mapGetters("checkout", {
      selectedPlans: "getMonthlyPlans",
    }),
  },
  watch: {
    // dirtyItems() {
    //     // if (this.dirtyItems.find((dirty:any) => !dirty) === false) {
    //     //     this.setSubmitWarningText(this.getTitle('cart', 'non_dirty_warning'));
    //     // } else {
    //     //     this.setSubmitWarningText(null);
    //     //     this.setIsFormValid(true);
    //     // }
    // },
    // initMonthlyPaymentOptions (newInitMonthlyPaymentOptions) {
    //     const selectedPlans = newInitMonthlyPaymentOptions.map((_op:any) => null);
    //     store.commit('checkout/setMonthlyPlans', selectedPlans);
    // }
  },
  created() {
    // const storeKey = (this.isTransaction ? 'transaction' : 'cart');
    // Importante para el carrito de sesión que permita calcular en base al total sin oferta de meses aplicada ya que esta se aplicará posteriormente sino se duplicará.
    // Initially items that do not have many months will be dirty automatically.
    if (this.selectedPlans.length != this.monthlyPaymentOptions.length) {
      const selectedPlans = this.initMonthlyPaymentOptions.map(() => null);
      store.commit("checkout/setMonthlyPlans", selectedPlans);
    }
    this.dirtyItems = this.itemsWithPlanInfo.map(
      (it) => it.selected_plan.months === 1,
    );
    this.initialSubtotal = store.getters[this.moduleState + "/geSsubtotal"];
    this.initialTotal = store.getters[this.moduleState + "/getTotal"];
    this.initialInsurance =
      store.getters[this.moduleState + "/getShippingInsurance"];
    this.initialShipping = store.getters[this.moduleState + "/getShipping"];
    this.initialShippingMinAmount =
      store.getters[this.moduleState + "/getShippingMinAmount"];
    // By default select first plan of each option and calculate each plan to visually see final amounts.
    this.monthlyPaymentOptions.forEach((op, opIndex) => {
      op.plans.forEach((_p: any, planIndex: number) =>
        this.choosePlan(opIndex, planIndex),
      );
      this.choosePlan(opIndex, 0);
    });
  },
  mounted() {
    // $('[data-toggle="tooltip"]').tooltip();
  },
  methods: {
    // ...mapMutations([
    //     'setSubmitWarningText',
    //     'setIsFormValid',
    // ]),
    isPlanSelected(optionIndex: number, planIndex: number) {
      return this.selectedPlans[optionIndex] === planIndex;
    },
    // Return true if there are another plan with at least a duplicated item in indicated option.
    hasSelectedAnotherPlanWithDuplicatedItem(optionIndex: number) {
      return this.monthlyPaymentOptions[optionIndex].items.find(
        (itemId: number) =>
          this.monthlyPaymentOptions.find(
            (thatOp, thatOpIndex) =>
              thatOpIndex !== optionIndex &&
              thatOp.items.find(
                (thatItemId: number) => thatItemId === itemId,
              ) &&
              this.selectedPlans[thatOpIndex] !== null,
          ),
      );
    },
    choosePlan(optionIndex: number, planIndex: number) {
      // const op = this.monthlyPaymentOptions[optionIndex];
      // const plan = op.plans[planIndex];
      // const requiredItems = plan.required_item_ids;
      const selectedPlans = this.selectedPlans.map((p: number, o: number) =>
        o === optionIndex ? planIndex : p,
      );
      store.commit("checkout/setMonthlyPlans", selectedPlans);
      // this.monthlyPaymentOptions.forEach((thatOp, thatOpIndex) => {
      //   if (thatOpIndex !== optionIndex) {
      //     // move other required products to apply months with required products
      //     requiredItems.forEach((itemId: number) => {
      //       if (
      //         thatOp.items.find((thatItemId: number) => thatItemId === itemId)
      //       ) {
      //         const newSelectPlan = thatOp.plans.findIndex(
      //           (thatPlan: any) =>
      //             thatPlan.months === plan.months &&
      //             !thatPlan.disabled_by_amount,
      //         );
      //         store.commit("checkout/setMonthlyPlansByIndex", {
      //           value: newSelectPlan,
      //           index: thatOpIndex,
      //         });
      //       }
      //     });
      //     // move other plan to the same month or first month if that plan had previous month in this plan and required same products
      //     thatOp.plans.forEach((thatPlan: any, thatPlanIndex: any) => {
      //       if (
      //         thatPlan.months != plan.months &&
      //         thatPlanIndex === this.selectedPlans[thatOpIndex] &&
      //         !thatPlan.disabled_by_amount
      //       ) {
      //         const thatRequiredItems = thatPlan.required_item_ids;
      //         thatRequiredItems.forEach((thatItemId: number) => {
      //           if (op.items.find((itemId: number) => itemId === thatItemId)) {
      //             let newThatPlanIndex = 0;
      //             thatOp.plans.some((thatPlan: any, i: any) => {
      //               if (
      //                 thatPlan.months === plan.months &&
      //                 !thatPlan.disabled_by_amount
      //               ) {
      //                 newThatPlanIndex = i;
      //                 return true;
      //               }
      //             });
      //             this.selectedPlans[thatOpIndex] = newThatPlanIndex;
      //             store.commit("checkout/setMonthlyPlansByIndex", {
      //               value: newThatPlanIndex,
      //               index: thatOpIndex,
      //             });
      //           }
      //         });
      //       }
      //     });
      //   }
      // });
      this.monthlyPaymentOptions[optionIndex].items.forEach(
        (itemId: number) => {
          // Deselect plans where has a duplicated item from selected plan.
          this.monthlyPaymentOptions.forEach((thatOp, thatOpIndex) => {
            if (
              thatOpIndex !== optionIndex &&
              thatOp.items.find((thatItemId: number) => thatItemId === itemId)
            ) {
              // this.selectedPlans[thatOpIndex] = null;
              store.commit("checkout/setMonthlyPlansByIndex", {
                value: null,
                index: thatOpIndex,
              });
            }
          });
          // Dirty items in selected plan.
          this.items.forEach((item: any, itemIndex) => {
            if (item.id === itemId) {
              this.dirtyItems = this.dirtyItems.map(
                (dirty: any, idx: number) => (idx === itemIndex ? true : dirty),
              );
            }
          });
        },
      );
      this.recalculatePlans();
      this.showRecalculationEffect();
    },
    recalculatePlans() {
      // Calculate new cart subtotal from selected plans.
      let newSubtotal = 0;
      let paidSubtototal = 0;
      let paidDisc = 0;
      const takedItems: Array<any> = [];
      this.selectedPlans.forEach((planIndex: number, opIndex: number) => {
        const op = this.monthlyPaymentOptions[opIndex];
        const plan = op.plans[planIndex || 0];
        newSubtotal += plan.subtotal;
        op.items.forEach((itemId: any) => (takedItems[itemId] = itemId));
      });
      this.items.forEach((item: any) => {
        if (!takedItems[item.id]) {
          paidSubtototal += item.subtotal;
          paidDisc += item.discount;
        }
      });
      const ivaPercent = store.getters[this.moduleState + "/getIvaPercentage"];
      const extraPaid = (paidSubtototal - paidDisc) * (1 + ivaPercent / 100);
      store.dispatch(this.moduleState + "/setShipping", this.initialShipping);
      store.dispatch(
        this.moduleState + "/setSubtotalAndRecalcInsurance",
        newSubtotal + paidSubtototal,
      );
      // Calculate extra cost like shipping and insurance.
      const disc = store.getters[this.moduleState + "/getPromotionDiscount"];
      const subtotal = store.getters[this.moduleState + "/getSubtotal"];
      const total = store.getters[this.moduleState + "/getTotal"];
      const extra =
        total - (subtotal - disc) * (1 + ivaPercent / 100) + extraPaid;
      this.selectedPlans.forEach((planIndex: number, opIndex: number) => {
        const op = this.monthlyPaymentOptionsFixed[opIndex];
        const plan = op.plans[planIndex || 0];
        // Save initial plan amount to recalculate correctly.
        if (plan._init_amount === undefined) {
          plan._init_amount = plan.items_amount;
        }
        if (plan._original_init_amount === undefined) {
          plan._original_init_amount = plan.original_items_amount;
        }
        // Distribute extra cost
        const distRatio = plan.distribution_ratio;
        const distExtra = extra * distRatio;
        const distPaid = this.paidAmount * distRatio;
        plan.amount = +(plan._init_amount + distExtra - distPaid).toFixed(2);
        plan.price = +(plan.amount / plan.months).toFixed(2) * 1;
        plan.offset_amount = distExtra - distPaid;

        plan.original_amount =
          +(plan._original_init_amount + distExtra - distPaid).toFixed(2) * 1;
        plan.original_price =
          +(plan.original_amount / plan.months).toFixed(2) * 1;
        plan.original_offset_amount = distExtra - distPaid;
      });
      const tax = store.getters[this.moduleState + "/getTax"];
      const shippingDiscount =
        store.getters[this.moduleState + "/getShippingDiscount"];
      if (
        this.initialShippingMinAmount >
          +(subtotal + tax - disc).toFixed(2) * 1 &&
        shippingDiscount
      ) {
        store.dispatch(this.moduleState + "/setShipping", shippingDiscount);
      }
    },
    getOptionItems(optionIndex: number) {
      return this.monthlyPaymentOptions[optionIndex].items
        .map((itemId: string) => this.items.find((it: any) => it.id == itemId))
        .filter((item: any) => item);
    },
    showRecalculationEffect() {
      if (this.recalculationEffectTid) {
        clearInterval(this.recalculationEffectTid);
        this.recalculationEffectTid = setTimeout(async () => {
          this.recalculationEffectTid = null;
          await nextTick();
        }, 300);
      }
    },
    formattedPrice(price: number) {
      return formatPrice(price);
    },
    formattedProductName(productName: string): string {
      if (typeof productName === "string" && productName.trim().length > 0) {
        return this.capitalizeFirstLetter(productName);
      }
      return "";
    },
    capitalizeFirstLetter(str: string): string {
      if (typeof str !== "string") {
        console.error("Expected a string but received:", str);
        return "";
      }
      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    },
    getTitle(section: string, name: string) {
      return getTitleTranslate(section, name);
    },
    getTitleOptions(section: string, name: string, options: unknown) {
      return getTitleTranslateWithOptions(section, name, options);
    },
  },
};
</script>
