import { store } from "@/store";
import axios, { AxiosError, AxiosResponse, AxiosRequestConfig } from "axios";
import {
  VITE_API_ENDPOINT,
  SHIPPING_DELIVERY,
  SHIPPING_PICK_UP,
} from "@/config/constants.js";

export class ShippingService {
  public async getCouriers() {
    const paymentMethodCode = store.getters["checkout/getPaymentMethodCode"];
    const selectedAddress = store.getters["checkout/getCustomerAddress"];
    const zipCode = selectedAddress?.postal_code;

    if (zipCode) {
      const params = {
        zip_code: zipCode,
        payment_method_code: paymentMethodCode,
      };
      const url = `/eshop/couriers`;
      const response = await axios.get(url, { params });
      if (response) {
        return response.data;
      }
    }
    return null;
  }

  public async getPickupStores(origin?: string) {
    const paymentMethodCode = store.getters["checkout/getPaymentMethodCode"];

    if (origin) {
      const params = {
        pickup: true,
        payment_method_code: paymentMethodCode,
        origin: origin,
        cart: true,
      };
      const url = `/eshop/pickup-stores`;
      const response = await axios.get(url, { params });

      return response;
    }
    return null;
  }

  public async getSettlemant(postalCode: number) {
    const type = "postal-codes";
    const options: AxiosRequestConfig = {
      baseURL: VITE_API_ENDPOINT,
      headers: {
        Accept: "application/vnd.api+json",
      },
    };
    const url = `/shop-v1/${type}/${encodeURIComponent(postalCode)}?include=state,settlements,settlements.city,settlements.municipality`;
    const response = await axios.get(url, options);
    if (!(response instanceof AxiosError)) {
      return response.data;
    } else if (response.response) {
      const resp: AxiosResponse = response.response;
      return resp.data;
    } else {
      return null;
    }
  }

  public async storeShipping() {
    const paymentMethodCode = store.getters["checkout/getPaymentMethodCode"];
    const customerAddress = store.getters["checkout/getCustomerAddress"];
    const courier = store.getters["checkout/getCourier"];
    const isShipping = store.getters["checkout/getIsShipping"];
    const storeBranch = store.getters["checkout/getStore"];
    const person = store.getters["checkout/getPickupPerson"];
    const hasShippingInsurance =
      store.getters["checkout/getHasShippingInsurance"];
    let body = {};
    if (isShipping) {
      body = {
        shipping_type: SHIPPING_DELIVERY,
        payment_method_code: paymentMethodCode,
        delivery_address_id: customerAddress.id,
        courier_id: courier.id,
        apply_shipping_insurance: hasShippingInsurance ? 1 : 0,
      };
    } else {
      body = {
        shipping_type: SHIPPING_PICK_UP,
        payment_method_code: paymentMethodCode,
        pickup_store_id: storeBranch.id,
        pickup_person_first_name: person.name,
        pickup_person_last_name: person.lastName,
        pickup_person_mother_last_name: person.motherLastName,
        pickup_person_phone: person.telephone,
      };
    }

    const url = `/eshop/shippings`;
    const response = await axios.post(url, body);
    if (!(response instanceof AxiosError)) {
      return response.data;
    } else if (response.response) {
      const resp: AxiosResponse = response.response;
      return resp.data;
    } else {
      return null;
    }
  }
}

export const shippingService = new ShippingService();
