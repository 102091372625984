<template lang="">
  <base-transition>
    <div v-if="isActive && isOpen" class="triangle position-absolute" />
  </base-transition>
  <button
    v-element-hover="onHover"
    class="animate-hover-border text-decoration-none p-2 text-primary position-relative d-flex justify-content-center align-items-center w-100 h-100 border-0 text-uppercase"
    :class="[isHovered ? 'bg-body-secondary' : 'bg-transparent']"
    @click="activeMenu"
  >
    <span
      class="ml-2 fs-7 text-center"
      :class="{ 'text-white': isActive && isOpen }"
    >
      {{ text }}
    </span>
  </button>
</template>
<script lang="ts">
import BaseTransition from "@/components/common/BaseTransition.vue";
import { vElementHover } from "@vueuse/components";
export default {
  directives: {
    "element-hover": vElementHover,
  },
  components: {
    BaseTransition,
  },
  props: {
    text: {
      type: String,
      default: "",
    },
    uniqueId: {
      type: String,
      default: "",
    },
    active: {
      type: String,
      default: "",
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["active-menu", "change-open"],
  data: function () {
    return {
      isHovered: false,
    };
  },
  computed: {
    isActive() {
      return this.uniqueId == this.active;
    },
  },
  methods: {
    activeMenu() {
      this.$emit("active-menu", this.uniqueId);
    },
    changeOpen() {
      this.$emit("change-open", true);
    },
    onHover(state: boolean) {
      this.isHovered = state;
    },
  },
};
</script>
<style lang="sass" scoped>
.triangle
    bottom: -16px
    right: 50%
    width: 0
    height: 0
    border-right: 6px solid transparent
    border-left: 6px solid transparent
    border-bottom: 10px solid var(--bs-red)
</style>
