import { ResourceIdObjMeta } from "./ResourceIdObjMeta";
import { TransactionDetail } from "./TransactionDetail";

export interface ITransaction {
  id: string | null;
  folio: string | null;
  simple_payment_status_text: string;
  subtotal: number | null;
  discount: number | null;
  iva: number | null;
  shipping_cost: number | null;
  shipping_insurance_cost: number | null;
  coupon_discount: number | null;
  cash_discount: number | null;
  one_pay_discount: number | null;
  total: number | null;
  customer_wallet_promotion_amount: number | null;
  iva_percentage: number | null;
  type: number | null;
  status: number | string | null;
  payment_status: number | null;
  payment_confirmed_at: string | null;
  arrival_status: number | null;
  customer_name: string | null;
  customer_email: string | null;
  customer_phone: string | null;
  drawer_opening_id: string | null;
  salesperson_id: string | null;
  customer_id: string | null;
  delivery_address_id: string | null;
  billing_address_id: string | null;
  invoice_no_delivered: number | null;
  pickup_id: string | null;
  coupon_id: string | null;
  shipping_promotion_id: string | null;
  point_card_id: string | null;
  transaction_id: string | null;
  courier_id: string | null;
  is_draft: boolean;
  quote_days: number | null;
  quote_sent_at: string | null;
  has_messages: boolean;
  generated_points: number | null;
  order_comments: string | null;
  cancel_comments: string | null;
  request_ip: string | null;
  is_for_web: boolean;
  reprinted: number | null;
  wallet_reprinted: number | null;
  mkp_order_id: string | null;
  recalculated_at: string | null;
  fully_packed_at: string | null;
  payment_method: string | null;
  courier: string | null;
  resourceIdObjMeta: ResourceIdObjMeta | null;
  relationships: TransactionDetail | null;
}

export default class Transaction implements ITransaction {
  public id: string | null = null;
  public folio: string | null = null;
  public simple_payment_status_text: string;
  public subtotal: number | null = null;
  public discount: number | null = null;
  public iva: number | null = null;
  public shipping_cost: number | null = null;
  public shipping_insurance_cost: number | null = null;
  public coupon_discount: number | null = null;
  public cash_discount: number | null = null;
  public one_pay_discount: number | null = null;
  public total: number | null = null;
  public customer_wallet_promotion_amount: number | null = null;
  public iva_percentage: number | null = null;
  public type: number | null = null;
  public status: number | string | null = null;
  public payment_status: number | null = null;
  public payment_confirmed_at: string | null = null;
  public arrival_status: number | null = null;
  public customer_name: string | null = null;
  public customer_email: string | null = null;
  public customer_phone: string | null = null;
  public drawer_opening_id: string | null = null;
  public salesperson_id: string | null = null;
  public customer_id: string | null = null;
  public delivery_address_id: string | null = null;
  public billing_address_id: string | null = null;
  public invoice_no_delivered: number | null = null;
  public pickup_id: string | null = null;
  public coupon_id: string | null = null;
  public shipping_promotion_id: string | null = null;
  public point_card_id: string | null = null;
  public transaction_id: string | null = null;
  public courier_id: string | null = null;
  public is_draft: boolean = false;
  public quote_days: number | null = null;
  public quote_sent_at: string | null = null;
  public has_messages: boolean = false;
  public generated_points: number | null = null;
  public order_comments: string | null = null;
  public cancel_comments: string | null = null;
  public request_ip: string | null = null;
  public is_for_web: boolean = false;
  public reprinted: number | null = null;
  public wallet_reprinted: number | null = null;
  public mkp_order_id: string | null = null;
  public recalculated_at: string | null = null;
  public fully_packed_at: string | null = null;
  public payment_method: string | null = null;
  public courier: string | null = null;
  public resourceIdObjMeta: ResourceIdObjMeta | null = null;
  public relationships: TransactionDetail | null = null;

  public constructor({
    id,
    folio,
    simple_payment_status_text,
    subtotal,
    discount,
    iva,
    shipping_cost,
    shipping_insurance_cost,
    coupon_discount,
    cash_discount,
    one_pay_discount,
    total,
    customer_wallet_promotion_amount,
    iva_percentage,
    type,
    status,
    payment_status,
    payment_confirmed_at,
    arrival_status,
    customer_name,
    customer_email,
    customer_phone,
    drawer_opening_id,
    salesperson_id,
    customer_id,
    delivery_address_id,
    billing_address_id,
    invoice_no_delivered,
    pickup_id,
    coupon_id,
    shipping_promotion_id,
    point_card_id,
    transaction_id,
    courier_id,
    is_draft,
    quote_days,
    quote_sent_at,
    has_messages,
    generated_points,
    order_comments,
    cancel_comments,
    request_ip,
    is_for_web,
    reprinted,
    wallet_reprinted,
    mkp_order_id,
    recalculated_at,
    fully_packed_at,
    payment_method,
    courier,
    resourceIdObjMeta,
    relationships,
  }: ITransaction) {
    this.id = id || null;
    this.folio = folio || null;
    this.simple_payment_status_text = simple_payment_status_text;
    this.subtotal = subtotal || null;
    this.discount = discount || null;
    this.iva = iva || null;
    this.shipping_cost = shipping_cost || null;
    this.shipping_insurance_cost = shipping_insurance_cost || null;
    this.coupon_discount = coupon_discount || null;
    this.cash_discount = cash_discount || null;
    this.one_pay_discount = one_pay_discount || null;
    this.total = total || null;
    this.customer_wallet_promotion_amount =
      customer_wallet_promotion_amount || null;
    this.iva_percentage = iva_percentage || null;
    this.type = type || null;
    this.status = status || null;
    this.payment_status = payment_status || null;
    this.payment_confirmed_at = payment_confirmed_at || null;
    this.arrival_status = arrival_status || null;
    this.customer_name = customer_name || null;
    this.customer_email = customer_email || null;
    this.customer_phone = customer_phone || null;
    this.drawer_opening_id = drawer_opening_id || null;
    this.salesperson_id = salesperson_id || null;
    this.customer_id = customer_id || null;
    this.delivery_address_id = delivery_address_id || null;
    this.billing_address_id = billing_address_id || null;
    this.invoice_no_delivered = invoice_no_delivered || null;
    this.pickup_id = pickup_id || null;
    this.coupon_id = coupon_id || null;
    this.shipping_promotion_id = shipping_promotion_id || null;
    this.point_card_id = point_card_id || null;
    this.transaction_id = transaction_id || null;
    this.courier_id = courier_id || null;
    this.is_draft = is_draft || false;
    this.quote_days = quote_days || null;
    this.quote_sent_at = quote_sent_at || null;
    this.has_messages = has_messages || false;
    this.generated_points = generated_points || null;
    this.order_comments = order_comments || null;
    this.cancel_comments = cancel_comments || null;
    this.request_ip = request_ip || null;
    this.is_for_web = is_for_web || false;
    this.reprinted = reprinted || null;
    this.wallet_reprinted = wallet_reprinted || null;
    this.mkp_order_id = mkp_order_id || null;
    this.recalculated_at = recalculated_at || null;
    this.fully_packed_at = fully_packed_at || null;
    this.payment_method = payment_method || null;
    this.courier = courier || null;
    this.resourceIdObjMeta = resourceIdObjMeta || null;
    this.relationships = relationships || null;
  }

  public static fromArray(items: ITransaction[]): Transaction[] {
    return items.map((item: ITransaction) => new Transaction(item));
  }
}
