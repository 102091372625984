<template lang="">
  <div class="px-1 px-lg-2 d-flex justify-content-center">
    <img
      v-if="!imageError"
      v-lazy="image"
      class="image-product mx-auto"
      :style="styleResponsive"
      @error="imageError = true"
    />
    <img
      v-if="imageError"
      class="image-product mx-auto"
      src="/images/imagen-no-disponible.png"
      :style="styleResponsive"
    />
  </div>
</template>
<script lang="ts">
import { ref, onMounted, onUnmounted } from "vue";
export default {
  props: {
    image: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    fontSize: {
      type: String,
      default: "fs-8",
    },
    maxHeight: {
      type: Number,
      default: 50,
    },
    maxSmHeight: {
      type: Number,
      default: 0,
    },
  },
  setup() {
    const viewportWidth = ref(window.innerWidth);
    const viewportHeight = ref(window.innerHeight);

    // Función para actualizar las dimensiones del viewport
    const updateViewportDimensions = () => {
      viewportWidth.value = window.innerWidth;
      viewportHeight.value = window.innerHeight;
    };

    // Agregar un listener para el evento 'resize'
    onMounted(() => {
      window.addEventListener("resize", updateViewportDimensions);
    });

    // Eliminar el listener al desmontar el componente
    onUnmounted(() => {
      window.removeEventListener("resize", updateViewportDimensions);
    });

    return {
      viewportWidth,
      viewportHeight,
    };
  },
  data: function () {
    return {
      imageError: false,
    };
  },
  computed: {
    styleResponsive() {
      if (this.viewportWidth <= 992) {
        return {
          maxHeight:
            (this.maxSmHeight > 0 ? this.maxSmHeight : this.maxHeight) + "px",
        };
      } else {
        return {
          maxHeight: this.maxHeight + "px",
        };
      }
    },
  },
};
</script>
<style lang="sass" scoped>
.image-product
    width: 100%
    object-fit: contain
</style>
